import React, {Component} from 'react';
import UnauthorizedUser from "../../../views/layouts/UnauthorizedUser";
import PageTitle from "../../../components/PageTitle/PageTitle";
import ContainerForm from "./ContainerForm";
import { Alert } from 'react-bootstrap';
import {ALERT_TIMEOUT} from '../../../constants/Values';
import {CONFIG_CONTAINER_GUIDE} from '../../../constants/Links';
import GuideLink from './GuideLink';
import API from "@aws-amplify/api";
import "../../../components/Spinner/spinner.css";
import "./createConfig.css"
import { getAuthorizationHeader } from '@common/Auth';
import UnAuthorizedAccessAlert from "../../../views/layouts/UnAuthorizedAccessAlert";
import {RegionContext} from '@common/RegionContext';
import {constructButaneApiName} from '../../../apiConfig';

class CreateContainer extends Component {
    constructor(props) {
        super(props);
        this.state={
            loading: false,
            noResult: false,
            successfullAdded: false,
            openForbiddenSnackbar: false,
            error: false,
            errorMessage: ""
        }
    }

    createContainer = async (containerData, error) => {
        if(error) {
            return this.setState({error: true, errorMessage: error.message},()=>{
                window.setTimeout(()=>{
                  this.setState({error:false, errorMessage: ""})
                },ALERT_TIMEOUT)
            });
        }

        const init = {
            body: containerData,
            headers: await getAuthorizationHeader()
        }
        
        this.setState({
            loading: true
        });
        return API.post(constructButaneApiName(this.context.region), `/vwp/config/container`, init)
        .then(response => {
            return this.setState({loading: false, successfullAdded: true},()=>{
                window.setTimeout(()=>{
                  this.setState({successfullAdded: false});
                },ALERT_TIMEOUT)
            });
        })
        .catch(error => {
          if (typeof error.response === 'undefined') {
            return this.setState({
              loading: false,
              error: true,
              errorMessage: "Backend type error !"
            })
          }

          if(error.response.status === 403) {
              return this.setState({
                loading: false,
                openForbiddenSnackbar: true
              });
          }

          if(error.response.status >= 500) {
              return this.setState({
                  loading: false,
                  error: true,
                  errorMessage: "Something Went Wrong !"
              });
          }

          return this.setState({
            loading: false,
            error: true,
            errorMessage: "Invalid Parameters !"
          });
          
        });

        
    }

    render() {
        if(this.props.isAuthenticated) {
            if(this.state.loading) {
                return <div className="spinner"></div>;
            }
    
            if(this.state.openForbiddenSnackbar) {
                return <UnauthorizedUser openErrorSnackbar = {true} />;
            }

            let alert = null;
            if(this.state.error) {
                alert = (<Alert
                            onClose={() => {
                            this.setState({ error: false, errorMessage: "" });
                            }}
                            variant="danger"
                            dismissible
                        >
                            {this.state.errorMessage}
                        </Alert>)
            }

            if(this.state.successfullAdded) {
                alert = (<Alert
                            onClose={() => {
                            this.setState({ successfullAdded: false });
                            }}
                            variant="success"
                            dismissible
                        >
                            Successfully Added !
                        </Alert>)
            }
            return (
               <div>
                   {this.props.isExternal && <UnAuthorizedAccessAlert/>}
                   {alert}
                   {!this.props.isExternal && <PageTitle>
                       Create Config Container
                   </PageTitle>}
                   {!this.props.isExternal && <GuideLink link={CONFIG_CONTAINER_GUIDE} />}
                   {!this.props.isExternal && <ContainerForm
                        onSubmit={this.createContainer}
                        clearData={this.state.successfullAdded}
                   />}
               </div>
            )
        }
        this.props.history.push("/login");
        return null;
    }
}

CreateContainer.contextType = RegionContext;

export default CreateContainer;