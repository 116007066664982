import React, { Dispatch, SetStateAction } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import Modal from "@amzn/awsui-components-react/polaris/modal";

import { SelectAmznShipRefIdForm } from "@modules/transfer/common/components";
import { FAILED_DEPART_ITEMS_BASE_PATH } from "@modules/transfer/common/constants/paths";

interface SelectAmznShipRefIdModalProps extends RouteComponentProps {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
}

export const SelectAmznShipRefIdModal = withRouter(
  ({ isVisible, setIsVisible, history }: SelectAmznShipRefIdModalProps) => {
    function constructRedirectPath(amznShipRefId: string) {
      const redirectPath = `${FAILED_DEPART_ITEMS_BASE_PATH}/${amznShipRefId}`;

      setIsVisible(false);
      history.push(redirectPath);
    }

    return (
      <Modal
        header="Select Amazon Shipment Reference ID"
        visible={isVisible}
        onDismiss={() => setIsVisible(false)}
      >
        <SelectAmznShipRefIdForm
          constructRedirectPath={constructRedirectPath}
          containerless={true}
        />
      </Modal>
    );
  }
);
