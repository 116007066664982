import { amazonEmailRegex, genericEmailRegex, inputWarehouseListRegex } from "@constants/Regex";

export interface UserInput {
    email: string;
    firstName: string;
    warehouses: string;
    warehouseErrorMessage: string;
    emailErrorMessage: string;
}

export interface Users {
    users: UserInformation[];
}

export interface UserInformation {
    userEmail: string;
    userStatus?: string;
    firstName: string;
    warehouses: string[];
}

export interface Emails {
    emails: string[];
}

export const WAREHOUSE_ERROR_MESSAGE = "At least 1 warehouse is required for Non-Amazon Employees";
export const EMAIL_ERROR_MESSAGE = "Valid e-mail address is required";
export const EMPTY_STRING = "";

export function updateHeader(items: Array<UserInput>, itemIndex: number, updater: (header: UserInput) => UserInput) {
    return items.map((item, index) => (index === itemIndex ? updater(item) : item));
}

export function validateInput(users: UserInput[]): boolean {
    var isValidInput = true;
    if (users.length === 0) {
        return false;
    }
    users.forEach(user => {
        if (user.warehouseErrorMessage !== EMPTY_STRING ||
            user.emailErrorMessage !== EMPTY_STRING ||
            user.email === EMPTY_STRING) {
            isValidInput = false;
        }
    })
    return isValidInput;
}

export function validateUserInformation(user: UserInput, eventKey: string, eventValue: string): UserInput {
    const validatedUser: UserInput = { email: user.email, warehouses: user.warehouses, firstName: user.firstName, warehouseErrorMessage: user.warehouseErrorMessage, emailErrorMessage: user.emailErrorMessage }
    switch (eventKey) {
        case "emailEvent": {
            if((genericEmailRegex.test(eventValue) && !amazonEmailRegex.test(eventValue) &&
                inputWarehouseListRegex.test(user.warehouses)) || amazonEmailRegex.test(eventValue)) {
                validatedUser.warehouseErrorMessage = EMPTY_STRING
                validatedUser.emailErrorMessage = EMPTY_STRING
            } else if (eventValue.length === 0 || eventValue === EMPTY_STRING) {
                validatedUser.warehouseErrorMessage = EMPTY_STRING
                validatedUser.emailErrorMessage = EMAIL_ERROR_MESSAGE;
            } else if((genericEmailRegex.test(eventValue) && !amazonEmailRegex.test(eventValue) &&
                !inputWarehouseListRegex.test(user.warehouses)) || !genericEmailRegex.test(eventValue)) {
                validatedUser.warehouseErrorMessage = WAREHOUSE_ERROR_MESSAGE;
                validatedUser.emailErrorMessage = EMPTY_STRING;
            }
            validatedUser.email = eventValue;
            break;
        } case "warehousesEvent": {
            if ((inputWarehouseListRegex.test(eventValue) && user.email === EMPTY_STRING) ||
                (inputWarehouseListRegex.test(eventValue) && genericEmailRegex.test(user.email))) {
                validatedUser.warehouseErrorMessage = EMPTY_STRING;
            } else if ((eventValue === EMPTY_STRING && user.email !== EMPTY_STRING &&
                !amazonEmailRegex.test(user.email)) || (genericEmailRegex.test(user.email) &&
                !inputWarehouseListRegex.test(eventValue))) {
                validatedUser.warehouseErrorMessage = WAREHOUSE_ERROR_MESSAGE;
            } else if (user.email === EMPTY_STRING) {
                validatedUser.warehouseErrorMessage = EMPTY_STRING;
            }
            validatedUser.warehouses = eventValue;
            break;
        }
    }
    return validatedUser;
}

export function validateUserEmail(user: UserInput, eventKey: string, eventValue: string): UserInput {
    const validatedUser: UserInput = { email: user.email, warehouses: user.warehouses, firstName: user.firstName, warehouseErrorMessage: user.warehouseErrorMessage, emailErrorMessage: user.emailErrorMessage }
    if(!genericEmailRegex.test(eventValue))
        validatedUser.emailErrorMessage = EMAIL_ERROR_MESSAGE;
    if(genericEmailRegex.test(eventValue))
        validatedUser.emailErrorMessage = EMPTY_STRING;
    validatedUser.email = eventValue;
    return validatedUser;
}
