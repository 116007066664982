import React from "react";
import Box from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";

interface TableNoMatchStateProps {
  onClearFilterClick: () => void;
}

export function TableNoMatchState({
  onClearFilterClick
}: TableNoMatchStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <b>No matches</b>
      <Box padding={{ bottom: "s" }} variant="p" color="inherit">
        We can't find a match.
      </Box>
      <Button onClick={onClearFilterClick}>Clear filter</Button>
    </Box>
  );
}
