export default class RemovalItemDetails {
    constructor(fnsku, quantitySubmitted, quantityInProcess, quantityShipped, quantityCancelled, 
        quantityUnavailable, reasonCode) {
        this.fnsku = fnsku;
        this.quantitySubmitted = quantitySubmitted;
        this.quantityInProcess = quantityInProcess;
        this.quantityShipped = quantityShipped;
        this.quantityCancelled = quantityCancelled;
        this.quantityUnavailable = quantityUnavailable;
        this.reasonCode = reasonCode;
    }
}