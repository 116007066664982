import React from "react";
import WarehousesSearchBox from "./WarehousesSearchBox";
import ShipmentSummaryTable from "./ShipmentSummaryTable";

export default class SearchByWarehouses extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            warehouseList: "",
            spinnerData: []
        };
    }

    renderTables = (warehouses, shipmentType) => {
        this.setState(() => ({
            warehouseList: warehouses,
            shipmentType: shipmentType
        }));
    };

    render() {
        if (this.props.isAuthenticated) {
            return (
                <div>
                    <WarehousesSearchBox
                        warehouseList={this.state.warehouseList}
                        renderTables={this.renderTables}
                    />
                    <ShipmentSummaryTable
                        warehouseList={this.state.warehouseList}
                        shipmentType={this.state.shipmentType}

                    />
                </div>
            );
        }
        this.props.history.push("/login");
        return null;
    }
}
