import React, { Component } from "react";
import { Button, TextField } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import PageTitle from "../../../components/PageTitle/PageTitle";
import { warehouseIdRegex, commaSeparatedListRegex } from "../../../constants/Regex";
import { getErrorMessage } from "../../../constants/ErrorMessageMapper";
import "./BulkDeleteProblemReceiveItems.css";

class BulkDeleteProblemReceiveItemsForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            warehouseId: "",
            problemItemList: "",
            warehouseIdModified: false,
            problemItemListModified: false
        };
    }

    /*
    *If any of the input field is modified then this function will be executed and it will set the modified state for that
    * particular field to true and update the state also
    */
    handleChange = e => {
        e.preventDefault();
        const value = e.target.value.toUpperCase();
        this.setState({
            [e.target.name]: value.trim(),
            [e.target.name + "Modified"]: true
        });
    };

    areAllInputsValid = () => {
        return (!!this.state.warehouseId && warehouseIdRegex.test(this.state.warehouseId) && 
            !!this.state.problemItemList && commaSeparatedListRegex.test(this.state.problemItemList));
    };

    isProblemItemListUpdatedAndInvalid = () => {
        return (this.state.problemItemListModified && !commaSeparatedListRegex.test(this.state.problemItemList));
    }

    isWarehouseIdUpdatedAndInvalid = () => {
        return (this.state.warehouseIdModified && !warehouseIdRegex.test(this.state.warehouseId));
    }

    handleFormSubmit = (event) => {
        event.preventDefault();
        const value = event.target.value;
        
        this.setState({
            [event.target.name]: value,
        });
        this.props.handleSubmit(this.state.problemItemList, this.state.warehouseId);
    }

    render() {
        return (
            <div>
                <div>
                    <PageTitle>Delete Problem Items</PageTitle>
                </div>
                <div>
                    <div className="form-inline">
                        <div>
                            <div className="filter-form">
                                <div className="filters">
                                    <div className="delete-problem-items-warehouse-input-box">
                                        <label htmlFor="warehouseId" className="delete-problem-items-warehouse-params">Warehouse</label>
                                        <TextField
                                            className="delete-problem-items-warehouse-input"
                                            error={this.isWarehouseIdUpdatedAndInvalid()}
                                            placeholder="Warehouse Id"
                                            name="warehouseId"
                                            helperText={this.isWarehouseIdUpdatedAndInvalid() && getErrorMessage("warehouseId")}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="delete-problem-items-list-input-box">
                                        <label htmlFor="problemItemList" className="delete-problem-items-list-params">Problem Item IDs</label>
                                        <TextField
                                            className="delete-problem-items-list-input"
                                            error={this.isProblemItemListUpdatedAndInvalid()}
                                            name="problemItemList"
                                            placeholder="Comma separated list of Problem Items e.g. 123456,123457,123458... (Max 10)"
                                            helperText={this.isProblemItemListUpdatedAndInvalid() && getErrorMessage("problemItemList")}
                                            rows={4}
                                            multiline
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="inline align-middle text-center">
                                    <div className="problem-items-submit-button-padding">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<DeleteIcon />}
                                            disabled={!this.areAllInputsValid()}
                                            onClick={this.handleFormSubmit}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BulkDeleteProblemReceiveItemsForm;