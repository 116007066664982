import * as React from "react";
import { useEffect, useState } from "react";
import Container from "@amzn/awsui-components-react/polaris/container";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Header from "@amzn/awsui-components-react/polaris/header";
import Input from "@amzn/awsui-components-react/polaris/input";
import AttributeEditor from "@amzn/awsui-components-react/polaris/attribute-editor";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";

import { AppLayout, Button } from "@amzn/awsui-components-react";
import { useDeleteUsers } from "./hooks/useDeleteUsers";
import {
  EMAIL_ERROR_MESSAGE,
  UserInput,
  updateHeader,
  validateInput,
  validateUserEmail,
  Emails
} from "./utils/Utils";
import { RouteComponentProps } from "react-router-dom";
import UnAuthorizedAccessAlert from "../../views/layouts/UnAuthorizedAccessAlert";

interface DeleteUsersProps extends RouteComponentProps {
  isAuthenticated: boolean;
  isExternal: boolean;
}

export const DeleteUsersForm: React.FC<DeleteUsersProps> = (
  props: DeleteUsersProps
) => {
  if (!props.isAuthenticated) {
    props.history.push("/login");
  }

  const [headers, setHeaders] = useState<Array<UserInput>>([]);
  const [requestBody, setRequestBody] = useState<Emails>();
  const { isLoading, deleteUsersResponse, deleteUsers, error } =
    useDeleteUsers();

  function DeleteUsersAlert() {
    if (error) {
      return <Alert header={error?.message} type="error"></Alert>;
    }

    if (deleteUsersResponse.length === 0 || isLoading) {
      return null;
    }

    var failedUserInfo: string[] = [];
    deleteUsersResponse.forEach((response) => {
      if (response.userStatus === "DELETE_FAILED") {
        failedUserInfo.push(response.userEmail);
      }
    });

    if (
      deleteUsersResponse.length !== 0 &&
      failedUserInfo.length === 0 &&
      !isLoading
    ) {
      return (
        <Alert
          header="Deleted all the users successfully"
          type="success"
        ></Alert>
      );
    }

    const listItems = failedUserInfo.map((element) => (
      <li key={element}>{element}</li>
    ));
    return (
      <Alert header="Failed to delete following users : " type={"error"}>
        {listItems}
      </Alert>
    );
  }

  function handleBtnClick() {
    let emailList: string[] = [];
    let requestBody: Emails = { emails: emailList };
    headers.forEach((input) => {
      requestBody.emails.push(input.email);
    });
    setRequestBody(requestBody);
  }

  useEffect(() => {
    deleteUsers(requestBody);
  }, [requestBody, deleteUsers]);

  if (props.isExternal) {
    return <UnAuthorizedAccessAlert />;
  }

  return (
    <>
      <AppLayout
        navigationHide={true}
        toolsHide={true}
        headerSelector="#top-navigation"
        content={
          <Container header={<Header>Delete Users</Header>}>
            <SpaceBetween size="l">
              <FormField
                label="Delete Users"
                description="Enter the details of the users you want to delete."
              />
              <AttributeEditor<UserInput>
                addButtonText="Add User"
                removeButtonText="Remove User"
                items={headers}
                definition={[
                  {
                    label: "User Email",
                    errorText: (item) => {
                      return item.emailErrorMessage;
                    },
                    control: (item, index) => (
                      <Input
                        value={item.email}
                        inputMode="email"
                        placeholder="Email of the User"
                        autoFocus={true}
                        onChange={(event) => {
                          const user = validateUserEmail(
                            item,
                            "emailEvent",
                            event.detail.value
                          );
                          setHeaders((headers) =>
                            updateHeader(headers, index, (header) => ({
                              ...header,
                              email: user.email,
                              emailErrorMessage: user.emailErrorMessage
                            }))
                          );
                        }}
                      />
                    )
                  }
                ]}
                onAddButtonClick={() =>
                  setHeaders((headers) =>
                    headers.concat({
                      email: "",
                      firstName: "",
                      warehouses: "",
                      warehouseErrorMessage: "",
                      emailErrorMessage: EMAIL_ERROR_MESSAGE
                    })
                  )
                }
                onRemoveButtonClick={(event) =>
                  setHeaders((headers) =>
                    headers.filter(
                      (item, index) => index !== event.detail.itemIndex
                    )
                  )
                }
                empty="No Users deleted. Please click Add users button to enter user details."
                disableAddButton={headers.length === 10}
                additionalInfo={
                  <span>
                    You can add up to {10 - headers.length} more items.
                  </span>
                }
              />

              <Button
                variant="primary"
                disabled={!validateInput(headers)}
                loading={isLoading}
                onClick={handleBtnClick}
              >
                Delete Users
              </Button>
              <DeleteUsersAlert />
            </SpaceBetween>
          </Container>
        }
      />
    </>
  );
};
