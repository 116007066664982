import React from "react";
import { Alert, AlertProps } from "@amzn/awsui-components-react";

export interface alertMessageProps {
    alertMessage: any;
    visible: boolean;
    onDismiss?: any;
    alertType: AlertProps.Type;
    dismissible: boolean;
}

export const AlertMessage = (props: alertMessageProps) => {
    return (
        <Alert
            onDismiss={props?.onDismiss}
            visible={props.visible}
            dismissAriaLabel="X"
            dismissible={props.dismissible}
            type={props.alertType}
        > {props.alertMessage}</Alert>
    );
};