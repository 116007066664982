import React, { Component } from 'react';
import PropTypes from 'prop-types';
import API from "@aws-amplify/api";
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';

/* Importing components*/
import ProblemItem from './model/ProblemItem';
import GriddleTable from '../../../components/Table/GriddleTable';
import ProblemItemsSummary from './ProblemItemsSummary'
import { PROBLEM_RECEIVE_ITEM_DETAIL_METADATA } from '../../../constants/table/metadata/ProblemReceiveItemColumn';
import './ProblemItemInfo.css';
import { getAuthorizationHeader } from '@common/Auth';
import {RegionContext} from '@common/RegionContext';
import {constructButaneApiName} from '../../../apiConfig';

class ProblemItemsDetail extends Component {
    static propTypes = {
        warehouseId: PropTypes.string
    };

    constructor(props) {
        super(props);
        this.state = {
            totalQuantity: 0,
            problemItemsDetail: [],
            fetchingRecords: false,
            errorMessage: undefined,
            openErrorSnackbar: false
        };
    }

    async fetchProblemItemsDetailData(warehouseId) {
        API.get(constructButaneApiName(this.context.region), `/api/inbounds/po/warehouses/${warehouseId}`, {
            headers: await getAuthorizationHeader()
        })
        .then((data) => {
            let sum = 0;
            let problemItemList = [];
            const problemItemDetails = data.itemDetails;
            for (let i = 0; i < problemItemDetails.length; i++) {
                let itemData = new ProblemItem(
                    problemItemDetails[i].problemItemId,
                    problemItemDetails[i].fnsku,
                    problemItemDetails[i].quantity,
                    problemItemDetails[i].orderId
                );
                sum = sum + problemItemDetails[i].quantity;
                problemItemList.push(itemData);
            }

            this.setState(() => ({
                problemItemsDetail: problemItemList,
                totalQuantity: sum,
                fetchingRecords: false,
                errorMessage: undefined
            }));
        })
        .catch(error => {
            this.setState(() => ({
                problemItemsDetail: [],
                fetchingRecords: false,
                openErrorSnackbar: true,
            }));
            if(error.response) {
                this.setState(() => ({
                    errorMessage: (error.response.status === 500 && 
                        error.response.data.message === "EFProblemReceiveService or its dependency failed.") ?
                        'Something went wrong' : 'Cannot find Problem Items'
                }));
            } else {
                this.setState(() => ({
                    errorMessage: 'Something went wrong' 
                }));
            }
        });
    }

    getProblemItemsDetailsData(warehouseId) {
        this.setState({
            fetchingRecords: true
        }, () => {
            this.fetchProblemItemsDetailData(warehouseId);
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.warehouseId !== this.props.warehouseId) {
            this.getProblemItemsDetailsData(this.props.warehouseId);
        }
    }

    handleClose = () => {
        this.setState(() => ({
            openErrorSnackbar: false
        }));
    }

    renderProblemItemDetails() {
        const columnMetadata = PROBLEM_RECEIVE_ITEM_DETAIL_METADATA;
        return (
            <div>
                {!!this.state.errorMessage ? (
                    <Snackbar open={this.state.openErrorSnackbar} onClose={this.handleClose}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    >
                        <Alert onClose={this.handleClose} severity="error" variant="filled">
                            {this.state.errorMessage}
                        </Alert>
                    </Snackbar>)
                    : (
                        <div className="problem-item-details">
                            <ProblemItemsSummary
                                warehouseId={this.props.warehouseId}
                                totalQuantity={this.state.totalQuantity}
                            />
                            <GriddleTable
                                results={this.state.problemItemsDetail}
                                columnMetadata={columnMetadata}
                            />
                        </div>
                    )
                }
            </div>
        );
    }

    render() {
        let content;
        if (!!this.state.fetchingRecords) {
            return <div className="spinner"></div>
        }
        if (this.props.warehouseId && !this.state.fetchingRecords) {
            content = this.renderProblemItemDetails();
        }
        return <div>{content}</div>
    }
}

ProblemItemsDetail.contextType = RegionContext;

export default ProblemItemsDetail;