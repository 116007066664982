import { Box, ColumnLayout, ExpandableSection, Header, Spinner } from "@amzn/awsui-components-react";
import React from "react";
import { Status, NodeLaunchPlan } from "../Types";
import { EXPECTED_LAUNCH_DATE, DAILY_SHIPMENT_PER_NODE } from "../Constants";
import ValueWithLabel from "../ValueWithLabel";
import Popovers from "../Popovers";
import formatDate from "../../../common/DateFormatter";
import * as _ from "lodash";
interface LaunchPlanDetailsContainerProps {
  loading: boolean
  launchPlan?: NodeLaunchPlan;
}

const LaunchPlanDetailsContainer = (props: LaunchPlanDetailsContainerProps) => {
  const getSectionContents = () => {
    if (props.loading) {
      return (
        <Box textAlign="center">
          <Spinner />
        </Box>
      );
    } else if (_.isNil(props.launchPlan)) {
      return (
        <Box textAlign="center">
          No launch plan was selected
        </Box>
      );
    } else {
      return (
        <ColumnLayout columns={3} borders="vertical">
          <ValueWithLabel label="Launch Plan ID">{props.launchPlan.nodeLaunchPlanId}</ValueWithLabel>
          <ValueWithLabel label={ <> {"Daily Shipment Per Node "}
            <Popovers content={DAILY_SHIPMENT_PER_NODE}></Popovers>
          </>
          }>
            {props.launchPlan.expectedShipmentVolumePerDay}
          </ValueWithLabel>
          <ValueWithLabel label="Created">{formatDate(props.launchPlan.createdDate)}</ValueWithLabel>
          <ValueWithLabel label="Nodes Planned">{props.launchPlan.nodesPlanned}</ValueWithLabel>
          <ValueWithLabel label={ <> {"Expected Launch Date "}
            <Popovers content={EXPECTED_LAUNCH_DATE}></Popovers>
          </>
          }>
            {formatDate(props.launchPlan.expectedLaunchDate)}</ValueWithLabel>
          <ValueWithLabel label="Last Updated">{formatDate(props.launchPlan.lastUpdatedDate)}</ValueWithLabel>
          <ValueWithLabel label="Nodes Operational">{props.launchPlan.nodesOperational}</ValueWithLabel>
          <ValueWithLabel label="Status">{Status[props.launchPlan.status]}</ValueWithLabel>
        </ColumnLayout>
      );
    }
  }

  return (
    <ExpandableSection
      defaultExpanded={true}
      variant="container"
      header={
        <Header variant="h2">Launch plan details</Header>
      }
    >
      {getSectionContents()}
    </ExpandableSection>
  );
}

export default LaunchPlanDetailsContainer;