const CANCEL_PROD='https://efpm.amazon.com/tools/cancel_shipment';
const CANCEL_BETA='https://efpm-beta.integ.amazon.com/tools/cancel_shipment';
const fetchCancelShipmentLink = function(environment) {

    switch(environment.toLowerCase()) {
        case 'prod':
            return CANCEL_PROD;
        default:
            return CANCEL_BETA;
    }
};
export { fetchCancelShipmentLink };