import { DateRangePickerProps } from "@amzn/awsui-components-react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";

import { DateRange } from "@common/model";

dayjs.extend(utc);
dayjs.extend(duration);

const DAYJS_DURATION_UNIT_BY_POLARIS_TIME_UNIT = new Map<
  DateRangePickerProps.TimeUnit,
  duration.DurationUnitType
>([
  ["second", "seconds"],
  ["minute", "minutes"],
  ["hour", "hours"],
  ["day", "days"],
  ["week", "weeks"],
  ["month", "months"],
  ["year", "years"]
]);

export class DateRangePickerConverter {
  public static getDurationFromRelativeValue(
    range: DateRangePickerProps.RelativeValue
  ) {
    const durationUnit = DAYJS_DURATION_UNIT_BY_POLARIS_TIME_UNIT.get(
      range.unit
    )!;

    return dayjs.duration({ [durationUnit]: range.amount });
  }

  public static getDateRange(range: DateRangePickerProps.Value): DateRange {
    if (!range) {
      throw new Error("Must provide a valid range");
    }

    const { type } = range;

    switch (type) {
      case "relative": {
        return getRelativeDateRange();
      }
      case "absolute": {
        return getAbsoluteDateRange();
      }
      default:
        throw new Error(`Unrecognized range type: ${type}`);
    }

    function getRelativeDateRange() {
      const duration = DateRangePickerConverter.getDurationFromRelativeValue(
        range as DateRangePickerProps.RelativeValue
      );
      const fromDate = dayjs.utc().subtract(duration).startOf("day");

      return new DateRange({ fromDate });
    }

    function getAbsoluteDateRange() {
      const fromDate = dayjs
        .utc((range as DateRangePickerProps.AbsoluteValue).startDate)
        .startOf("day");
      const toDate = dayjs
        .utc((range as DateRangePickerProps.AbsoluteValue).endDate)
        .endOf("day");

      return new DateRange({ fromDate, toDate });
    }
  }
}
