import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";

import { useFlashbar } from "@common/flashbar";
import {
  FailedDepartItemsTable,
  SelectAmznShipRefIdForm
} from "@modules/transfer/common/components";
import { FAILED_DEPART_ITEMS_BASE_PATH } from "@modules/transfer/common/constants/paths";

interface LegacyFailedDepartItemsRouteProps {
  amznShipRefId: string;
}

interface LegacyFailedDepartItemsOverviewProps
  extends RouteComponentProps<LegacyFailedDepartItemsRouteProps, {}> {}

export const LegacyFailedDepartItemsOverview = withRouter(
  ({ history, match }: LegacyFailedDepartItemsOverviewProps) => {
    const { FlashbarContainer } = useFlashbar();

    function constructRedirectPath(transferId: string) {
      return history.push(`${FAILED_DEPART_ITEMS_BASE_PATH}/${transferId}`);
    }

    if (!hasAmznShipRefId()) {
      return (
        <SelectAmznShipRefIdForm
          constructRedirectPath={constructRedirectPath}
        />
      );
    }

    function hasAmznShipRefId() {
      return match?.params?.amznShipRefId;
    }

    return (
      <AppLayout
        navigationHide={true}
        notifications={<FlashbarContainer />}
        toolsHide={true}
        headerSelector="#top-navigation"
        contentType="default"
        content={
          <>
            <FailedDepartItemsTable
              amznShipRefId={match?.params?.amznShipRefId}
              isLegacyTransfer={true}
            />
          </>
        }
      />
    );
  }
);
