import React from 'react';
import { useState } from "react";

import {
    Button,
    Form,
    Header,
    FormField,
    Input,
    Container,
    Box,
    Grid
} from "@amzn/awsui-components-react";
import Auth from '@aws-amplify/auth';
import { useBetween } from "use-between";
import {
    useSharedUnAuthEmailState,
    useSharedAuthState,
    useSharedUserState
} from "./LoginWithAuth";
import { AlertMessage } from "../../components/AlertMessage"
import { AuthState, ChallengeName } from '@aws-amplify/ui-components';
import { Link } from 'react-router-dom';
import { isValidEmailRegex, validatePassword } from './Utils';

export const Login = () => {

    const { unAuthEmail, setUnAuthEmail } = useBetween(useSharedUnAuthEmailState);
    const { setAuthState } = useBetween(useSharedAuthState);
    const { setUser } = useBetween(useSharedUserState);
    const [loading, setLoading] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [isValidEmail, setIsValidEmail] = useState<boolean>(false)

    const handleLoginBtnClick = async () => {
        setLoading(true);
        try {
            var cognitoUser = await Auth.signIn(email, password);
            setUser(cognitoUser);
            if(cognitoUser.challengeName === ChallengeName.NewPasswordRequired) {
                setAuthState(AuthState.confirmingSignUpCustomFlow);
            } else {
                setAuthState(AuthState.confirmingSignInCustomFlow);
            }
            
        } catch (err) {
            if (err.name === "NotAuthorizedException") {
                console.error("Incorrect username or password");
            }
        }
        setUnAuthEmail(email);
        setLoading(false);
    };

    const setEmailEntered = (e: any) => {
        let email = e.detail.value
        setEmail(email)
        if (isValidEmailRegex(email)) {
            setIsValidEmail(true)
        }
    }

    const setPasswordEntered = (e: any) => {
        let password = e.detail.value
        setPassword(password)
    }

    return (
        <>
            <div>
                {/* colspan places the login container centre of the screen */}
                <Grid
                    gridDefinition={[{ colspan: 3 }, { colspan: 6 }, { colspan: 3 }]}
                >
                    <div>
                    </div>
                    <div>
                        <Box margin="xxl" padding="xxl" textAlign="center">
                            <Container
                                header={
                                    <Header >
                                        Sign in to your account to access Butane Static Website
                                </Header>
                                }
                            >
                                <Form
                                    actions={
                                        <Button
                                            variant="primary"
                                            onClick={handleLoginBtnClick}
                                            disabled={!isValidEmail && !validatePassword(password)}
                                            loading={loading}
                                        >
                                            Login
                                        </Button>
                                    }
                                >
                                    <FormField
                                        description={"Please enter your work email address"}
                                    >

                                        <Input
                                            type='email'
                                            inputMode="text"
                                            value={email}
                                            autoComplete={true}
                                            autoFocus
                                            placeholder="Enter your email address"
                                            onChange={setEmailEntered}
                                            onKeyDown={(e) => {
                                                if (e.detail.key === "Enter" && isValidEmail) handleLoginBtnClick();
                                            }}
                                        />
                                    </FormField>
                                    <FormField
                                        description={"Please enter your password"}
                                    >
                                        <Input
                                            type='password'
                                            inputMode="text"
                                            value={password}
                                            placeholder="Enter your password"
                                            onChange={setPasswordEntered}
                                            onKeyDown={(e) => {
                                                if (e.detail.key === "Enter" && isValidEmail) handleLoginBtnClick();
                                            }}
                                        />
                                    </FormField>
                                </Form>
                                <div className="mt-4">
                                    <Link to='/forgot-password'> Forgot Your Password?</Link>
                                </div>
                            </Container>
                            {!loading && unAuthEmail && (<><br />
                                <br /> <AlertMessage
                                    alertMessage={`Email: ${unAuthEmail} is not authorized to use this website or entered wrong login credentials.`}
                                    visible={true}
                                    dismissible={false}
                                    alertType={"error"}
                                /> </>)}
                        </Box>
                    </div>
                </Grid >
            </div>
        </>
    )
}