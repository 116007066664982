import React from 'react';
import API from "@aws-amplify/api";
import dateFormat from 'dateformat';
import MessageProvider from "./MessageProvider";
import {Alert} from "react-bootstrap";
import {fetchShipmentsDetailsInCondition62And305Column} from "../../../constants/table/metadata/shipment/WarehousesShipmentsInConditions62_305Column";
import {fetchShipmentsDetailsInCondition6009Column} from "../../../constants/table/metadata/shipment/WarehousesShipmentsInConditions6009Column";
import {SHIPMENTS_IN_CONDITIONS_EXCEPT_62_305_6009_COLUMN_METADATA} from "../../../constants/table/metadata/shipment/WarehousesShipmentsInConditionsExcept62_305_6009Column";
import ShipmentsDetailsInCondition6009 from "../model/ShipmentsDetailsInCondition6009";
import ShipmentsDetailsInCondition62And305 from "../model/ShipmentsDetailsInCondition62And305";
import ShipmentDetailsInConditionExcept_62_305_6009 from "../model/ShipmentDetailsInConditionExcept_62_305_6009";
import {fetchArsenalLink} from "../../../constants/FetchArsenalLink";
import '../../../components/Table/./griddleTable.css';
import '../../../../src/components/Spinner/spinner.css';
import TableForShipmentsInCondition from "../../../components/Table/TableForShipmentsInCondition";
import { getAuthorizationHeader } from '@common/Auth';
import {RegionContext} from '@common/RegionContext';
import {constructButaneApiName} from '../../../apiConfig';
import {getEnvironment} from '@modules/signup/Utils';

const formatOfDate = "yyyy-mm-dd";

export default class WarehousesShipmentsInConditionsTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            shipmentSnapshot: [],
            fetchingRecords: false,
            errorMessage: undefined,
            agingConditionsList: ["62", "3", "305", "8", "5406", "5409", "36", "59", "7", "13", "29", "54", "4", "11", "12", "32", "60", "6001", "6003", "6009", "6006", "2505", "15", "1501", "MISSEDEXSD"]
        };
    }

    async shipmentDetailsForWarehousesInConditionExcept_62_305_ALL_6009(conditionValue, warehouseList, shipmentType) {
        let shipmentSnapshot = [];
        let shipmentTuple;
        let arrayOfWarehouse = warehouseList.split(',');
        return API.get(constructButaneApiName(this.context.region), `/api/shipments/shipmentsDetailsForWarehouseInCondition/${conditionValue}?warehouses=${warehouseList}&shipmentType=${shipmentType}`, {
                headers: await getAuthorizationHeader()
            })
            .then((data) => {

                let warehouseIdToAgingShipmentMap = data.warehouseIdToAgingShipmentMap;
                if (Object.entries(warehouseIdToAgingShipmentMap).length !== 0) {
                    for (let i = 0; i < arrayOfWarehouse.length; i++) {
                        let agingShipmentDetailsList = [];
                        if (warehouseIdToAgingShipmentMap.hasOwnProperty(arrayOfWarehouse[i]))
                            agingShipmentDetailsList = warehouseIdToAgingShipmentMap[arrayOfWarehouse[i]].agingShipmentDetailsList;
                        for (let j = 0; j < agingShipmentDetailsList.length; j++) {
                            let expectedShipDate = null;
                            let orderDate = null;
                            let promisedShipDate = null;
                            if (agingShipmentDetailsList[j].expectedShipDate !== null)
                                expectedShipDate = dateFormat((new Date(agingShipmentDetailsList[j].expectedShipDate * 1000)).toString(), formatOfDate);
                            if (agingShipmentDetailsList[j].promisedShipDate !== null)
                                promisedShipDate = dateFormat((new Date(agingShipmentDetailsList[j].promisedShipDate * 1000)).toString(), formatOfDate);
                            if (agingShipmentDetailsList[j].orderDate !== null)
                                orderDate = dateFormat((new Date(agingShipmentDetailsList[j].orderDate * 1000)).toString(), formatOfDate);
                            shipmentTuple = new ShipmentDetailsInConditionExcept_62_305_6009(
                                agingShipmentDetailsList[j].warehouseId,
                                agingShipmentDetailsList[j].shipmentId,
                                agingShipmentDetailsList[j].encryptedShipmentId,
                                agingShipmentDetailsList[j].orderId,
                                agingShipmentDetailsList[j].asin,
                                Number(agingShipmentDetailsList[j].quantity),
                                orderDate,
                                promisedShipDate,
                                agingShipmentDetailsList[j].shipMethod,
                                expectedShipDate,
                                agingShipmentDetailsList[j].condition,
                                Number((agingShipmentDetailsList[j].shipmentAge).toFixed(1)),
                                Number((agingShipmentDetailsList[j].lateAge).toFixed(1)),
                                agingShipmentDetailsList[j].shipmentType
                            );
                            shipmentSnapshot.push(shipmentTuple);
                        }
                    }
                }
                this.setState(() => ({
                    shipmentSnapshot: shipmentSnapshot,
                    fetchingRecords: false,
                    errorMessage: ""
                }));
            }).catch((error) => {
                    this.setState(() => ({
                        shipmentSnapshot: [],
                        fetchingRecords: false,
                        errorMessage: (error.message === "Request failed with status code 400") ? 'Shipments Summary not found' : 'Something went wrong'
                    }));
                }
            );
    }

    async shipmentDetailsForWarehousesInCondition_ALL(warehouseList, shipmentType) {
        let shipmentSnapshot = [];
        let arrayOfWarehouse = warehouseList.split(',');
        for (const condition of this.state.agingConditionsList) {
            await API.get(constructButaneApiName(this.context.region), `/api/shipments/shipmentsDetailsForWarehouseInCondition/${condition}?warehouses=${warehouseList}&shipmentType=${shipmentType}`, {
                    headers: await getAuthorizationHeader()
                })
                .then(data => {

                    let warehouseIdToAgingShipmentMap = data.warehouseIdToAgingShipmentMap;
                    if (Object.entries(warehouseIdToAgingShipmentMap).length !== 0) {
                        for (let i = 0; i < arrayOfWarehouse.length; i++) {
                            let agingShipmentDetailsList = [];
                            if (warehouseIdToAgingShipmentMap.hasOwnProperty(arrayOfWarehouse[i]))
                                agingShipmentDetailsList = warehouseIdToAgingShipmentMap[arrayOfWarehouse[i]].agingShipmentDetailsList;
                            for (let j = 0; j < agingShipmentDetailsList.length; j++) {
                                let expectedShipDate = null;
                                let orderDate = null;
                                let promisedShipDate = null;
                                if (agingShipmentDetailsList[j].expectedShipDate !== null)
                                    expectedShipDate = dateFormat((new Date(agingShipmentDetailsList[j].expectedShipDate * 1000)).toString(), formatOfDate);
                                if (agingShipmentDetailsList[j].promisedShipDate !== null)
                                    promisedShipDate = dateFormat((new Date(agingShipmentDetailsList[j].promisedShipDate * 1000)).toString(), formatOfDate);
                                if (agingShipmentDetailsList[j].orderDate !== null)
                                    orderDate = dateFormat((new Date(agingShipmentDetailsList[j].orderDate * 1000)).toString(), formatOfDate);
                                let shipmentTuple = new ShipmentDetailsInConditionExcept_62_305_6009(
                                    agingShipmentDetailsList[j].warehouseId,
                                    agingShipmentDetailsList[j].shipmentId,
                                    agingShipmentDetailsList[j].encryptedShipmentId,
                                    agingShipmentDetailsList[j].orderId,
                                    agingShipmentDetailsList[j].asin,
                                    Number(agingShipmentDetailsList[j].quantity),
                                    orderDate,
                                    promisedShipDate,
                                    agingShipmentDetailsList[j].shipMethod,
                                    expectedShipDate,
                                    agingShipmentDetailsList[j].condition,
                                    Number((agingShipmentDetailsList[j].shipmentAge).toFixed(1)),
                                    Number((agingShipmentDetailsList[j].lateAge).toFixed(1)),
                                    agingShipmentDetailsList[j].shipmentType
                                );
                                shipmentSnapshot.push(shipmentTuple);
                            }
                        }
                    }
                }).catch((error) => {
                        this.setState(() => ({
                            shipmentSnapshot: [],
                            fetchingRecords: false,
                            errorMessage: (error.message === "Request failed with status code 400") ? 'Shipments Summary not found' : 'Something went wrong'
                        }));
                    }
                );
        }
        this.setState(() => ({
            shipmentSnapshot: shipmentSnapshot,
            fetchingRecords: false,
            errorMessage: ""
        }));

    }

    async shipmentDetailsForWarehousesInCondition62And305(condition, warehouseList, shipmentType) {
        let shipmentSnapshot = [];
        let shipmentTuple;
        let arrayOfWarehouse = warehouseList.split(',');
        API.get(constructButaneApiName(this.context.region), `/api/shipments/shipmentsDetailsForWarehouseInCondition/${condition}?warehouses=${warehouseList}&shipmentType=${shipmentType}`, {
                headers: await getAuthorizationHeader()
            })
            .then((data) => {

                let warehouseIdToAgingShipmentMap = data.warehouseIdToAgingShipmentMap;
                if (Object.entries(warehouseIdToAgingShipmentMap).length !== 0) {
                    for (let i = 0; i < arrayOfWarehouse.length; i++) {
                        let agingShipmentDetailsList = [];
                        if (warehouseIdToAgingShipmentMap.hasOwnProperty(arrayOfWarehouse[i]))
                            agingShipmentDetailsList = warehouseIdToAgingShipmentMap[arrayOfWarehouse[i]].agingShipmentDetailsList;
                        for (let j = 0; j < agingShipmentDetailsList.length; j++) {
                            let expectedShipDate = null;
                            let orderDate = null;
                            let promisedShipDate = null;
                            if (agingShipmentDetailsList[j].expectedShipDate !== null)
                                expectedShipDate = dateFormat((new Date(agingShipmentDetailsList[j].expectedShipDate * 1000)).toString(), formatOfDate);
                            if (agingShipmentDetailsList[j].promisedShipDate !== null)
                                promisedShipDate = dateFormat((new Date(agingShipmentDetailsList[j].promisedShipDate * 1000)).toString(), formatOfDate);
                            if (agingShipmentDetailsList[j].orderDate !== null)
                                orderDate = dateFormat((new Date(agingShipmentDetailsList[j].orderDate * 1000)).toString(), formatOfDate);
                            let value;
                            if (condition === "62")
                                value = agingShipmentDetailsList[j].shipmentId;
                            else
                                value = agingShipmentDetailsList[j].asin;
                            shipmentTuple = new ShipmentsDetailsInCondition62And305(
                                agingShipmentDetailsList[j].warehouseId,
                                agingShipmentDetailsList[j].shipmentId,
                                agingShipmentDetailsList[j].encryptedShipmentId,
                                agingShipmentDetailsList[j].orderId,
                                agingShipmentDetailsList[j].asin,
                                Number(agingShipmentDetailsList[j].quantity),
                                orderDate,
                                promisedShipDate,
                                agingShipmentDetailsList[j].shipMethod,
                                expectedShipDate,
                                agingShipmentDetailsList[j].condition,
                                Number((agingShipmentDetailsList[j].shipmentAge).toFixed(1)),
                                Number((agingShipmentDetailsList[j].lateAge).toFixed(1)),
                                value,
                                agingShipmentDetailsList[j].shipmentType
                            );
                            shipmentSnapshot.push(shipmentTuple);
                        }
                    }
                }
                this.setState(() => ({
                    shipmentSnapshot: shipmentSnapshot,
                    fetchingRecords: false,
                    errorMessage: ""
                }));

            }).catch((error) => {
                this.setState(() => ({
                    shipmentSnapshot: [],
                    fetchingRecords: false,
                    errorMessage: (error.message === "Request failed with status code 400") ? 'Shipments Summary not found' : 'Something went wrong'
                }));
            }
        );
    }

    async shipmentDetailsForWarehousesInCondition_6009(condition, warehouseList, shipmentType) {
        let shipmentSnapshot = [];
        let shipmentTuple;
        let arrayOfWarehouse = warehouseList.split(',');
        await API.get(constructButaneApiName(this.context.region), `/api/shipments/shipmentsDetailsForWarehouseInCondition/${condition}?warehouses=${warehouseList}&shipmentType=${shipmentType}`, {
                headers: await getAuthorizationHeader()
            })
            .then(async (data) => {

                let warehouseIdToAgingShipmentMap = data.warehouseIdToAgingShipmentMap;
                if (Object.entries(warehouseIdToAgingShipmentMap).length !== 0) {
                    for (let i = 0; i < arrayOfWarehouse.length; i++) {
                        let agingShipmentDetailsList = [];
                        let shipmentExeptions;
                        if (warehouseIdToAgingShipmentMap.hasOwnProperty(arrayOfWarehouse[i])) {
                            agingShipmentDetailsList = warehouseIdToAgingShipmentMap[arrayOfWarehouse[i]].agingShipmentDetailsList;
                            shipmentExeptions = warehouseIdToAgingShipmentMap[arrayOfWarehouse[i]].shipmentExceptions;
                        }
                        for (let j = 0; j < agingShipmentDetailsList.length; j++) {
                            let asinExeptionCode = null;
                            let shipmentExeptionCode = null;
                            let netAvailableQuantity = 0;
                            let expectedShipDate = null;
                            let orderDate = null;
                            let promisedShipDate = null;
                            if (agingShipmentDetailsList[j].expectedShipDate !== null)
                                expectedShipDate = dateFormat((new Date(agingShipmentDetailsList[j].expectedShipDate * 1000)).toString(), formatOfDate);
                            if (agingShipmentDetailsList[j].promisedShipDate !== null)
                                promisedShipDate = dateFormat((new Date(agingShipmentDetailsList[j].promisedShipDate * 1000)).toString(), formatOfDate);
                            if (agingShipmentDetailsList[j].orderDate !== null)
                                orderDate = dateFormat((new Date(agingShipmentDetailsList[j].orderDate * 1000)).toString(), formatOfDate);
                            if (Object.entries(shipmentExeptions).length !== 0) {
                                if ((agingShipmentDetailsList[j].shipmentId !== null) && (shipmentExeptions.hasOwnProperty(agingShipmentDetailsList[j].shipmentId)) && (shipmentExeptions[agingShipmentDetailsList[j].shipmentId].exception !== null)) {
                                    shipmentExeptionCode = shipmentExeptions[agingShipmentDetailsList[j].shipmentId].exception.code;
                                }
                                if ((agingShipmentDetailsList[j].shipmentId !== null) && (shipmentExeptions.hasOwnProperty(agingShipmentDetailsList[j].shipmentId)) && (agingShipmentDetailsList[j].asin !== null) && (Object.entries(shipmentExeptions[agingShipmentDetailsList[j].shipmentId].asinExceptions).length !== 0) && (shipmentExeptions[agingShipmentDetailsList[j].shipmentId].asinExceptions.hasOwnProperty(agingShipmentDetailsList[j].asin))) {

                                    asinExeptionCode = shipmentExeptions[agingShipmentDetailsList[j].shipmentId].asinExceptions[agingShipmentDetailsList[j].asin].code;
                                }
                            }
                            await API.get(constructButaneApiName(this.context.region), `/api/network/inventory/${agingShipmentDetailsList[j].warehouseId}/${agingShipmentDetailsList[j].asin}?inventoryCondition=${"SELLABLE"}`, {
                                    headers: await getAuthorizationHeader()
                                })
                                .then((data) => {

                                    let inventory_snapshot = data.snapshot;
                                    for (const snapshot of inventory_snapshot) {
                                        if (snapshot.asin === agingShipmentDetailsList[j].asin && snapshot.condition === "SELLABLE") {
                                            netAvailableQuantity = netAvailableQuantity + snapshot.quantity - snapshot.boundQuantity;
                                        }
                                    }
                                }).catch((error) => {
                                        this.setState(() => ({
                                            shipmentSnapshot: [],
                                            fetchingRecords: false,
                                            errorMessage: (error.message === "Request failed with status code 400") ? 'Shipments Summary not found' : 'Something went wrong'
                                        }));
                                    }
                                );
                            shipmentTuple = new ShipmentsDetailsInCondition6009(
                                agingShipmentDetailsList[j].warehouseId,
                                agingShipmentDetailsList[j].shipmentId,
                                agingShipmentDetailsList[j].encryptedShipmentId,
                                agingShipmentDetailsList[j].orderId,
                                agingShipmentDetailsList[j].asin,
                                Number(agingShipmentDetailsList[j].quantity),
                                asinExeptionCode,
                                shipmentExeptionCode,
                                Number(netAvailableQuantity),
                                orderDate,
                                promisedShipDate,
                                agingShipmentDetailsList[j].shipMethod,
                                expectedShipDate,
                                agingShipmentDetailsList[j].condition,
                                Number((agingShipmentDetailsList[j].shipmentAge).toFixed(1)),
                                Number((agingShipmentDetailsList[j].lateAge).toFixed(1)),
                                agingShipmentDetailsList[j].shipmentId,
                                agingShipmentDetailsList[j].shipmentType
                            );
                            shipmentSnapshot.push(shipmentTuple);
                        }
                    }
                }
                this.setState(() => ({
                    shipmentSnapshot: shipmentSnapshot,
                    fetchingRecords: false,
                    errorMessage: ""
                }));
            }).catch((error) => {
                    this.setState(() => ({
                        shipmentSnapshot: [],
                        fetchingRecords: false,
                        errorMessage: (error.message === "Request failed with status code 400") ? 'Shipments Summary not found' : 'Something went wrong'
                    }));
                }
            );
    }

    componentDidMount() {
        this.setState(() => ({
            fetchingRecords: true
        }));
        let arrayOfWarehouse = this.props.warehouseList.split(',');
        if ((this.props.row === "0" && this.props.condition === "ALL") || (this.props.row !== "0" && arrayOfWarehouse.length > 1)) {
            this.setState(() => ({
                shipmentSnapshot: [],
                fetchingRecords: false,
                errorMessage: "Fetching data by URL. So Give Right Inputs."
            }));
        }
        switch (this.props.condition) {
            case ("62"):
            case("305"):
                this.shipmentDetailsForWarehousesInCondition62And305(this.props.condition, this.props.warehouseList, this.props.shipmentType);
                break;
            case ("ALL"):
                this.shipmentDetailsForWarehousesInCondition_ALL(this.props.warehouseList, this.props.shipmentType);
                break;
            case("6009"):
                this.shipmentDetailsForWarehousesInCondition_6009(this.props.condition, this.props.warehouseList, this.props.shipmentType);
                break;
            default:
                this.shipmentDetailsForWarehousesInConditionExcept_62_305_ALL_6009(this.props.condition, this.props.warehouseList, this.props.shipmentType);
        }
    }

    CustomLinkColumn = (rowData) => {
        switch (this.props.condition) {
            case("62"):
                return <a href={`${fetchArsenalLink(this.context.region, getEnvironment())}/search?id=${rowData.shipmentId}`} className="LinkStyle"
                          target="_blank"
                          rel="noopener noreferrer">Fix Exception</a>
            case("6009"):
                return <a href={`${fetchArsenalLink(this.context.region, getEnvironment())}/search?id=${rowData.shipmentId}`} className="LinkStyle"
                          target="_blank"
                          rel="noopener noreferrer">Check</a>
            default:
                return <a
                    href={`https://cost.amazon.com/costManagement/costLookup/asin/${rowData.shipmentId}/showTerminated/false`}
                    className="LinkStyle" target="_blank" rel="noopener noreferrer">Actions</a>;
        }

    }

    renderRealTimeShipmentTable() {

        let columnMetadata;
        switch (this.props.condition) {
            case("305"):
            case ("62"):
                columnMetadata = fetchShipmentsDetailsInCondition62And305Column(this.CustomLinkColumn);
                break;
            case("6009"):
                columnMetadata = fetchShipmentsDetailsInCondition6009Column(this.CustomLinkColumn);
                break;
            default:
                columnMetadata = SHIPMENTS_IN_CONDITIONS_EXCEPT_62_305_6009_COLUMN_METADATA;
        }
        return (
            <div>
                {!!this.state.errorMessage ? (
                        <Alert variant="danger">
                            <Alert.Heading>
                                {this.state.errorMessage}
                            </Alert.Heading>
                            <p>
                                Could Not Find the Shipments details for given Request.
                            </p>
                        </Alert>)
                    : (<div>
                            <MessageProvider
                                condition={this.props.condition}
                                warehouseList={this.props.warehouseList}
                                shipmentType={this.props.shipmentType}
                                row={this.props.row}
                            />
                            <TableForShipmentsInCondition
                                results={this.state.shipmentSnapshot}
                                columnMetadata={columnMetadata}
                            />
                        </div>
                    )}
            </div>
        );
    }

    render() {
        let content;
        if (!!this.state.fetchingRecords) {
            return (
                <div className="spinner"></div>
            );
        }
        if (!!this.props.condition && !!this.props.warehouseList && !this.state.fetchingRecords) {
            content = this.renderRealTimeShipmentTable();
        }
        return (
            <div>
                {content}
            </div>
        );
    }
}

WarehousesShipmentsInConditionsTable.contextType = RegionContext;
