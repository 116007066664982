import {TableEmptyState} from "@common/table";
import Table from "@amzn/awsui-components-react/polaris/table";
import React, {useContext, useEffect, useState} from "react";
import {useCollection} from "@amzn/awsui-collection-hooks";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import API from "@aws-amplify/api";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import { getAuthorizationHeader } from "@common/Auth";
import {RegionContext} from "@common/RegionContext";
import {constructButaneApiName} from "../../../../apiConfig";

export interface CommittedGradingItem {
    gradingOrderId: string,
    quantity: string,
}

interface CommittedGradingDetailsTableProps {
    fnSku: string,
    warehouse: string,
}

export const CommittedGradingDetailsTable = (props: CommittedGradingDetailsTableProps) => {
    const [committedGradingItems, setCommittedGradingItems] = useState<CommittedGradingItem[]>([]);
    const [fetchingCommittedGrading, setFetchingCommittedGrading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const regionContext = useContext(RegionContext);

    useEffect(() => {
        setCommittedGradingItems([]);
        setFetchingCommittedGrading(true);
        setErrorMessage("");
        getCommittedGradingDetails(props, regionContext.region);
    }, [props, regionContext]);

    const getCommittedGradingDetails = async (props: CommittedGradingDetailsTableProps, region: string) => {
        setFetchingCommittedGrading(true);
        let gradingItems: CommittedGradingItem[] = [];
        API.get(constructButaneApiName(region), `/api/grading/fnSku/${props.fnSku}/warehouse/${props.warehouse}`, {
                headers: await getAuthorizationHeader()
            })
            .then((data) => {
                return data.gradings.forEach(grading => {
                    gradingItems.push({
                        gradingOrderId: grading.gradingOrderId,
                        quantity: grading.quantity
                    })
                });
            }).catch(() => {
                setErrorMessage("Error fetching committed grading order details.")
            }).finally(() => {
                setCommittedGradingItems(gradingItems);
                setFetchingCommittedGrading(false);
            });
    }

    const {
        items,
        filterProps,
        paginationProps,
    } = useCollection(committedGradingItems, {
        filtering: {},
        pagination: {
            pageSize: 10
        }
    });

    return <div>
        {
            errorMessage &&
            <Alert type={"error"}>
                {errorMessage}
            </Alert>
        }
        <Table
            loading={fetchingCommittedGrading}
            loadingText={`Fetching committed grading orders for FNSKU: ${props.fnSku}`}
            items={items}
            empty={
                <TableEmptyState
                    resourceName={"Commited Grading Order"}
                    resourceNamePlural={"Commited Grading Orders"}
                    showCreateResourceButton={false}
                />
            }
            columnDefinitions={[
                {
                    id: "gradingOrderId",
                    header: "GradingOrderId",
                    cell: e => e.gradingOrderId,
                },
                {
                    id: "quantity",
                    header: "Quantity",
                    cell: e => e.quantity,
                }
            ]}
            filter={
                <div>
                    <TextFilter
                        {...filterProps}
                    />
                </div>
            }
            pagination={
                <Pagination
                    {...paginationProps}
                />
            }
        />
    </div>;
}