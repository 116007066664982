import React, { Component } from "react";

/* Import Components */
import RemovalOrderInfoForm from "./RemovalOrderInfoForm";
import RemovalOrderDetails from "./RemovalOrderDetails";

class RemovalOrderInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orderId: this.props.match.params.orderId,
            warehouseId: this.props.match.params.warehouseId,
        }
    }
    
    getFormInput = (orderId, warehouseId) => {
        this.setState(() => ({
            orderId: orderId,
            warehouseId: warehouseId
        }));
        this.props.history.push(`/removal/info/${warehouseId}/${orderId}`);
    }

    render() {
        if (this.props.isAuthenticated) {
            return (
            <div>
                <RemovalOrderInfoForm
                    orderId={this.state.orderId}
                    warehouseId={this.state.warehouseId} 
                    getFormInput={this.getFormInput} 
                />
                <RemovalOrderDetails 
                    orderId={this.state.orderId}
                    warehouseId={this.state.warehouseId} 
                />
            </div>
            );
        }
        this.props.history.push("/login");
        return null;
    }
}

export default RemovalOrderInfo;