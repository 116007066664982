import {useCallback, useContext, useState} from "react";
import API from "@aws-amplify/api";
import { StatusCodes } from "http-status-codes";

import { DateRange } from "@common/model";
import { ApiError } from "@common/exception";
import {
    TransferDepartRequest,
    TransferDepartRequestStatus,
    TransferDepartWorkflow
} from "@modules/transfer/common/model";

import { WorkflowStateConverter } from "@modules/transfer/departure-requests/logic";
import { getAuthorizationHeader } from "@common/Auth";
import {RegionContext} from "@common/RegionContext";
import {constructButaneApiName} from "../../../apiConfig";

const TRANSFER_DEPART_REQUESTS_DATA_KEY = "transferDepartRequests";
const TRANSFER_DEPART_WORKFLOW_DETAILS_DATA_KEY = "transferWorkflowDetails";
const MESSAGE_DATA_KEY = "message";

const FROM_CREATION_DATE_KEY = "fromCreationDate";
const TO_CREATION_DATE_KEY = "toCreationDate";

const EMPTY_STRING = "";
const UNKNOWN_WORKFLOW_STATE = "UNKNOWN";
const PROCESSED_WORKFLOW_STATE = "PROCESSED";
const FAILED_WORKFLOW_STATE = "FAILED";
const IN_PROGRESS_WORKFLOW_STATE = "IN PROGRESS";


function getMessageStatus(transferDepartWorkflowDetails: TransferDepartWorkflow) {
    if (transferDepartWorkflowDetails?.isClosed) {
        return PROCESSED_WORKFLOW_STATE;
    }
    const butaneState = getButaneState();

    if (transferDepartWorkflowDetails?.isErrored) {
        return FAILED_WORKFLOW_STATE;
    }
    if (transferDepartWorkflowDetails?.isOpen) {
        return (
            IN_PROGRESS_WORKFLOW_STATE
        );
    }
    return butaneState;

    function getButaneState() {
        if (!transferDepartWorkflowDetails) {
            return UNKNOWN_WORKFLOW_STATE;
        }

        const herdState = WorkflowStateConverter.toHerdState(
            transferDepartWorkflowDetails
        );
        return (
            WorkflowStateConverter.toButaneState(herdState) ??
            UNKNOWN_WORKFLOW_STATE
        );
    }
}
export function useGetTransferDepartRequests() {
  const [error, setError] = useState<ApiError | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<TransferDepartRequest[]>([]);
  // eslint-disable-next-line
  const [departRequest, setDepartRequest] = useState<TransferDepartRequest>();
    const regionContext = useContext(RegionContext);

     async function setWorkflowStatus(transferDepartRequest: TransferDepartRequest) {

         transferDepartRequest.Status = '';
         const apiUrl = `/transfer/workflowDetails/${transferDepartRequest.workflowId}`;
         try {
             const response = await API.get(
                 constructButaneApiName(regionContext.region),
                 apiUrl, {
                  response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                  headers: await getAuthorizationHeader()                
                }
             );

             let transferDepartWorkflowDetails = response?.data
                 [TRANSFER_DEPART_WORKFLOW_DETAILS_DATA_KEY] as unknown as TransferDepartWorkflow;
             transferDepartRequest.Status = getMessageStatus(transferDepartWorkflowDetails);
             setDepartRequest(transferDepartRequest);

         } catch (exception) {
             console.error(
                 `Something went wrong while retrieving Transfer Depart Workflow details for Workflow ID: 
                ${transferDepartRequest.workflowId}`);
             transferDepartRequest.Status = TransferDepartRequestStatus.UNKNOWN;
         }
     }

    const getTransferDepartRequests = useCallback(
    async (warehouse: string, dateRange?: DateRange) => {
      if (!warehouse || !dateRange) {
        return;
      }

      setIsLoading(true);
      setError(null);

      const apiUrl = getApiUrl();
      try {
        const response = await API.get(
          constructButaneApiName(regionContext.region),
          apiUrl, {
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            headers: await getAuthorizationHeader()
          }
        );

        let transferDepartRequests = (response?.data[TRANSFER_DEPART_REQUESTS_DATA_KEY] ??
              []) as unknown as TransferDepartRequest[];
              
        transferDepartRequests.forEach(transferDepartRequestItem => {
          transferDepartRequestItem.creationDate = new Date(Number(transferDepartRequestItem.creationDate)*1000.0).toUTCString();
          transferDepartRequestItem.lastUpdatedDate = new Date(Number(transferDepartRequestItem.lastUpdatedDate)*1000.0).toUTCString();
        });
        
        transferDepartRequests.forEach(setWorkflowStatus);
        setData(transferDepartRequests);

      } catch (exception) {
        console.error(
          `Something went wrong while retrieving transfer depart requests for warehouse: ${warehouse}`
        );

        const apiError = getApiError(exception, warehouse);

        setError(apiError);
        setData([]);
      }

      setIsLoading(false);

      function getApiUrl() {
        const warehouseSpecificUri = `/transfer/${warehouse}/departRequests?`;
        const fromCreationDateUri = `${FROM_CREATION_DATE_KEY}=${dateRange?.fromDate.toISOString()}`;

        const apiUriComponents = [warehouseSpecificUri, fromCreationDateUri];

        if (dateRange?.toDate) {
          apiUriComponents.push(
            `&${TO_CREATION_DATE_KEY}=${dateRange?.toDate.toISOString()}`
          );
        }

        return apiUriComponents.join(EMPTY_STRING);
      }
    },

     // eslint-disable-next-line react-hooks/exhaustive-deps
    [regionContext]
  );

  function getApiError(error: any, warehouse: string) {
      if (error?.response?.status === StatusCodes.BAD_REQUEST) {
          return new ApiError(
              `Invalid request for warehouse ${warehouse}.`,
              StatusCodes.BAD_REQUEST,
              error.response.data[MESSAGE_DATA_KEY]
          );
      }
      if (error?.response?.status === StatusCodes.NOT_FOUND) {
          return new ApiError(
              `No transfer depart requests found for warehouse ${warehouse}.`,
              StatusCodes.NOT_FOUND
          );
      }

      return new ApiError(
          `Error while retrieving transfer depart requests for warehouse ${warehouse}, please try again later.`,
          StatusCodes.INTERNAL_SERVER_ERROR
      );
  }

  return {
    error,
    isLoading,
    transferDepartRequests: data,
    getTransferDepartRequests
  };
}
