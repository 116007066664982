import * as React from "react";
import Box from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";
import DatePicker from "@amzn/awsui-components-react/polaris/date-picker";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input from "@amzn/awsui-components-react/polaris/input";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";

import { EXPECTED_LAUNCH_DATE, DAILY_SHIPMENT_PER_NODE, HOURS_IN_SEVEN_DAYS, LOWER_LIMIT_FOR_NUMBER_OF_NODES_PLANNED, 
UPPER_LIMIT_FOR_NUMBER_OF_NODES_PLANNED, LOWER_LIMIT_FOR_DAILY_SHIPMENT_PER_NODE, UPPER_LIMIT_FOR_DAILY_SHIPMENT_PER_NODE } from "../Constants";
import Popovers from "../Popovers";

interface CreateLaunchPlanModalProps {
  visible: boolean;
  hideModal: () => void;
  submitForm: (nodesPlanned: number, expectedShipmentPerDay: number, expectedShipmentLaunchDate: Date) => void;
}

const CreateLaunchPlanModal: React.FC<CreateLaunchPlanModalProps> = (props: CreateLaunchPlanModalProps) => {
  const [nodesPlanned, setNodesPlanned] = React.useState("1");
  const [expectedShipmentPerDay, setExpectedShipmentPerDay] = React.useState("1");
  const [expectedLaunchDate, setExpectedLaunchDate] = React.useState("");
  const validateDateIsInFuture = (date: Date): boolean => {
    const inputDate = new Date(date);
    return inputDate.setHours(0, 0, 0, 0) >= new Date().setHours(HOURS_IN_SEVEN_DAYS, 0, 0, 0);
  };

  return (
    <Modal
      onDismiss={props.hideModal}
      visible={props.visible}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={props.hideModal}>Cancel</Button>
            <Button variant="primary" onClick={
              () => {
                props.hideModal();
                props.submitForm(parseInt(nodesPlanned),  parseInt(expectedShipmentPerDay), new Date(expectedLaunchDate))
              }
            }>Create</Button>
          </SpaceBetween>
        </Box>
      }
      header="Create new launch plan"
    >

      <FormField label="Number of nodes planned (1-5000)">
        <Input
          type="number"
          value={nodesPlanned}
          onChange={event => 
            Number(event.detail.value) >= LOWER_LIMIT_FOR_NUMBER_OF_NODES_PLANNED && 
            Number(event.detail.value) <= UPPER_LIMIT_FOR_NUMBER_OF_NODES_PLANNED && 
            setNodesPlanned(event.detail.value)}
        />
      </FormField>
      <FormField label="Daily shipment per node (1-100000)"
        info={<Popovers content={DAILY_SHIPMENT_PER_NODE}></Popovers>}
      >
        <Input
          type="number"
          value={expectedShipmentPerDay}
          onChange={event => 
            Number(event.detail.value) >= LOWER_LIMIT_FOR_DAILY_SHIPMENT_PER_NODE && 
            Number(event.detail.value) <= UPPER_LIMIT_FOR_DAILY_SHIPMENT_PER_NODE && 
            setExpectedShipmentPerDay(event.detail.value)} 
        />
      </FormField>
      <FormField label="Expected launch date"
        info={<Popovers content={EXPECTED_LAUNCH_DATE}></Popovers>}
      >
        <DatePicker
          onChange={event => setExpectedLaunchDate(event.detail.value)}
          placeholder="YYYY/MM/DD"
          value={expectedLaunchDate}
          nextMonthAriaLabel="Next month"
          previousMonthAriaLabel="Previous month"
          todayAriaLabel="Today"
          isDateEnabled={validateDateIsInFuture}
        />
      </FormField>
    </Modal>
  );
}

export default CreateLaunchPlanModal;