import React, { Component } from "react";
import { Redirect } from "react-router";
import SelectWarehouseForm from "../../../components/SelectWarehouse/SelectWarehouseForm";
import UnAuthorizedAccessAlert from "../../../views/layouts/UnAuthorizedAccessAlert";

class SelectWarehouseOpConfig extends Component {
  constructor(props) {
    super(props);

    this.state = {
      warehouse: "",
      redirect: false,
      redirectPath: "",
    };
  }

  constructRedirectPath = (warehouse) => {
    const redirectPath = "/op-config/" + warehouse + "/settings";
    this.setState({
      warehouse: warehouse,
      redirectPath: redirectPath,
      redirect: true,
    });
  };

  render() {
    if (this.props.isAuthenticated) {
      if (this.state.redirect) {
        return (
          <Redirect
            push
            to={{
              pathname: this.state.redirectPath,
              state: { warehouse: this.state.warehouse },
            }}
          />
        );
      }

      return (
        <div>
          {this.props.isExternal && <UnAuthorizedAccessAlert/>}
          {!this.props.isExternal && <SelectWarehouseForm
            warehouse={this.state.warehouse}
            constructRedirectPath={this.constructRedirectPath}
          />}
        </div>
      );
    }
    this.props.history.push("/login");
    return null;
  }
}

export default SelectWarehouseOpConfig;
