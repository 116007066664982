const ARSENAL_EU='https://arsenal-eu.amazon.com/';
const ARSENAL_NA='https://arsenal-na.amazon.com/';
const ARSENAL_PDX='https://arsenal-jp.amazon.com/';
const ARSENAL_BETA='https://arsenal.integ.amazon.com/';
const fetchArsenalLink = function(region, environment) {
    console.log(region, environment)
    if (environment === 'prod') {
        switch(region.toLowerCase()) {
            case 'iad':
                return ARSENAL_NA;
            case 'dub':
                return ARSENAL_EU;
            case 'pdx':
                return ARSENAL_PDX;
            default:
                return ARSENAL_BETA;
        }
    } else {
        return ARSENAL_BETA
    }
};
export { fetchArsenalLink };