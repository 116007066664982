import React, { useState } from "react";
import PolarisButton from "@amzn/awsui-components-react/polaris/button";
import Box from "@amzn/awsui-components-react/polaris/box";
import Header from "@amzn/awsui-components-react/polaris/header";
import Container from "@amzn/awsui-components-react/polaris/container";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input from "@amzn/awsui-components-react/polaris/input";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import { Key } from "ts-key-enum";
import { BaseChangeDetail } from "@amzn/awsui-components-react/polaris/input/interfaces";
import { NonCancelableCustomEvent } from "@amzn/awsui-components-react";

const TRANSFER_ID_REGEX = new RegExp(/^[A-Z0-9-]+$/);

interface SelectAmznShipRefIdFormProps {
  constructRedirectPath: (input: string) => void;
  containerless?: boolean;
}

export function SelectAmznShipRefIdForm({
  constructRedirectPath,
  containerless
}: SelectAmznShipRefIdFormProps) {
  const [transferId, setTransferId] = useState("");

  function handleFormSubmit(e) {
    e.preventDefault();

    if (isValidInput()) {
      constructRedirectPath(transferId);
    }
  }

  function handleChange(e: NonCancelableCustomEvent<BaseChangeDetail>) {
    // @ts-expect-error
    e.preventDefault();

    const trimmedTransferId = e.detail.value.trim();
    setTransferId(trimmedTransferId.toUpperCase());
  }

  function handleKeyDown(e) {
    if (isValidInput() && isEnterKey()) {
      constructRedirectPath(transferId);
      setTransferId("");
      e.preventDefault();
    }

    function isEnterKey() {
      return e?.detail?.key === Key.Enter;
    }
  }

  // TODO: add better input validation to prevent redundant calls to downstream service.
  function isValidInput() {
    return TRANSFER_ID_REGEX.test(transferId);
  }

  function BaseFormContent() {
    return (
      <ColumnLayout columns={2}>
        <FormField label="Amazon Shipment Reference ID">
          <Input
            value={transferId}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder="e.g. VUSB-VUSA-1038793366-10073159670924"
            type="search"
            autoFocus={true}
          />
        </FormField>
        <FormField label="ㅤ">
          <PolarisButton
            iconName="search"
            variant="primary"
            onClick={handleFormSubmit}
            disabled={!isValidInput()}
          >
            Search
          </PolarisButton>
        </FormField>
      </ColumnLayout>
    );
  }

  if (containerless) {
    return <>{BaseFormContent()}</>;
  }

  return (
    <div className="select-container">
      <Box margin={{ vertical: "l", horizontal: "l" }}>
        <Container
          header={
            <Header variant="h2">Select Amazon Shipment Reference ID</Header>
          }
        >
          {BaseFormContent()}
        </Container>
      </Box>
    </div>
  );
}
