export const getErrorMessage = (label) => {
    let errorMessageMapper = {
        warehouseId: "Invalid WarehouseId",
        orderId: "Invalid OrderId",
        problemItemList: "Invalid Problem Item Ids",
        warehouseList: "Invalid Warehouse List",
        invalidContainerName: "Container Name Invalid",
        mismatchContainerNameAndContainerType: "Container Name and Container Type does not match",
        noDescription: "Please provide description",
        invalidParentContainer: "Parent Container Invalid",
        levelMismatchContainerAndParent: "Parent Container should be of higher level than container",
        invalidSchemaName: "Schema Name cannot be empty or contain numeric values",
        invalidHierarchyList: "Hierarchy List cannot be empty",
        emptyFields: "Fields cannot be empty",
        invalidParamName: "Invalid Param Name",
        notNumeric: "Invalid Min or Max value, should be numeric for Param : ",
        invalidlistValidValues: "Valid Values is invalid for Param : ",

    }
    return errorMessageMapper[label];
}