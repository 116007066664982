import React from "react";
import Select from "react-select";

const DropDownMultiSelectField =  ({list, value, param, handleChange, error}) => {
  let dropDownMultiSelectFieldClasses = (error === true) ? "basic-multi-select config-field-input error-input": "basic-multi-select config-field-input";
  return(
        <div className="config-field display-inline">
          <Select
            defaultValue={value}
            isMulti
            options={list}
            className={dropDownMultiSelectFieldClasses}
            classNamePrefix="select"
            onChange={(e) => handleChange(e)}
          />
        </div>
      )
};

export default DropDownMultiSelectField;