import Header from "@amzn/awsui-components-react/polaris/header";
import Container from "@amzn/awsui-components-react/polaris/container";
import React, {useEffect, useState} from "react";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import {ColumnLayout, StatusIndicator} from "@amzn/awsui-components-react";
import {
  ValueWithLabel
} from "@modules/inbound/inventoryReceiveNotification/table/inventoryReceiptDetail/ValueWithLabel";

type Type = 'error' | 'warning' | 'success' | 'info' | 'stopped' | 'pending' | 'in-progress' | 'loading';

export const InventoryReceiptDetailsHeader = (
  {
    siteName,
    receiveType,
    status,
    failureReason,
    creationDate
  }
) => {
  const [successStatus, setSuccessStatus] = useState<Type>('loading');
  useEffect(() => {
    if (status === 'SUCCESS') {
      setSuccessStatus('success');
    } else if (status === 'FAILURE') {
      setSuccessStatus('error');
    }
  }, [status])

  return (
    <Container header={<Header headingTagOverride="h3">Inventory Receipt Details</Header>}>
      <ColumnLayout columns={2} variant="text-grid">
        <SpaceBetween size="m">
          <ValueWithLabel label="Site">{siteName}</ValueWithLabel>
          <ValueWithLabel label="Receive Type">{receiveType}</ValueWithLabel>
          <ValueWithLabel label="Status">
            <StatusIndicator type={successStatus}>{status}</StatusIndicator>
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="m">
          <ValueWithLabel label="Failure Reason">{failureReason}</ValueWithLabel>
          <ValueWithLabel label="Creation Date">{creationDate}</ValueWithLabel>
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  )
}