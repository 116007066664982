import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout"
import Flashbar from "@amzn/awsui-components-react/polaris/flashbar"
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between"
import queryString from "query-string";
import _ from "lodash";
import API from "@aws-amplify/api";

import { Registration, NodeLaunchPlan, Filter } from "../Types";
import RegistrationDetailsContainer from "./RegistrationDetailsContainer";
import CreateLaunchPlanModal from "./CreateLaunchPlanModal";
import { FlashbarCustomProps } from "@common/Types";
import NodeLaunchPlansTable from "./NodeLaunchPlansTable";
import { getAuthorizationHeader } from "@common/Auth";
import {useContext} from "react";
import {RegionContext} from "@common/RegionContext";
import {constructButaneApiName} from "../../../apiConfig";

interface RegistrationDetailsProps extends RouteComponentProps<{ registrationId: string }> {
  isAuthenticated: boolean;
  userHasAuthenticated: boolean;
}

const RegistrationDetails = (props: RegistrationDetailsProps) => {
  if (!props.isAuthenticated) {
    props.history.push("/login");
  }
  const [registration, setRegistration] = React.useState<Registration>();
  const [nodeLaunchPlans, setNodeLaunchPlans] = React.useState<NodeLaunchPlan[]>([]);
  const [newNodeLaunchPlanModalVisible, setNewNodeLaunchPlanModalVisible] = React.useState<boolean>(false);
  const [flashbarProps, setFlashbarProps] = React.useState<FlashbarCustomProps>({});
  const [registrationDetailsLoading, setRegistrationDetailsLoading] = React.useState<boolean>(true);
  const [nodeLaunchPlansTableLoading, setNodeLaunchPlansTableLoading] = React.useState<boolean>(true);
  const regionContext = useContext(RegionContext);

  React.useEffect(() => {
    const fetchRegistration = async () => {
      setRegistrationDetailsLoading(true);
      API.get(constructButaneApiName(regionContext.region), `/api/v1/registrations/${props.match.params.registrationId}`, {
          headers: await getAuthorizationHeader()
        })
        .then((registration: Registration) => {
          setRegistration(registration);
        }).catch((error) => {
          setFlashbarProps({ header: error.response.data.code, msg: error.response.data.message, type: "error" });
        }).finally(() => setRegistrationDetailsLoading(false));
    }

    fetchRegistration();
    fetchNodeLaunchPlans({});

    // eslint-disable-next-line
  }, [props.match.params.registrationId]);

  const fetchNodeLaunchPlans = async (filter: Filter) => {
    setNodeLaunchPlansTableLoading(true);
    API.get(constructButaneApiName(regionContext.region), `/api/v1/registrations/${props.match.params.registrationId}/launchPlans?${queryString.stringify(filter)}`, {
        headers: await getAuthorizationHeader()
      })
      .then((response: { nodeLaunchPlans: NodeLaunchPlan[] }) => {
        response.nodeLaunchPlans.sort((a, b) => b.createdDate - a.createdDate);
        setNodeLaunchPlans(response.nodeLaunchPlans);
      }).catch((error) => {
        setFlashbarProps({ header: error.response.data.code, msg: error.response.data.message, type: "error" });
      }).finally(() => {
        setNodeLaunchPlansTableLoading(false);
      });
  }

  const createNewLaunchPlan = async (nodesPlanned: number,  expectedShipmentPerDay: number, expectedShipmentLaunchDate: Date) => {
    const requestBody = {
      nodesPlanned: nodesPlanned,
      nodesOperational: 0,
      expectedShipmentVolumePerDay: expectedShipmentPerDay,
      expectedLaunchDate: expectedShipmentLaunchDate.getTime() / 1000
    }

    setNodeLaunchPlansTableLoading(true);
    API.post(constructButaneApiName(regionContext.region), `/api/v1/registrations/${props.match.params.registrationId}/launchPlans`, { 
        body: requestBody, 
        headers: await getAuthorizationHeader()
      })
      .then((response) => {
        setFlashbarProps({
          header: "Node launch plan created successfully",
          msg: `New Node launch plan was created under registration : "${props.match.params.registrationId}" with node launch plan id: "${response.nodeLaunchPlanId}"`,
          type: "success"
        });
        fetchNodeLaunchPlans({});
      }).catch((error) => {
        setFlashbarProps({ header: error.response.data.code, msg: error.response.data.message, type: "error" })
        setNodeLaunchPlansTableLoading(false);
      })
  }

  return (
    <AppLayout
      navigationHide={true}
      toolsHide={true}
      headerSelector="#top-navigation"
      content={
        <>
          {
            newNodeLaunchPlanModalVisible &&
            <CreateLaunchPlanModal
              visible={newNodeLaunchPlanModalVisible}
              hideModal={() => setNewNodeLaunchPlanModalVisible(false)}
              submitForm={createNewLaunchPlan} />
          }
          <SpaceBetween size="m">
            {
              (!_.isEmpty(flashbarProps.header) || !_.isEmpty(flashbarProps.msg)) &&
              <Flashbar
                items={[{
                  header: flashbarProps.header,
                  content: flashbarProps.msg,
                  type: (flashbarProps.type ? flashbarProps.type : "error"),
                  dismissible: true,
                  onDismiss: () => setFlashbarProps({ header: "", msg: "" })
                }]}
              />
            }

            <RegistrationDetailsContainer
              loading={registrationDetailsLoading}
              registrationId={props.match.params.registrationId}
              registration={registration}
            />
            <NodeLaunchPlansTable
              loading={nodeLaunchPlansTableLoading}
              showNewNodeLaunchPlanModal={() => setNewNodeLaunchPlanModalVisible(true)}
              nodeLaunchPlans={nodeLaunchPlans}
              fetchData={fetchNodeLaunchPlans}
            />
          </SpaceBetween>
        </>
      }
    />
  );
};

export default RegistrationDetails;