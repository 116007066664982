import React, {useContext, useEffect} from "react";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import Link from "@amzn/awsui-components-react/polaris/link";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { StatusCodes } from "http-status-codes";

import { useFlashbar } from "@common/flashbar";
import {
  StandaloneValue,
  ValueWithLabel,
  SpinnerWithLabel
} from "@common/presentation";
import { useGetTransferDepartWorkflowDetails } from "@modules/transfer/hooks";
import { WorkflowDetailsStepper } from "@modules/transfer/departure-requests/components";
import { WorkflowStateConverter } from "@modules/transfer/departure-requests/logic";
import { fetchArsenalLink } from "@constants/FetchArsenalLink";
import {RegionContext} from "@common/RegionContext";
import {getEnvironment} from "@modules/signup/Utils";

const UNKNOWN_WORKFLOW_STATE = "Unknown";

const CLIENT_ID = "transfer-manifest-service";
const INSTRUCTION_ID = "EFP.TransferManifestService";

interface WorkflowDetailsParams {
  sourceWarehouse: string;
  amznShipRefId: string;
}

interface WorkflowDetailsProps
  extends RouteComponentProps<WorkflowDetailsParams> {}

export const WorkflowDetails = withRouter(({ match }: WorkflowDetailsProps) => {
  const {
    params: { sourceWarehouse, amznShipRefId }
  } = match;

  const { addMessage, clearAllMessages } = useFlashbar();
  const regionContext = useContext(RegionContext);

  const {
    isLoading: isLoadingWorkflowDetails,
    error,
    getTransferDepartWorkflowDetails,
    transferDepartWorkflowDetails
  } = useGetTransferDepartWorkflowDetails();

  useEffect(() => {
    handleRefreshClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceWarehouse, amznShipRefId]);

  async function handleRefreshClick() {
    clearAllMessages();

    await getTransferDepartWorkflowDetails(amznShipRefId);
  }

  useEffect(() => {
    if (error) {
      switch (error.statusCode) {
        case StatusCodes.NOT_FOUND:
          return addMessage({
            header: error.message,
            type: "warning"
          });
        default:
          return addMessage({
            header: error.message,
            content: error.details ?? null,
            type: "error",
            buttonText: "Retry",
            dismissible: true,
            onButtonClick: async () => {
              await handleRefreshClick();
            }
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  function hasApiResponse() {
    return transferDepartWorkflowDetails || error;
  }

  function getHerdUrl(objectId: string) {
    return `https://${getHerdDomain()}/ui/work.jsp?clientId=${CLIENT_ID}&objectId=${objectId}&instructionId=${INSTRUCTION_ID}`;

    function getHerdDomain() {
      if (getEnvironment() !== "prod") {
        return 'herdui.integ.amazon.com';
      } else {
        switch (regionContext.region) {
          case 'dub':
            return 'herdui-eu.amazon.com'
          case 'iad':
            return 'herdui-na.amazon.com'
          case 'pdx':
            return 'herdui-fe.amazon.com'
          default:
            return 'herdui.integ.amazon.com'
        }
      }
    }
  }

  function getArsenalUrl(messageId: string) {
    return `${fetchArsenalLink(regionContext.region, getEnvironment())}/search?id=${messageId}`;
  }
  
  function HerdState() {
    if (transferDepartWorkflowDetails?.isClosed) {
      return <StatusIndicator type="success">Closed</StatusIndicator>;
    }

    const butaneState = getButaneState();

    if (transferDepartWorkflowDetails?.isErrored) {
      return <StatusIndicator type="error">{butaneState}</StatusIndicator>;
    }
    if (transferDepartWorkflowDetails?.isOpen) {
      return (
        <StatusIndicator type="in-progress">{butaneState}</StatusIndicator>
      );
    }

    return <StatusIndicator type="info">{butaneState}</StatusIndicator>;

    function getButaneState() {
      if (!transferDepartWorkflowDetails) {
        return UNKNOWN_WORKFLOW_STATE;
      }

      const herdState = WorkflowStateConverter.toHerdState(
        transferDepartWorkflowDetails
      );
      return (
        WorkflowStateConverter.toButaneState(herdState) ??
        UNKNOWN_WORKFLOW_STATE
      );
    }
  }

  function Details() {
    let messageId = amznShipRefId.split("-").slice(-1)[0];
    return (
      <>
        {!error && (
          <WorkflowDetailsStepper
            transferDepartWorkflow={transferDepartWorkflowDetails}
          />
        )}
        <ColumnLayout columns={3} variant="text-grid">
          <SpaceBetween size="l">
            <ValueWithLabel label="Amazon Shipment Reference ID">
              <Link external={true} href={getHerdUrl(amznShipRefId)}>
                {amznShipRefId}
              </Link>
            </ValueWithLabel>
            <ValueWithLabel label="Depart Message">
              <Link external={true} href={getArsenalUrl(messageId)}>
                {messageId}
              </Link>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label="State">
              <HerdState />
            </ValueWithLabel>
            <ValueWithLabel label="Destination Warehouse">
              <StandaloneValue
                value={transferDepartWorkflowDetails?.destinationWarehouse}
              />
            </ValueWithLabel>
          </SpaceBetween>
        </ColumnLayout>
      </>
    );
  }

  if (isLoadingWorkflowDetails || !hasApiResponse()) {
    return (
      <SpinnerWithLabel
        label={`Loading workflow details for ${amznShipRefId}`}
      />
    );
  }

  return <Details />;
});
