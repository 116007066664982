import {useCallback, useState} from "react";
import {ApiError} from "@common/exception";
import {InventoryReceiptDetail, LineItem} from "@modules/inbound/inventoryReceiveNotification/model";
import {CommonUtils} from "@modules/inbound/inventoryReceiveNotification/common";

export function useGetInventoryReceiptDetails() {
  const [error, setError] = useState<ApiError | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [lineItemData, setLineItemData] = useState<LineItem[]>([]);
  const [inventoryReceiptDetailData, setInventoryReceiptDetailData] =
    useState<InventoryReceiptDetail | null>(null);

  const getInventoryReceiptDetail = useCallback(
    async (siteName: string, mcnBatchId: string) => {
      setIsLoading(true);
      setError(null);
      try {
        let receiveExecutions = [] as unknown as LineItem[];
        const receiveExecution = new LineItem(siteName, 'fnSku', 'po',
          5, 'SELLABLE', 'PRIME', 'Success', 'fcSku', []);
        receiveExecutions.push(receiveExecution);
        const inventoryReceiptDetail = new InventoryReceiptDetail(siteName,
          'mcn', 'batchId', 'poId', 'RECEIVE', 'OTHER',
          '2023', receiveExecutions);
        setLineItemData(receiveExecutions);
        setInventoryReceiptDetailData(inventoryReceiptDetail);
      } catch (exception) {
        const apiError = CommonUtils.getApiError(exception, siteName, 'inventory receipt detail');
        setError(apiError);
        setLineItemData([]);
      }
      setIsLoading(false);
    }, [])

  return {
    error,
    isLoading,
    lineItems: lineItemData,
    inventoryReceiptDetail: inventoryReceiptDetailData,
    getInventoryReceiptDetail
  }

}