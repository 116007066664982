import { amazonEmailRegex, passwordRegex } from "../../constants/Regex";

export function validateEmail(email: string) {
    return amazonEmailRegex.test(email.toLowerCase());
};

export function validatePassword(password: string) {
    return passwordRegex.test(password) && password.length >=8;
};


export function arePasswordsEquals(newPassword: string, confirmPassword: string) {
    return newPassword !== undefined && confirmPassword !== undefined && newPassword.length !==0  && (newPassword === confirmPassword) ;
};

//simple email regex
export const isValidEmailRegex = (email: string) => /^.+@.+\..+$/.test(email);

export const getEnvironment = () => {
    const hostname = window.location.hostname;

    if (process.env.NODE_ENV === "development") {
        return  'localhost'
    } else {

        const initialSubdomain = `${hostname}`.split(".")[0];

        if (initialSubdomain === 'butane') {
            return  'prod'
        } else {
            return  initialSubdomain;
        }
    }
}