
const milliSecondsToDateFormatter = new Intl.DateTimeFormat(
  `${navigator.language}`,
  {
    timeZoneName: "short",
    year: "numeric", month: "long", day: "numeric",
    hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false
  }
);

const formatDate = (seconds: number) => {
  return milliSecondsToDateFormatter.format(new Date(seconds * 1000));
}

export default formatDate;