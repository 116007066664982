import Link from "@amzn/awsui-components-react/polaris/link";
import Table from "@amzn/awsui-components-react/polaris/table";
import React from "react";

interface CommittedSummaryDetailsTableProps {
    committedOrderQuantity: number,
    committedTransferQuantity: number,
    committedRemovalQuantity: number,
    committedGradingQuantity: number,
    onSelectDropDown(value: string): any
}

export const CommittedSummaryDetailsTable = (props: CommittedSummaryDetailsTableProps) => {
    return <Table
        items={[{
            committedOrderQuantity: props.committedOrderQuantity,
            committedTransferQuantity: props.committedTransferQuantity,
            committedRemovalQuantity: props.committedRemovalQuantity,
            committedGradingQuantity: props.committedGradingQuantity,

        }]}
        columnDefinitions={[
            {
                id: "order",
                header: "Order",
                cell: e => e.committedOrderQuantity === 0 ? e.committedOrderQuantity :
                    <Link
                        onFollow={() => {props.onSelectDropDown("Order")}}
                    >{e.committedOrderQuantity}
                    </Link>,
            },
            {
                id: "transfer",
                header: "Transfer",
                cell: e => e.committedTransferQuantity === 0 ? e.committedTransferQuantity :
                    <Link
                        onFollow={() => {props.onSelectDropDown("Transfer")}}
                    >{e.committedTransferQuantity}
                    </Link>,
            },
            {
                id: "removal",
                header: "Removal",
                cell: e => e.committedRemovalQuantity === 0 ? e.committedRemovalQuantity :
                    <Link
                        onFollow={() => {props.onSelectDropDown("Removal")}
                    }>{e.committedRemovalQuantity}
                    </Link>,
            },
            {
                id: "grading",
                header: "Grading",
                cell: e => e.committedGradingQuantity === 0 ? e.committedGradingQuantity :
                    <Link
                        onFollow={() => {props.onSelectDropDown("Grading")}}
                    >{e.committedGradingQuantity}
                    </Link>,
            },
        ]}
    />
}