import React, { Component } from "react";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import Input from "@amzn/awsui-components-react/polaris/input";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Button from "@amzn/awsui-components-react/polaris/button";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";


import { warehouseIdRegex } from "@constants/Regex";
import { Alert } from "@amzn/awsui-components-react";

class CancelTransferRequestForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            warehouseId: "",
            transferRequestIds: "",
            warehouseIdModified: false,
            transferRequestIdModified: false,
            cancelConfirmation: false,
        };
    }


    onWarehouseIdChange = e => {
        this.setState(
            {
                ...this.state,
                warehouseId: e.detail.value,
                warehouseIdModified: true
            }
        );
    }

    onTransferRequestIdsChange = e => {
            this.setState(
                {
                    ...this.state,
                    transferRequestIds: e.detail.value,
                    transferRequestIdModified: true
                }
            );
        }


    areAllInputsValid = () => {
        return (!!this.state.warehouseId && warehouseIdRegex.test(this.state.warehouseId)
            && this.state.cancelConfirmation);
    };


    isWarehouseIdUpdatedAndInvalid = () => {
        return (this.state.warehouseIdModified && !warehouseIdRegex.test(this.state.warehouseId));
    }

    isTransferRequestIdsUpdatedAndInvalid = () => {
        return (this.state.transferRequestIdsModified && this.state.transferRequestIds === "");
    }

    handleFormSubmit = (event) => {
        event.preventDefault();
        const transferRequestIdsArray = this.state.transferRequestIds.split(",").map(requestId => requestId.trim())
        this.props.handleSubmit(this.state.warehouseId, transferRequestIdsArray);
    }

    confirmCancellation = () => {
        this.setState({
            ...this.state,
            cancelConfirmation: true
        });
    }

    render() {
        return (
                <Container header={ <Header variant="h2"> Cancel Transfer Request </Header>}>
                    <SpaceBetween size="l">
                        <Alert
                            visible = {!this.state.cancelConfirmation}
                            statusIconAriaLabel="Info"
                            action={<Button variant="primary" onClick={this.confirmCancellation}>Confirm</Button>}
                            header="Please confirm the transfer requests are cancelled in WMS"
                        >
                            Before using this cancellation tool, the transfer request should be cancelled in WMS as well
                        </Alert>
                        <FormField label="Warehouse ID">
                            <Input
                                inputMode="text"
                                name="warehouseId"
                                placeholder="eg. VUSB"
                                onChange={ this.onWarehouseIdChange }
                                invalid={this.isWarehouseIdUpdatedAndInvalid()}
                                value={this.state.warehouseId} />
                        </FormField>
                        <FormField label="List of Transfer Request IDs (comma separated)">
                            <Input
                                inputMode="text"
                                name="transferRequestId"
                                placeholder="eg. VF-VUSB-VUSB-1111111111-1111111111, VF-VUSB-VUSB-22222222-22222222"
                                onChange={ this.onTransferRequestIdsChange }
                                invalid={this.isTransferRequestIdsUpdatedAndInvalid()}
                                value={this.state.transferRequestIds} />
                        </FormField>
                        <Button
                            variant="primary"
                            disabled={!this.areAllInputsValid()}
                            onClick={this.handleFormSubmit}>
                            Cancel
                        </Button>
                    </SpaceBetween>
                </Container>
        )
    }
}

export default CancelTransferRequestForm;