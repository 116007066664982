export default class RemovalOrderLevelInfo {
    constructor(removalOrderId, shipmentRequestId, fulfillmentChannel, condition, warehouseId, totalQuantityRequested, 
        totalQuantityCancelled, totalQuantityShipped, totalQuantityInProcess, totalQuantityUnavailable) {
        this.removalOrderId = removalOrderId;
        this.shipmentRequestId = shipmentRequestId;
        this.fulfillmentChannel = fulfillmentChannel;
        this.condition = condition;
        this.warehouseId = warehouseId;
        this.totalQuantityRequested = totalQuantityRequested;
        this.totalQuantityCancelled = totalQuantityCancelled;
        this.totalQuantityShipped = totalQuantityShipped;
        this.totalQuantityInProcess = totalQuantityInProcess;
        this.totalQuantityUnavailable = totalQuantityUnavailable;
    }
}