import React, { Component } from "react";

/* Import Components */
import ProblemItemDetailsInfoForm from "./ProblemItemDetailsInfoForm";
import ProblemItemsDetail from "./ProblemItemsDetail";

class ProblemItemInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            warehouseId: ''
        }
    }

    handleSubmit = (warehouseId) => {
        this.setState(() => ({
            warehouseId: warehouseId,
        }));
    }

    render() {
        if (this.props.isAuthenticated) {
            return (
                <div>
                    <ProblemItemDetailsInfoForm
                        warehouseId={this.state.warehouseId}
                        handleSubmit={this.handleSubmit}
                    />
                    <ProblemItemsDetail
                        warehouseId={this.state.warehouseId}
                    />
                </div>
            );
        }
        this.props.history.push("/login");
        return null;
    }
}

export default ProblemItemInfo;