import enhancedWithRowData from '../../../components/Table/RowDataHelper';
import DeleteRPI from '../../../modules/inbound/problemreceive/DeleteRPI';
import ResolveRPI from '../../../modules/inbound/problemreceive/ResolveRPI';

export const PROBLEM_RECEIVE_ITEM_DETAIL_METADATA = [
  {
    columnName: 'problemItemId',
    width: '30rem',
    displayName: 'RPI Id'
  },
  {
    columnName: 'fnsku',
    width: '30rem',
    displayName: 'FNSKU'
  },
  {
    columnName: 'orderId',
    width: '30rem',
    displayName: 'Order Id'
  },
  {
    columnName: 'quantity',
    width: '30rem',
    displayName: 'Quantity'
  },
  {
    columnName: 'resolveRPI',
    width: '30rem',
    displayName: 'Resolve RPI',
    customComponent: enhancedWithRowData(ResolveRPI)
  },
  {
    columnName: 'blowOutRPI',
    width: '30rem',
    displayName: 'Blow Out RPI',
    customComponent: enhancedWithRowData(DeleteRPI)
  }
];
