import React, { Component } from "react";
import { Alert } from "@material-ui/lab";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import API from "@aws-amplify/api";
import PageTitle from "../../../components/PageTitle/PageTitle";
import UnauthorizedUser from "../../../views/layouts/UnauthorizedUser";
import ContentEditable from "./ContentEditable";
import "../config.css";
import { getAuthorizationHeader } from "@common/Auth";
import BackendError from "../../../views/layouts/BackendError";
import {RegionContext} from '@common/RegionContext';
import {constructButaneApiName} from '../../../apiConfig';

const CONTAINER = "warehouse";
const SEPERATOR = "-"; 

class NodeSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
      editDisabled: true,
      warehouse: this.props.location.state.warehouse,
      formResponse: [],
      formFieldMapping: [],
      addInputElement: false,
      addButtonDisabled: false,
      showEditOption: true,
      showDeleteOption: true,
      fetchingRecords: true,
      noResult: false,
      successfulUpdate: 0,
      openForbiddenSnackbar: false,
      networkError: false
    };
  }

  onUpdate = (paramName, paramValue, index, isValidParamName, container , schemaName, version) => {
    this.setState({
      disabled: !isValidParamName
    });
    let obj = {
      paramName: paramName,
      paramValue: paramValue,
      container: (container !==undefined)? container : null,
      schemaName: (schemaName !== undefined)? schemaName : null,
      version: (version !== undefined)? version : null
    };
    const formFieldMapping = this.state.formFieldMapping;
    formFieldMapping[index] = obj;
    this.setState({ formFieldMapping });
  };

  getContainerNameForConfig() {
    const {warehouse} = this.state;
    return CONTAINER + SEPERATOR + warehouse;
  }

  
  async getVWPConfig() {
    return API.get(
      constructButaneApiName(this.context.region),
      `/vwp/config/warehouse/${this.state.warehouse}`, {
        headers: await getAuthorizationHeader()
      }
    )
      .then(response => {
        this.setState({
          formResponse: response.config
        });
        if (this.state.formResponse.length === 0) {
          this.setState({
            fetchingRecords: false,
            noResult: true
          });
        } else {
          this.fetchVWPDescription();
        }
      })
      .catch(error => {
        if (typeof error.response === 'undefined') {
          this.setState(() => ({ networkError: true }));
        }
        else (error.response.status === 403) ?
          this.setState(() => ({ openForbiddenSnackbar: true })): this.setState(() => ({ openForbiddenSnackbar: false }));
        this.setState(() => ({
          fetchingRecords: false,
          noResult: true
        }));
      });
  }

  componentDidMount() {
    this.getVWPConfig();
  }

  async fetchVWPDescription() {
    if (this.state.formResponse.length !== 0) {
      return API.get(constructButaneApiName(this.context.region), `/vwp/param-details`, {
        headers: await getAuthorizationHeader()
      })
        .then(response => {
          let paramDescription = response.paramDetails;
          let paramDetails = this.state.formResponse;
          paramDetails.map(obj => {
            let paramName = obj.paramName;
            obj.description = paramDescription[paramName];
            return obj;
          });
          this.setState({
            formResponse: paramDetails,
            fetchingRecords: false
          });
        })
        .catch(error => {
          if (typeof error.response === 'undefined') {
            this.setState(() => ({ networkError: true }));
          }
          error.response.status === 403 ? 
          this.setState(() => ({ openForbiddenSnackbar: true })): this.setState(() => ({ openForbiddenSnackbar: false }));
          this.setState(() => ({
            fetchingRecords: false
          }));
        });
    }
  }

  handleFormSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      editDisabled: true,
      disabled: true
    });
    // Update API
    let formFieldMappings = this.state.formFieldMapping;
    let updatedNodeSettings = formFieldMappings.filter(
      obj => Object.keys(obj).length !== 0
    );
    let requestObject = {
      warehouse: this.state.warehouse,
      config: updatedNodeSettings
    };
    let init = {
      body: requestObject,
      headers: await getAuthorizationHeader()
    };
    return API.post(constructButaneApiName(this.context.region), `/vwp/setup-node-config`, init)
      .then(response => {
        this.setState({ successfulUpdate: 1 });
        this.reloadVWPConfig();
      })
      .catch(error => {
        if (typeof error.response === 'undefined') {
          this.setState(() => ({ networkError: true }));
        }
        error.response.status === 403 ? 
          this.setState(() => ({ openForbiddenSnackbar: true })): this.setState(() => ({ openForbiddenSnackbar: false }));
        this.setState({ successfulUpdate: 2});
      });
  };

  reloadVWPConfig = () => {
    this.setState({
      editDisabled: true,
      formResponse: [],
      formFieldMapping: [],
      addInputElement: false,
      addButtonDisabled: false,
      showEditOption: true,
      showDeleteOption: true,
      fetchingRecords: true
    });
    this.getVWPConfig();
  };

  addInputElement = () => {
    this.setState({
      addInputElement: true,
      addButtonDisabled: true
    });
  };

  deleteInputElement = () => {
    this.setState({
      addInputElement: false,
      addButtonDisabled: false
    });
  };

  adaptColumnConfig = response =>
    response.map(object => ({
      type: "TEXT",
      name: object.paramName,
      attributes: {
        label: object.paramName,
        value: object.paramValue,
        description: object.description,
        container: object.container,
        schemaName: object.schemaName,
        version: object.version
      }
    }));

  render() {
    if (this.props.isAuthenticated) {
      if (!!this.state.fetchingRecords) {
        return <div className="spinner"></div>;
      }
      if(this.state.openForbiddenSnackbar) {
        return <UnauthorizedUser openErrorSnackbar = {true} />;
      }
      if(this.state.networkError) {
        return <BackendError/>;
      }
      if (!this.state.fetchingRecords && !!this.state.noResult) {
        return (
          <div>
            <div>
              <PageTitle>Node Settings</PageTitle>
            </div>
            <Alert severity="warning">
              No Results found for following warehouse: {this.state.warehouse}
            </Alert>
          </div>
        );
      }

      const { formResponse } = this.state;
      const columnConfig = this.adaptColumnConfig(formResponse);
      const items = columnConfig.map((config, index) => (
        <ContentEditable
          editDisabled={this.state.editDisabled}
          paramName={config.attributes.label}
          paramValue={config.attributes.value}
          description={config.attributes.description}
          showEditOption={this.state.showEditOption}
          index={index}
          onChange={this.onUpdate}
          container={config.attributes.container}
          schemaName={config.attributes.schemaName}
          version={config.attributes.version}
        />
      ));
      let addParam;
      const index = columnConfig.length;
      if (this.state.addInputElement) {
        const editDisabled = false;
        addParam = (
          <ContentEditable
            editDisabled={editDisabled}
            paramName=""
            paramValue=""
            showEditOption={!this.state.showEditOption}
            showDeleteOption={this.state.showDeleteOption}
            index={index}
            onChange={this.onUpdate}
            onClick={this.deleteInputElement}
            container={this.getContainerNameForConfig()}
          />
        );
      }

      return (
        <div>
          <div>
            <PageTitle>Node Settings: {this.state.warehouse}</PageTitle>
          </div>
          {this.state.successfulUpdate === 1 && (
            <Alert
              onClose={() => {
                this.setState({ successfulUpdate: 0 });
              }}
              severity="info"
            >
              Successfully Updated Node settings for warehouse:{" "}
              {this.state.warehouse}
            </Alert>
          )}
          {this.state.successfulUpdate === 2 && (
            <Alert
              onClose={() => {
                this.setState({ successfulUpdate: 0 });
              }}
              severity="error"
            >
              Update Failure! Please try again later.
            </Alert>
          )}
          <div className="add-parameter-div">
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              style={{ marginRight: 10 }}
              onClick={this.addInputElement}
              disabled={this.state.addButtonDisabled}
            >
              Add
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              disabled={this.state.disabled}
              onClick={this.handleFormSubmit}
            >
              Save
            </Button>
          </div>
          <div className="form-inline">
            <div className="node-settings-form">
              <div className="filters">
                <div className="row">
                  <div className="column left">
                    <p className="para-input">
                      <b>Parameter Name</b>
                    </p>
                  </div>
                  <div className="column middle"></div>
                  <div className="column middle1">
                    <p className="para-input">
                      <b>Value</b>
                    </p>
                  </div>
                  <div className="column right"></div>
                </div>
                <hr className="hr" />
                {addParam}
                {items}
              </div>
            </div>
          </div>
        </div>
      );
    }
    this.props.history.push("/login");
    return null;
  }
}

NodeSettings.contextType = RegionContext;

export default NodeSettings;
