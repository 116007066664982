import { createContext } from "react";
import { FlashbarProps } from "@amzn/awsui-components-react/polaris/flashbar";

type FlashbarContextProps =
  | {
      addMessage: (message: FlashbarProps.MessageDefinition) => void;
      clearAllMessages: () => void;
      deleteMessage: (id: string) => void;
      messages: FlashbarProps.MessageDefinition[];
    }
  | undefined;

export const FlashbarContext = createContext<FlashbarContextProps>(undefined);
