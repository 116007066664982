export interface Region {
    name: string,
    awsRegion: string
}

export const DUB_REGION: Region = {
    name: 'dub',
    awsRegion: 'eu-west-1'
}

export const PDX_REGION: Region = {
    name: 'pdx',
    awsRegion: 'us-west-2'
}

export const IAD_REGION: Region = {
    name: 'iad',
    awsRegion: 'us-east-1'
}

export const ENV_TO_REGIONS = {
    localhost: [DUB_REGION],
    beta: [DUB_REGION],
    gamma: [IAD_REGION],
    prod: [DUB_REGION, IAD_REGION, PDX_REGION]
};

export const ENV_TO_DEFAULT_REGIONS = {
    localhost: DUB_REGION,
    beta: DUB_REGION,
    gamma: IAD_REGION,
    prod: DUB_REGION
};
