import React from "react";
import {
  CollectionPreferences,
  PropertyFilterProps,
  TableProps
} from "@amzn/awsui-components-react";
import RadioGroup from "@amzn/awsui-components-react/polaris/radio-group";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import { PropertyFilterProperty } from "@amzn/awsui-collection-hooks/dist/cjs/interfaces";

import { PROPERTY_FILTERING_I18N_LABELS } from "@common/table";
import { TransferDepartWorkflowDetailsLink } from "./TransferDepartWorkflowDetailsLink";
import { FormattedISOTimestamp } from "@common/date";

export const TRACK_BY_COLUMN_NAME = "amazonShipmentReferenceId";

export const LOADING_TEXT = "Loading Transfer Depart Requests...";

export const ARIA_LABELS = {
  selectionGroupLabel: "Transfer Depart Requests selection",
  allItemsSelectionLabel: ({ selectedItems }) =>
    `${selectedItems.length} ${
      selectedItems.length === 1
        ? "Transfer Depart Request"
        : "Transfer Depart Requests"
    } selected`,
  itemSelectionLabel: ({ selectedItems }, item) => {
    const isItemSelected = selectedItems.filter(
      (i) => i.name === item.name
    ).length;
    return `${item.name} is ${isItemSelected ? "" : "not"} selected`;
  }
};

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<any>[] = [
  {
    id: "amazonShipmentReferenceId",
    header: "Transfer ID",
    cell: (item) => (
      <TransferDepartWorkflowDetailsLink
        amazonShipmentReferenceId={item.amazonShipmentReferenceId}
        warehouse={item.warehouseId}
        workflowId={item.amazonShipmentReferenceId}
      />
    ),
    sortingField: "amazonShipmentReferenceId",
    minWidth: "70px"
  },
  {
    id: "trailerId",
    header: "Trailer ID",
    cell: (item) => item.trailerId,
    sortingField: "trailerId",
    minWidth: "60px"
  },
  {
    id: "warehouseId",
    header: "Source Warehouse",
    cell: (item) => item.warehouseId,
    sortingField: "warehouseId",
    minWidth: "145px"
  },
  {
    id: "destinationWarehouseId",
    header: "Destination Warehouse",
    cell: (item) => item.destinationWarehouseId,
    sortingField: "destinationWarehouseId",
    minWidth: "145px"
  },
  {
    id: "workflowId",
    header: "Herd Workflow ID",
    cell: (item) => item.workflowId,
    sortingField: "workflowId",
    minWidth: "145px"
  },
  {
    id: "creationDate",
    header: "Request Date",
    cell: (item) => (
      <FormattedISOTimestamp date={item.creationDate} format="dateTime" />
    ),
    sortingField: "creationDate",
    minWidth: "145px"
  },
  {
    id: "status",
    header: "Status",
    cell: (item) => item.Status,
    minWidth: "145px"
  }
];

export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: "Main Transfer Depart Request properties",
    options: [
      {
        id: "amazonShipmentReferenceId",
        label: "Transfer ID",
        editable: false,
        visible: true
      },
      {
        id: "trailerId",
        label: "Trailer ID",
        editable: true,
        visible: true
      },
      {
        id: "warehouseId",
        label: "Source Warehouse",
        editable: true,
        visible: true
      },
      {
        id: "destinationWarehouseId",
        label: "Destination Warehouse",
        editable: true,
        visible: true
      },
      {
        id: "workflowId",
        label: "Herd Workflow ID",
        editable: true,
        visible: true
      },
      {
        id: "creationDate",
        label: "Request Date",
        editable: true,
        visible: true
      },
      {
        id: "status",
        label: "Status",
        editable: true,
        visible: true
      }
    ]
  }
];

export const FILTERING_PROPERTIES: PropertyFilterProperty[] = [
  {
    propertyLabel: "Amazon shipment reference ID",
    key: "amazonShipmentReferenceId",
    groupValuesLabel: "Shipment reference ID values",
    operators: [":", "!:", "=", "!="]
  },
  {
    propertyLabel: "Source Warehouse",
    key: "warehouseId",
    groupValuesLabel: "Source Warehouse values",
    operators: [":", "!:", "=", "!="]
  },
  {
    propertyLabel: "Destination Warehouse",
    key: "destinationWarehouseId",
    groupValuesLabel: "Destination Warehouse values",
    operators: [":", "!:", "=", "!="]
  },
  {
    propertyLabel: "Trailer ID",
    key: "trailerId",
    groupValuesLabel: "Trailer ID values",
    operators: [":", "!:", "=", "!="]
  },
  {
    propertyLabel: "Herd Workflow ID",
    key: "workflowId",
    groupValuesLabel: "Herd Workflow ID values",
    operators: [":", "!:", "=", "!="]
  },
  {
    propertyLabel: "Status",
    key: "Status",
    groupValuesLabel: "Depart status values",
    operators: [":", "!:", "=", "!="]
  }
];

export const PROPERTY_FILTERING_LABELS: PropertyFilterProps.I18nStrings = {
  ...PROPERTY_FILTERING_I18N_LABELS,
  filteringPlaceholder: "Filter transfer depart requests by property or value"
};

export const PAGE_SIZE_OPTIONS = [
  { value: 25, label: "25 Transfer Depart Requests" },
  { value: 50, label: "50 Transfer Depart Requests" },
  { value: 100, label: "100 Transfer Depart Requests" }
];

export const CUSTOM_PREFERENCE_OPTIONS = [
  { value: "table", label: "Table" },
  { value: "cards", label: "Cards" }
];

export const DEFAULT_PREFERENCES = {
  pageSize: 25,
  visibleContent: [
    "amazonShipmentReferenceId",
    "trailerId",
    "warehouseId",
    "destinationWarehouseId",
    "workflowId",
    "status",
    "creationDate",
    "bar"
  ],
  wraplines: false,
  custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};

export const Preferences = ({
  preferences,
  setPreferences,
  disabled = false,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  visibleContentOptions = VISIBLE_CONTENT_OPTIONS
}) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: "Page size",
      options: pageSizeOptions
    }}
    wrapLinesPreference={{
      label: "Wrap lines",
      description: "Check to see all the text and wrap the lines"
    }}
    visibleContentPreference={{
      title: "Select visible columns",
      options: visibleContentOptions
    }}
    customPreference={(value, setValue) => (
      <FormField stretch={true} label="View as">
        <RadioGroup
          value={value}
          onChange={({ detail }) => setValue(detail.value)}
          items={CUSTOM_PREFERENCE_OPTIONS}
        />
      </FormField>
    )}
  />
);
