export const fetchRemovalShipmentDetailsColumns = function (getStateDescription, getAction) {
    return [

        {
            field: 'shipmentId',
            width: '15rem',
            title: 'Shipment ID'
        },
        {
            field: 'state',
            width: '15rem',
            title: 'Shipment State',
            render: rowData => getStateDescription(rowData)
        },
        {
            field: 'cancellationReason',
            width: '40em',
            title: 'Comments'
        },
        {
            field: 'lastUpdatedDate',
            width: '15rem',
            title: 'Last Updated Date'
        },
        {
            field: 'action',
            width: '20rem',
            title: 'Action',
            render: rowData => getAction(rowData)
        }
    ];

}