import Box from "@amzn/awsui-components-react/polaris/box"
import Button from "@amzn/awsui-components-react/polaris/button"
import FormField from "@amzn/awsui-components-react/polaris/form-field"
import Input from "@amzn/awsui-components-react/polaris/input"
import Modal from "@amzn/awsui-components-react/polaris/modal"
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between"
import Toggle from "@amzn/awsui-components-react/polaris/toggle"
import * as React from "react";
import * as _ from "lodash";
import Select from "react-select"

import { validateEmail } from "../../signup/Utils";
import {
  FilterBusinessType,
  ConvertEnumToReactSelectFormat,
  WarehouseManagementSystem, BusinessType
} from "@modules/node-management-service/Types";

interface CreateRegistrationModalProps {
  visible: boolean;
  hideModal: () => void;
  submitForm: (businessType: string, marketplace: string, warehouseManagementSystem: string, contact: string, registrationForNewInventoryService: boolean, newInventoryServiceEquipments: any[]) => void;
}

const CreateRegistrationModal: React.FC<CreateRegistrationModalProps> = (props: CreateRegistrationModalProps) => {
  const [businessType, setBusinessType] = React.useState("");
  const [marketplace, setMarketplace] = React.useState("");
  const [warehouseManagementSystem, setWarehouseManagementSystem] = React.useState("");
  const [contact, setContact] = React.useState("");
  const [isRegistrationForNewInventoryService, setIsRegistrationForNewInventoryService] = React.useState(false);
  const [newInventoryServiceEquipments, setNewInventoryServiceEquipments] = React.useState<any[]>([]);
  const [customWMS, setCustomWMS] = React.useState(false);
  const [customBusinessType, setCustomBusinessType] = React.useState(false);

  const dismissModal = () => {
    setBusinessType("");
    setMarketplace("");
    setWarehouseManagementSystem("");
    setContact("");
    setIsRegistrationForNewInventoryService(false)
    setNewInventoryServiceEquipments([])
    props.hideModal();
  }

  
  const newInventoryServiceEquipmentsListValues = [
    { label: "PRIME", value: "PRIME"},
    { label: "RESERVE", value: "RESERVE"},
    { label: "SPECIAL", value: "SPECIAL"},
  ];

  const showNewInventoryServiceEquipmentsInput = () => {
    if ((isRegistrationForNewInventoryService)) {
      return (
        <div>
          <FormField label="Select Equipments for New Inventory Service Nodes"></FormField>
          <Select
            defaultValue={newInventoryServiceEquipments}
            isMulti
            options={newInventoryServiceEquipmentsListValues}
            onChange={(e) => setNewInventoryServiceEquipments(e)}
            maxMenuHeight={100}
          />
        </div>

      )
    }
  }

  const shouldShowCustomWMSField = () => {
    if (customWMS) {
      return (
          <FormField>
            <Input value={warehouseManagementSystem} placeholder={"Please enter the WMS name"}
                   onChange={event => setWarehouseManagementSystem(event.detail.value)} />
          </FormField>
    )}
  }

  const showCustomBusinessTypeField = () => {
    if (customBusinessType) {
      return (
          <FormField>
            <Input value={businessType} placeholder={"Please enter the Business Type"}
                   onChange={event => setBusinessType(event.detail.value)} />
          </FormField>
      )}
  }

  const toggleChecked = () => {
    setIsRegistrationForNewInventoryService((prev) => !prev);
    setNewInventoryServiceEquipments([]);
  };

  const handleWMSChange = (event) => {
    setWarehouseManagementSystem(event && event.value !== WarehouseManagementSystem.OTHER ? event.value : "");
    setCustomWMS(event ? event.value === WarehouseManagementSystem.OTHER : false);
    setBusinessType("");
    setCustomBusinessType(false);
  }

  const handleBusinessTypeChange = (event) => {
    setBusinessType(event && event.value !== BusinessType.OTHER ? event.value : "");
    setCustomBusinessType(event ? event.value === BusinessType.OTHER : false);
  }

  return (
    <Modal
      onDismiss={dismissModal}
      visible={props.visible}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={dismissModal}>Cancel</Button>
            <Button
              variant="primary"
              onClick={
                () => {
                  props.hideModal();
                  props.submitForm(businessType, marketplace, warehouseManagementSystem, contact, isRegistrationForNewInventoryService, newInventoryServiceEquipments)
                }
              }
              disabled={_.isEmpty(businessType) || _.isEmpty(marketplace) || _.isEmpty(warehouseManagementSystem) || _.isEmpty(contact) || !validateEmail(contact) || (isRegistrationForNewInventoryService && (_.isNil(newInventoryServiceEquipments) || newInventoryServiceEquipments.length === 0))}
            >
              Create
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Create new registration"
    >
      <FormField label="Warehouse Management System">
        <Select
          options={ConvertEnumToReactSelectFormat(WarehouseManagementSystem)}
          defaultValue={warehouseManagementSystem}
          onChange={event => handleWMSChange(event)}
          placeholder={"Please select the WMS"}
          isClearable
        />
        {shouldShowCustomWMSField()}
      </FormField>
      <FormField label="Business type">
        <Select
          options={ConvertEnumToReactSelectFormat(FilterBusinessType(warehouseManagementSystem))}
          key={warehouseManagementSystem} // https://github.com/JedWatson/react-select/issues/2846#issuecomment-745728738
          defaultValue={businessType}
          onChange={event => handleBusinessTypeChange(event)}
          placeholder={"Please select the Business Type"}
          isClearable
        />
        {showCustomBusinessTypeField()}
      </FormField>
      <FormField label="Marketplace">
        <Input value={marketplace} onChange={event => setMarketplace(event.detail.value)} />
      </FormField>
      <FormField
        label="Contact"
        errorText={(_.isEmpty(contact) || validateEmail(contact)) ? "" : "Invalid email."}
      >
        <Input type="email" value={contact} placeholder="email.amazon.com" onChange={event => setContact(event.detail.value)} />
      </FormField>
      <FormField>
        <Toggle onChange={toggleChecked} checked={isRegistrationForNewInventoryService}>Is Registration for New Inventory Service</Toggle>
      </FormField>
      {showNewInventoryServiceEquipmentsInput()}
    </Modal>
  );
}

export default CreateRegistrationModal;