import { Box } from "@amzn/awsui-components-react";
import React from "react";

const ValueWithLabel = ({ label, children }) => (
  <>
    <Box margin={{ bottom: 'xxxs' }} color="text-label">
      {label}
    </Box>
    <div>{children}</div>
  </>
);

export default ValueWithLabel;