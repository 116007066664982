export type TransferDepartWorkflowHerdState =
  | "ValidateMessageToProcess"
  | "TransferManifestCreation"
  | "TransferManifestItemsCreation"
  | "WaitForAllTransferManifestItemsToBeCreated"
  | "AllTMItemsCreated"
  | "TransferManifestItemsShipout"
  | "WaitForAllTransferManifestItemsToBeShippedOut"
  | "AwaitingCostConsumption"
  | "DesirableNumOfCostsConsumed"
  | "PostAsinsToDestinationWarehouse"
  | "CreateOutboundContainerHierarchy"
  | "CalculateTax"
  | "PublishTransferManifest"
  | "TransferOutboundManifestPublished"
  | "PublishTRsAvailability"
  | "PublishFailureNotification";

export enum TransferDepartWorkflowButaneState {
  "Validation" = "Validation",
  "Manifest Creation" = "Manifest Creation",
  "Manifest Items Creation" = "Manifest Items Creation",
  "Invoicing" = "Invoicing",
  "Inventory Shipout" = "Inventory Shipout",
  "Cost Consumption" = "Cost Consumption",
  "Post ASINs to Destination" = "Post ASINs to Destination",
  "Container Hierarchy Creation" = "Container Hierarchy Creation",
  "Publish Transfer Manifest" = "Publish Transfer Manifest",
  "Publish Availability Notification" = "Publish Availability Notification"
}

export type TransferDepartWorkflowErrorState = "ERROR";

export type TransferDepartWorkflowButanteStateWithError =
  | TransferDepartWorkflowButaneState
  | TransferDepartWorkflowErrorState;

export const TRANSFER_DEPART_WORKFLOW_BUTANE_STATE_BY_HERD_STATE: Record<
  TransferDepartWorkflowHerdState,
  TransferDepartWorkflowButanteStateWithError
> = {
  ValidateMessageToProcess: TransferDepartWorkflowButaneState.Validation,
  TransferManifestCreation:
    TransferDepartWorkflowButaneState["Publish Transfer Manifest"],
  TransferManifestItemsCreation:
    TransferDepartWorkflowButaneState["Manifest Items Creation"],
  WaitForAllTransferManifestItemsToBeCreated:
    TransferDepartWorkflowButaneState["Manifest Items Creation"],
  AllTMItemsCreated:
    TransferDepartWorkflowButaneState["Manifest Items Creation"],
  TransferManifestItemsShipout:
    TransferDepartWorkflowButaneState["Inventory Shipout"],
  WaitForAllTransferManifestItemsToBeShippedOut:
    TransferDepartWorkflowButaneState["Inventory Shipout"],
  AwaitingCostConsumption:
    TransferDepartWorkflowButaneState["Cost Consumption"],
  DesirableNumOfCostsConsumed:
    TransferDepartWorkflowButaneState["Cost Consumption"],
  PostAsinsToDestinationWarehouse:
    TransferDepartWorkflowButaneState["Post ASINs to Destination"],
  CreateOutboundContainerHierarchy:
    TransferDepartWorkflowButaneState["Container Hierarchy Creation"],
  CalculateTax: TransferDepartWorkflowButaneState["Invoicing"],
  PublishTransferManifest:
    TransferDepartWorkflowButaneState["Publish Transfer Manifest"],
  TransferOutboundManifestPublished:
    TransferDepartWorkflowButaneState["Publish Transfer Manifest"],
  PublishTRsAvailability:
    TransferDepartWorkflowButaneState["Publish Availability Notification"],
  PublishFailureNotification: "ERROR"
};
