import * as React from "react";
import Box from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import Header from "@amzn/awsui-components-react/polaris/header";
import Link from "@amzn/awsui-components-react/polaris/link";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import Select from "@amzn/awsui-components-react/polaris/select";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Table from "@amzn/awsui-components-react/polaris/table";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import {  TableProps } from "@amzn/awsui-components-react";
import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";
import { useCollection } from "@amzn/awsui-collection-hooks";
import * as _ from "lodash";

import formatDate from "../../../common/DateFormatter";
import { Filter, NodeLaunchPlan, Status } from "../Types";

interface NodeLaunchPlansTable {
  loading: boolean;
  nodeLaunchPlans: NodeLaunchPlan[];
  showNewNodeLaunchPlanModal: () => void;
  fetchData: (filterObject: Filter) => void;
}

const NodeLaunchPlansTable = (props: NodeLaunchPlansTable) => {
  const ALL_OPTION: OptionDefinition = { label: "Any Status", value: undefined };
  const [selectedOption, setSelectedOption] = React.useState<OptionDefinition>(ALL_OPTION);
  const PAGE_SIZE = 20;

  const COLUMN_DEFINITIONS: ReadonlyArray<TableProps.ColumnDefinition<NodeLaunchPlan>> = [
    { id: "nodeLaunchPlanId", header: "Launch Plan ID", cell: item => 
      <Link href={`/registrations/${item.registrationId}/launch-plans/${item.nodeLaunchPlanId}`}>{item.nodeLaunchPlanId}</Link> },
    { id: "nodesPlanned", sortingField: "nodesPlanned", header: "Nodes Planned", cell: item => item.nodesPlanned },
    { id: "nodesOperational", sortingField: "nodesOperational", header: "Nodes Operational", cell: item => item.nodesOperational },
    { id: "createdDate", sortingField: "createdDate", header: "Creation Date", cell: item => formatDate(item.createdDate) },
    { id: "status", header: "Status", cell: item => Status[item.status] },
  ]

  const collectionHook = useCollection<NodeLaunchPlan>(
    props.nodeLaunchPlans ?? [],
    {
      filtering: {
        fields: ["nodeLaunchPlanId", "nodesPlanned", "nodesOperational"],
        empty: (
          <Box textAlign="center">
            <b>No Node launch plans available</b>
            <p>Couldn't find any node launch plans available for this registraion</p>
            <Button onClick={props.showNewNodeLaunchPlanModal}>Create launch plan</Button>
          </Box>
        ),
        noMatch: (
          <Box textAlign="center">
            <b>No matches</b>
            <p>We can’t find a match.</p>
            <Button onClick={() => {
              collectionHook.actions.setFiltering('')
              setSelectedOption(ALL_OPTION);
              props.fetchData({})
            }}>Clear filter</Button>
          </Box>
        )
      },
      sorting: {
        defaultState: {
          sortingColumn: {
            sortingField: "createdDate",
          },
          isDescending: true
        },
      },
      pagination: {
        defaultPage: 1,
        pageSize: PAGE_SIZE
      }
    },
  );

  return (
    <Table
      {...collectionHook.collectionProps}
      loading={props.loading}
      columnDefinitions={COLUMN_DEFINITIONS}
      header={
        <Header
          variant="h2"
          actions={<Button variant="primary" onClick={props.showNewNodeLaunchPlanModal}>Create launch plan</Button>}
        >
          Available node launch plans
        </Header>
      }
      items={collectionHook.items}
      filter={
        <SpaceBetween direction="horizontal" size="s">
          <Grid gridDefinition={[{ colspan: 7 }, { colspan: 5 }]}>
            <TextFilter {...collectionHook.filterProps} filteringPlaceholder="Find node launch plan" />
            <Select
              selectedOption={selectedOption}
              options={_.concat([ALL_OPTION], Object.entries(Status).map(entry => ({ label: entry[1], value: entry[0] })))}
              onChange={event => {
                setSelectedOption(event.detail.selectedOption);
                props.fetchData({ "status": event.detail.selectedOption.value })
              }}
              placeholder="Launch plan status"
            />
          </Grid>
        </SpaceBetween>
      }
      pagination={
        <Pagination {...collectionHook.paginationProps} />
      }
      wrapLines={true}
    />
  );
}

export default NodeLaunchPlansTable;