import React, {useContext, useEffect} from "react";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import Link from "@amzn/awsui-components-react/polaris/link";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { StatusCodes } from "http-status-codes";
import { useFlashbar } from "@common/flashbar";
import {
  StandaloneValue,
  ValueWithLabel,
  SpinnerWithLabel
} from "@common/presentation";
import { useGetShipmentDetails } from "@modules/shipment/searchByShipmentId/useGetShipmentDetails";
import { fetchArsenalLink } from "@constants/FetchArsenalLink";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import Button from "@amzn/awsui-components-react/polaris/button";
import ShipmentItem from "@modules/shipment/model/ShipmentItem";
import { ShipmentItemsTable } from "@modules/shipment/searchByShipmentId/ShipmentItemsTable";
import {RegionContext} from "@common/RegionContext";
import {getEnvironment} from "@modules/signup/Utils";

interface ShipmentDetailsParams {
  shipmentId: string;
}

interface ShipmentDetailsProps
  extends RouteComponentProps<ShipmentDetailsParams> {}

export const ShipmentDetails = withRouter(({ match }: ShipmentDetailsProps) => {
  const {
    params: { shipmentId }
  } = match;

  const { addMessage, clearAllMessages } = useFlashbar();
  const regionContext = useContext(RegionContext);

  const {
    isLoading: isLoadingShipmentDetails,
    error,
    getShipmentDetails,
    shipmentDetails
  } = useGetShipmentDetails();

  useEffect(() => {
    handleRefreshClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentId]);

  async function handleRefreshClick() {
    clearAllMessages();

    await getShipmentDetails(shipmentId);
  }

  useEffect(() => {
    if (error) {
      switch (error.statusCode) {
        case StatusCodes.NOT_FOUND:
          return addMessage({
            header: error.message,
            type: "warning"
          });
        default:
          return addMessage({
            header: error.message,
            content: error.details ?? null,
            type: "error",
            buttonText: "Retry",
            dismissible: true,
            onButtonClick: async () => {
              await handleRefreshClick();
            }
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  function hasApiResponse() {
    return shipmentDetails || error;
  }

  function getArsenalUrl(messageId: string) {
    return `${fetchArsenalLink(regionContext.region, getEnvironment())}/search?id=${messageId}`;
  }
  
  function ShipmentState() {
    if (shipmentDetails?.status === 'COMPLETED') {
      return <StatusIndicator type="success">{shipmentDetails?.status}</StatusIndicator>;
    }
    if (shipmentDetails?.status === 'DELETED') {
      return <StatusIndicator type="info">{shipmentDetails?.status}</StatusIndicator>;
    }
    return <StatusIndicator type="in-progress">{shipmentDetails?.status}</StatusIndicator>;
  }

  function Details() {
    return (
        <AppLayout
            navigationHide={true}
            toolsHide={true}
            headerSelector="#top-navigation"
            contentType="default"
            content={
              <>
                <Container
                    header={
                      <Header
                          variant="h2"
                          actions={
                            <>
                              <Button
                                  iconName="refresh"
                                  onClick={() => handleRefreshClick()}
                              />
                            </>
                          }
                      >
                        Shipment Details for {shipmentId}
                      </Header>
                    }
                    footer={
                        <ShipmentItemsTable
                            shipmentStatus={shipmentDetails?.status}
                            shipmentItems={(shipmentDetails?.items ?? []) as unknown as ShipmentItem[]}
                        />
                    }
                >
                  <>
                    {!error}
                    <ColumnLayout columns={2} variant="text-grid">
                      <SpaceBetween size="l">
                        <ValueWithLabel label="Shipment Id / Encrypted Shipment Id">
                          <Link external={true} href={getArsenalUrl(shipmentId)}>
                            {shipmentDetails?.id} / {shipmentDetails?.encryptedId}
                          </Link>
                        </ValueWithLabel>
                        <ValueWithLabel label="Warehouse Id">
                          <StandaloneValue  value={shipmentDetails?.warehouseId}/>
                        </ValueWithLabel>
                        <ValueWithLabel label="Condition">
                          <StandaloneValue  value={shipmentDetails?.condition + " : " + shipmentDetails?.conditionDescription}/>
                        </ValueWithLabel>
                        <ValueWithLabel label="Order Id">
                          <StandaloneValue  value={shipmentDetails?.orderId}/>
                        </ValueWithLabel>
                      </SpaceBetween>
                      <SpaceBetween size="l">
                        <ValueWithLabel label="State">
                          <ShipmentState/>
                        </ValueWithLabel>
                        <ValueWithLabel label="Shipment Type">
                          <StandaloneValue  value={shipmentDetails?.shipmentType}/>
                        </ValueWithLabel>
                        <ValueWithLabel label="Country">
                          <StandaloneValue  value={shipmentDetails?.addressCountryCode}/>
                        </ValueWithLabel>
                        <ValueWithLabel label="Ship Method">
                          <StandaloneValue  value={shipmentDetails?.shipMethod}/>
                        </ValueWithLabel>
                      </SpaceBetween>
                    </ColumnLayout>
                  </>
                </Container>
              </>
            }
        />
    );
  }

  if (isLoadingShipmentDetails || !hasApiResponse()) {
    return (
      <SpinnerWithLabel
        label={`Loading details for shipment ${shipmentId}`}
      />
    );
  }

  return <Details />;
});
