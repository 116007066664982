export class LineItem {
  siteName!: string;
  fnSku!: string;
  fcSku?: string;
  purchaseOrderId!: string;
  quantity!: number;
  inventoryCondition!: string;
  location!: string;
  problemTypes?: string[];
  status!: string;
  failureReason?: string;

  constructor(siteName: string, fnSku: string, purchaseOrderId: string, quantity: number,
              inventoryCondition: string, location: string, status: string, fcSku?: string,
              problemTypes?: string[], failureReason?: string) {
    this.siteName = siteName;
    this.fnSku = fnSku;
    this.purchaseOrderId = purchaseOrderId;
    this.quantity = quantity;
    this.inventoryCondition = inventoryCondition;
    this.location = location;
    this.fcSku = fcSku;
    this.problemTypes = problemTypes;
    this.status = status;
    this.failureReason = failureReason;
  }

  public fromPartial(partialReceiveExecution: Partial<LineItem>) {
    Object.assign(this, {partialReceiveExecution});
  }
}