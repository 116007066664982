export const fetchShipmentsDetailsInCondition62And305Column = function (CustomLinkColumn) {
    return [
        {
            field: 'warehouseId',
            title: 'WarehouseId',
            width: '5rem'
        },
        {
            field: 'shipmentId',
            title: 'ShipmentId',
            width: '5rem'
        },
        {
            field: 'encryptedShipmentId',
            title: 'EncryptedShipmentId',
            width: '5rem'
        },
        {
            field: 'orderId',
            title: 'OrderId',
            width: '5rem'
        },
        {
            field: 'asin',
            title: 'Asin',
            width: '5rem'
        },
        {
            field: 'quantity',
            title: 'Quantity',
            width: '5rem'
        },
        {
            field: 'orderDate',
            title: 'Order__Date',
            width: '5rem'
        },
        {
            field: 'promisedShipDate',
            title: 'PromisedShipDate',
            width: '5rem'
        },
        {
            field: 'shipMethod',
            title: 'ShipMethod',
            width: '5rem'
        },
        {
            field: 'expectedShipDate',
            title: 'ExpectedShipDate',
            width: '5rem'
        },
        {
            field: 'condition',
            title: 'Condition',
            width: '5rem'
        },
        {
            field: 'conditionAge',
            title: 'ConditionAge',
            width: '5rem'
        },
        {
            field: 'hoursPassedExsd',
            title: 'HoursPassedExSD',
            width: '5rem'
        },
        {
            field: 'action',
            title: 'Action',
            width: '5rem',
            render: rowData => CustomLinkColumn(rowData)
        },
        {
            field: 'shipmentType',
            title: 'ShipmentType',
            width: '5rem'
        }
    ];
}

