import React from 'react';
import { useState } from "react";
import {
    Button,
    Form,
    Header,
    FormField,
    Input,
    Container,
    Box,
    Grid
} from "@amzn/awsui-components-react";
import  Auth from '@aws-amplify/auth';
import { AuthState } from '@aws-amplify/ui-components';
import { useBetween } from "use-between";
import {
    useSharedUserState,
    useSharedUnAuthEmailState,
    useSharedAuthState,
} from "./LoginWithAuth";
import { arePasswordsEquals } from './Utils';
import { passwordRegex } from '@constants/Regex';

export const ResetPassword: React.FC = () => {

    const [newPassword, setNewPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [errorMessage, setErrorMessage ] = useState<string>("");
    const [loading, setLoading ] = useState<boolean>(false);
    const { user } = useBetween(useSharedUserState);
    const { setAuthState } = useBetween(useSharedAuthState);
    const { unAuthEmail } = useBetween(useSharedUnAuthEmailState);

    const handleResetPasswordBtnClick = async () => {
        setLoading(true);
        try {
            await Auth.completeNewPassword(user, newPassword, {})
            setAuthState(AuthState.SignedIn);
        } catch (err) {
            console.error(err)
        }
        setLoading(false);
    };

    return (
        <>
            <div>
                {/* colspan places the login container centre of the screen */}
                <Grid
                    gridDefinition={[{ colspan: 3 }, { colspan: 6 }, { colspan: 3 }]}
                >
                    <div>
                    </div>
                    <div>
                        <Box margin="xxl" padding="xxl" textAlign="center">
                            <Container>
                                <Form
                                    header={<Header variant="h2">You are required to setup a new password</Header>}
                                    actions={
                                        <Button
                                            variant="primary"
                                            loading={loading}
                                            onClick={handleResetPasswordBtnClick}
                                            disabled={!arePasswordsEquals(confirmPassword, newPassword)}
                                            
                                        >
                                            Enter
                                        </Button>
                                    }
                                >
                                    <FormField
                                        description={"User Email"}
                                    >
                                        <Input
                                            value={unAuthEmail}
                                            autoComplete={true}
                                            disabled
                                        />
                                    </FormField>
                                    <FormField
                                        description={"Please enter new password. Password must be at least 8 characters and contain 1 number, 1 uppercase letter, 1 lowercase letter and 1 symbol."}
                                    >
                                        
                                        <Input
                                            value={newPassword}
                                            invalid={!passwordRegex.test(newPassword) && newPassword.length !==0}
                                            type='password'
                                            placeholder="Enter your password here"
                                            onChange={(e: any) => { setNewPassword(e.detail.value)}}
                                            onKeyDown={(e) => {
                                                if (e.detail.key === "Enter") handleResetPasswordBtnClick();
                                            }}
                                        />
                                    </FormField>
                                    <FormField
                                        description={"Confirm password"}
                                        errorText={errorMessage}

                                    >
                                        <Input
                                            value={confirmPassword}
                                            autoComplete={true}
                                            type='password'
                                            placeholder="Confirm your password here"
                                            onChange={async (e: any) => { setConfirmPassword(e.detail.value)
                                                if(e.detail.value !== newPassword && e.detail.value !== "") {
                                                    setErrorMessage("Passwords do not match");
                                                } else {
                                                    setErrorMessage("");
                                                }
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.detail.key === "Enter") handleResetPasswordBtnClick();
                                            }}
                                        />
                                    </FormField>
                                </Form>
                            </Container>
                        </Box>
                    </div>
                </Grid >
            </div>
        </>
    )
}