import React, { useEffect } from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import { StatusCodes } from "http-status-codes";

import { FailedDepartItem } from "@modules/transfer/common/model";
import { useFlashbar } from "@common/flashbar";
import { TableEmptyState } from "@common/table";
import { useGetFailedDepartItems } from "@modules/transfer/hooks";
import {
  COLUMN_DEFINITIONS,
  LOADING_TEXT,
  FailedDepartItemsTableHeader,
  LegacyFailedDepartItemsTableHeader,
  TRACK_BY_COLUMN_NAME
} from "@modules/transfer/common/components/failed-depart-items";

interface FailedDepartItemsTableProps {
  amznShipRefId: string;
  isLegacyTransfer?: boolean;
  /**
   * Allow errors to be surpressed, e.g. inside `TransferDepartWorkflowDetails`.
   */
  suppressErrors?: boolean;
}

export function FailedDepartItemsTable({
  amznShipRefId,
  isLegacyTransfer,
  suppressErrors
}: FailedDepartItemsTableProps) {
  const { isLoading, failedDepartItems, error, getFailedDepartItems } =
    useGetFailedDepartItems();

  const { addMessage, clearAllMessages } = useFlashbar();

  useEffect(() => {
    handleRefreshClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amznShipRefId]);

  async function handleRefreshClick() {
    resetErrorMessage();

    await getFailedDepartItems(amznShipRefId);
  }

  function resetErrorMessage() {
    clearAllMessages();
  }

  useEffect(() => {
    if (error && !suppressErrors) {
      switch (error.statusCode) {
        case StatusCodes.NOT_FOUND:
          return addMessage({
            header: error.message,
            type: "warning"
          });
        default:
          return addMessage({
            header: error.message,
            content: error.details ?? null,
            type: "error",
            buttonText: "Retry",
            dismissible: true,
            onButtonClick: async () => {
              await handleRefreshClick();
            }
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  function Header() {
    if (isLegacyTransfer) {
      return (
        <LegacyFailedDepartItemsTableHeader
          amznShipRefId={amznShipRefId}
          items={failedDepartItems}
        />
      );
    }
    return <FailedDepartItemsTableHeader items={failedDepartItems} />;
  }

  return (
    <>
      <Table<FailedDepartItem>
        trackBy={TRACK_BY_COLUMN_NAME}
        header={<Header />}
        wrapLines={true}
        items={failedDepartItems}
        columnDefinitions={COLUMN_DEFINITIONS}
        loading={isLoading}
        loadingText={LOADING_TEXT}
        empty={
          <TableEmptyState
            resourceName="Failed Depart Item"
            resourceNamePlural="Failed Depart Items"
            showCreateResourceButton={false}
          />
        }
      />
    </>
  );
}
