const PROCUREMENT_PORTAL_EU='https://procurementportal-eu.corp.amazon.com/bp/problemReceive';
const PROCUREMENT_PORTAL_NA='https://procurementportal-na.corp.amazon.com/bp/problemReceive';
const PROCUREMENT_PORTAL_JP='https://procurementportal-fe.corp.amazon.com/bp/problemReceive';

const fetchResolveRPILink = function(region, environment) {
    if (environment === 'prod') {
        switch(region.toLowerCase()) {
            case 'iad':
                return PROCUREMENT_PORTAL_NA;
            case 'dub':
                return PROCUREMENT_PORTAL_EU;
            case 'pdx':
                return PROCUREMENT_PORTAL_JP;
            default:
                return PROCUREMENT_PORTAL_EU;
        }
    } else {
        return PROCUREMENT_PORTAL_EU
    }
};
export { fetchResolveRPILink };
