const FOSPORTAL_DUB='https://fos-portal-eu.amazon.com/';
const FOSPORTAL_IAD='https://fos-portal-na.amazon.com/';
const FOSPORTAL_PDX='https://fos-portal-fe.amazon.com/';
const FOSPORTAL_BETA='https://fosportal-eu.integ.amazon.com/';
export const fetchFosPortalLink = (region, environment) => {
    if (environment === 'prod') {
        switch(region.toLowerCase()) {
            case 'iad':
                return FOSPORTAL_IAD;
            case 'dub':
                return FOSPORTAL_DUB;
            case 'pdx':
                return FOSPORTAL_PDX;
            default:
                return FOSPORTAL_BETA;
        }
    } else {
        return FOSPORTAL_BETA
    }
};
