import React from "react";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";

interface SpinnerWithLabelProps {
  label: string;
}

export function SpinnerWithLabel({ label }: SpinnerWithLabelProps) {
  return (
    <>
      <Spinner /> {label}
    </>
  );
}
