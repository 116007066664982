export default {
  // Butane lambda service API config
  endpoints: {
    localhost: [
      {
        name: 'butane_dub',
        endpoint: 'https://0d9u6cls01.execute-api.eu-west-1.amazonaws.com/beta',
        region: 'eu-west-1',
      },
    ],
    beta: [
      {
        name: 'butane_dub',
        endpoint: 'https://0d9u6cls01.execute-api.eu-west-1.amazonaws.com/beta',
        region: 'eu-west-1',
      },
    ],
    gamma: [
      {
        name: 'butane_iad',
        endpoint: 'https://9zncr6ki87.execute-api.us-east-1.amazonaws.com/gamma',
        region: 'us-east-1',
      },
    ],
    prod: [
      {
        name: 'butane_dub',
        endpoint: 'https://u0l30aoucf.execute-api.eu-west-1.amazonaws.com/prod',
        region: 'eu-west-1',
      },
      {
        name: 'butane_iad',
        endpoint: 'https://1bgrbth202.execute-api.us-east-1.amazonaws.com/prod',
        region: 'us-east-1',
      },
      {
        name: 'butane_pdx',
        endpoint: 'https://6ix5uoowp0.execute-api.us-west-2.amazonaws.com/prod',
        region: 'us-west-2',
      },
    ],
  },
};

export const constructButaneApiName = (region: string ): string => {
  return 'butane_' + region;
}
