import React, {useEffect, useState} from "react";
import Form from "@amzn/awsui-components-react/polaris/form";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import PolarisButton from "@amzn/awsui-components-react/polaris/button";
import {Checkbox, ColumnLayout} from "@amzn/awsui-components-react";
import Input from "@amzn/awsui-components-react/polaris/input";
import {RouteComponentProps} from "react-router-dom";
import DatePicker from "@amzn/awsui-components-react/polaris/date-picker";
import {warehouseIdRegex} from "@constants/Regex";
import "./fcSkuInventoryDiscrepancySnapshot.css"

interface FcSkuInventoryDiscrepancySnapshotInputProps extends RouteComponentProps {
    warehouse: string,
    startDate: Date,
    endDate: Date,
    onlyDiscrepantInventory: boolean,
    renderTable(value: boolean): any,
}

export const FcSkuInventoryDiscrepancySnapshotInput = (props: FcSkuInventoryDiscrepancySnapshotInputProps) => {
    const [warehouse, setWarehouse] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [onlyDiscrepantInventory, setOnlyDiscrepantInventory] = useState(false);

    useEffect(() => {
        setWarehouse(props.warehouse);
        setStartDate(props.startDate);
        setEndDate(props.endDate);
        setOnlyDiscrepantInventory(props.onlyDiscrepantInventory);
        renderTableIfInputsValid(props);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const renderTableIfInputsValid = (props: FcSkuInventoryDiscrepancySnapshotInputProps) => {
        if (areInputsValid(props.warehouse, props.startDate, props.endDate)) {
            props.renderTable(true);
        }
    }

    const areInputsValid = (warehouse: string, startDate: Date, endDate: Date) => {
        return (warehouseIdRegex.test(warehouse) && startDate && endDate && (startDate <= endDate));
    }

    const onSnapshotSearch = () => {
        props.history.push(`/inventory/fcsku/discrepancy/?warehouse=${warehouse}&startDate=${startDate.toISOString().slice(0,10)}&endDate=${endDate.toISOString().slice(0,10)}&onlyDiscrepantInventory=${onlyDiscrepantInventory}`);
    }

    const isEndDateValid = (startDate: Date, endDate: Date) => {
        if (startDate) {
            return endDate >= startDate && endDate < new Date();
        } else {
            return endDate < new Date();
        }
    }

    return (
        <Form actions={
            <PolarisButton
                data-fcsku-snapshot-input="search-button-pane"
                onClick={onSnapshotSearch}
                iconName="search"
                variant="primary"
                disabled={!areInputsValid(warehouse, startDate, endDate)}
            >
                Search
            </PolarisButton>
        }
              data-fcsku-snapshot-input="input-container"
        >
            <Container
                header={
                    <Header>
                        Search FCSKU Inventory Discrepancy Snapshot
                    </Header>
                }
            >
                <SpaceBetween size={"l"}>
                    <ColumnLayout columns={2}>
                        <FormField label="Warehouse"
                                   description="Warehouse code e.g. VUSB"
                        >
                            <Input type="text"
                                   value={warehouse}
                                   onChange={(e) => setWarehouse(e.detail.value)}
                            />
                        </FormField>
                    </ColumnLayout>
                    <ColumnLayout columns={2}>
                        <FormField label="Start Date">
                            <DatePicker
                                onChange={startDate => setStartDate(new Date(startDate.detail.value))}
                                value={startDate.toISOString()}
                            />
                        </FormField>
                        <FormField label="End Date">
                            <DatePicker
                                onChange={endDate => setEndDate(new Date(endDate.detail.value))}
                                value={endDate.toISOString()}
                                isDateEnabled={(date) => isEndDateValid(startDate, date)}
                            />
                        </FormField>
                    </ColumnLayout>
                    <Checkbox onChange={(e) => setOnlyDiscrepantInventory(e.detail.checked)}
                              checked={onlyDiscrepantInventory}
                    >
                        Show only discrepant inventory
                    </Checkbox>
                </SpaceBetween>
            </Container>
        </Form>
    );
}