import React from 'react';
import API from "@aws-amplify/api";
import {Alert} from "react-bootstrap";
import TableForShipmentSummary from '../../../components/Table/TableForShipmentSummary';
import ShipmentSummary from "../model/ShipmentSummary";
import '../../../../src/components/Spinner/spinner.css'
import {fetchShipmentSummaryColumns} from "../../../constants/table/metadata/shipment/ShipmentSummaryTableColumn";
import { getAuthorizationHeader } from '@common/Auth';
import {RegionContext} from '@common/RegionContext';
import {constructButaneApiName} from '../../../apiConfig';


export default class ShipmentSummaryTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            shipmentSnapshot: [],
            fetchingRecords: false,
            conditionDescriptionList: [],
            errorMessage: undefined,
            agingConditionsList: ["62", "3", "305", "8", "5406", "5409", "36", "59", "7", "13", "29", "54", "4", "11", "12", "32", "60", "6001", "6003", "6009", "6006", "2505", "15", "1501", "MISSEDEXSD"]
        };
    }

    async searchShipment(warehouseList, shipmentType) {
        let shipmentSnapshot = [];
        let shipmentTuple;
        let totalSumForEveryCondition = {};
        for (let i = 0; i < this.state.agingConditionsList.length; i++) {
            totalSumForEveryCondition[this.state.agingConditionsList[i]] = 0;
        }
        return API.get(constructButaneApiName(this.context.region), `/api/shipments/summary?warehouses=${warehouseList}&shipmentType=${shipmentType}`, {
                headers: await getAuthorizationHeader()
            })
            .then((data) => {

                this.setState({conditionDescriptionList: data.shipmentSummaryList[0].conditionDescription});

                for (let i = 0; i < data.shipmentSummaryList.length; i++) {
                    let shipmentSummaryList = data.shipmentSummaryList[i];
                    let ConditionCountMap = shipmentSummaryList.stuckShipment;
                    for (let listItem = 0; listItem < this.state.agingConditionsList.length; listItem++) {
                        totalSumForEveryCondition[this.state.agingConditionsList[listItem]] = totalSumForEveryCondition[this.state.agingConditionsList[listItem]] + (ConditionCountMap[this.state.agingConditionsList[listItem]] ? ConditionCountMap[this.state.agingConditionsList[listItem]] : 0);
                    }
                    shipmentTuple = new ShipmentSummary(
                        shipmentSummaryList.warehouseId,
                        ConditionCountMap[this.state.agingConditionsList[0]] ? ConditionCountMap[this.state.agingConditionsList[0]] : 0,
                        ConditionCountMap[this.state.agingConditionsList[1]] ? ConditionCountMap[this.state.agingConditionsList[1]] : 0,
                        ConditionCountMap[this.state.agingConditionsList[2]] ? ConditionCountMap[this.state.agingConditionsList[2]] : 0,
                        ConditionCountMap[this.state.agingConditionsList[3]] ? ConditionCountMap[this.state.agingConditionsList[3]] : 0,
                        ConditionCountMap[this.state.agingConditionsList[4]] ? ConditionCountMap[this.state.agingConditionsList[4]] : 0,
                        ConditionCountMap[this.state.agingConditionsList[5]] ? ConditionCountMap[this.state.agingConditionsList[5]] : 0,
                        ConditionCountMap[this.state.agingConditionsList[6]] ? ConditionCountMap[this.state.agingConditionsList[6]] : 0,
                        ConditionCountMap[this.state.agingConditionsList[7]] ? ConditionCountMap[this.state.agingConditionsList[7]] : 0,
                        ConditionCountMap[this.state.agingConditionsList[8]] ? ConditionCountMap[this.state.agingConditionsList[8]] : 0,
                        ConditionCountMap[this.state.agingConditionsList[9]] ? ConditionCountMap[this.state.agingConditionsList[9]] : 0,
                        ConditionCountMap[this.state.agingConditionsList[10]] ? ConditionCountMap[this.state.agingConditionsList[10]] : 0,
                        ConditionCountMap[this.state.agingConditionsList[11]] ? ConditionCountMap[this.state.agingConditionsList[11]] : 0,
                        ConditionCountMap[this.state.agingConditionsList[12]] ? ConditionCountMap[this.state.agingConditionsList[12]] : 0,
                        ConditionCountMap[this.state.agingConditionsList[13]] ? ConditionCountMap[this.state.agingConditionsList[13]] : 0,
                        ConditionCountMap[this.state.agingConditionsList[14]] ? ConditionCountMap[this.state.agingConditionsList[14]] : 0,
                        ConditionCountMap[this.state.agingConditionsList[15]] ? ConditionCountMap[this.state.agingConditionsList[15]] : 0,
                        ConditionCountMap[this.state.agingConditionsList[16]] ? ConditionCountMap[this.state.agingConditionsList[16]] : 0,
                        ConditionCountMap[this.state.agingConditionsList[17]] ? ConditionCountMap[this.state.agingConditionsList[17]] : 0,
                        ConditionCountMap[this.state.agingConditionsList[18]] ? ConditionCountMap[this.state.agingConditionsList[18]] : 0,
                        ConditionCountMap[this.state.agingConditionsList[19]] ? ConditionCountMap[this.state.agingConditionsList[19]] : 0,
                        ConditionCountMap[this.state.agingConditionsList[20]] ? ConditionCountMap[this.state.agingConditionsList[20]] : 0,
                        ConditionCountMap[this.state.agingConditionsList[21]] ? ConditionCountMap[this.state.agingConditionsList[21]] : 0,
                        ConditionCountMap[this.state.agingConditionsList[22]] ? ConditionCountMap[this.state.agingConditionsList[22]] : 0,
                        ConditionCountMap[this.state.agingConditionsList[23]] ? ConditionCountMap[this.state.agingConditionsList[23]] : 0,
                        ConditionCountMap[this.state.agingConditionsList[24]] ? ConditionCountMap[this.state.agingConditionsList[24]] : 0,
                        shipmentType
                    )
                    shipmentSnapshot.push(shipmentTuple);
                }
                shipmentTuple = new ShipmentSummary(
                    "Total",
                    totalSumForEveryCondition[this.state.agingConditionsList[0]],
                    totalSumForEveryCondition[this.state.agingConditionsList[1]],
                    totalSumForEveryCondition[this.state.agingConditionsList[2]],
                    totalSumForEveryCondition[this.state.agingConditionsList[3]],
                    totalSumForEveryCondition[this.state.agingConditionsList[4]],
                    totalSumForEveryCondition[this.state.agingConditionsList[5]],
                    totalSumForEveryCondition[this.state.agingConditionsList[6]],
                    totalSumForEveryCondition[this.state.agingConditionsList[7]],
                    totalSumForEveryCondition[this.state.agingConditionsList[8]],
                    totalSumForEveryCondition[this.state.agingConditionsList[9]],
                    totalSumForEveryCondition[this.state.agingConditionsList[10]],
                    totalSumForEveryCondition[this.state.agingConditionsList[11]],
                    totalSumForEveryCondition[this.state.agingConditionsList[12]],
                    totalSumForEveryCondition[this.state.agingConditionsList[13]],
                    totalSumForEveryCondition[this.state.agingConditionsList[14]],
                    totalSumForEveryCondition[this.state.agingConditionsList[15]],
                    totalSumForEveryCondition[this.state.agingConditionsList[16]],
                    totalSumForEveryCondition[this.state.agingConditionsList[17]],
                    totalSumForEveryCondition[this.state.agingConditionsList[18]],
                    totalSumForEveryCondition[this.state.agingConditionsList[19]],
                    totalSumForEveryCondition[this.state.agingConditionsList[20]],
                    totalSumForEveryCondition[this.state.agingConditionsList[21]],
                    totalSumForEveryCondition[this.state.agingConditionsList[22]],
                    totalSumForEveryCondition[this.state.agingConditionsList[23]],
                    totalSumForEveryCondition[this.state.agingConditionsList[24]],
                    shipmentType
                )
                shipmentSnapshot.unshift(shipmentTuple);
                this.setState(() => ({
                    shipmentSnapshot: shipmentSnapshot,
                    fetchingRecords: false,
                    errorMessage: ""
                }));
            }).catch((error) => {
                    this.setState(() => ({
                        shipmentSnapshot: [],
                        fetchingRecords: false,
                        errorMessage: (error.message === "Request failed with status code 400") ? 'Shipments Summary not found' : 'Something went wrong'
                    }));
                }
            );
    }

    getWarehousesData(warehouses, shipmentType) {
        if (warehouses === '') {
            return;
        }
        this.setState(() => ({
            fetchingRecords: true
        }));
        this.searchShipment(warehouses, shipmentType);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.warehouseList !== this.props.warehouseList || prevProps.shipmentType !== this.props.shipmentType) {
            this.getWarehousesData(this.props.warehouseList, this.props.shipmentType);
        }
    }

    getConditionValue = (rowData, columnName, displayName) => {
        if (rowData !== undefined) {

            let warehouseList = (rowData.warehouseId === ("Total")) ? this.props.warehouseList.replace(/\s/g, "") : rowData.warehouseId;
            let Condition = (displayName === ("LATE")) ? "MISSEDEXSD" : displayName;

            if (Condition === "WarehouseId") {
                Condition = "ALL";
            }

            if (rowData.warehouseId === "Total" && Condition === "ALL") {
                return rowData[columnName]
            }

            const hrefLink = `/metrics/aging-report-detail?warehouses=${warehouseList}&condition=${Condition}&rowNumber=${rowData.tableData.id}&shipmentType=${rowData.shipmentType}`
            return <a className="LinkStyle" target="_blank" rel="noopener noreferrer"
                      href={hrefLink}>{rowData[columnName]}</a>;
        }
        return null
    }

    renderShipmentSummaryTable() {
        const columnMetadata = fetchShipmentSummaryColumns(this.getConditionValue, this.state.conditionDescriptionList);
        return (
            <div>
                {!!this.state.errorMessage ? (
                        <Alert variant="danger">
                            <Alert.Heading>
                                {this.state.errorMessage}
                            </Alert.Heading>
                            <p>
                                Could Not Find the Shipments details for given Request.
                            </p>
                        </Alert>)
                    : (<div>
                            <TableForShipmentSummary
                                results={this.state.shipmentSnapshot}
                                columnMetadata={columnMetadata}
                            />
                        </div>
                    )}
            </div>
        );
    }

    render() {
        let content;
        if (!!this.state.fetchingRecords) {
            return (
                <div className="spinner"></div>
            );
        }
        if (!!this.props.warehouseList && !this.state.fetchingRecords) {
            content = this.renderShipmentSummaryTable();
        }
        return (
            <div>
                {content}
            </div>
        );
    }
}

ShipmentSummaryTable.contextType = RegionContext;
