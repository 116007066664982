import React, { Component} from "react";

/* Import Components */
import AuditDetailTable from "./AuditDetailTable";
import AuditDetailForm from "./AuditDetailForm";
import UnAuthorizedAccessAlert from "../../../views/layouts/UnAuthorizedAccessAlert";

class AuditDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isButtonDisabled: false,
      auditRequest: {
        level: "",
        id: "",
        params: ""
      }
    };
  }

  renderTables = (id, level, params) => {
    this.setState(() => ({
      id: id,
      level: level,
      params: params
    }));
  };

  render() {
    if (this.props.isAuthenticated) {
      return (
        <div>
          {this.props.isExternal && <UnAuthorizedAccessAlert/>}
          {!this.props.isExternal && <AuditDetailForm
            id={this.state.id}
            level={this.state.level}
            params={this.state.params}
            renderTables={this.renderTables}
          />}
          {!this.props.isExternal&&<AuditDetailTable
            id={this.state.id}
            level={this.state.level}
            params={this.state.params}
          />}
        </div>
      );
    }
    this.props.history.push("/login");
    return null;
  }
}

export default AuditDetail;
