import React, { Component } from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import PageTitle from '../../../components/PageTitle/PageTitle';

class ProblemItemsSummary extends Component {

    getProblemReceiveSummaryDetails() {
        return (
            <div>
                <div className="problem-item-page-title">
                    <PageTitle>
                        Problem Items Detail for warehouse : {this.props.warehouseId}
                    </PageTitle>
                </div>
                <div className="problem-item-summary">
                    <Card bg="light">
                        <Card.Header bsPrefix="problem-item-summary-title">Problem Receive Summary</Card.Header>
                        <Card.Body bsPrefix="total-problem-item-details">
                            <Container>
                                <Row>
                                    <Col lg={true}>Total Problem Receive Quantity</Col>
                                </Row>
                                <Row>
                                    <Col lg={true}>{this.props.totalQuantity}</Col>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        )
    }

    render() {
        return (
            this.getProblemReceiveSummaryDetails()
        );
    }
}

export default ProblemItemsSummary;