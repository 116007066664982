import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout"
import Flashbar from "@amzn/awsui-components-react/polaris/flashbar"
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between"
import _ from "lodash";
import API from "@aws-amplify/api";

import LaunchPlanDetailsContainer from "./LaunchPlanDetailsContainer";
import { NodeLaunchPlan, NodesUnderLaunchPlan } from "../Types";
import { FlashbarCustomProps } from "../../../common/Types";
import WarehouseDetailsTable from "./WarehouseDetailsTable";
import { getAuthorizationHeader } from "@common/Auth";
import {useContext} from "react";
import {RegionContext} from "@common/RegionContext";
import {constructButaneApiName} from "../../../apiConfig";

interface LaunchPlanDetailsProps extends RouteComponentProps<{ registrationId: string, nodeLaunchPlanId: string, nodeId: string }> {
  isAuthenticated: boolean;
  userHasAuthenticated: boolean;
};

const LaunchPlanDetails = (props: LaunchPlanDetailsProps) => {
  if (!props.isAuthenticated) {
    props.history.push("/login");
  }

  const [nodeLaunchPlan, setNodeLaunchPlan] = React.useState<NodeLaunchPlan>();
  const [nodesUnderLaunchPlan, setNodesUnderLaunchPlan] = React.useState<NodesUnderLaunchPlan[]>([]);
  const [nodeLaunchPlanDetailsLoading, setNodeLaunchPlanDetailsLoading] = React.useState<boolean>(true);
  const [nodesUnderLaunchPlanTableLoading, setNodesUnderLaunchPlanTableLoading] = React.useState<boolean>(true);
  const [flashbarProps, setFlashbarProps] = React.useState<FlashbarCustomProps>({});
  const regionContext = useContext(RegionContext);


  React.useEffect(() => {
    const fetchNodeLaunchPlan = async () => {
      setNodeLaunchPlanDetailsLoading(true);
      API.get(constructButaneApiName(regionContext.region), `/api/v1/registrations/${props.match.params.registrationId}/launchPlans/?`, {
          headers: await getAuthorizationHeader()
        })
        .then((response: { nodeLaunchPlans: NodeLaunchPlan[] }) => {
          for (let plan of response.nodeLaunchPlans){
            if (plan.nodeLaunchPlanId===props.match.params.nodeLaunchPlanId){
              setNodeLaunchPlan(plan);
              break;
            }
          }
        }).catch(error => {
          setFlashbarProps({ header: error.response.data.code, msg: error.response.data.message, type: "error" });
        }).finally(() => setNodeLaunchPlanDetailsLoading(false));
    }
     fetchNodeLaunchPlan();
     fetchWarehouseDetail();
     // eslint-disable-next-line
  }, [props.match.params.registrationId,props.match.params.nodeLaunchPlanId]);

  
  const fetchWarehouseDetail = async () => {
    setNodesUnderLaunchPlanTableLoading(true);
    API.get(constructButaneApiName(regionContext.region), `/api/v1/registrations/${props.match.params.registrationId}/launchPlans/${props.match.params.nodeLaunchPlanId}/nodes/?`, {
        headers: await getAuthorizationHeader()
      })
      .then((response: { nodesUnderLaunchPlan: NodesUnderLaunchPlan[] }) => {
        response.nodesUnderLaunchPlan.sort((a, b) => b.created - a.created);
        setNodesUnderLaunchPlan(response.nodesUnderLaunchPlan);
      }).catch((error) => {
        setFlashbarProps({ header: error.response.data.code, msg: error.response.data.message, type: "error" });
      }).finally(() => {
        setNodesUnderLaunchPlanTableLoading(false);
      });
  }

  return (
    <AppLayout
      navigationHide={true}
      toolsHide={true}
      headerSelector="#top-navigation"
      content={
          <SpaceBetween size="m">
            {
              (!_.isEmpty(flashbarProps.header) || !_.isEmpty(flashbarProps.msg)) &&
              <Flashbar
                items={[{
                  header: flashbarProps.header,
                  content: flashbarProps.msg,
                  type: (flashbarProps.type ? flashbarProps.type : "error"),
                  dismissible: true,
                  onDismiss: () => setFlashbarProps({ header: "", msg: "" })
                }]}
              />
            }

            <LaunchPlanDetailsContainer
              loading={nodeLaunchPlanDetailsLoading}
              launchPlan={nodeLaunchPlan}
            />
            <WarehouseDetailsTable
              loading={nodesUnderLaunchPlanTableLoading}
              nodesUnderLaunchPlan={nodesUnderLaunchPlan}
              fetchData={fetchWarehouseDetail}
            />
          </SpaceBetween>
      }
    />
  );
};

export default LaunchPlanDetails;
