import {useCallback, useState} from "react";
import {ApiError} from "@common/exception";
import {InventoryReceipt} from "@modules/inbound/inventoryReceiveNotification/model";
import {DateRange} from "@common/model";
import {CommonUtils} from "@modules/inbound/inventoryReceiveNotification/common";


export function useGetInventoryReceipts() {
  const [error, setError] = useState<ApiError | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<InventoryReceipt[]>([]);

  const getInventoryReceipts = useCallback(
    async (siteName: string, dateRange?: DateRange) => {
      if (!siteName || !dateRange) {
        return;
      }
      setIsLoading(true);
      setError(null);
      try {
        let irs = [] as unknown as InventoryReceipt[];
        const ir = new InventoryReceipt(`dummy_po_${siteName}`, '2023-10-10', '1234',
          'RECEIVE', 'SUCCESS', 'VUSB', 'NONE');
        irs.push(ir);
        setData(irs);
      } catch (exception) {
        const apiError = CommonUtils.getApiError(exception, siteName, 'inventory receipt');
        setError(apiError);
        setData([]);
      }
      setIsLoading(false);
    }, []);

  return {
    error,
    isLoading,
    inventoryReceipts: data,
    getInventoryReceipts
  };
}