import React, { Component } from "react";
/* Import Components */
import BulkDeleteProblemReceiveItemsForm from "./BulkDeleteProblemReceiveItemsForm";
import BulkDeleteProblemReceiveItemDetails from "./BulkDeleteProblemReceiveItemDetails";
import UnAuthorizedAccessAlert from "../../../views/layouts/UnAuthorizedAccessAlert";

class BulkDeleteProblemReceiveItems extends Component {
    constructor(props) {
        super(props);

        this.state = {
            problemItemList: '',
            warehouseId: ''
        }
    }

    handleSubmit = (problemItemList, warehouseId) => {
        this.setState(() => ({
            problemItemList: problemItemList,
            warehouseId: warehouseId,
        }));
    }

    render() {
        if (this.props.isAuthenticated) {
            return (
                <div>
                    {this.props.isExternal && <UnAuthorizedAccessAlert/>}
                    {!this.props.isExternal && <BulkDeleteProblemReceiveItemsForm
                        problemItemList={this.state.problemItemList}
                        warehouseId={this.state.warehouseId}
                        handleSubmit={this.handleSubmit}
                    />}
                    {!this.props.isExternal && <BulkDeleteProblemReceiveItemDetails
                        problemItemList={this.state.problemItemList}
                        warehouseId={this.state.warehouseId}
                    />}
                </div>
            );
        }
        this.props.history.push("/login");
        return null;
    }
}

export default BulkDeleteProblemReceiveItems;