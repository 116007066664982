import {TableEmptyState} from "@common/table";
import Table from "@amzn/awsui-components-react/polaris/table";
import React, {useContext, useEffect, useState} from "react";
import {useCollection} from "@amzn/awsui-collection-hooks";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import API from "@aws-amplify/api";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import { getAuthorizationHeader } from "@common/Auth";
import {RegionContext} from "@common/RegionContext";
import {constructButaneApiName} from "../../../../apiConfig";

export interface CommittedOrderItem {
    orderId: string,
    shipmentId: string,
    quantity: number,
}

interface CommittedOrderDetailsTableProps {
    fnSku: string,
    warehouse: string,
}

export const CommittedOrderDetailsTable = (props: CommittedOrderDetailsTableProps) => {
    const [fetchingCommittedOrders, setFetchingCommittedOrders] = useState(false);
    const [committedOrderItems, setCommittedOrderItems] = useState<CommittedOrderItem[]>([]);
    const [errorMessage, setErrorMessage] = useState("");
    const regionContext = useContext(RegionContext);

    useEffect(() => {
        setCommittedOrderItems([]);
        setFetchingCommittedOrders(false);
        setErrorMessage("");
        getCommittedOrderDetails(props, regionContext.region);
    }, [props, regionContext]);

    const getCommittedOrderDetails = async (props: CommittedOrderDetailsTableProps, region: string) => {
        setFetchingCommittedOrders(true);
        let orderItems: CommittedOrderItem[] = [];
        API.get(constructButaneApiName(region), `/api/order/fnSku/${props.fnSku}/warehouse/${props.warehouse}`, {
                headers: await getAuthorizationHeader()
            })
            .then((data) => {
                data.orders.forEach(order => {
                    orderItems.push({
                        orderId: order.orderId,
                        shipmentId: order.shipmentId,
                        quantity: order.quantity
                    });
                });
            }).catch(() => {
                setErrorMessage("Error fetching committed grading order details.")
            }).finally(() => {
                setCommittedOrderItems(orderItems);
                setFetchingCommittedOrders(false)
            });
    }

    const {
        items,
        filterProps,
        paginationProps,
    } = useCollection(committedOrderItems, {
        filtering: {},
        pagination: {
            pageSize: 10
        }
    });

    return <div>
        {
            errorMessage &&
            <Alert type={"error"}>
                {errorMessage}
            </Alert>
        }
        <Table
            loading={fetchingCommittedOrders}
            loadingText={`Fetching committed customer orders for FNSKU: ${props.fnSku}`}
            items={items}
            empty={
                <TableEmptyState
                    resourceName={"Commited Customer Order"}
                    resourceNamePlural={"Commited Customer Orders"}
                    showCreateResourceButton={false}/>
            }
            columnDefinitions={[
                {
                    id: "orderId",
                    header: "OrderId",
                    cell: e => e.orderId,
                },
                {
                    id: "shipmentId",
                    header: "ShipmentId",
                    cell: e => e.shipmentId,
                },
                {
                    id: "quantity",
                    header: "Quantity",
                    cell: e => e.quantity,
                }
            ]}
            filter={
                <div>
                    <TextFilter
                        {...filterProps}
                    />
                </div>
            }
            pagination={
                <Pagination
                    {...paginationProps}
                />
            }
        />
    </div>
}