import React from 'react';

const GuideLink = ({link}) => {
    return (
        <h4>
            <p className="guide-link">
                <a href={link}>Usage guide</a>
            </p>
        </h4>
    )
}

export default GuideLink;