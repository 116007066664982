import React from 'react';
import { ChevronLeft, ChevronRight, FirstPage, LastPage, SaveAlt, Search, Clear, ArrowDownward } from '@material-ui/icons';

export const TABLE_ICONS = {

    Export: React.forwardRef((props, ref) => <SaveAlt{...props} ref={ref} />),

    FirstPage: React.forwardRef((props, ref) => <FirstPage{...props} ref={ref} />),

    LastPage: React.forwardRef((props, ref) => <LastPage{...props} ref={ref} />),

    NextPage: React.forwardRef((props, ref) => <ChevronRight{...props} ref={ref} />),

    PreviousPage: React.forwardRef((props, ref) => <ChevronLeft{...props} ref={ref} />),

    ResetSearch: React.forwardRef((props, ref) => <Clear{...props} ref={ref} />),

    Search: React.forwardRef((props, ref) => <Search{...props} ref={ref} />),

    SortArrow: React.forwardRef((props, ref) => <ArrowDownward{...props} ref={ref} />)

};