export class TransferDepartWorkflow {
  public readonly isClosed!: boolean;

  public readonly isErrored!: boolean;

  public readonly isOpen!: boolean;

  public readonly state!: string;

  public readonly workflowId!: string;

  public readonly sourceWarehouse!: string;

  public readonly destinationWarehouse!: string;
}
