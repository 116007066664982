import dayjs from "dayjs";

interface DateRangeProps {
  /**
   * The start of the date range.
   */
  fromDate: dayjs.Dayjs;

  /**
   * The end of the date range.
   * @optional
   */
  toDate?: dayjs.Dayjs;
}

/**
 * Class which holds a `fromDate` and (optional) `toDate`.
 */
export class DateRange {
  public readonly fromDate!: dayjs.Dayjs;

  public readonly toDate?: dayjs.Dayjs;

  constructor({ fromDate, toDate }: DateRangeProps) {
    this.fromDate = fromDate;
    this.toDate = toDate;
  }
}
