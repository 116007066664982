import * as React from "react";
import Box from "@amzn/awsui-components-react/polaris/box"
import Header from "@amzn/awsui-components-react/polaris/header"
import Pagination from "@amzn/awsui-components-react/polaris/pagination"
import Table from "@amzn/awsui-components-react/polaris/table"
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter"
import { TableProps } from "@amzn/awsui-components-react";
import { useCollection } from "@amzn/awsui-collection-hooks";

import { NodesUnderLaunchPlan } from "../Types";
import { WAREHOUSE_DETAILS } from "../Constants";
import formatDate from "../../../common/DateFormatter";
import Popovers from "../Popovers";

interface WarehouseDetails {
  loading: boolean;
  nodesUnderLaunchPlan: NodesUnderLaunchPlan[];
  fetchData: () => void;
}

const WarehouseDetailsTable = (props: WarehouseDetails) => {
  const PAGE_SIZE = 10;

  const COLUMN_DEFINITIONS: ReadonlyArray<TableProps.ColumnDefinition<NodesUnderLaunchPlan>> = [
    { id: "nodeId", header: "Warehouse ID", cell: item => item.id},
    { id: "fulfilmentNodeType", header: "Warehouse Type", cell: item => item.fulfilmentNodeType},
    { id: "nodeName", header: "Warehouse Name", cell: item => item.name},
    { id: "vendorCode", header: "Vendor Code", cell: item => item.vendor.code},
    { id: "vendorId", header: "Vendor Id", cell: item => item.vendor.id},
    { id: "created", sortingField: "created", header: "Creation Date", cell: item => formatDate(item.created) },
  ]

  const collectionHook = useCollection<NodesUnderLaunchPlan>(
    props.nodesUnderLaunchPlan ?? [],
    {
      filtering: {
        fields: ["id", "fulfilmentNodeType", "name"],
        empty: (
          <Box textAlign="center">
            <b>No Warehouse details available</b>
          </Box>
       ),
        noMatch: (
         <Box textAlign="center">
           <b>No matches</b>
           <p>We can’t find a match.</p>
         </Box>
        )
      },
      sorting: {
        defaultState: {
          sortingColumn: {
            sortingField: "created",
          },
          isDescending: true
        }
      },
      pagination: {
        defaultPage: 1,
        pageSize: PAGE_SIZE
      }
    },
  );

  return (
    <Table
      {...collectionHook.collectionProps}
      loading={props.loading}
      columnDefinitions={COLUMN_DEFINITIONS}
      header={
        <Header variant="h2" 
          counter={ (`(${props.nodesUnderLaunchPlan.length})`) }
          info={<Popovers content={WAREHOUSE_DETAILS}></Popovers>}
        >
          Warehouse details
        </Header>
      }
      items={collectionHook.items}
      filter={
            <TextFilter {...collectionHook.filterProps} filteringPlaceholder="Find warehouse details" />
      }
      pagination={
        <Pagination {...collectionHook.paginationProps} />
      }
      wrapLines={true}
    />
  );
}

export default WarehouseDetailsTable;