import React, { useEffect, useState } from "react";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import StepConnector from "@material-ui/core/StepConnector";
import { withStyles } from "@material-ui/core/styles";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";

import {
  TransferDepartWorkflow,
  TransferDepartWorkflowButaneState
} from "@modules/transfer/common/model";
import { WorkflowStateConverter } from "@modules/transfer/departure-requests/logic";

import "./workflowDetailsStepper.css";

const BUTANE_STATES = Object.keys(TransferDepartWorkflowButaneState);

const BUTANE_STATE_STEPS = BUTANE_STATES.map((step) => {
  return {
    label: step
  };
});

const BUTANE_STATE_STEPS_WITH_ERROR = [
  ...BUTANE_STATE_STEPS,
  { label: "ERROR" }
];

const StyledConnector = withStyles({
  line: {
    height: 1,
    border: 0,
    backgroundColor: "darkGray",
    borderRadius: 1
  }
})(StepConnector);

export interface WorkflowDetailsStepperProps {
  transferDepartWorkflow: TransferDepartWorkflow | null;
}

export function WorkflowDetailsStepper({
  transferDepartWorkflow
}: WorkflowDetailsStepperProps) {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [steps, setSteps] = useState(BUTANE_STATE_STEPS);

  function setActiveStep(transferDepartWorkflow: TransferDepartWorkflow) {
    if (transferDepartWorkflow.isClosed) {
      return setActiveStepIndex(BUTANE_STATE_STEPS.length);
    }

    const herdState = WorkflowStateConverter.toHerdState(
      transferDepartWorkflow
    );
    const butaneState = WorkflowStateConverter.toButaneState(herdState);

    const activeIndex = BUTANE_STATE_STEPS_WITH_ERROR.findIndex(
      (state) => state.label === butaneState
    );
    setActiveStepIndex(activeIndex);
  }

  useEffect(() => {
    if (!transferDepartWorkflow) {
      return;
    }

    setActiveStep(transferDepartWorkflow);

    if (transferDepartWorkflow.isErrored) {
      setSteps(BUTANE_STATE_STEPS_WITH_ERROR);
    }
  }, [transferDepartWorkflow]);

  function StepIcon({ active, completed, icon: iconIndex, ...props }) {
    if (shouldDisplayErrorState()) {
      return <StatusIndicator type="error" />;
    }
    if (shouldDisplayStoppedState()) {
      return <StatusIndicator type="stopped" />;
    }
    if (active) {
      return <StatusIndicator type="in-progress" />;
    }
    if (completed) {
      return <StatusIndicator type="success" />;
    }

    return <StatusIndicator type="pending" />;

    function shouldDisplayErrorState() {
      const oneBasedActiveStepIndex = activeStepIndex + 1;
      return (
        transferDepartWorkflow?.isErrored &&
        iconIndex === oneBasedActiveStepIndex
      );
    }

    function shouldDisplayStoppedState() {
      return transferDepartWorkflow?.isErrored && iconIndex === steps.length;
    }
  }

  if (!transferDepartWorkflow) {
    return null;
  }

  function isActiveErroredStep(index: number) {
    return transferDepartWorkflow?.isErrored && index === activeStepIndex;
  }

  return (
    <div className="workflow-stepper">
      <Stepper
        alternativeLabel
        activeStep={activeStepIndex}
        connector={<StyledConnector />}
      >
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              error={isActiveErroredStep(index)}
              StepIconComponent={StepIcon}
            >
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
