import React, {useEffect, useState} from "react";
import Form from "@amzn/awsui-components-react/polaris/form";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import PolarisButton from "@amzn/awsui-components-react/polaris/button";
import {ColumnLayout} from "@amzn/awsui-components-react";
import Input from "@amzn/awsui-components-react/polaris/input";
import {RouteComponentProps} from "react-router-dom";
import DatePicker from "@amzn/awsui-components-react/polaris/date-picker";
import "./fcSkuInventoryAuditInput.css"
import {fcSkuRegex, warehouseIdRegex} from "@constants/Regex";

interface FcSkuInventoryAuditInputProps extends RouteComponentProps {
    fcSku: string,
    warehouse: string,
    startDate: Date,
    endDate: Date,
    renderTable(value: boolean): any,
}

export const FcSkuInventoryAuditInput = (props: FcSkuInventoryAuditInputProps) => {
    const [fcSku, setFcSku] = useState("");
    const [warehouse, setWarehouse] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    useEffect(() => {
        setFcSku(props.fcSku);
        setWarehouse(props.warehouse);
        setStartDate(props.startDate);
        setEndDate(props.endDate);
        renderTableIfInputsValid(props);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const renderTableIfInputsValid = (props: FcSkuInventoryAuditInputProps) => {
        if (areInputsValid(props.warehouse, props.fcSku, props.startDate, props.endDate)) {
            props.renderTable(true);
        }
    }

    const areInputsValid = (warehouse: string, fcSku: string, startDate: Date, endDate: Date) => {
        return (warehouseIdRegex.test(warehouse) && fcSkuRegex.test(fcSku) && startDate && endDate && (startDate <= endDate));
    }

    const onAuditsSearch = () => {
        props.history.push(`/inventory/audits/fcsku/${fcSku}?warehouse=${warehouse}&startDate=${startDate.toISOString().slice(0,10)}&endDate=${endDate.toISOString().slice(0,10)}`);
    }

    const isEndDateValid = (startDate: Date, endDate: Date) => {
        if (startDate) {
            return endDate >= startDate && endDate < new Date();
        } else {
            return endDate < new Date();
        }
    }

    return (
        <Form
            data-fcsku-audit-input="input-container"
            actions={<PolarisButton
                onClick={onAuditsSearch}
                iconName="search"
                variant="primary"
                disabled={!areInputsValid(warehouse, fcSku, startDate, endDate)}
            >
                Search
            </PolarisButton>}
        >
            <Container
                header={
                    <Header>
                        Search FCSKU Inventory Audits
                    </Header>
                }
            >
                <SpaceBetween size={"l"}>
                    <div>
                        <ColumnLayout columns={2}>
                            <FormField label="FCSKU"
                                       description="FCSKU identifier e.g. BT00CTP9EC"
                            >
                                <Input type="text"
                                       value={fcSku}
                                       onChange={(e) => setFcSku(e.detail.value)}
                                />
                            </FormField>
                            <FormField label="Warehouse"
                                       description="Warehouse code e.g. VUSB"
                            >
                                <Input type="text"
                                       value={warehouse}
                                       onChange={(e) => setWarehouse(e.detail.value)}
                                />
                            </FormField>
                        </ColumnLayout>
                        <ColumnLayout columns={2}>
                            <FormField label="Start Date">
                                <DatePicker
                                    onChange={startDate => setStartDate(new Date(startDate.detail.value))}
                                    value={startDate.toISOString()}
                                />
                            </FormField>
                            <FormField label="End Date">
                                <DatePicker
                                    onChange={endDate => setEndDate(new Date(endDate.detail.value))}
                                    value={endDate.toISOString()}
                                    isDateEnabled={(date) => isEndDateValid(startDate, date)}
                                />
                            </FormField>
                        </ColumnLayout>
                    </div>
                </SpaceBetween>
            </Container>
        </Form>
    );
}
