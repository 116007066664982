import React from "react";
import {TABLE_ICONS} from "../../constants/table/metadata/MaterialTableIcons";
import MaterialTable from "material-table";
import "./MaterialTable.css";

export default class TableForShipmentsInCondition extends React.Component {

    render() {
        const columns = this.props.columnMetadata;
        const data = this.props.results;

        return (
            <div>
                <MaterialTable
                    icons={TABLE_ICONS}
                    title="Shipment Details"
                    columns={columns}
                    data={data}
                    options={{
                        rowStyle: (rowData) => ({
                            backgroundColor:
                                (rowData.hoursPassedExsd > 0) ? '#E33232' : '#FFFFFF',
                        }),
                        headerStyle: {fontWeight: "bold", fontSize: 15},
                        search: true,
                        exportButton: true,
                        exportAllData: true,
                        emptyRowsWhenPaging: false,
                        sorting: true,
                        draggable: false,
                        pageSizeOptions: [5, 10, 20, 40]
                    }}
                    style={{
                        marginLeft: "7px"
                    }}
                />
            </div>
        )
    }
}