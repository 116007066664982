// React
import React from "react";

import Alert from "@amzn/awsui-components-react/polaris/alert";

function UnAuthorizedAccessAlert(){
    return (
        <Alert type={"error"} header={"Unathorized Access"}>
          User is not authorized access to this resource.<br/>
        </Alert>
    );
}

export default UnAuthorizedAccessAlert;
