import React, {useEffect, useState} from "react";
import Form from "@amzn/awsui-components-react/polaris/form";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import PolarisButton from "@amzn/awsui-components-react/polaris/button";
import {ColumnLayout} from "@amzn/awsui-components-react";
import Input from "@amzn/awsui-components-react/polaris/input";
import {RouteComponentProps} from "react-router-dom";
import Select from "@amzn/awsui-components-react/polaris/select";
import {OptionDefinition} from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";
import "./fcSkuTrackingInput.css"
import {asinRegex, fcSkuRegex, lpnRegex, warehouseIdRegex} from "@constants/Regex";

interface FcSkuTrackingInputProps extends RouteComponentProps {
    warehouse: string,
    attributeName: string,
    attributeValue: string,
    asin: string,
    renderTable(value: boolean): any
}

export const FcSkuTrackingInput = (props: FcSkuTrackingInputProps) => {
    const [warehouse, setWarehouse] = useState("");
    const [attributeName, setAttributeName] = useState("FCSKU");
    const [attributeValue, setAttributeValue] = useState("");
    const [asin, setAsin] = useState("");

    const FCSKU = "FCSKU";
    const LPN = "LPN";

    useEffect(() => {
        setWarehouse(props.warehouse);
        setAttributeName(props.attributeName ? props.attributeName : FCSKU);
        setAttributeValue(props.attributeValue);
        setAsin(props.asin);
        renderTableIfPropsValid(props);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const renderTableIfPropsValid = (props: FcSkuTrackingInputProps) => {
        isInputValid(props.warehouse, props.asin, props.attributeName, props.attributeValue) &&
        props.renderTable(true);
    }

    const isInputValid = (warehouse: string, asin: string, attributeName: string, attributeValue: string) => {
        return warehouseIdRegex.test(warehouse) && ( isAsinRequired() ? asinRegex.test(asin) : true) &&
            (attributeName === FCSKU ? fcSkuRegex.test(attributeValue) : true) &&
            (attributeName === LPN ? lpnRegex.test(attributeValue) : true) &&
            attributeValue && attributeValue;
    }

    const isAsinRequired = () => {
        return attributeName === LPN;
    }

    const onSearch = () => {
        let path = `/fcsku/details?warehouse=${warehouse}&attributeName=${attributeName}&attributeValue=${attributeValue}`;
        if (isAsinRequired()) {
            path = path.concat(`&asin=${asin}`);
        }
        props.history.push(path);
    }

    const getSupportedAttributeName = () => {
        let displayOptions: OptionDefinition[] = [];
        displayOptions.push({label: FCSKU, value: FCSKU});
        displayOptions.push({label: LPN, value: LPN});
        return displayOptions;
    }

    return (
        <Form data-fcsku-tracking-input="input-container"
              actions={
                    <SpaceBetween size={"l"}>
                        <PolarisButton
                            onClick={onSearch}
                            iconName="search"
                            variant="primary"
                            data-fcsku-tracking-input="search-button"
                            disabled={!isInputValid(warehouse, asin, attributeName, attributeValue)}
                        >
                            Search
                        </PolarisButton>
                    </SpaceBetween>
              }
        >
            <SpaceBetween size={"l"}>
            <Container
                header={
                    <Header variant="h2">
                        FCSKU Tracking Tool
                    </Header>
                }
            >
                <SpaceBetween direction="vertical" size="s">
                    <FormField label="Warehouse">
                        <Input type="text"
                               data-fcsku-tracking-input="input-field"
                               value={warehouse}
                               onChange={(e) => setWarehouse(e.detail.value)}
                        />
                    </FormField>
                    <ColumnLayout columns={2}>
                        <FormField label="Select attribute name">
                            <Select
                                selectedOption={{
                                    label: attributeName,
                                    value: attributeName
                                }}
                                options={getSupportedAttributeName()}
                                onChange={(e) => {setAttributeName(e.detail.selectedOption.value ?? FCSKU)}}
                            />
                        </FormField>
                        <FormField label="Identifier">
                            <Input type="text"
                                   value={attributeValue}
                                   onChange={(e) => setAttributeValue(e.detail.value)}
                            />
                        </FormField>
                    </ColumnLayout>
                    <FormField
                        label="ASIN"
                        description={"Only required when looking up by LPN"}
                    >
                        <Input type="text"
                               value={asin}
                               disabled={!isAsinRequired()}
                               onChange={(e) => setAsin(e.detail.value)}
                               data-fcsku-tracking-input="input-field"
                        />
                    </FormField>
                </SpaceBetween>
            </Container>
            </SpaceBetween>
        </Form>
    );
}
