import React, { Component } from 'react';
import { Button, TextField } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import PageTitle from '../../components/PageTitle/PageTitle';

import '../../common/common.css';
import './RemovalOrderInfo.css';
import { warehouseIdRegex, removalIdRegex } from '../../constants/Regex';

class RemovalOrderInfoForm extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            orderId: this.props.orderId,
            warehouseId: this.props.warehouseId
        };
    }

    handleChange = (event) => {
        const { target: { name, value } } = event
        this.setState({ 
            [name]: value.toUpperCase() 
        })
    }

    isValidInput = () => {
        return !!this.state.warehouseId && warehouseIdRegex.test(this.state.warehouseId)
            && !!this.state.orderId && removalIdRegex.test(this.state.orderId);
    };

    handleFormSubmit = (event) => {
        event.preventDefault();
        const value = event.target.value;
    
        this.setState({
            [event.target.name]: value
        });
    
        this.props.getFormInput(this.state.orderId, this.state.warehouseId);
    }

    render() {
        return (
            <div>
                <div>
                    <PageTitle>Removal Order Info</PageTitle>
                </div>    
                <div>
                    <div className="form-inline">
                        <div>
                            <div className="filter-form">
                                <div className="filters">
                                    <div>
                                        <Tooltip
                                            placement="top"
                                            title="Removal Order Id or VRET/LIQN Id"
                                        >
                                            <label className="input-params"> Order Id</label>
                                        </Tooltip>
                                        <TextField
                                            className="removal-orderId-input-box"
                                            placeholder="Removal Id or VRET/LIQN Id"
                                            name="orderId"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.orderId}
                                        />    
                                    </div>
                                    <div>
                                        <label className="input-params">Warehouse</label>
                                        <TextField
                                            className="removal-warehouse-input-box"
                                            placeholder="Warehouse Id"
                                            name="warehouseId"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.warehouseId}
                                        />
                                    </div>
                                </div>    
                                <div className="inline align-middle text-center">
                                    <div className="removal-submit-button-padding" />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<SearchOutlinedIcon />}
                                        disabled={!this.isValidInput()}
                                        onClick={this.handleFormSubmit}
                                    >
                                        Search
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        )
    }
}

export default RemovalOrderInfoForm;