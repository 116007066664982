export const genericEmailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
export const amazonEmailRegex = /^.*@amazon\.(com(\.au|\.mx|\.br)?|co\.jp|co\.uk|ca|cn|de|es|fr|in|it|nl|lu)$/;
export const passwordRegex = /^((?=.*\d)(?=.*[A-Z])(?=.*\W).{8,})$/;
export const warehouseIdRegex = /^[A-Z][A-Z0-9]{3,4}$/;
export const asinRegex = /^[A-Z0-9]{10}$/;
export const fcSkuRegex = /^[0-9BXZ][0-9A-Z]{9}$/;
export const lpnRegex = /^LPN[0-9a-zA-Z]{1,37}$/;
export const removalIdRegex = /^(VRET|LIQN)?\d+$/;
export const inputWarehouseListRegex = /^(( )*[A-Z][A-Z0-9]{3,4}( )*(,)){0,99}( )*[A-Z][A-Z0-9]{3,4}( )*$/;
export const alphaNumericRegex = /^[a-zA-Z0-9]+$/;
export const commaSeparatedListRegex =/^[\d]+(\s*,\s*\d+){0,9}$/;
export const dateRegex = /^\d{4}[./-]\d{2}[./-]\d{2}$/;
export const containerRegex = /^warehouse-[A-Z][A-Z0-9]{3,4}|vendor-[1-9][0-9]*|marketplace-[1-9][0-9]*$/; // valid container name
export const parentContainerRegex = /^vendor-[1-9][0-9]*|marketplace-[1-9][0-9]*|global$/; // valid container name which excludes warehouse
export const numericRegex = /^\d+$/; // to support numeric values only