import React from "react";
import OpConfigFormTitle from "./OpConfigFormTitle";
import InputField from "./InputField";
import Button from "@material-ui/core/Button";
import OperationalConfigurations from "./OperationalConfigurations";
import "./opConfigForm.css";
import {AlertMessage} from "@components/AlertMessage";

class OpConfigForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formResponses: this.clone(this.props.data),
      updateCount: 0,
      invalidCount: 0,
      showError: this.initializeShowError(),
      disableSubmitButton: true,
      disableCancelButton: true,
    };
  }

  initializeShowError = () => {
    let showError = new Map();
    for (let paramName of this.props.data.keys()) {
      if (paramName !== "FormTitle") {
        showError.set(paramName, false);
      }
    }
    return showError;
  };

  clone = (data) => {
    return new Map(JSON.parse(JSON.stringify(Array.from(data))));
  };

  handleSubmitButton = (invalidCount, updateCount) => {
    this.setState({
      disableSubmitButton: !(invalidCount === 0 && updateCount > 0),
    });
  };

  handleCancelButton = (updateCount) => {
    this.setState({
      disableCancelButton: updateCount === 0,
    });
  };

  handleChange = (event, paramName) => {
    let { formResponses, invalidCount, updateCount, showError } = this.state;
    let paramValue = formResponses.get(paramName).paramValue;
    const paramData = this.props.data.get(paramName);
    const curValue = event.target.value.trim();
    const prevValue = paramValue.trim();
    const initValue = this.props.data.get(paramName).paramValue;
    paramValue = event.target.value;
    formResponses.set(paramName, {
      ...paramData,
      paramValue
    });
    const type = OperationalConfigurations.get(this.props.formName).get(
        paramName
    ).type;
    let error =
        type === "Number" &&
        (curValue === "" || !Number.isInteger(Number(curValue)));
    if (!error && type === "Number" && curValue.slice(-1) === ".") {
      error = true;
    }
    invalidCount += Number(error) - Number(showError.get(paramName));
    showError.set(paramName, error);

    if (type === "Number") {
      updateCount -= Number(Number(prevValue) !== Number(initValue));
      updateCount += Number(Number(curValue) !== Number(initValue));
    } else {
      updateCount -= Number(prevValue !== initValue);
      updateCount += Number(curValue !== initValue);
    }

    this.handleSubmitButton(invalidCount, updateCount);
    this.handleCancelButton(updateCount);
    this.setState({
      formResponses,
      updateCount,
      invalidCount,
      showError,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let { formResponses, invalidCount, updateCount } = this.state;
    let config = [];
    const formDetails = OperationalConfigurations.get(this.props.formName);
    for (let paramName of formDetails.keys()) {
      if (paramName !== "FormTitle") {
        const initParamValue = this.props.data.get(paramName).paramValue;
        const curParamValue = formResponses.get(paramName).paramValue;
        const paramData = formResponses.get(paramName);
        if (curParamValue.trim() !== initParamValue) {
          config.push({
            ...paramData,
            paramName: paramName,
            paramValue: curParamValue.trim(),
          });
        }
      }
    }
    updateCount = 0;
    this.setState({
      updateCount,
    });
    this.handleSubmitButton(invalidCount, updateCount);
    this.handleCancelButton(updateCount);
    this.props.postVWPConfig(config, this.props.formName);
  };

  handleCancel = (event) => {
    event.preventDefault();
    let { formResponses, showError, invalidCount, updateCount } = this.state;
    showError = this.initializeShowError();
    invalidCount = updateCount = 0;
    formResponses = this.clone(this.props.data);
    this.setState({
      formResponses,
      showError,
      invalidCount,
      updateCount,
    });
    this.handleSubmitButton(invalidCount, updateCount);
    this.handleCancelButton(updateCount);
  };

  render() {
    let responses = [];
    const formDetails = OperationalConfigurations.get(this.props.formName);
    for (let [paramName, paramDetails] of formDetails) {
      if (paramName !== "FormTitle") {
        let showError = this.state.showError.get(paramName);
        let paramValue = this.state.formResponses.get(paramName).paramValue;
        responses.push(
            <InputField
                key={paramName}
                displayName={paramDetails.displayName}
                paramName={paramName}
                paramValue={paramValue}
                handleChange={this.handleChange}
                editEnabled={this.props.editEnabled}
                showError={showError}
                unit={paramDetails.unit}
            />
        );
      }
    }

    let { title, description } = formDetails.get("FormTitle");
    return (
        <div className="box-container">
          <div className="box-container">
            <div className="opconfig-flex-container">
              <OpConfigFormTitle title={title} description={description} />

              <div className="responses">{responses}</div>
            </div>

            {this.props.editEnabled && (
                <div className="opConfig-button-container">
                  <div className="submitButton">
                    <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={(event) => {
                          this.handleSubmit(event);
                        }}
                        disabled={this.state.disableSubmitButton}
                    >
                      Submit
                    </Button>
                  </div>
                  <div className="cancelButton">
                    <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={(event) => {
                          this.handleCancel(event);
                        }}
                        disabled={this.state.disableCancelButton}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
            )}
          </div>
          <AlertMessage
              alertMessage={`We have disabled the edit/view feature of Return Address due to security concerns. 
                Please raise a ticket with EFP-VFX or use efpm.amazon.com to modify the Return Address.`}
              visible={true}
              dismissible={false}
              alertType={"info"}
          />
        </div>
    );
  }
}

export default OpConfigForm;