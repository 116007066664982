import React, { Component } from 'react';
import { Button, TextField } from "@material-ui/core";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";

import PageTitle from '../../../components/PageTitle/PageTitle';
import { warehouseIdRegex } from '../../../constants/Regex';
import { getErrorMessage } from "../../../constants/ErrorMessageMapper";
import './ProblemItemInfo.css';

class ProblemItemDetailsInfoForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            warehouseId: "",
            warehouseIdModified: false
        };
    }

    handleChange = e => {
        e.preventDefault();
        const value = e.target.value.toUpperCase();
        this.setState({
            [e.target.name]: value.trim(),
            [e.target.name + "Modified"]: true
        });
    };

    isInputValid = () => {
        return !!this.state.warehouseId && warehouseIdRegex.test(this.state.warehouseId);
    };

    isWarehouseIdUpdatedAndInvalid = () => {
        return (this.state.warehouseIdModified && !warehouseIdRegex.test(this.state.warehouseId));
    }

    handleFormSubmit = (event) => {
        event.preventDefault();
        const value = event.target.value;

        this.setState({
            [event.target.name]: value
        });

        this.props.handleSubmit(this.state.warehouseId);
    }

    render() {
        return (
            <div>
                <div>
                    <PageTitle>Search Problem Item Details</PageTitle>
                </div>
                <div>
                    <div>
                        <div className="form-inline">
                            <div>
                                <div className="filter-form">
                                    <div className="filters">
                                        <div className="warehouse-input-box-height">
                                            <label htmlFor="warehouseId" className="problem-items-input-params">Warehouse</label>
                                            <TextField
                                                error={this.isWarehouseIdUpdatedAndInvalid()}
                                                placeholder="Warehouse Id"
                                                name="warehouseId"
                                                helperText={this.isWarehouseIdUpdatedAndInvalid() && getErrorMessage("warehouseId")}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="inline align-middle text-center">
                                        <div className="inbound-submit-button-padding" />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<SearchOutlinedIcon />}
                                            onClick={this.handleFormSubmit}
                                            disabled={!this.isInputValid()}
                                        >
                                            Search
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProblemItemDetailsInfoForm;