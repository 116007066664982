import React, { Component } from 'react';
import Button from "@material-ui/core/Button";
import {fetchResolveRPILink} from "../../../constants/FetchResolveRPILink"
import {RegionContext} from '@common/RegionContext';
import {getEnvironment} from '@modules/signup/Utils';

export default class ResolveRPI extends Component {
    render() {
        return (
	    <a href={`${fetchResolveRPILink(this.context.region, getEnvironment())}`}
               className="rpi-button"
               target="_blank"
               rel="noopener noreferrer">
                    <Button variant="contained" size="small"> 
                        RESOLVE RPI
                    </Button>
            </a>
        )
    }
}

ResolveRPI.contextType = RegionContext;
