import { PropertyFilterProps } from "@amzn/awsui-components-react";

export const PAGINATION_LABELS = {
  nextPageLabel: "Next page",
  previousPageLabel: "Previous page",
  pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`
};

function formatRelativeRange(range) {
  const unit = range.amount === 1 ? range.unit : `${range.unit}s`;
  return `Last ${range.amount} ${unit}`;
}

export const DATE_RANGE_FILTER_I18N_LABELS = {
  todayAriaLabel: "Today",
  nextMonthAriaLabel: "Next month",
  previousMonthAriaLabel: "Previous month",
  customRelativeRangeDurationLabel: "Duration",
  customRelativeRangeDurationPlaceholder: "Enter duration",
  customRelativeRangeOptionLabel: "Custom duration",
  customRelativeRangeOptionDescription: "Set a custom range in the past",
  customRelativeRangeUnitLabel: "Unit of time",
  formatUnit: (unit, value) => (value === 1 ? unit : `${unit}s`),
  formatRelativeRange: formatRelativeRange,
  dateTimeConstraintText: "",
  relativeModeTitle: "Relative range",
  absoluteModeTitle: "Absolute range",
  relativeRangeSelectionHeading: "Choose a range",
  startDateLabel: "Start date",
  endDateLabel: "End date",
  startTimeLabel: "Start time",
  endTimeLabel: "End time",
  clearButtonLabel: "Clear",
  cancelButtonLabel: "Cancel",
  applyButtonLabel: "Apply"
};

export const PROPERTY_FILTERING_I18N_LABELS: PropertyFilterProps.I18nStrings = {
  filteringAriaLabel: "your choice",
  dismissAriaLabel: "Dismiss",

  filteringPlaceholder: "Search",
  groupValuesText: "Values",
  groupPropertiesText: "Properties",
  operatorsText: "Operators",

  operationAndText: "and",
  operationOrText: "or",

  operatorLessText: "Less than",
  operatorLessOrEqualText: "Less than or equal",
  operatorGreaterText: "Greater than",
  operatorGreaterOrEqualText: "Greater than or equal",
  operatorContainsText: "Contains",
  operatorDoesNotContainText: "Does not contain",
  operatorEqualsText: "Equals",
  operatorDoesNotEqualText: "Does not equal",

  editTokenHeader: "Edit filter",
  propertyText: "Property",
  operatorText: "Operator",
  valueText: "Value",
  cancelActionText: "Cancel",
  applyActionText: "Apply",
  allPropertiesLabel: "All properties",

  tokenLimitShowMore: "Show more",
  tokenLimitShowFewer: "Show fewer",
  clearFiltersText: "Clear filters",
  removeTokenButtonAriaLabel: () => "Remove token",
  enteredTextLabel: (text) => `Use: "${text}"`
};
