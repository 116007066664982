import * as React from "react";
import { useEffect, useState } from 'react';
import Container from "@amzn/awsui-components-react/polaris/container";
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Header from '@amzn/awsui-components-react/polaris/header';
import Input from '@amzn/awsui-components-react/polaris/input';
import AttributeEditor from '@amzn/awsui-components-react/polaris/attribute-editor';
import Alert from "@amzn/awsui-components-react/polaris/alert";
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';

import { AppLayout, Button } from "@amzn/awsui-components-react";
import { useInviteUsers } from "./hooks/useInviteUsers";
import { EMAIL_ERROR_MESSAGE, UserInput, UserInformation, Users, updateHeader, validateInput, validateUserInformation } from "./utils/Utils";
import { RouteComponentProps } from "react-router-dom";
import UnAuthorizedAccessAlert from "../../views/layouts/UnAuthorizedAccessAlert";

interface InviteUsersProps extends RouteComponentProps {
  isAuthenticated: boolean;
  userHasAuthenticated: boolean;
  isExternal: boolean;
}

export const InviteUsersForm: React.FC<InviteUsersProps> = (props : InviteUsersProps) => {

  //Redirect users to login page if they are not authenticated.
  if (!props.isAuthenticated) {
    props.history.push("/login");
  }

  const [headers, setHeaders] = useState<Array<UserInput>>([]);
  const [requestBody, setRequestBody] = useState<Users>();
  const { isLoading, inviteUsersResponse, inviteUsers, error } = useInviteUsers();


  function InviteUsersAlert() {
    if (error !== null){
      return (<Alert header={error?.message} type="error"></Alert>);
    }

    if (inviteUsersResponse.length === 0 || isLoading) {
      return null;
    }

    var userInfo: string[] = [];
    inviteUsersResponse.forEach(response => {
      if(response.userStatus ===  "INVITE_FAILED") {
        userInfo.push(response.userEmail);
      }
    });

    if(inviteUsersResponse.length !== 0 && userInfo.length === 0 && !isLoading) {
      return (<Alert header="Invited all the users successfully" type="success"></Alert>);
    }

    const listItems = userInfo.map( element => (
      <li key={element}>{element}</li>
    ));
    return (
    <Alert header="Failed to invite following users : " type={"error"}>
      {listItems}
    </Alert>);
  }

  function handleBtnClick() {
    let userInformation: UserInformation[]= [];
    let requestBody: Users  = {users : userInformation};
    headers.forEach(input => {
      const warehouseList: string[] = [];
      input.warehouses.split(',').forEach(warehouse => {
        warehouseList.push(warehouse);
      })
      const data: UserInformation = {
        userEmail: input.email,
        firstName: input.firstName,
        warehouses: warehouseList,
      }
      requestBody.users.push(data);
    });
    setRequestBody(requestBody);
  }

  useEffect(() => {
    inviteUsers(requestBody);
}, [requestBody, inviteUsers]);

  return (

    <div>
      {props.isExternal && <UnAuthorizedAccessAlert/>}
      {!props.isExternal && <AppLayout
        navigationHide={true}
        toolsHide={true}
        headerSelector="#top-navigation"
        content={
          <Container header={<Header>Invite Users</Header>}>
            <SpaceBetween size="l">
              <FormField
                label="Invite Users"
                description="Enter the details of the users you want to invite."
              >
              </FormField>
              <AttributeEditor<UserInput>
                addButtonText="Add User"
                removeButtonText="Remove User"
                items={headers}
                definition={[
                  {
                    label: 'User Email',
                    errorText: (item) => {
                      return item.emailErrorMessage;
                    },
                    control: (item, index) => (
                      <Input
                        value={item.email}
                        inputMode="email"
                        placeholder="Email of the User"
                        autoFocus={true}
                        onChange={event => {
                          const user = validateUserInformation(item, "emailEvent", event.detail.value);
                          setHeaders(headers =>
                                updateHeader(headers, index, header => ({ ...header, email: user.email, firstName: user.firstName, warehouses: user.warehouses, warehouseErrorMessage: user.warehouseErrorMessage, emailErrorMessage: user.emailErrorMessage}))
                              )
                        }
                      }
                      />
                    )
                  },
                  {
                    label: (
                      <span>
                        First Name
                        <i> - optional</i>
                      </span>
                    ),
                    control: (item, index) => (
                      <Input
                        value={item.firstName}
                        placeholder="First Name of the User"
                        onChange={event =>
                          setHeaders(headers =>
                            updateHeader(headers, index, header => ({ ...header, firstName: event.detail.value }))
                          )
                        }
                      />
                    )
                  },
                  {
                      label: (
                        <span>
                          Warehouses
                          <i> - Optional for Amazon Employees</i>
                        </span>
                      ),
                      errorText: (item) => {
                        return item.warehouseErrorMessage;
                      },
                      control: (item, index) => (
                        <Input
                          value={item.warehouses}
                          placeholder="Comma separated warehouses. Max : 10"
                          onChange={event => {
                            const user = validateUserInformation(item, "warehousesEvent", event.detail.value);
                            setHeaders(headers =>
                              updateHeader(headers, index, header => ({ ...header, email: user.email, firstName: user.firstName, warehouses: user.warehouses, warehouseErrorMessage: user.warehouseErrorMessage, emailErrorMessage: user.emailErrorMessage}))
                              )
                          }
                        }
                        />
                      )
                    }
                ]}

                onAddButtonClick={() => setHeaders(headers => headers.concat({ email: '', firstName: '', warehouses: '', warehouseErrorMessage: '', emailErrorMessage: EMAIL_ERROR_MESSAGE }))}

                onRemoveButtonClick={event =>
                  setHeaders(headers => headers.filter((item, index) => index !== event.detail.itemIndex))
                }

                empty="No Users added. Please click Add users button to enter user details."
                disableAddButton = {headers.length === 10}
                additionalInfo={
                  <span>
                    You can add up to {10 - headers.length} more
                    items.
                  </span>
                }
              />

              <Button
                  variant="primary"
                  disabled= {!validateInput(headers)}
                  loading={isLoading}
                  onClick={handleBtnClick}>
                  Send Invitation
              </Button>
              <InviteUsersAlert />
            </SpaceBetween>
          </Container>
        }/>}
    </div>
  );
}
