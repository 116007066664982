export default class PurchaseOrderItemDetails {
    constructor(fnsku, quantitySubmitted, quantityExpected, quantityOrdered, quantityReceived, quantityMisreceived, quantityNetReceived) {
        this.fnsku = fnsku;
        this.quantitySubmitted = quantitySubmitted;
        this.quantityExpected = quantityExpected;
        this.quantityOrdered = quantityOrdered;
        this.quantityReceived = quantityReceived;
        this.quantityMisreceived = quantityMisreceived;
        this.quantityNetReceived = quantityNetReceived;
    }
}