import {TableEmptyState} from "@common/table";
import Table from "@amzn/awsui-components-react/polaris/table";
import React, {useContext, useEffect, useState} from "react";
import {useCollection} from "@amzn/awsui-collection-hooks";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import API from "@aws-amplify/api";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import Header from "@amzn/awsui-components-react/polaris/header";
import Link from "@amzn/awsui-components-react/polaris/link";
import {fetchArsenalLink} from "@constants/FetchArsenalLink";
import {
    FcSkuInventoryDiscrepancyRecord
} from "@modules/inventory/discrepancy-snapshot/model/FcSkuInventoryDiscrepancyRecord";
import { parse } from "json2csv";
import Button from "@amzn/awsui-components-react/polaris/button";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Select from "@amzn/awsui-components-react/polaris/select";
import {OptionDefinition} from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";
import {isEmpty, toNumber} from "lodash";
import Form from "@amzn/awsui-components-react/polaris/form";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { getAuthorizationHeader } from "@common/Auth";
import {RegionContext} from "@common/RegionContext";
import {constructButaneApiName} from "../../../apiConfig";
import {getEnvironment} from "@modules/signup/Utils";

interface FcSkuInventoryDiscrepancySnapshotTableProps {
    warehouse: string,
    startDate: Date,
    endDate: Date,
    onlyDiscrepantInventory: boolean
}

export const FcSkuInventoryDiscrepancySnapshotTable = (props: FcSkuInventoryDiscrepancySnapshotTableProps) => {
    const [fetchingDiscrepancySnapshot, setFetchingDiscrepancySnapshot] = useState(false);
    const [fcSkuInventoryDiscrepancyRecords, setFcSkuInventoryDiscrepancyRecords] =
        useState<FcSkuInventoryDiscrepancyRecord[]>([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
    const [selectedDownloadOption, setSelectedDownloadOption] = useState<OptionDefinition>({
        label: "",
        value: ""
    });
    const [downloadErrorMessage, setDownloadErrorMessage] = useState("");
    const [downloadingSnapshot, setDownloadingSnapshot] = useState(false);
    const regionContext = useContext(RegionContext);

    const DEFAULT_PAGE_SIZE = 20;
    const DEFAULT_DOWNLOAD_PAGE_SIZE = 20000;

    useEffect(() => {
        setFetchingDiscrepancySnapshot(false);
        setFcSkuInventoryDiscrepancyRecords([]);
        setErrorMessage("");
        setTotalItemCount(0);
        setCurrentPageIndex(1);
        setIsDownloadModalVisible(false);
        setSelectedDownloadOption({
            label: "",
            value: ""
        });
        setDownloadErrorMessage("");
        setDownloadingSnapshot(false);
        getFcSkuInventoryDiscrepancySnapshot(props.warehouse, props.startDate, props.endDate,
            props.onlyDiscrepantInventory, currentPageIndex - 1 , DEFAULT_PAGE_SIZE);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const roundStartDate = (date: Date) => {
        date.setHours(0);
        date.setMinutes(0, 0, 0);
        return date;
    }

    const roundEndDate = (date: Date) => {
        date.setHours(23);
        date.setMinutes(59, 59, 0);
        return date;
    }

    const getFcSkuInventoryDiscrepancySnapshot = (warehouse: string, startDate: Date, endDate: Date,
                                                  onlyDiscrepantInventory: boolean, pageNumber: number,
                                                  pageSize: number) => {
        setFetchingDiscrepancySnapshot(true);
        setFcSkuInventoryDiscrepancyRecords([])

        callAPIEndpoint(warehouse, startDate, endDate, onlyDiscrepantInventory, pageNumber, pageSize)
            .then((data) => {
                const fcSkuInventoryDiscrepancyRecords = convertAPIResponseToObjects(data);
                setTotalItemCount(data.totalRecords);
                setFcSkuInventoryDiscrepancyRecords(fcSkuInventoryDiscrepancyRecords);
            }, () => {
                setErrorMessage("Error occurred when fetching FCSKU inventory discrepancy snapshot")
            }).finally(() => {
                setFetchingDiscrepancySnapshot(false);
            });
    }

    const callAPIEndpoint = async (warehouse: string, startDate: Date, endDate: Date,
                             onlyDiscrepantInventory: boolean, pageNumber: number,
                             pageSize: number) => {
        const epochStartDate = roundStartDate(startDate).getTime()/1000;
        const epochEndDate = roundEndDate(endDate).getTime()/1000;

        return API.get(constructButaneApiName(regionContext.region), `/api/inventory/discrepancy/fcsku/?warehouse=${warehouse}&startDate=${epochStartDate}&endDate=${epochEndDate}&pageNumber=${pageNumber}&pageSize=${pageSize}&onlyDiscrepancy=${onlyDiscrepantInventory}`, {
            headers: await getAuthorizationHeader()
        })
    }

    const convertAPIResponseToObjects = (data: any): FcSkuInventoryDiscrepancyRecord[] => {
        let records: FcSkuInventoryDiscrepancyRecord[] = [];
        data.fcSkuInventoryDiscrepancyRecords.forEach(record => {
            records.push({
                fnSku: record.fnSku,
                fcSku: record.fcSku,
                inventoryCondition: record.inventoryCondition,
                messageId: record.messageId,
                warehouseQuantity: record.warehouseQuantity,
                amazonQuantity: record.amazonQuantity,
                entryDate: new Date(record.entryDate*1000),
            })
        });
        return records;
    }

    const onSnapshotDownload = (warehouse: string, startDate: Date, endDate: Date,
                                onlyDiscrepantInventory: boolean, pageNumber: number, pageSize: number) => {
        setDownloadingSnapshot(true);

        callAPIEndpoint(warehouse, startDate, endDate, onlyDiscrepantInventory, pageNumber, pageSize)
            .then((data) => {
                const fcSkuInventoryDiscrepancyRecords = convertAPIResponseToObjects(data);

                const blob = new Blob([parse(fcSkuInventoryDiscrepancyRecords)], { type: 'text/csv' });
                const url = window.URL.createObjectURL(blob)
                const downloadLink = document.createElement('a')
                downloadLink.href = url;
                const fileName = `fcsku_discrepancy_snapshot_${pageNumber}`;
                downloadLink.download = `${fileName}.csv`;
                downloadLink.click();
            }, () => {
                setDownloadErrorMessage("Error occurred when downloading FCSKU inventory discrepancy snapshot")
            }).finally(() => {
                setDownloadingSnapshot(false);
        })
    }

    const onPageChange = (pageIndex: number) => {
        setCurrentPageIndex(pageIndex);
        getFcSkuInventoryDiscrepancySnapshot(props.warehouse, props.startDate, props.endDate,
            props.onlyDiscrepantInventory, currentPageIndex - 1 , DEFAULT_PAGE_SIZE);
    }

    const getDownloadOptions = () => {
        let displayOptions: OptionDefinition[] = [];
        for (let i = 0; i < Math.ceil(totalItemCount / DEFAULT_DOWNLOAD_PAGE_SIZE); i++) {
            const startFrom = i * DEFAULT_DOWNLOAD_PAGE_SIZE;
            const endTo = Math.min((i + 1) * DEFAULT_DOWNLOAD_PAGE_SIZE, totalItemCount);
            displayOptions.push({label: `${startFrom} to ${endTo}`, value: i.toString()})
        }
        return displayOptions;
    }

    const onModalDismiss = () => {
        setIsDownloadModalVisible(false);
        setDownloadingSnapshot(false);
        setDownloadErrorMessage("");
        setSelectedDownloadOption({
            label: "",
            value: ""
        });
    }

    const {
        items,
        filterProps,
        paginationProps,
        collectionProps,
    } = useCollection(fcSkuInventoryDiscrepancyRecords, {
        filtering: {},
        pagination: {
            pageSize: DEFAULT_PAGE_SIZE,
            defaultPage: 0
        },
        sorting: {
            defaultState: {
                isDescending: true,
                sortingColumn: {sortingField: "entryDate"}
            }
        },
    });

    return <div>
        {
            <Modal header={"Download FCSKU Inventory Discrepancy Snapshot"}
                   visible={isDownloadModalVisible}
                   onDismiss={onModalDismiss}
            >
                <Form actions={
                    <Button variant={"primary"}
                            onClick={() => onSnapshotDownload(props.warehouse, props.startDate, props.endDate,
                                props.onlyDiscrepantInventory, toNumber(selectedDownloadOption.value),
                                DEFAULT_DOWNLOAD_PAGE_SIZE)}
                            disabled={isEmpty(selectedDownloadOption.value)}
                            loading={downloadingSnapshot}
                    >
                        Download
                    </Button>
                }>
                    <SpaceBetween size={"xs"}>
                        {
                            downloadErrorMessage &&
                            <Alert
                                type={"error"}
                                dismissible={true}
                                onDismiss={() => setDownloadErrorMessage("")}
                            >
                                {downloadErrorMessage}
                            </Alert>
                        }
                        <ColumnLayout columns={2}>
                            <FormField label={"Choose number of records to download"} />
                            <Select
                                selectedOption={selectedDownloadOption}
                                options={getDownloadOptions()}
                                onChange={(e) => setSelectedDownloadOption(e.detail.selectedOption)}
                            />
                        </ColumnLayout>
                    </SpaceBetween>
                </Form>
            </Modal>
        }
        <SpaceBetween size={"xs"}>
            {
                errorMessage &&
                <Alert
                    type={"error"}
                    dismissible={true}
                    onDismiss={() => setErrorMessage("")}
                >
                    {errorMessage}
                </Alert>
            }
            <Table
                {...collectionProps}
                totalItemsCount={totalItemCount}
                header={
                    <Header actions={
                        <Button onClick={() => {setIsDownloadModalVisible(true)}}
                                variant={"primary"}
                                disabled={totalItemCount <= 0}
                        >
                            Download CSV
                        </Button>
                    }>
                        {`FCSKU Inventory Discrepancy Snapshot ${totalItemCount ? `(Total items: ${totalItemCount})` : ''}`}
                    </Header>
                }
                loading={fetchingDiscrepancySnapshot}
                loadingText={"Fetching FCSKU Inventory Discrepancy Snapshot"}
                items={items}
                empty={
                    <TableEmptyState
                        resourceName={"FCSKU Inventory Discrepancy Snapshot"}
                        resourceNamePlural={"FCSKU Inventory Discrepancy Snapshot"}
                        showCreateResourceButton={false}
                    />
                }
                columnDefinitions={[
                    {
                        id: "entryDate",
                        header: "Entry Date",
                        cell: e => e.entryDate.toLocaleString(),
                        sortingField: "entryDate"
                    },
                    {
                        id: "fnSku",
                        header: "FNSKU",
                        cell: e => e.fnSku,
                        sortingField: "fnSku"
                    },
                    {
                        id: "fcSku",
                        header: "FCSKU",
                        cell: e => e.fcSku,
                        sortingField: "fcSku"
                    },
                    {
                        id: "inventoryCondition",
                        header: "Inventory Condition",
                        cell: e => e.inventoryCondition,
                        sortingField: "inventoryCondition"
                    },
                    {
                        id: "warehouseQuantity",
                        header: "Warehouse Quantity",
                        cell: e => e.warehouseQuantity,
                        sortingField: "warehouseQuantity"
                    },
                    {
                        id: "amazonQuantity",
                        header: "Amazon Quantity",
                        cell: e => e.amazonQuantity,
                        sortingField: "amazonQuantity"
                    },
                    {
                        id: "messageId",
                        header: "Message Id",
                        cell: e => (
                            <Link href={fetchArsenalLink(regionContext.region, getEnvironment()) + `search?id=${e.messageId}`}>{e.messageId}</Link>
                        ),
                        sortingField: "messageId"
                    },
                    {
                        id: "discrepancy",
                        header: "Discrepancy (Amazon - Warehouse)",
                        cell: e => (e.amazonQuantity - e.warehouseQuantity),
                        sortingField: "discrepancy"
                    }
                ]}
                filter={
                    <div>
                        <TextFilter
                            {...filterProps}
                        />
                    </div>
                }
                pagination={
                    <Pagination
                        {...paginationProps}
                        pagesCount={Math.ceil(totalItemCount/DEFAULT_PAGE_SIZE)}
                        currentPageIndex={currentPageIndex}
                        disabled={fetchingDiscrepancySnapshot}
                        onChange={event => onPageChange(event.detail.currentPageIndex)}
                    />
                }
            />
        </SpaceBetween>
    </div>;
}