export const AUDIT_DETAIL_COLUMN_METADATA = [
  {
    columnName: "paramName",
    width: "25rem",
    displayName: "Attribute Name"
  },
  {
    columnName: "paramValue",
    width: "30rem",
    displayName: "Attribute Value"
  },
  {
    columnName: "updatedBy",
    width: "30rem",
    displayName: "Updated By"
  },
  {
    columnName: "updatedAt",
    width: "25rem",
    displayName: "Updated At"
  },
  {
    columnName: "activityName",
    width: "25rem",
    displayName: "Activity Name"
  }
];
