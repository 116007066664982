import {PropertyFilterProperty} from "@amzn/awsui-collection-hooks/dist/cjs/interfaces";
import {CollectionPreferences, PropertyFilterProps, TableProps} from "@amzn/awsui-components-react";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import RadioGroup from "@amzn/awsui-components-react/polaris/radio-group";
import React from "react";
import {PROPERTY_FILTERING_I18N_LABELS} from "@common/table";

export const CUSTOM_PREFERENCE_OPTIONS = [
  {value: "table", label: "Table"}
];

export const PROPERTY_FILTERING_LABELS: PropertyFilterProps.I18nStrings = {
  ...PROPERTY_FILTERING_I18N_LABELS,
  filteringPlaceholder: "Filter receive line items by property or value"
};

export const DEFAULT_PREFERENCES = {
  pageSize: 25,
  visibleContent: [
    "fnSku",
    "fcSku",
    "purchaseOrderId",
    "quantity",
    "inventoryCondition",
    "location",
    "problemTypes",
    "status",
    "failureReason"
  ],
  wraplines: false,
  custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<any>[] = [
  {
    id: "fnSku",
    header: "FnSku",
    cell: (item) => item.fnSku,
    sortingField: "fnSku",
    minWidth: "60px"
  },
  {
    id: "fcSku",
    header: "FcSku",
    cell: (item) => item.fcSku,
    sortingField: "fcSku",
    minWidth: "60px"
  },
  {
    id: "purchaseOrderId",
    header: "Purchase Order Id",
    cell: (item) => item.purchaseOrderId,
    sortingField: "purchaseOrderId",
    minWidth: "60px"
  },
  {
    id: "quantity",
    header: "Quantity",
    cell: (item) => item.quantity,
    sortingField: "quantity",
    minWidth: "60px"
  },
  {
    id: "inventoryCondition",
    header: "Inventory Condition",
    cell: (item) => item.inventoryCondition,
    sortingField: "inventoryCondition",
    minWidth: "60px"
  },
  {
    id: "location",
    header: "Location",
    cell: (item) => item.location,
    sortingField: "location",
    minWidth: "60px"
  },
  {
    id: "problemTypes",
    header: "Problem Types",
    cell: (item) => item.problemTypes,
    sortingField: "problemTypes",
    minWidth: "120px"
  },
  {
    id: "status",
    header: "Status",
    cell: (item) => item.status,
    sortingField: "status",
    minWidth: "60px"
  },
  {
    id: "failureReason",
    header: "Failure Reason",
    cell: (item) => item.failureReason,
    sortingField: "failureReason",
    minWidth: "100px"
  }
];

export const FILTERING_PROPERTIES: PropertyFilterProperty[] = [
  {
    propertyLabel: "FnSku",
    key: "fnSku",
    groupValuesLabel: "FnSku values",
    operators: [":", "!:", "=", "!="]
  },
  {
    propertyLabel: "FcSku",
    key: "fcSku",
    groupValuesLabel: "FcSku values",
    operators: [":", "!:", "=", "!="]
  },
  {
    propertyLabel: "Purchase Order Id",
    key: "purchaseOrderId",
    groupValuesLabel: "Purchase Order Id values",
    operators: [":", "!:", "=", "!="]
  },
  {
    propertyLabel: "Inventory Condition",
    key: "inventoryCondition",
    groupValuesLabel: "Inventory Condition values",
    operators: [":", "!:", "=", "!="]
  },
  {
    propertyLabel: "Location",
    key: "location",
    groupValuesLabel: "Location values",
    operators: [":", "!:", "=", "!="]
  },
  {
    propertyLabel: "Status",
    key: "status",
    groupValuesLabel: "Status values",
    operators: [":", "!:", "=", "!="]
  }
];

export const PAGE_SIZE_OPTIONS = [
  {value: 25, label: "25 items"},
  {value: 50, label: "50 items"},
  {value: 100, label: "100 items"}
];

export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: "Main Inventory Receipts properties",
    options: [
      {
        id: "fnSku",
        label: "FnSku",
        editable: false,
        visible: true
      },
      {
        id: "fcSku",
        label: "FcSku",
        editable: true,
        visible: true
      },
      {
        id: "purchaseOrderId",
        label: "Purchase Order Id",
        editable: false,
        visible: true
      },
      {
        id: "quantity",
        label: "Quantity",
        editable: false,
        visible: true
      },
      {
        id: "inventoryCondition",
        label: "Inventory Condition",
        editable: false,
        visible: true
      },
      {
        id: "location",
        label: "Location",
        editable: false,
        visible: true
      },
      {
        id: "problemTypes",
        label: "Problem Types",
        editable: true,
        visible: true
      },
      {
        id: "status",
        label: "Status",
        editable: false,
        visible: true
      },
      {
        id: "failureReason",
        label: "Failure Reason",
        editable: true,
        visible: true
      }
    ]
  }
];

export const Preferences = (
  {
    preferences,
    setPreferences,
    disabled = false,
    pageSizeOptions = PAGE_SIZE_OPTIONS,
    visibleContentOptions = VISIBLE_CONTENT_OPTIONS
  }) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({detail}) => setPreferences(detail)}
    pageSizePreference={{
      title: "Page size",
      options: pageSizeOptions
    }}
    wrapLinesPreference={{
      label: "Wrap lines",
      description: "Check to see all the text and wrap the lines"
    }}
    visibleContentPreference={{
      title: "Select visible columns",
      options: visibleContentOptions
    }}
    customPreference={(value, setValue) => (
      <FormField stretch={true} label="View as">
        <RadioGroup
          value={value}
          onChange={({detail}) => setValue(detail.value)}
          items={CUSTOM_PREFERENCE_OPTIONS}
        />
      </FormField>
    )}
  />
);