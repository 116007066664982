import {
  TransferDepartWorkflow,
  TransferDepartWorkflowButanteStateWithError,
  TransferDepartWorkflowHerdState,
  TRANSFER_DEPART_WORKFLOW_BUTANE_STATE_BY_HERD_STATE
} from "@modules/transfer/common/model";

const HERD_WORKFLOW_STATE_SEPERATOR_CHARACTER = "::";

export class WorkflowStateConverter {
  public static toHerdState(
    transferDepartWorkflow: TransferDepartWorkflow
  ): TransferDepartWorkflowHerdState {
    const [herdState] = transferDepartWorkflow?.state.split(
      HERD_WORKFLOW_STATE_SEPERATOR_CHARACTER
    );
    return herdState as TransferDepartWorkflowHerdState;
  }

  public static toButaneState(
    herdState: TransferDepartWorkflowHerdState
  ): TransferDepartWorkflowButanteStateWithError {
    const butaneState: TransferDepartWorkflowButanteStateWithError =
      TRANSFER_DEPART_WORKFLOW_BUTANE_STATE_BY_HERD_STATE[herdState];

    return butaneState;
  }
}
