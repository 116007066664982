import React from "react";

interface StandaloneValueProps {
  value: string | number | undefined;
}

export function StandaloneValue({ value }: StandaloneValueProps) {
  function getValue() {
    if (!value || value === "") {
      return "-";
    }
    return value;
  }

  return <span>{getValue()}</span>;
}
