import React, { Component } from "react";

/* Import Components */
import { Button } from "@material-ui/core";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import PageTitle from "../../../components/PageTitle/PageTitle";
import "../config.css";

class AuditDetailForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isButtonDisabled: true,
      auditRequest: {
        level: this.props.level,
        id: this.props.id,
        params: this.props.params
      }
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState(() => ({
      level: nextProps.level,
      id: nextProps.id,
      params: nextProps.params
    }));
  }

  handleFormSubmit(e) {
    e.preventDefault();
    const value = e.target.value;
    this.setState({
      isButtonDisabled: true,
      [e.target.name]: value
    });
    this.props.renderTables(this.state.id, this.state.level, this.state.params);
  }

  handleChange = (e) => {
    const value = e.target.value;
    this.setState({
      [e.target.name]: value
    });
    if (
      (typeof this.state.id !== "undefined" &&
        typeof this.state.level !== "undefined") ||
      (e.target.name === "id" && typeof this.state.level !== "undefined") ||
      (e.target.name === "level" && typeof this.state.id !== "undefined")
    ) {
      this.setState({ isButtonDisabled: false });
    }
  };

  render() {
    return (
      <div>
        <div>
          <PageTitle>Configuration Audits</PageTitle>
        </div>
        <div className="form-inline">
          <div>
            <div className="filter-form config-audit-filter-form">
              <div className="filters">
                <div className="row">
                  <div className="column1">
                    <label className="input-params">Level</label>
                  </div>
                  <div className="column2">
                    <select
                      name="level"
                      className="config-input-box"
                      onChange={this.handleChange}
                      required
                    >
                      <option value="">Choose...</option>
                      <option value="Warehouse">Warehouse</option>
                      <option value="Marketplace">Marketplace</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="column1">
                    <label className="input-params">Id</label>
                  </div>
                  <div className="column2">
                    <input
                      className="config-input-box"
                      placeholder="Enter Warehouse or Marketplace"
                      name="id"
                      onChange={this.handleChange}
                      defaultValue={this.state.id}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="column1">
                    <label className="input-params">
                      Parameter Names(Optional)
                    </label>
                  </div>
                  <div className="column2">
                    <textarea
                      rows={6}
                      cols={25}
                      maxLength={120}
                      className="params-input-box"
                      placeholder="Comma separated list of vwp attributes. If not given, will fetch all attribute data"
                      name="params"
                      onChange={this.handleChange}
                      defaultValue={this.state.params}
                    />
                  </div>
                </div>
              </div>
              <div className="inline align-middle text-center">
                <div className="audit-submit-button-padding"></div>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SearchOutlinedIcon />}
                  onClick={this.handleFormSubmit}
                  disabled={this.state.isButtonDisabled}
                >
                  Search
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AuditDetailForm;
