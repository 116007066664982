import React from "react";
import Select from "react-select";

const DropDown = ({value, param, list, handleChange, error}) => {
    let dropDownClasses = (error === true) ? "config-field-input error-input" : "config-field-input";
    return (
        <div className="config-field display-inline">
            <Select 
                className={dropDownClasses}
                value={value}
                options={list}
                onChange={(selected) => handleChange(selected)}
                />
        </div>
    );
};

export default DropDown;