import React from "react";
import {TABLE_ICONS} from "../../constants/table/metadata/MaterialTableIcons";
import MaterialTable from "material-table";
import "./MaterialTable.css";

export default class MaterialUITable extends React.Component {

    render() {
        const columns = this.props.columnMetadata;
        const data = this.props.results;
        const title = this.props.title;

        return (
            <div>
                <MaterialTable
                    icons={TABLE_ICONS}
                    title={title}
                    columns={columns}
                    data={data}
                    options={{
                        search: true,
                        headerStyle: {fontWeight: "bold", fontSize: 15},
                        exportButton: true,
                        exportAllData: true,
                        emptyRowsWhenPaging: false,
                        sorting: true,
                        draggable: false,
                        pageSizeOptions: [5, 10, 20, 40]
                    }}
                />
            </div>
        )
    }
}