import React, { Component } from "react";
import { Button, TextField } from "@material-ui/core";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import PageTitle from "../PageTitle/PageTitle";
import PolarisButton from "@amzn/awsui-components-react/polaris/button";
import Box from "@amzn/awsui-components-react/polaris/box";
import Header from "@amzn/awsui-components-react/polaris/header";
import Container from "@amzn/awsui-components-react/polaris/container";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input from "@amzn/awsui-components-react/polaris/input";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";

import "../../common/common.css"
import "./selectWarehouseForm.css";

const warehouseRegex = /^[A-Z][A-Z0-9]{3,4}$/;
const ENTER_KEY_KEY_CODE = 13;

interface SelectWarehouseFormProps {
  warehouse: string;
  usePolaris?: boolean;
  containerless?: boolean;
  constructRedirectPath: (input: string) => void;
}

interface SelectWarehouseFormState {
  warehouse: string;
}

class SelectWarehouseForm extends Component<
  SelectWarehouseFormProps,
  SelectWarehouseFormState
> {
  constructor(props) {
    super(props);

    this.state = {
      warehouse: this.props.warehouse
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit(e) {
    e.preventDefault();

    const warehouse = this.props.usePolaris ? e.detail.value : e.target.value;
    this.setState({
      warehouse: warehouse
    });

    this.props.constructRedirectPath(this.state.warehouse);
  }

  handleChange = (e) => {
    e.preventDefault();

    const warehouse = this.props.usePolaris ? e.detail.value : e.target.value;
    this.setState({
      warehouse: warehouse.toUpperCase()
    });
  };

  handleKeyDown = (e) => {
    if (this.isValidInput() && e?.detail?.keyCode === ENTER_KEY_KEY_CODE) {
      this.props.constructRedirectPath(this.state.warehouse);
      this.resetWarehouseState();
      e.preventDefault();
    }
  };

  isValidInput = () => {
    return !!this.state.warehouse && warehouseRegex.test(this.state.warehouse);
  };

  resetWarehouseState = () => {
    this.setState({
      warehouse: ""
    });
  };

  render() {
    if (this.props.usePolaris) {
      const BasePolarisContent = () => {
        return (
          <ColumnLayout columns={2}>
            <FormField label="Warehouse">
              <Input
                value={this.state.warehouse}
                onChange={this.handleChange}
                onKeyDown={this.handleKeyDown}
                placeholder="Warehouse ID, e.g. VUSB"
                type="search"
                autoFocus={true}
              />
            </FormField>
            <FormField label="ㅤ">
              <PolarisButton
                iconName="search"
                variant="primary"
                onClick={this.handleFormSubmit}
                disabled={!this.isValidInput()}
              >
                Search
              </PolarisButton>
            </FormField>
          </ColumnLayout>
        );
      };

      if (this.props.containerless) {
        return <>{BasePolarisContent()} </>;
      }

      return (
        <div className="select-container">
          <Box margin={{ vertical: "l", horizontal: "l" }}>
            <Container header={<Header variant="h2">Select Warehouse</Header>}>
              {BasePolarisContent()}
            </Container>
          </Box>
        </div>
      );
    }

    return (
      <div>
        <div>
          <PageTitle>Select Warehouse</PageTitle>
        </div>
        <div className="form-inline">
          <div>
            <div className="filter-form">
              <div className="filters">
                <div>
                  <label className="input-params">Warehouse</label>
                  <div className="select-warehouse-form-input-box">
                    <TextField
                      placeholder="Warehouse ID, eg.VUSB"
                      name="warehouse"
                      className="select-warehouse-form-input"
                      defaultValue={this.state.warehouse}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div></div>
              </div>
              <div className="inline align-middle text-center">
                <div className="single-input-upper-margin"></div>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SearchOutlinedIcon />}
                  onClick={this.handleFormSubmit}
                  disabled={!this.isValidInput()}
                >
                  Search
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SelectWarehouseForm;
