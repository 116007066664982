import React from "react";
import { Alert } from "react-bootstrap";
import { NodesUpdateResponse } from "../model/NodesUpdateResponse";
import { MULTI_NODE_CONFIG_UPDATE_COLUMN_METADATA } from "../../../constants/table/metadata/ConfigMultiNodeUpdate";
import PropTypes from "prop-types";
import API from "@aws-amplify/api";
import GriddleTable from "../../../components/Table/GriddleTable";
import UnauthorizedUser from "../../../views/layouts/UnauthorizedUser";
import { getAuthorizationHeader } from "@common/Auth";
import BackendError from "../../../views/layouts/BackendError";
import {RegionContext} from '@common/RegionContext';
import {constructButaneApiName} from '../../../apiConfig';

class MultiNodeConfigUpdateTable extends React.Component {
  static propTypes = {
    warehouses: PropTypes.string,
    paramName: PropTypes.string,
    paramValue: PropTypes.string,
    noResult: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      bulkUpdateResponse: [],
      fetchingRecords: false,
      noResult: undefined,
      nonExistentNodes: [],
      openForbiddenSnackbar: false,
      networkError: false
    };
  }

  updateNodesConfig = async () => {
    const {warehouses, paramName, paramValue} = this.props;
    this.setState(() => ({
      noResult: undefined,
      fetchingRecords: true
    }));
    let bulkUpdateResponse = [];
    let warehouseList = [];
    warehouseList = warehouses.replace(/\s/g, "").split(",");
    const requestObject = {
      warehouses: warehouseList,
      paramName: paramName,
      paramValue: paramValue
    };
    const init = {
      body: requestObject, 
      headers: await getAuthorizationHeader()
    };
    return API.post(constructButaneApiName(this.context.region), `/vwp/config`, init)
      .then((data) => {
        const updatedNodesList = data.updatedNodes;
        let updatedWarehouseList = [];
        for (let i = 0; i < updatedNodesList.length; i++) {
          let obj = new NodesUpdateResponse(
            updatedNodesList[i].warehouse,
            updatedNodesList[i].paramName,
            updatedNodesList[i].paramValue
          );
          bulkUpdateResponse.push(obj);
          updatedWarehouseList.push(updatedNodesList[i].warehouse);
        }
        const nonExistentNodes = warehouseList.filter(
          (o) => updatedWarehouseList.indexOf(o) === -1
        );
        this.setState(() => ({
          bulkUpdateResponse: bulkUpdateResponse,
          fetchingRecords: false,
          nonExistentNodes: nonExistentNodes
        }));
        if (nonExistentNodes && nonExistentNodes.length > 0) {
          this.setState(() => ({
            noResult: "Partially Updated",
          }));
        } else {
          this.setState(() => ({
            noResult: "Successfully Updated",
          }));
        }
      })
      .catch((e) => {
        if (typeof e.response === 'undefined') {
          this.setState(() => ({ networkError: true }));
        }
        else
        e.response.status === 403 ? 
          this.setState(() => ({ openForbiddenSnackbar: true })): this.setState(() => ({ openForbiddenSnackbar: false }));
        this.setState(() => ({
          bulkUpdateResponse: [],
          fetchingRecords: false
        }));
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.warehouses !== this.props.warehouses ||
      prevProps.paramName !== this.props.paramName ||
      prevProps.paramValue !== this.props.paramValue
    ) {
      this.updateNodesConfig();
    }
  }

  renderNodesConfig() {
    const columnMetadata = MULTI_NODE_CONFIG_UPDATE_COLUMN_METADATA;
    return (
      <div>
        <h5 className="id">
          <span>{this.props.id}</span>
        </h5>
        <div>
          {!!this.state.noResult ? (
            this.state.noResult === "Partially Updated" ? (
              <Alert variant="warning">
                Warehouses {this.state.nonExistentNodes.join(", ")} or Parameter
                Name doesn't exist
              </Alert>
            ) : (
              <Alert variant="info">Successfully Updated Nodes Config.</Alert>
            )
          ) : (
            <Alert variant="warning">
              Update Failed! Please try again later.
            </Alert>
          )}
        </div>
        <h4>
          <span className="orange-title">Updated Nodes</span>
        </h4>
        <GriddleTable
          results={this.state.bulkUpdateResponse}
          columnMetadata={columnMetadata}
        />
      </div>
    );
  }

  isInputNotNull = () => {
    return !!this.props.warehouses && !!this.props.paramName && !! this.props.paramValue;
  }

  render() {
    let content;
    if (!!this.state.fetchingRecords) {
      return <div className="spinner"></div>;
    }
    if(this.state.openForbiddenSnackbar) {
      return <UnauthorizedUser openErrorSnackbar = {true} />;
    }
    if(this.state.networkError){
      return <BackendError/>;
    }
    if (this.isInputNotNull() && !this.state.fetchingRecords) {
      content = this.renderNodesConfig();
    }

    return <div>{content}</div>;
  }
}

MultiNodeConfigUpdateTable.contextType = RegionContext;

export default MultiNodeConfigUpdateTable;
