import React, { Component, PropsWithChildren } from "react";
import { Navbar, Nav, NavDropdown, Dropdown, NavLink } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";

import Feedback from "./Feedback";
import {Region} from "@constants/Region";
import {RegionContext} from "@common/RegionContext";
import { Icon, Popover} from "@amzn/awsui-components-react";

const NAVBAR_HEADER_TEXT = "External Fulfillment Platform";

interface HeaderProps extends RouteComponentProps {
  isAuthenticated: boolean;
  showLoggedInBar: () => React.ReactNode;
  supportedRegions: Region[];
}

interface HeaderState {
    region: string;
}

interface NavItemProps extends RouteComponentProps {
  href?: string;
  id?: string;
  onClick?: any;
}

const NavDropdownItem = withRouter(
  ({ children, href, history, id, onClick }: PropsWithChildren<NavItemProps>) => {
    function handleClientSideRouting(event: any) {
      event.preventDefault();
      href && history.push(href);
    }

    return (
      <Dropdown.Item href={href} id={id} onClick={onClick ? onClick : handleClientSideRouting}>
        {children}
      </Dropdown.Item>
    );
  }
);

const NavLinkItem = withRouter(
  ({ children, href, history }: PropsWithChildren<NavItemProps>) => {
    function handleClientSideRouting(event: any) {
      event.preventDefault();
      href && history.push(href);
    }

    return (
      <NavLink href={href} onClick={handleClientSideRouting}>
        {children}
      </NavLink>
    );
  }
);

class Header extends Component<HeaderProps, HeaderState> {

  onRegionSelection = (region: string) => {
    if (this.context.region !== region) {
        this.context.setRegion(region);
        this.props.history.push("/")
    }
  }

  render() {
    return (
      <Navbar sticky="top" bg="dark" variant="dark" id="top-navigation">
        <Navbar.Brand>
          <Link
            style={{ textDecoration: "none", color: "white" }}
            href="/"
            to="/"
          >
            {NAVBAR_HEADER_TEXT}
          </Link>
        </Navbar.Brand>

        {this.props.isAuthenticated && (
          <Nav className="mr-auto navbar-margin">
            <NavDropdown title="Inventory" id="basic-nav-dropdown">
              <NavDropdownItem href="/inventory/research">
                Inventory Research
              </NavDropdownItem>
              <NavDropdownItem href="/inventory/audits">
                FnSku Inventory Audits
              </NavDropdownItem>
              <NavDropdownItem href="/inventory/audits/fcsku">
                FcSku Inventory Audits
              </NavDropdownItem>
              <NavDropdownItem href="/inventory/fcsku/discrepancy">
                FcSku Inventory Discrepancy
              </NavDropdownItem>
              <NavDropdownItem href="/fcsku/details">
                FcSku Tracking
              </NavDropdownItem>
            </NavDropdown>
            <NavDropdown
              title="Configuration Management"
              id="basic-nav-dropdown"
            >
              <NavDropdownItem href="/node/settings/audit">
                Audit Details
              </NavDropdownItem>
              <NavDropdownItem href="/node/settings/bulk-update">
                Bulk Update
              </NavDropdownItem>
              <NavDropdownItem href="/node/select">
                Node Settings
              </NavDropdownItem>
              <NavDropdownItem href="/op-config/select">
                Operational Configurations
              </NavDropdownItem>
              <NavDropdownItem href="/config/create-container">
                Create Container
              </NavDropdownItem>
              <NavDropdownItem href="/config/create-schema">
                Create Schema
              </NavDropdownItem>
            </NavDropdown>
            <NavDropdown title="Shipment" id="basic-nav-dropdown">
              <NavDropdownItem href="/metrics/aging-report">
                Shipments Research
              </NavDropdownItem>
                <NavDropdownItem href="/shipment/search">
                    Search Shipment
                </NavDropdownItem>
            </NavDropdown>
            <NavDropdown title="Removal" id="basic-nav-dropdown">
              <NavDropdownItem href="/removal/info/">
                Removal Info
              </NavDropdownItem>
            </NavDropdown>
            <NavDropdown title="Inbound" id="basic-nav-dropdown">
              <NavDropdownItem href="/inbounds/po" id="dropdown-content">
                PO Summary
              </NavDropdownItem>
              <NavDropdownItem href="/inbounds/summary" id="dropdown-content">
                Problem Item Info
              </NavDropdownItem>
              <NavDropdownItem
                href="/inbounds/delete-rpi"
                id="dropdown-content"
              >
                Blowout RPI
              </NavDropdownItem>
            </NavDropdown>
            <NavDropdown title="Transfer" id="basic-nav-dropdown">
              <NavDropdownItem
                href="/transfers/departure-requests"
                id="dropdown-content"
              >
                Transfer Depart Requests
              </NavDropdownItem>
              <NavDropdownItem
                href="/transfers/failed-depart-items"
                id="dropdown-content"
              >
                Failed Depart Items
              </NavDropdownItem>
              <NavDropdownItem
                  href="/transfers/cancel"
                  id="dropdown-content"
                >
                 Cancel Transfer Request
                </NavDropdownItem>
            </NavDropdown>
            <NavDropdown title="Admin" id="basic-nav-dropdown">
              <NavDropdownItem
                  href="/admin/invite-users"
                  id="dropdown-content"
                >
                 Invite Users
                </NavDropdownItem>
              <NavDropdownItem
                  href="/admin/delete-users"
                  id="dropdown-content"
                >
                  Delete Users
              </NavDropdownItem>
            </NavDropdown>
            <NavLinkItem href="/registrations">Node Launch</NavLinkItem>
            <Feedback />
          </Nav>
        )}
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav className="ml-auto navbar-width">
            {this.props.isAuthenticated &&
              <div className="form-inline" >
                <Popover
                    size={"small"}
                    content={"Please select region from dropdown"}
                    triggerType="custom"
                    position="bottom"
                >
                  <div id={'region-help-popover'} ><Icon name={"status-info"} variant={"subtle"}/></div>
                </Popover>
                <NavDropdown className="mr-2" title={this.context.region.toUpperCase()} id="basic-nav-dropdown">
                    {
                       this.props.supportedRegions.map((region) => {
                            return (
                                <NavDropdownItem onClick={() => {this.onRegionSelection(region.name)}}>
                                    {region.name.toUpperCase()}
                                </NavDropdownItem>
                            )
                        })
                    }
                </NavDropdown>
                {this.props.showLoggedInBar()}
              </div>
            }
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

Header.contextType = RegionContext;

export default withRouter(Header);
