import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";

import { TransferDepartRequest } from "@modules/transfer/common/model";
import { getHeaderCounterText, TableHeader } from "@common/table";
import { SelectWarehouseModal } from "./SelectWarehouseModal";

const DESCRIPTION = "All displayed times are in UTC.";

interface TransferDepartRequestsHeaderProps extends RouteComponentProps {
  items: TransferDepartRequest[];
  isLoading: boolean;
  selectedItems: readonly TransferDepartRequest[];
  warehouse: string;
  onRefreshClick: () => void;
}

export const TransferDepartRequestsHeader = withRouter(
  ({
    items,
    isLoading,
    selectedItems,
    warehouse,
    history,
    location,
    onRefreshClick
  }: TransferDepartRequestsHeaderProps) => {
    const [displaySelectWarehouseModal, setDisplaySelectWarehouseModal] =
      useState(false);

    const isOnlyOneSelected = selectedItems?.length === 1 ?? false;

    const [transferDepartRequest] = selectedItems;

    function handleViewDetailsClick() {
      history.push(
        `${location.pathname}/${transferDepartRequest.amazonShipmentReferenceId}`
      );
    }

    return (
      <TableHeader
        actions={
          <SpaceBetween size="xs" direction="horizontal">
            <Button
              iconName="refresh"
              onClick={onRefreshClick}
              loading={isLoading}
            />
            <Button
              iconName="settings"
              onClick={() =>
                setDisplaySelectWarehouseModal((prevState) => !prevState)
              }
            >
              Select Warehouse
            </Button>
            <Button disabled={true}>View Payload</Button>
            <Button
              variant="primary"
              onClick={handleViewDetailsClick}
              disabled={!isOnlyOneSelected}
            >
              View Details
            </Button>
          </SpaceBetween>
        }
        counterText={getHeaderCounterText(items)}
        children={
          <>
            <SelectWarehouseModal
              isVisible={displaySelectWarehouseModal}
              setIsVisible={setDisplaySelectWarehouseModal}
            />
            Transfer Depart Requests for {warehouse}
          </>
        }
        description={DESCRIPTION}
      />
    );
  }
);
