import React from "react";
import {Button, TextField, FormControl, Radio, RadioGroup, FormControlLabel} from "@material-ui/core";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import PageTitle from "../../../components/PageTitle/PageTitle";
import {inputWarehouseListRegex} from "../../../constants/Regex"
import "./warehousesSearchBox.css";
import {getErrorMessage} from "../../../constants/ErrorMessageMapper";

export default class WarehousesSearchBox extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            warehouseList: this.props.warehouseList,
            shipmentType: "CUSTOMER",
            warehouseListModified: false
        };

    }

    handleChange = e => {
        const value = e.target.value.toUpperCase();
        this.setState({
            [e.target.name]: value,
            [e.target.name + "Modified"]: true
        });
    };

    handleSelectChange = event => {
        const value = event.target.value
        this.setState({
            [event.target.name]: value
        })
    }

    isWarehouseListValid = () => {
        return this.state.warehouseListModified && !inputWarehouseListRegex.test(this.state.warehouseList);
    }

    validInput = () => {
        return inputWarehouseListRegex.test(this.state.warehouseList);
    };

    searchForWarehousesShipmentsSummary = () => {
        this.props.renderTables(this.state.warehouseList, this.state.shipmentType);
    };

    render() {

        return (
            <div>
                <div>
                    <PageTitle>Search Shipment Summary</PageTitle>
                </div>
                <div className="form-inline">
                    <div>
                        <div className="shipment-filter-form">
                            <div className="filters">
                                <div>
                                    <label className="shipment-input-params">WAREHOUSES</label>
                                    <div className='shipment-warehouse-input-box'>
                                        <TextField
                                            placeholder="Comma separated list of warehouses e.g. XDEB, XFRF, XTRA... (Max
                                             100)"
                                            error={this.isWarehouseListValid()}
                                            name="warehouseList"
                                            defaultValue={this.state.warehouseList}
                                            onChange={this.handleChange}
                                            helperText={this.isWarehouseListValid() && getErrorMessage("warehouseList")}
                                            className='shipment-warehouse-input'
                                            rows={3}
                                            multiline
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label className="shipment-select-params">SHIPMENT TYPE</label>
                                    <div className="shipment-type-select-buttons">
                                        <FormControl>
                                            <RadioGroup row name="shipmentType" value={this.state.shipmentType}
                                                        onChange={this.handleSelectChange}>
                                                <FormControlLabel value="CUSTOMER" control={<Radio/>}
                                                                  label="Customer Shipment"/>
                                                <FormControlLabel value="REMOVALS" control={<Radio/>}
                                                                  label="Removal Shipment"/>
                                                <FormControlLabel value="ALL" control={<Radio/>} label="All"/>
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className="inline align-middle text-center">
                                <div className="shipment-button-padding"></div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<SearchOutlinedIcon/>}
                                    disabled={!this.validInput()}
                                    onClick={this.searchForWarehousesShipmentsSummary}
                                >
                                    Search
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
