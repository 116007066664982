import React, { Component } from 'react';
import FormField from "./FormField";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import { containerRegex, parentContainerRegex } from "../../../constants/Regex";
import { getErrorMessage } from "../../../constants/ErrorMessageMapper";
import {INPUT_ERROR_TIMEOUT} from "../../../constants/Values";

const containerTypeList = [
    {label: "Warehouse", value: "warehouse"},
    {label: "Vendor", value: "vendor"},
    {label: "MarketPlace", value: "marketplace"}
];

class ContainerForm extends Component {
    constructor(props) {
        super(props);
        this.state= {  
            containerName: "",
            containerType: {label: "Warehouse", value: "warehouse"},
            containerDescription: "",
            parentContainer: "",
            containerNameError: false,
            containerTypeError: false,
            containerDescriptionError: false,
            parentContainerError: false
        };
    }

    handleChange = (e, fieldName) => {
        e.preventDefault();
        const val = e.target.value;
        const currState = this.state;
        currState[fieldName] = val;
        this.setState(() => ({
            ...currState
        }));
    }

    containerTypeChange = (value) => {
        const {containerType} = this.state;
        if(containerType !== value) {
            this.setState({
                containerType: value
            });
        }
    }

    validate = () => {
        const {containerName, containerType, containerDescription, parentContainer} = this.state;
        
        if(!containerRegex.test(containerName)) {
            this.setState({containerNameError: true},()=>{
                window.setTimeout(()=>{
                  this.setState({containerNameError: false})
                },INPUT_ERROR_TIMEOUT)
            });
            return getErrorMessage("invalidContainerName");
        }

        if (!containerName.includes(containerType.value)) {
            this.setState({containerTypeError: true},()=>{
                window.setTimeout(()=>{
                  this.setState({containerTypeError: false})
                },INPUT_ERROR_TIMEOUT)
            });
            return getErrorMessage("mismatchContainerNameAndContainerType");
        }

        if(containerDescription.trim().length === 0) {
            this.setState({containerDescriptionError: true},()=>{
                window.setTimeout(()=>{
                  this.setState({containerDescriptionError: false})
                },INPUT_ERROR_TIMEOUT)
            });
            return getErrorMessage("noDescription");
        }

        if(!parentContainerRegex.test(parentContainer)) {
            this.setState({parentContainerError: true},()=>{
                window.setTimeout(()=>{
                  this.setState({parentContainerError: false})
                },INPUT_ERROR_TIMEOUT)
            });
            return getErrorMessage("invalidParentContainer");
        }

        if(containerType.value === "warehouse") {
            if(!parentContainer.includes("vendor")) {
                this.setState({parentContainerError: true},()=>{
                    window.setTimeout(()=>{
                      this.setState({parentContainerError: false})
                    },INPUT_ERROR_TIMEOUT)
                });
                return getErrorMessage("levelMismatchContainerAndParent");
            }
        }

        if(containerType.value === "vendor") {
            if(!parentContainer.includes("marketplace")) {
                this.setState({parentContainerError: true},()=>{
                    window.setTimeout(()=>{
                      this.setState({parentContainerError: false})
                    },INPUT_ERROR_TIMEOUT)
                });
                return getErrorMessage("levelMismatchContainerAndParent");
            }
        }

        if(containerType.value === "marketplace") {
            if(!parentContainer.includes("global")) {
                this.setState({parentContainerError: true},()=>{
                    window.setTimeout(()=>{
                      this.setState({parentContainerError: false})
                    },INPUT_ERROR_TIMEOUT)
                });
                return getErrorMessage("levelMismatchContainerAndParent");
            }
        }

        return null;
    }

    onSubmit = () => {
        const err = this.validate();
        if(err !== null) {
            return this.props.onSubmit(null, {message: err});
        }

        const {containerName, containerType, containerDescription, parentContainer} = this.state;
        const formData = {
            containerName,
            containerType: containerType.value,
            containerDescription,
            parentContainer
        }

        return this.props.onSubmit(formData, null);
    }

    componentDidUpdate = () => {
        if(this.props.clearData) {
            this.setState({
                containerName: "",
                containerType: {label: "Warehouse", value: "warehouse"},
                containerDescription: "",
                parentContainer: ""
            })
        }
    }

    getContainerFormData() {
        const {containerName, containerType, containerDescription, parentContainer} = this.state;
        const {containerNameError, containerTypeError, containerDescriptionError, parentContainerError} = this.state;
        const formData = [
            {
                label: "Container Type",
                param: "containerType",
                value: containerType,
                type: "dropdown",
                list: containerTypeList,
                handleChange: this.containerTypeChange,
                error: containerTypeError
            },
            {
                label: "Container Name",
                param: "containerName",
                value: containerName,
                type: "input",
                handleChange: this.handleChange,
                placeholder: "warehouse-VUSB",
                error: containerNameError
            },
            {
                label: "Container Description",
                param: "containerDescription",
                value: containerDescription,
                type: "input",
                handleChange: this.handleChange,
                placeholder: "Please provide description !",
                error: containerDescriptionError
            },
            {
                label: "Parent Container",
                param: "parentContainer",
                value: parentContainer,
                type: "input",
                handleChange: this.handleChange,
                placeholder: "vendor-123",
                error: parentContainerError
            }
        ]
        return formData;
    }

    render() {
        const formData = this.getContainerFormData();
        return(
            <div className="box-container box-medium">
                <div className="box-container-form">
                    <div className="box-form-field">
                        <FormField
                            formData={formData}
                            onSubmit={this.onSubmit}
                        />
                    </div>
                    <div className="box-submit">
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            startIcon={<SaveIcon />}
                            onClick={this.onSubmit}
                            >
                            Save
                        </Button>
                    </div>
                </div>  
            </div>
        )
    }
}

export default ContainerForm;