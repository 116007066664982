import React from 'react';
import "./home.css";
import Alert from "@amzn/awsui-components-react/polaris/alert";

interface HomeProps {
  isAuthenticated: boolean;
}

export const Home: React.FC<HomeProps> = ({ isAuthenticated
}) => {

  const renderLanding = () => {
    return (
      <div>
          <Alert type={"info"} header={"NOTICE"}>
              EFP has launched a new and improved version of ButaneWebsite on 23rd October, 2023 which is accessible by External (Non-amazon Employees) users as well. <br/>
              All current users of ButaneWebsite should have receive an email from <em>no-reply@butane.efp.amazonoperations.app</em> with subject <em>You’re invited by EFP Team to use Butane Website. </em>
              This email will contain temporary login credential to access new version of ButaneWebsite. You are required to use that temporary login credentials and re-create new login credentials on the signup.
              You can read more on new functionalities of ButaneWebsite at our user guide <a href={"/userguide"}>here</a>.<br />
              <br/>
              Should you have any concerns, please comment on SIM with relevant details : https://sim.amazon.com/issues/EFP-VFX-508123 or reach out to us on butane-efp@amazon.com.
          </Alert>

        <div className="lander">
          <h2>EFP Tools</h2>
          <hr />
          <p>Self-Diagnostic & Operational Tools Framework.</p>
          <div className="button-container col-md-12">
            <a href="/login" className="orange-link">Login to explore.</a>
          </div>
        </div>
      </div>);
  }

  const renderHome = () => {
    return (<div>
        <Alert type={"info"} header={"NOTICE"}>
            EFP has launched a new and improved version of Butane Website on 23rd October, 2023 which is accessible by External (Non-amazon Employees) users as well. <br/>
            All current users of Butane Website should have receive an email from <em>no-reply@butane.efp.amazonoperations.app</em> with subject <em>You’re invited by EFP Team to use Butane Website. </em>
            This email will contain temporary login credential to access new version of Butane Website. You are required to use that temporary login credentials and re-create new login credentials on the signup.
            You can read more on new functionalities of Butane Website at our user guide <a href={"/userguide"}>here</a>.<br />
            <br/>
            Should you have any concerns, please comment on SIM with relevant details : https://sim.amazon.com/issues/EFP-VFX-508123 or reach out to us on butane-efp@amazon.com.
        </Alert>
            </div>);
  }

  return (
    <div className="Home">
      {isAuthenticated ? renderHome() : renderLanding()}
    </div>
  );
}
