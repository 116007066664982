import {RouteComponentProps, withRouter} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useGetInventoryReceiptDetails} from "@modules/inbound/inventoryReceiveNotification/hook";
import {useFlashbar} from "@common/flashbar";
import {
  COLUMN_DEFINITIONS,
  DEFAULT_PREFERENCES,
  FILTERING_PROPERTIES,
  InventoryReceiptDetailsHeader,
  Preferences,
  PROPERTY_FILTERING_LABELS
} from "@modules/inbound/inventoryReceiveNotification/table/inventoryReceiptDetail";
import {StatusCodes} from "http-status-codes";
import {useCollection} from "@amzn/awsui-collection-hooks";
import {getFilterCounterText, TableEmptyState, TableNoMatchState} from "@common/table";
import {AppLayout} from "@amzn/awsui-components-react";
import Table from "@amzn/awsui-components-react/polaris/table";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import PropertyFilter from "@amzn/awsui-components-react/polaris/property-filter";
import BreadcrumbGroup from "@amzn/awsui-components-react/polaris/breadcrumb-group";

interface InventoryReceiptDetailsProps {
  siteName: string,
  mcnBatchId: string
}

interface InventoryReceiptDetailsRouteComponentProps extends RouteComponentProps<InventoryReceiptDetailsProps> {
}

export const InventoryReceiptDetailsTable = withRouter(
    (
      {
        history,
        match
      }: InventoryReceiptDetailsRouteComponentProps) => {

      const {
        error,
        isLoading,
        lineItems,
        inventoryReceiptDetail,
        getInventoryReceiptDetail
      } = useGetInventoryReceiptDetails();

      const {addMessage, clearAllMessages} = useFlashbar();
      const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
      const {FlashbarContainer} = useFlashbar();

      async function refreshAndFetch() {
        clearAllMessages();
        getInventoryReceiptDetail(match?.params?.siteName, match?.params?.mcnBatchId);
      }

      useEffect(() => {
        if (error) {
          switch (error.statusCode) {
            case StatusCodes.NOT_FOUND:
              return addMessage({
                header: error.message,
                type: "warning"
              });
            default:
              return addMessage({
                header: error.message,
                content: error.details ?? null,
                type: "error",
                buttonText: "Retry",
                dismissible: true,
                onButtonClick: async () => {
                  await refreshAndFetch();
                }
              });
          }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [error]);

      const {
        items,
        actions,
        filteredItemsCount,
        collectionProps,
        paginationProps,
        propertyFilterProps
      } = useCollection(
        lineItems, {
          propertyFiltering: {
            filteringProperties: FILTERING_PROPERTIES,
            empty: (
              <TableEmptyState
                showCreateResourceButton={false}
                resourceName="Inventory Receipt Detail"
                resourceNamePlural="Inventory Receipt Details"
              />
            ),
            noMatch: (
              <TableNoMatchState
                onClearFilterClick={() => {
                  actions.setPropertyFiltering({tokens: [], operation: "and"});
                }}
              />
            )
          },
          pagination: {pageSize: preferences.pageSize},
          sorting: {},
          selection: {}
        });

      useEffect(() => {
        refreshAndFetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [match?.params?.siteName, match?.params?.mcnBatchId]);


      return (
        <AppLayout
          notifications={<FlashbarContainer/>}
          navigationHide={true}
          breadcrumbs={
            <BreadcrumbGroup
              onFollow={(e) => {
                e.preventDefault();
                history.push(e.detail.href);
              }}
              items={[
                {
                  text: 'Inventory Receipts',
                  href: '/receive/inventory_receipts'
                },
                {
                  text: `${match?.params?.siteName}`,
                  href: `/receive/${match?.params?.siteName}/inventory_receipts`
                },
                {
                  text: match?.params?.mcnBatchId,
                  href: '/'
                }
              ]
              }
            />
          }
          toolsHide={true}
          headerSelector="#top-navigation"
          contentType="table"
          content={
            <Table
              {...collectionProps}
              items={items}
              columnDefinitions={COLUMN_DEFINITIONS}
              loading={isLoading}
              loadingText={'Loading Inventory Receipt Details'}
              wrapLines={preferences.wraplines}
              visibleColumns={preferences.visibleContent}
              resizableColumns={true}
              selectionType="single"
              pagination={
                <Pagination {...paginationProps}/>
              }
              preferences={
                <Preferences
                  preferences={preferences}
                  setPreferences={setPreferences}
                />
              }
              header={
                <InventoryReceiptDetailsHeader
                  siteName={inventoryReceiptDetail?.siteName}
                  receiveType={inventoryReceiptDetail?.receiveType}
                  status={inventoryReceiptDetail?.status}
                  failureReason={inventoryReceiptDetail?.failureReason}
                  creationDate={inventoryReceiptDetail?.creationDate}
                />
              }
              filter={
                <PropertyFilter
                  {...propertyFilterProps}
                  i18nStrings={PROPERTY_FILTERING_LABELS}
                  countText={getFilterCounterText(filteredItemsCount)}
                />
              }
            />
          }/>
      )
    }
  )
;