export default class ActionItemAuditRecord {
    constructor(entryDate, type, action, messageId, businessId, source, destination, quantity, failedQuantity) {
        this.entryDate = entryDate;
        this.type = type;
        this.action = action;
        this.messageId = messageId;
        this.businessId = businessId;
        this.source = source;
        this.destination = destination;
        this.quantity = quantity;
        this.failedQuantity = failedQuantity;
    }
}