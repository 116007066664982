export enum TransferDepartRequestStatus {
  "CREATED" = "CREATED",
  "IN_PROGRESS" = "IN_PROGRESS",
  "SUCCESS" = "SUCCESS",
  "FAILURE" = "FAILURE",
  "UNKNOWN" = "UNKNOWN"
}

export class TransferDepartRequest {
  public amazonShipmentReferenceId!: string;

  public warehouseId!: string;

  public lastUpdatedDate!: string;

  public trailerId!: string;

  public workflowId!: string;

  public destinationWarehouseId!: string;

  public creationDate!: string;

  public Status!: TransferDepartRequestStatus | string;

  public s3Path!: string;

  public fromPartial(
    data: Partial<TransferDepartRequest>
  ): TransferDepartRequest {
    Object.assign(this, { data });
    return this;
  }
}
