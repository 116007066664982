// React
import React from "react";

import Alert from "@amzn/awsui-components-react/polaris/alert";

class BackendError extends React.Component {
  state = {
    openErrorSnackbar: this.props.openErrorSnackbar
  };

  render() {
    return (
        <Alert type="error" header={"Network error"} >
          Trouble connecting to the backend, please reload the page or try again.<br/>
        </Alert>
    );
  }
}

export default BackendError;
