import React, { Component } from 'react';
import Button from "@material-ui/core/Button";
import {fetchDeleteRPILink} from "../../../constants/FetchDeleteRPILink"
import {RegionContext} from '@common/RegionContext';
import {getEnvironment} from '@modules/signup/Utils';

export default class DeleteRPI extends Component {
    render() {
        return (
	    <a href={`${fetchDeleteRPILink(this.context.region, getEnvironment())}`}
               className="rpi-button"
               target="_blank"
               rel="noopener noreferrer">
                    <Button variant="contained" size="small"> 
                        BLOW OUT RPI
                    </Button>
            </a>
        )
    }
}

DeleteRPI.contextType = RegionContext;
