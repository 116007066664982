export class InventoryReceipt {
  public purchaseOrderId!: string;
  public requestDate!: string;
  public mcnBatchId!: string;
  public receiveType!: string;
  public status!: string;
  public siteName!: string;
  public failureReason?: string;

  constructor(poId: string, requestDate: string, mcnBatchId: string,
              receiveType: string, status: string, siteName: string, failureReason?: string) {
    this.purchaseOrderId = poId;
    this.requestDate = requestDate;
    this.mcnBatchId = mcnBatchId;
    this.receiveType = receiveType;
    this.status = status;
    this.siteName = siteName;
    this.failureReason = failureReason;
  }

  public fromPartial(data: Partial<InventoryReceipt>): InventoryReceipt {
    Object.assign(this, {data});
    return this;
  }

}