import React, { Component } from 'react';
import { Button, TextField } from "@material-ui/core";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import PageTitle from '../../../components/PageTitle/PageTitle';

import { alphaNumericRegex, warehouseIdRegex } from '../../../constants/Regex';
import { getErrorMessage } from '../../../constants/ErrorMessageMapper';
import './PurchaseOrderInfo.css';

class PurchaseOrderInfoForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            orderId: "",
            warehouseId: "",
            orderIdModified: false,
            warehouseIdModified: false
        };
    }

    handleChange = e => {
        e.preventDefault();
        const value = e.target.value.toUpperCase();
        this.setState({
            [e.target.name]: value,
            [e.target.name + "Modified"]: true
        });
    };

    isWarehouseIdValid = () => {
        return this.state.warehouseIdModified && !warehouseIdRegex.test(this.state.warehouseId);
    }

    isOrderIdValid = () => {
        return this.state.orderIdModified && !alphaNumericRegex.test(this.state.orderId);
    }

    AreAllInputsValid = () => {
        return (!!this.state.warehouseId && warehouseIdRegex.test(this.state.warehouseId) && 
            !!this.state.orderId && alphaNumericRegex.test(this.state.orderId));
    };

    handleFormSubmit = (event) => {
        event.preventDefault();
        const value = event.target.value;

        this.setState({
            [event.target.name]: value
        });
        this.props.getFormInput(this.state.orderId, this.state.warehouseId);
    }

    render() {
        return (
            <div>
                <div>
                    <PageTitle>Search PO Details</PageTitle>
                </div>
                <div>
                    <div className="form-inline">
                        <div>
                            <div className="filter-form">
                                <div className="filters">
                                    <div>
                                        <div className="po-input-box-height">
                                            <label htmlFor="orderId" className="po-orderId-input-params">Order Id</label>
                                            <TextField
                                                error={this.isOrderIdValid()}
                                                placeholder="Order Id"
                                                name="orderId"
                                                helperText={this.isOrderIdValid() && getErrorMessage("orderId")}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="po-input-box-height">
                                        <label htmlFor="warehouseId" className="po-input-params">Warehouse</label>
                                        <TextField
                                            error={this.isWarehouseIdValid()}
                                            placeholder="Warehouse Id"
                                            name="warehouseId"
                                            helperText={this.isWarehouseIdValid() && getErrorMessage("warehouseId")}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="inline align-middle text-center">
                                    <div className="po-submit-button-padding" />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<SearchOutlinedIcon />}
                                        disabled={!this.AreAllInputsValid()}
                                        onClick={this.handleFormSubmit}
                                    >
                                        Search
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PurchaseOrderInfoForm;