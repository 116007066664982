import React, { ReactNode } from "react";
import Header from "@amzn/awsui-components-react/polaris/header";

interface TableHeaderProps {
  actions: ReactNode;
  counterText: string;
  children: ReactNode;
  description?: string;
}

export function TableHeader({
  actions,
  children,
  counterText,
  description
}: TableHeaderProps) {
  return (
    <>
      <Header
        variant="h2"
        actions={actions}
        counter={counterText}
        description={description}
      >
        {children}
      </Header>
    </>
  );
}
