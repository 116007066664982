import {LineItem} from "@modules/inbound/inventoryReceiveNotification/model/LineItem";

export class InventoryReceiptDetail {
  siteName!: string;
  mcn!: string;
  batchId!: string;
  purchaseOrderId!: string;
  receiveType!: string;
  status!: string;
  failureReason?: string;
  creationDate!: string;
  items!: LineItem[];

  constructor(siteName: string, mcn: string, batchId: string, purchaseOrderId: string, receiveType: string,
              status: string, creationDate: string, items: LineItem[], failureReason?: string) {
    this.siteName = siteName;
    this.mcn = mcn;
    this.batchId = batchId;
    this.purchaseOrderId = purchaseOrderId;
    this.receiveType = receiveType;
    this.status = status;
    this.creationDate = creationDate;
    this.items = items;
    this.failureReason = failureReason;
  }

  public fromPartial(partialReceiveExecution: Partial<InventoryReceiptDetail>) {
    Object.assign(this, {partialReceiveExecution});
  }

}