export default class ShipmentsDetailsInCondition6009 {
    constructor(warehouseId, shipmentId, encryptedShipmentId, orderId, asin, quantity, asinExeption, shipmentExeption, netInventory, orderDate, promisedShipDate, shipMethod, expectedShipDate, condition, conditionAge, hoursPassedExsd, action, shipmentType) {
        this.warehouseId = warehouseId;
        this.shipmentId = shipmentId;
        this.encryptedShipmentId = encryptedShipmentId;
        this.orderId = orderId;
        this.asin = asin;
        this.quantity = quantity;
        this.asinExeption = asinExeption;
        this.shipmentExeption = shipmentExeption;
        this.netInventory = netInventory;
        this.orderDate = orderDate;
        this.promisedShipDate = promisedShipDate;
        this.shipMethod = shipMethod;
        this.expectedShipDate = expectedShipDate;
        this.condition = condition;
        this.conditionAge = conditionAge;
        this.hoursPassedExsd = hoursPassedExsd;
        this.action = action;
        this.shipmentType = shipmentType;
    }
}
