import React, {Component} from "react";
import Label from "./Label";
import InputField from "./InputField";
import DropDownField from "./DropDownField";
import DropDownMultiSelectField from "./DropDownMultiSelectField";
import MultiField from "./MultiField";

class FormField extends Component {
    constructor(props) {
        super(props);
        this.state={
        }
    }

    createFormFields = () => {
        return this.props.formData.map((data, index) => {
            if(data.type === "input") {
                return (
                    <div className="config-form-field" key={index}>
                       <Label label={data.label}/>
                       <InputField
                            value={data.value}
                            handleChange={data.handleChange}
                            param={data.param}
                            placeholder={data.placeholder}
                            error={data.error}
                       />
                    </div> 
                )
            }

            if(data.type === "dropdown") {
                return (
                    <div className="config-form-field" key={index}>
                       <Label label={data.label}/>
                       <DropDownField
                            value={data.value}
                            handleChange={data.handleChange}
                            param={data.param}
                            list={data.list}
                            error={data.error}
                       />
                    </div> 
                )
            }

            if(data.type === "multi-dropdown") {
                return (
                    <div className="config-form-field" key={index}>
                       <Label label={data.label}/>
                       <DropDownMultiSelectField
                            value={data.value}
                            handleChange={data.handleChange}
                            param={data.param}
                            list={data.list}
                            error={data.error}
                       />
                    </div> 
                )
            }

            if(data.type === "multifield") {
                return (
                    <div className="config-form-field multifield-flex" key={index}>
                        <Label label={data.label} />
                        <MultiField
                            value={data.value}
                            handleChange={data.handleChange}
                            onAdd={data.onAdd}
                            onDelete={data.onDelete}
                            paramTypeList={data.paramTypeList}
                            errorIndex={data.errorIndex}
                        />
                    </div>
                )
            }
            return null;
        });
    }

    render() {
        const formFields = this.createFormFields();
        return(
          <div>
              {formFields} 
          </div>
        )
    }
}

export default FormField;