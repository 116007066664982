import React, { Component } from "react";
import Griddle, { plugins, RowDefinition, ColumnDefinition } from "griddle-react";
import "./griddleTable.css";

/**
 * This class uses Griddle to construct the Table. For full property list of Table component.
 * please look for documentation at:
 *
 * http://griddlegriddle.github.io/Griddle/properties.html
 */
export default class GriddleTable extends Component {
  render() {
    const TableLayout = ({ Table, Pagination, Filter }) => (
      <div>
        <Filter />
        <Table />
        <Pagination />
      </div>
    );
    return (
      <div className="table">
        <Griddle
          components={{ Layout: TableLayout }}
          data={this.props.results}
          plugins={[plugins.LocalPlugin]}
          pageProperties={this.props.pageProperties}
        >
          <RowDefinition>
            {this.props.columnMetadata.map(
              ({ displayName, columnName, width, customComponent }) => (
                <ColumnDefinition
                  id={columnName}
                  title={displayName}
                  width={width}
                  customComponent={customComponent}
                />
              )
            )}
          </RowDefinition>
        </Griddle>
      </div>
    );
  }
}
