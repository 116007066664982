import React from "react";
import { fetchArsenalLink } from '../../../constants/FetchArsenalLink'
import { TABLE_ICONS } from '../../../constants/table/metadata/inventory/InventoryAuditTableIcons.jsx';
import { fetchInventoryAuditTableColumns } from '../../../constants/table/metadata/inventory/InventoryAuditTableColumn.jsx';
import MaterialTable from 'material-table';
import API from "@aws-amplify/api";
import ActionItemAuditRecord from './model/ActionItemAuditRecord'
import NetAuditRecord from './model/NetAuditRecord'
import SnapshotAuditRecord from './model/SnapshotAuditRecord'
import "./inventoryAuditTableStyle.css";
import '../../../../src/components/Spinner/spinner.css';
import { getAuthorizationHeader } from "@common/Auth";
import {RegionContext} from '@common/RegionContext';
import {constructButaneApiName} from '../../../apiConfig';
import {getEnvironment} from '@modules/signup/Utils';

export default class InventoryAuditDetailsTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            auditDetails: [],
            fetchingRecords: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.asin !== this.props.asin || prevProps.warehouse !== this.props.warehouse
            || prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate
            || prevProps.inventoryLocation !== this.props.inventoryLocation || prevProps.search !== this.props.search) {
            this.getAuditDetails();
        }
    }

    getAuditDetails() {
        this.setState(() => ({
            fetchingRecords: true
        }));
        this.searchAudits();
    }

    getMessageIdStyle = (rowData) => {
        if(rowData !== undefined){
            const hrefLink = fetchArsenalLink(this.context.region, getEnvironment()) + `search?id=${rowData.messageId}`
            return <a className='link-style' href={ hrefLink }>{rowData.messageId}</a>;
        }
        return null
    }

    getQuantityStyle = (rowData) => {
        if(rowData !== undefined &&  rowData.type === 'net') {
            return(
                <span className='net-style'> {rowData.quantity} </span>
            );
        }
        return(
            <span> {rowData.quantity} </span>
        );
    }

    getDestinationStyle = (rowData) => {
        if(rowData !== undefined && rowData.type === 'net'){
            return(
                <span className='net-style'> {rowData.destination} </span>
            );
        }
        return(
            <span> {rowData.destination} </span>
        );
    }

    getAmzStyle = (rowData) => {
        if(rowData !== undefined && rowData.isDiscrepancy){
            return(
                <span className='failed-style'> {rowData.amazonAvailableAndCommitted} </span>
            );
        }
        return(
            <span> {rowData.amazonAvailableAndCommitted} </span>
        );
    }

    getWarehouseStyle = (rowData) => {
        if(rowData !== undefined && rowData.isDiscrepancy){
            return(
                <span className='failed-style'> {rowData.warehouseAvailableAndCommitted} </span>
            );
        }
        return(
            <span> {rowData.warehouseAvailableAndCommitted} </span>
        );
    }

    getDiffStyle = (rowData) => {
        if(rowData !== undefined && rowData.isDiscrepancy){
            return(
                <span className='failed-style'> {rowData.difference} </span>
            );
        }
        return(
            <span>{rowData.difference}</span>
        );
    }

    getFailedStyle = (rowData) => {
        return(
            <span className='failed-style'> {rowData.failedQuantity} </span>
        );
    }

    getActionStyle = (rowData) => {
        if(rowData !== undefined && rowData.type === 'failedAction') {
            return(
                <span className='failed-style'> {rowData.action} </span>
            );
        }
        return(
            <span>{rowData.action}</span>
        );
    }

    async searchAudits() {
        const longStartDate = new Date(this.props.startDate).getTime()/1000;
        const longEndDate = new Date(this.props.endDate).getTime()/1000;
        return API.get(constructButaneApiName(this.context.region), `/api/audit/inventory/warehouse/${this.props.warehouse}/fnSku/${this.props
        .asin}?location=${this.props.inventoryLocation}&startDate=${longStartDate}&endDate=${longEndDate}`, {
                headers: await getAuthorizationHeader()
            })
            .then((data) => {
                let inventoryAudits = [];
                let net = 0;
                let lastSnapshot = null;
                for(let i = 0; i < data.auditRecords.length; i++){
                    const temp = data.auditRecords[i];
                    if(temp.isSnapshot) {
                        if(lastSnapshot!==null && net!==0) {
                            const netRecord = new NetAuditRecord("Net", net, "net");
                            inventoryAudits.push(netRecord);
                        }
                        let availableDiff = temp.amazonAvailable - temp.warehouseAvailable;
                        let committedDiff = temp.amazonCommitted - temp.warehouseCommitted;
                        let isDiscrepancy = false;
                        if(availableDiff !== 0 || committedDiff !== 0 ){
                            isDiscrepancy = true
                        }
                        const snapshotRecord = new SnapshotAuditRecord(
                            new Date(temp.entryDate*1000).toLocaleString(),
                            temp.action,
                            temp.amazonAvailable+"("+temp.amazonCommitted+")",
                            temp.warehouseAvailable+"("+temp.warehouseCommitted+")",
                            availableDiff+"("+committedDiff+")",
                            isDiscrepancy,
                            "snapshot"
                        )
                        inventoryAudits.push(snapshotRecord);
                        lastSnapshot = temp;
                        net = 0;
                    } else {
                        if(!temp.isSuccess){
                            const failedActionItem = new ActionItemAuditRecord(
                                new Date(temp.entryDate*1000).toLocaleString(),
                                "failedAction",
                                temp.action+" (FAILED)",
                                temp.referenceId,
                                temp.businessId,
                                temp.source,
                                temp.destination,
                                "",
                                temp.failedQuantity
                            )
                            inventoryAudits.push(failedActionItem);
                        } else {
                            net += temp.quantity;
                            const successActionItem = new ActionItemAuditRecord(
                                new Date(temp.entryDate*1000).toLocaleString(),
                                "successAction",
                                temp.businessShortName,
                                temp.referenceId,
                                temp.businessId,
                                temp.source,
                                temp.destination,
                                temp.quantity,
                                ""
                            )
                            inventoryAudits.push(successActionItem);
                        }
                    }
                }
                this.setState(() => ({
                    auditDetails: inventoryAudits,
                    fetchingRecords: false
                }));
            }).catch((e) => {
              this.setState(() => ({
                   auditDetails: [],
                   fetchingRecords: false
            }));
        });
    }

    render() {

        if(this.state.fetchingRecords) {
          return (
            <div className="spinner"></div>
          );
        }
        if (!this.state.fetchingRecords && this.props.warehouse && this.props.asin && this.props.inventoryLocation
            && this.props.startDate && this.props.endDate && this.props.search) {
            return (
                <div className='inventory-audit-table-layout'>
                <p className='p-style'>* n(x): n = number of available units, x = number of committed units</p>
                    <MaterialTable
                        icons={ TABLE_ICONS }
                        title="Inventory Audit Details"
                        columns={ fetchInventoryAuditTableColumns(this.getActionStyle, this.getMessageIdStyle,
                                                                this.getDestinationStyle, this.getQuantityStyle,
                                                                this.getFailedStyle, this.getAmzStyle,
                                                                this.getWarehouseStyle, this.getDiffStyle) }
                        data={ this.state.auditDetails }
                        options={{
                            search: true,
                            exportButton: true,
                            exportAllData: true,
                            emptyRowsWhenPaging: false,
                            sorting:true,
                            draggable: false,
                            pageSizeOptions: [5, 10, 20, 40]
                        }}
                    />
                </div>
            );
        }
        return null;
    }
}

InventoryAuditDetailsTable.contextType = RegionContext;