export default class SnapshotAuditRecord {
    constructor(entryDate, action, amazonAvailableAndCommitted, warehouseAvailableAndCommitted, difference, isDiscrepancy, type) {
        this.entryDate = entryDate;
        this.action = action;
        this.amazonAvailableAndCommitted = amazonAvailableAndCommitted;
        this.warehouseAvailableAndCommitted = warehouseAvailableAndCommitted;
        this.difference = difference;
        this.isDiscrepancy = isDiscrepancy;
        this.type = type;
    }
}