import React, { Component } from "react";

/* Import Components */
import { Button } from "@material-ui/core";
import UpdateOutlinedIcon from "@material-ui/icons/UpdateOutlined";
import PageTitle from "../../../components/PageTitle/PageTitle";
import "../config.css";
import { Alert } from "react-bootstrap";
import {warehouseIdRegex} from "../../../constants/Regex"

class MultiNodeConfigUpdateForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isButtonDisabled: true,
      nodesUpdateRequest: {
        warehouses: this.props.warehouses,
        paramName: this.props.paramName,
        paramValue: this.props.paramValue,
      },
      validationErrorMsg: undefined
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState(() => ({
      warehouses: nextProps.warehouses,
      paramName: nextProps.paramName,
      paramValue: nextProps.paramValue,
    }));
  }

  handleFormSubmit(e) {
    e.preventDefault();
    this.setState({
      isButtonDisabled: true,
    });
    const warehouses = this.state.warehouses.split(/[,]+/);
    for(let warehouse of warehouses) {
      console.log(warehouse, warehouseIdRegex.test(warehouse))
      if(!warehouseIdRegex.test(warehouse)) {
        if(warehouse === "") {
          this.setState({
            isButtonDisabled: true,
            validationErrorMsg: `Blank and space is not allowed.`
          });
        } else {
          this.setState({
            isButtonDisabled: true,
            validationErrorMsg: `'${warehouse}' is Invalid input.`
          });
        }

        return;
      }
    }
    this.props.renderTables(
      this.state.warehouses,
      this.state.paramName,
      this.state.paramValue
    );
  }

  handleChange = (e) => {
    const value = (e.target.name === "warehouses") ?
                              e.target.value.trim().replace(/^,/, '').replace(/\s/g,'').replace(/,+/g,',') :
                              e.target.value.trim();
    this.setState({
      [e.target.name]: value
    });
    if (
      (typeof this.state.paramName !== "undefined" &&
        typeof this.state.paramValue !== "undefined" &&
        typeof this.state.warehouses !== "undefined") ||
      (e.target.name === "warehouses" &&
        typeof this.state.paramName !== "undefined" &&
        typeof this.state.paramValue !== "undefined") ||
      (e.target.name === "paramName" &&
        typeof this.state.warehouses !== "undefined" &&
        typeof this.state.paramValue !== "undefined") ||
      (e.target.name === "paramValue" &&
        typeof this.state.warehouses !== "undefined" &&
        typeof this.state.paramName !== "undefined")
    ) {
      this.setState({ isButtonDisabled: false });
    }
    if (e.target.name === "warehouses") {
      let warehouses = [];
      warehouses = value.split(/[ ,]+/);
      if(warehouses.length > 25) {
        this.setState({
          isButtonDisabled: true,
          validationErrorMsg: "Bulk update can be done for maximum 25 warehouses at once."
        });
      } else {
        this.setState({
          isButtonDisabled: !(this.state.paramName !== undefined && this.state.paramValue !== undefined), 
          validationErrorMsg: undefined
        });
      }
    }
  };

  render() {
    return (
      <div>
        <div>
          <PageTitle>Bulk Config Update</PageTitle>
        </div>
        <div>
          {!!this.state.validationErrorMsg 
            && <Alert variant="danger">{this.state.validationErrorMsg}</Alert> }
        </div>
        <div className="form-inline">
          <div>
            <div className="filter-form">
              <div className="filters">
                <div className="row">
                  <div className="column1">
                    <label className="input-params">Parameter Name</label>
                  </div>
                  <div className="column2">
                    <input
                      className="config-input-box"
                      name="paramName"
                      onChange={this.handleChange}
                      defaultValue={this.state.paramName}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="column1">
                    <label className="input-params">Parameter Value</label>
                  </div>
                  <div className="column2">
                    <input
                      className="config-input-box"
                      name="paramValue"
                      onChange={this.handleChange}
                      defaultValue={this.state.paramValue}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="column1">
                    <label className="input-params">Warehouses</label>
                  </div>
                  <div className="column2">
                    <textarea
                      rows={5}
                      cols={25}
                      maxLength={150}
                      className="params-input-box"
                      placeholder="Comma separated list of warehouses eg. ABCD,PQST"
                      name="warehouses"
                      onChange={this.handleChange}
                      value={this.state.warehouses}
                      defaultValue={this.state.warehouses}
                    />
                  </div>
                </div>
              </div>
              <div className="inline align-middle text-center">
                <div className="audit-submit-button-padding"></div>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<UpdateOutlinedIcon />}
                  onClick={this.handleFormSubmit}
                  disabled={this.state.isButtonDisabled}
                >
                  Update
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MultiNodeConfigUpdateForm;
