/**
 * Class which wraps API errors for further consumption.
 */
export class ApiError {
  public readonly message!: string;

  public readonly statusCode!: number;

  public readonly details?: string;

  constructor(message: string, statusCode: number, details?: string) {
    this.message = message;
    this.statusCode = statusCode;
    this.details = details;
  }
}
