import React from "react";
import "./inputField.css";

function InputField(props) {
  return (
    <div>
      <div className="field-input">
        <label> {props.displayName}: </label>

        <input
          className={props.showError ? "error" : "valid"}
          type="text"
          name={props.paramName}
          value={props.paramValue}
          onChange={(event) => {
            props.handleChange(event, props.paramName);
          }}
          disabled={!props.editEnabled}
        />

        <label> {props.unit} </label>
      </div>
    </div>
  );
}

export default InputField;
