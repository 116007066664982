import React, { useState } from "react";
import { RouteComponentProps } from "react-router";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";

import SelectWarehouseForm from "@components/SelectWarehouse/SelectWarehouseForm";
import { useFlashbar } from "@common/flashbar";
import { TransferDepartRequestsTable } from "./table";

import "@modules/transfer/Transfers.css";

interface TransferDepartRequestsOverviewParams {
  warehouse: string;
}

interface TransferDepartRequestsOverviewProps
  extends RouteComponentProps<TransferDepartRequestsOverviewParams> {}

export function TransferDepartRequestsOverview({
  history,
  match
}: TransferDepartRequestsOverviewProps) {
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [redirectPath, setRedirectPath] = useState("");
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const { FlashbarContainer } = useFlashbar();

  function constructRedirectPath(warehouse: string) {
    const redirectPath = `/transfers/${warehouse}/departure-requests`;

    setSelectedWarehouse(warehouse);
    setRedirectPath(redirectPath);
    setShouldRedirect(true);
  }

  function hasWarehouseRouteParam() {
    return match?.params?.warehouse;
  }

  function shouldSelectWarehouse() {
    return !hasWarehouseRouteParam() && !shouldRedirect;
  }

  function shouldRedirectWithWarehouseParam() {
    return !hasWarehouseRouteParam() && shouldRedirect;
  }

  if (shouldSelectWarehouse()) {
    return (
      <SelectWarehouseForm
        warehouse={selectedWarehouse}
        constructRedirectPath={constructRedirectPath}
        usePolaris={true}
      />
    );
  }

  if (shouldRedirectWithWarehouseParam()) {
    setShouldRedirect(false);
    history.push(redirectPath);
  }

  return (
    <AppLayout
      notifications={<FlashbarContainer />}
      navigationHide={true}
      toolsHide={true}
      headerSelector="#top-navigation"
      contentType="table"
      content={
        <>
          <TransferDepartRequestsTable />
        </>
      }
    />
  );
}
