import React from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";

import { YYYY_MM_DD_FORMAT, YYYY_MM_DD_HH_MM_MS_FORMAT } from "@constants/date";

dayjs.extend(utc);
dayjs.extend(relativeTime);

type TimeFormat = "date" | "dateTime" | "relative";

interface FormattedISOTimestampProps {
  date: Date;
  format: TimeFormat;
}

export function FormattedISOTimestamp({
  date,
  format
}: FormattedISOTimestampProps) {
  function getFormattedTimeValue() {
    if (!date) {
      return "-";
    }

    switch (format) {
      case "date":
        return dayjs(date).utc().format(YYYY_MM_DD_FORMAT);
      case "dateTime":
        return dayjs(date).utc().format(YYYY_MM_DD_HH_MM_MS_FORMAT);
      case "relative":
        return dayjs(date).utc().fromNow();
      default:
        throw new Error(`Format ${format} is unsupported`);
    }
  }

  return <span>{getFormattedTimeValue()}</span>;
}
