export interface Registration {
  registrationId: string;
  market: string;
  businessType: string;
  warehouseManagementSystem: string;
  email: string;
  creator: string;
  created: number;
  lastUpdated: number;
  operations: Operations;
  registrationSiteEquipments: string[]
}

export interface Operations {
  supportsOperationTickets: boolean;
  communication: CTI;
  inventory: CTI;
  receive: CTI;
  shipment: CTI;
  removal: CTI;
  transfer: CTI;
}

export interface CTI {
  category: string;
  type: string;
  item: string;
}

export enum Status {
  SUBMITTED = "Submitted",
  IN_PROCESS = "Processing",
  IN_RESOURCE_DEMAND = "Setting Up Resources",
  FAILED = "Failed",
  CANCELLED = "Cancelled",
  READY = "Ready",
  OPERATIONAL = "Operational",
  EXHAUSTED = "Exhausted"
};

export interface NodeLaunchPlan {
  nodeLaunchPlanId: string;
  registrationId: string;
  nodesPlanned: number;
  nodesOperational: number;
  expectedShipmentVolumePerDay: number;
  expectedLaunchDate: number;
  status: Status;
  createdDate: number;
  lastUpdatedDate: number;
  contact: string;
}

export interface NodesUnderLaunchPlan {
  nodeLaunchPlanId: string;
  id: string;
  name: string;
  fulfilmentNodeType: string;
  vendor:{
    id: number;
    code: string;
  };
  created: number;
}

export enum WarehouseManagementSystem {
  SELLER_FLEX = "Seller Flex",
  SMART_CONNECT = "Smart Connect",
  FAAST = "FaaST",
  THIRD_PARTY_LOGISTICS = "3PL",
  DC = "DC",
  OTHER = "Other"
}

export enum BusinessType {
  SELLER_FLEX = "Seller Flex",
  VENDOR_FLEX = "Vendor Flex",
  STORES = "Stores",
  ROAR = "Roar",
  WAREHOUSE_INTEGRATION = "Warehouse Integration",
  WHOLESALE = "Wholesale",
  MINI_FC = "Mini FC",
  KINDLE_FLEX = "Kindle Flex",
  AMXL = "AMXL",
  SNL = "SNL",
  SSD = "SSD",
  TREASURE_TRUCK = "Treasure Truck",
  UNO = "UNO",
  GLOBAL_IT_LOGISTICS = "Global IT Logistics",
  PHOTO_STUDIO = "Photo Studio",
  MPS = "MPS",
  REPAIR_AND_RECOMMERCE = "Repair and Recommerce",
  KRAKEN = "Kraken",
  JADOO_SUPPLY_CHAIN = "Jadoo Supply Chain",
  MAGAZINE = "Magazine",
  ZAPPOS = "Zappos",
  SHOPBOP = "Shopbop",
  VOSTOK = "Vostok",
  MOTHERSHIP = "Mothership",
  AMAZON_WAREHOUSING_AND_DISTRIBUTION = "Amazon Warehousing and Distribution",
  OTHER = "Other"
}

/**
 * Map that stores the Business Types supported by a particular WMS
 */
const WMS_BUSINESS_MAP = new Map<String, Array<BusinessType>>([
  [WarehouseManagementSystem.SELLER_FLEX, [BusinessType.SELLER_FLEX, BusinessType.VENDOR_FLEX, BusinessType.OTHER]],
  [WarehouseManagementSystem.SMART_CONNECT, [BusinessType.STORES, BusinessType.ROAR, BusinessType.WAREHOUSE_INTEGRATION,
    BusinessType.OTHER]],
  [WarehouseManagementSystem.FAAST, [BusinessType.VENDOR_FLEX, BusinessType.WHOLESALE, BusinessType.MINI_FC,
    BusinessType.KINDLE_FLEX, BusinessType.AMXL, BusinessType.SNL, BusinessType.SSD, BusinessType.TREASURE_TRUCK,
    BusinessType.UNO, BusinessType.GLOBAL_IT_LOGISTICS, BusinessType.OTHER]],
  [WarehouseManagementSystem.THIRD_PARTY_LOGISTICS, [BusinessType.AMXL, BusinessType.WAREHOUSE_INTEGRATION,
    BusinessType.PHOTO_STUDIO, BusinessType.STORES, BusinessType.MPS, BusinessType.REPAIR_AND_RECOMMERCE, BusinessType.KRAKEN,
    BusinessType.WHOLESALE, BusinessType.JADOO_SUPPLY_CHAIN, BusinessType.MAGAZINE, BusinessType.ZAPPOS, BusinessType.SHOPBOP,
    BusinessType.VOSTOK, BusinessType.MOTHERSHIP, BusinessType.OTHER]],
  [WarehouseManagementSystem.DC, [BusinessType.AMAZON_WAREHOUSING_AND_DISTRIBUTION, BusinessType.OTHER]]
]);

export const ConvertEnumToReactSelectFormat = <T>(enumToDeconstruct: T): Array<any> => {
  // https://react-select.com/ requires values in [{label: "", value: ""}] format
  return Object.entries(enumToDeconstruct).map(([_k, v]) => ({ label: v, value: v }));
};

export const FilterBusinessType = (wms: String): Array<BusinessType> => {
  // https://stackoverflow.com/a/57062363/9654497
  return WMS_BUSINESS_MAP.has(wms) ? WMS_BUSINESS_MAP.get(wms)! : Object.values(BusinessType);
}

export type Filter = { [key: string]: string } | {};