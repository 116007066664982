import React, {useEffect, useState} from "react";
import Form from "@amzn/awsui-components-react/polaris/form";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import PolarisButton from "@amzn/awsui-components-react/polaris/button";
import RadioGroup from "@amzn/awsui-components-react/polaris/radio-group";
import {ColumnLayout} from "@amzn/awsui-components-react";
import Input from "@amzn/awsui-components-react/polaris/input";
import './inventoryResearchInput.css'
import {RouteComponentProps} from "react-router-dom";
import {asinRegex, fcSkuRegex} from "@constants/Regex";

interface InventoryResearchInputProps extends RouteComponentProps {
    itemIdentifier: string,
    warehouseList: string,
    inventoryType: string,
}

export const InventoryResearchInput = (props: InventoryResearchInputProps) => {
    const [itemIdentifier, setItemIdentifier] = useState("");
    const [warehouseList, setWarehouseList] = useState("");
    const [inventoryType, setInventoryType] = useState("fnSku");

    const WAREHOUSE_LIST_REGEX = /^(( )*[A-Z][A-Z0-9]{3,4}( )*(,)){0,5}( )*[A-Z][A-Z0-9]{3,4}( )*$/;

    useEffect(() => {
        setItemIdentifier(props.itemIdentifier);
        setWarehouseList(props.warehouseList);
        setInventoryType(props.inventoryType ? props.inventoryType : "fnSku");
    }, [props]);

    const areInputsValid = (): boolean => {
        return !!itemIdentifier && WAREHOUSE_LIST_REGEX.test(warehouseList) &&
            (fcSkuRegex.test(itemIdentifier) || asinRegex.test(itemIdentifier));
    }

    const onInventorySearch = () => {
        props.history.push(`/inventory/research?itemIdentifier=${itemIdentifier}&warehouses=${warehouseList}&inventoryType=${inventoryType}`);
    }

    return (
            <Form
                actions={<PolarisButton
                    onClick={onInventorySearch}
                    iconName="search"
                    variant="primary"
                    disabled={!areInputsValid()}
                >
                    Search
                </PolarisButton>}
                data-item-identifier-input="input-container"
            >
                <Container
                    header={
                        <Header variant="h2">
                            Search Network Inventory
                        </Header>
                    }
                >
                    <ColumnLayout columns={2}>
                        <SpaceBetween direction="vertical" size="xs">
                            <FormField label="Item Identifier"
                                       description="FNSKU or FCSKU identifier e.g. BT00CTP9EC"
                            >
                                <Input type="text"
                                       value={itemIdentifier}
                                       onChange={(e) => setItemIdentifier(e.detail.value)}
                                />
                            </FormField>
                            <FormField label="Warehouses"
                                       description="Comma separated warehouse e.g. XDEB,XFRF"
                            >
                                <Input type="text"
                                       placeholder="At max 6 warehouse permitted"
                                       value={warehouseList}
                                       onChange={(e) => setWarehouseList(e.detail.value)}
                                />
                            </FormField>
                        </SpaceBetween>
                        <div data-item-identifier-input="search-button-pane">
                            <FormField label="Inventory Type"></FormField>
                            <SpaceBetween direction="vertical" size="l">
                                <RadioGroup
                                    onChange={(e) => setInventoryType(e.detail.value)}
                                    value={inventoryType}
                                    items={[
                                        {value: "fnSku", label: "FNSKU"},
                                        {value: "fcSku", label: "FCSKU"},
                                        {value: "fnSkuAndFcSku", label: "Both"}
                                    ]}
                                />
                            </SpaceBetween>
                        </div>
                    </ColumnLayout>
                </Container>
            </Form>
    );
}