import {TableResourceLink} from "@common/table";
import React from "react";

interface InventoryReceiptDetailLinkProps {
  siteName: string,
  mcnBatchId: string
}

export const InventoryReceiptDetailLink = (
  {
    siteName,
    mcnBatchId
  }: InventoryReceiptDetailLinkProps
) => {
  const INVENTORY_RECEIPT_DETAIL_URL = `/receive/${siteName}/inventory_receipts/${mcnBatchId}`;
  return <TableResourceLink
    href={INVENTORY_RECEIPT_DETAIL_URL}
    text={mcnBatchId}
  />
}