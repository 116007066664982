export default class DateUtils {
    static parseDateOrGetDefault(date: string | undefined) {
        try {
            if (date === undefined) {
                return new Date();
            }
            return new Date(date)
        } catch (e) {
            return new Date();
        }
    }
}