import { useCallback, useContext, useState } from "react";
import API from "@aws-amplify/api";
import { StatusCodes } from "http-status-codes";

import { ApiError } from "@common/exception";

import { constructButaneApiName } from "../../../apiConfig";
import { RegionContext } from "@common/RegionContext";
import Auth from "@aws-amplify/auth";
import { UserInformation } from "../utils/Utils";

const USERS_DATA_KEY = "users";

export function useDeleteUsers() {
  const [error, setError] = useState<ApiError | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<UserInformation[]>([]);
  const regionContext = useContext(RegionContext);

  const deleteUsers = useCallback(
    async (requestBody: any) => {
      if (!requestBody) {
        return;
      }

      const API_CONFIG = {
        // Return entire Axios response instead of response.data
        response: true,
        headers: {
          Authorization: `${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        body: requestBody
      };

      setIsLoading(true);
      setError(null);

      const apiUrl = `/api/v1/authn/delete/users`;

      try {
        const response = await API.del(
          constructButaneApiName(regionContext.region),
          apiUrl,
          API_CONFIG
        );
        let deleteUsersResponse = (response?.data[USERS_DATA_KEY] ??
          []) as unknown as UserInformation[];
        setData(deleteUsersResponse);
      } catch (exception) {
        const apiError = getApiError(exception);
        setError(apiError);
        setData([]);
      }
      setIsLoading(false);

      function getApiError(error: any) {
        if (typeof error.response === "undefined") {
          return new ApiError(`Network Error.`, StatusCodes.BAD_GATEWAY);
        }
        if (error?.response?.status === StatusCodes.BAD_REQUEST) {
          return new ApiError(
            `Invalid request for Deleting users.`,
            StatusCodes.BAD_REQUEST,
            error.response.data[USERS_DATA_KEY]
          );
        }
        if (error?.response?.status === StatusCodes.NOT_FOUND) {
          return new ApiError(`User(s) does not exist.`, StatusCodes.NOT_FOUND);
        }

        return new ApiError(
          `Error while deleting users, please try again later.`,
          StatusCodes.INTERNAL_SERVER_ERROR
        );
      }
    },
    [regionContext.region]
  );

  return {
    error,
    isLoading,
    deleteUsersResponse: data,
    deleteUsers
  };
}
