import React, { Component } from "react";

/* Import Components */
import PurchaseOrderInfoForm from "./PurchaseOrderInfoForm";
import PurchaseOrderItemsDetail from "./PurchaseOrderItemsDetail";

class PurchaseOrderInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orderId: '',
            warehouseId: ''
        }
    }

    getFormInput = (orderId, warehouseId) => {
        this.setState(() => ({
            orderId: orderId,
            warehouseId: warehouseId
        }));
    }

    render() {
        if (this.props.isAuthenticated) {
            return (
                <div>
                    <PurchaseOrderInfoForm
                        orderId={this.state.orderId}
                        warehouseId={this.state.warehouseId}
                        getFormInput={this.getFormInput}
                    />
                    <PurchaseOrderItemsDetail
                        orderId={this.state.orderId}
                        warehouseId={this.state.warehouseId}
                    />
                </div>
            );
        }
        this.props.history.push("/login");
        return null;
    }
}

export default PurchaseOrderInfo;