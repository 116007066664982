import React from "react";
import Box from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";

interface TableEmptyStateProps {
  resourceName: string;
  resourceNamePlural: string;
  showCreateResourceButton: boolean;
  onCreateResourceClick?: () => void;
}

export function TableEmptyState({
  resourceName,
  resourceNamePlural,
  showCreateResourceButton,
  onCreateResourceClick
}: TableEmptyStateProps) {
  function shouldDisplayCreateResourceButton() {
    return showCreateResourceButton && !!onCreateResourceClick;
  }

  return (
    <Box textAlign="center" color="inherit">
      <b>No {resourceNamePlural}</b>
      <Box padding={{ bottom: "s" }} variant="p" color="inherit">
        No {resourceNamePlural} to display.
      </Box>
      {shouldDisplayCreateResourceButton() && (
        <Button onClick={onCreateResourceClick}>Create {resourceName}</Button>
      )}
    </Box>
  );
}
