import React from "react";
import "./opConfigFormTitle.css";

function OpConfigFormTitle(props) {
  return (
    <div className="formTitle">
      <p>{props.title}</p>
      <p className="description">{props.description}</p>
    </div>
  );
}

export default OpConfigFormTitle;
