/**
 * Model which holds the definition of a `TransferManifestItemIdentifier`
 * (as defined in `TransferManifestServicePersistence`: https://tiny.amazon.com/s2pu1b3d/codeamazpackTranblobheadmain).
 */
export class TransferManifestItemIdentifier {
  public readonly asin!: string;

  public readonly fcsku!: string;

  public readonly transferRequestId!: string;

  public readonly inventoryConditionCode!: string;

  public readonly itemLocation!: string;
}
