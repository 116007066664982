import {useCallback, useState} from "react";
import API from "@aws-amplify/api";
import { StatusCodes } from "http-status-codes";

import { ApiError } from "@common/exception";

import Auth from "@aws-amplify/auth";
import { UserInformation } from "../utils/Utils";
import {constructButaneApiName} from "../../../apiConfig";
import {getEnvironment} from "@modules/signup/Utils";

const USERS_DATA_KEY = "users";

export function useInviteUsers() {
  const [error, setError] = useState<ApiError | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<UserInformation[]>([]);

  const inviteUsers = useCallback(async (requestBody: any) => {
    if (!requestBody) {
      return;
    }
    const API_CONFIG = {
      // Return entire Axios response instead of response.data
      response: true,
      headers: {
        Authorization: `${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      },
      body: requestBody,
    };

    setIsLoading(true);
    setError(null);

    const apiUrl = `/api/v1/authn/invite/users`;

    try {
      const response = await API.post(
        constructButaneApiName(getEnvironment() === 'beta' ? 'dub' : 'iad'),
        apiUrl,
        API_CONFIG
      );

      let inviteUsersResponse = 
        (response?.data[USERS_DATA_KEY] ??
          []) as unknown as UserInformation[]
        setData(inviteUsersResponse);
    } catch (exception) {
      const apiError = getApiError(exception);

      setError(apiError);
      setData([]);
    }
    setIsLoading(false);

    function getApiError(error: any) {
      if (error.response === 'undefined'){
        return new ApiError(
          `Network Error.`,
          StatusCodes.BAD_REQUEST,
        );
      }
      if (error?.response?.status === StatusCodes.BAD_REQUEST) {
        return new ApiError(
          `Invalid request for Inviting users.`,
          StatusCodes.BAD_REQUEST,
          error.response.data[USERS_DATA_KEY]
        );
      }
      if (error?.response?.status === StatusCodes.NOT_FOUND) {
        return new ApiError(
          `Failed to invite users.`,
          StatusCodes.NOT_FOUND
        );
      }

      return new ApiError(
        `Error while inviting users, please try again later.`,
        StatusCodes.INTERNAL_SERVER_ERROR
      );
    }
  }, []);

  return {
    error,
    isLoading,
    inviteUsersResponse: data,
    inviteUsers
  };
}
