import {StatusCodes} from "http-status-codes";
import {ApiError} from "@common/exception";

const MESSAGE_DATA_KEY = "message";

export class CommonUtils {
  static getApiError(error: any, siteName: string, itemType: string) {
    if (error?.response?.status === StatusCodes.BAD_REQUEST) {
      return new ApiError(
        `Invalid request for site ${siteName}.`,
        StatusCodes.BAD_REQUEST,
        error.response.data[MESSAGE_DATA_KEY]
      );
    }
    if (error?.response?.status === StatusCodes.NOT_FOUND) {
      return new ApiError(
        `No ${itemType} found for site ${siteName}.`,
        StatusCodes.NOT_FOUND
      );
    }

    return new ApiError(
      `Error while retrieving ${itemType}s for site ${siteName}, please try again later.`,
      StatusCodes.INTERNAL_SERVER_ERROR
    );
  }
}