import React from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import ShipmentItem from "@modules/shipment/model/ShipmentItem";
import { getHeaderCounterText, TableEmptyState, TableHeader } from "@common/table";
import { TableProps } from "@amzn/awsui-components-react";
import { StandaloneValue } from "@common/presentation";

interface ShipmentItemsTableProps {
  shipmentStatus: string;
  shipmentItems: ShipmentItem[];
}

export function ShipmentItemsTable({
   shipmentStatus,
   shipmentItems
}: ShipmentItemsTableProps) {

  function Header() {
    return <ShipmentItemsTableHeader shipmentStatus={shipmentStatus} shipmentItems={shipmentItems} />;
  }

  function ShipmentItemsTableHeader({
    shipmentStatus,
    shipmentItems
    }: ShipmentItemsTableProps) {
    return (
        <TableHeader
            actions={<></>}
            counterText={getHeaderCounterText(shipmentItems)}
            children={<>Shipment Items</>}
            description={`Items under the shipment`}
        />
    );
  }

  function getItemStatus(shipmentStatus: string, itemStatus: string) {
        if (shipmentStatus === 'PENDING') {
            return itemStatus;
        } else if (shipmentStatus === 'COMPLETED') {
                return 'SHIPPED';
        } else {
            return 'CANCELLED';
        }
  }

  function getPackageInfo(shipmentItem: ShipmentItem) {
      if (shipmentItem.packageId == null) {
          return '';
      } else {
          return `${shipmentItem.packageId}`;
      }
  }

  function getPackageTrackingInfo(shipmentItem: ShipmentItem) {
      if (shipmentItem.packageId == null) {
          return '';
      } else {
          return `ShipMethod: ${shipmentItem.packageShipMethod} | TrackingId: ${shipmentItem.packageTrackId}`;
      }
   }

  const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<ShipmentItem>[] =
    [
        {
            id: "asin",
            header: "ASIN",
            cell: (item) => (
                <StandaloneValue value={item?.asin} />
            ),
            minWidth: "60px"
        },
        {
            id: "quantity",
            header: "Quantity",
            cell: (item) => <StandaloneValue value={item.quantity} />,
            minWidth: "60px"
        },
        {
            id: "itemStatus",
            header: "Item Status",
            cell: (item) => <StandaloneValue value={getItemStatus(shipmentStatus, item.itemStatus)} />,
            minWidth: "60px"
        },
        {
            id: "packageId",
            header: "Corresponding Package ID",
            cell: (item) => <StandaloneValue value={getPackageInfo(item)} />,
            minWidth: "60px"
        },
        {
            id: "packageInfo",
            header: "Package Tracking Info",
            cell: (item) => <StandaloneValue value={getPackageTrackingInfo(item)} />,
            minWidth: "80px"
        }
    ];

  return (
    <>
      <Table<ShipmentItem>
        trackBy={"packageId"}
        header={<Header />}
        wrapLines={true}
        items={shipmentItems}
        columnDefinitions={COLUMN_DEFINITIONS}
        empty={
          <TableEmptyState
            resourceName="Shipment Item"
            resourceNamePlural="Shipment Items"
            showCreateResourceButton={false}
          />
        }
      />
    </>
  );
}
