import {RouteComponentProps} from "react-router-dom";
import React, {useState} from "react";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import {FcSkuInventoryAuditInput} from "@modules/inventory/audits/fcsku/FcSkuInventoryAuditInput";
import {FcSkuInventoryAuditsTable} from "@modules/inventory/audits/fcsku/table/FcSkuInventoryAuditsTable";
import DateUtils from "@common/date/DateUtils";

interface FcSkuInventoryAuditsPageProps extends RouteComponentProps<{fcsku: string}> {
    isAuthenticated: boolean,
}
export const FcSkuInventoryAuditsPage = (props: FcSkuInventoryAuditsPageProps) => {
    const [renderTable, setRenderTable] = useState(false);

    if (!props.isAuthenticated) {
        props.history.push("/login");
    }

    const urlParams = new URLSearchParams(window.location.search);
    const fcSku = props.match.params.fcsku;
    const warehouse = urlParams.get('warehouse') ?? "";
    const startDate = DateUtils.parseDateOrGetDefault(urlParams.get('startDate') ?? undefined);
    const endDate = DateUtils.parseDateOrGetDefault((urlParams.get('endDate') ?? undefined));

    return <div>
        <SpaceBetween size={"s"}>
            <FcSkuInventoryAuditInput
                {...props}
                fcSku={fcSku}
                warehouse={warehouse}
                startDate={startDate}
                endDate={endDate}
                renderTable={setRenderTable}
            />
            {
                renderTable &&
                <FcSkuInventoryAuditsTable
                    {...props}
                    fcSku={fcSku}
                    warehouse={warehouse}
                    startDate={startDate}
                    endDate={endDate}
                />
            }
        </SpaceBetween>
    </div>;
}
