import React from "react";
import PropTypes from "prop-types";
import { orange } from "@material-ui/core/colors";
import DeleteIcon from "@material-ui/icons/Delete";
import LiveHelpSharpIcon from "@material-ui/icons/LiveHelpSharp";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

class ContentEditable extends React.Component {
  static propTypes = {
    onChange: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      editDisabled: this.props.editDisabled,
      showEditOption: this.props.showEditOption,
      showDeleteOption: this.props.showDeleteOption,
      paramName: this.props.paramName,
      paramValue: this.props.paramValue,
      description: this.props.description,
      updatedParamName: undefined,
      updatedParamValue: undefined,
      container: this.props.container,
      schemaName: this.props.schemaName,
      version: this.props.version
    };
  }

  onKeyUpdate = e => {
    e.preventDefault();
    const value = e.target.value.trim();
    let paramValue;
    if (typeof this.state.updatedParamValue !== "undefined") {
      paramValue = this.state.updatedParamValue;
    } else {
      paramValue = this.state.paramValue;
    }
    this.props.onChange(
      value,
      paramValue,
      this.props.index,
      this.isValidParameterName(value),
      this.props.container,
      this.props.schemaName,
      this.props.version
    );
    this.setState({ updatedParamName: value });
  };

  onValueUpdate = e => {
    e.preventDefault();
    const value = e.target.value.trim();
    let paramName;
    if (typeof this.state.updatedParamName !== "undefined") {
      paramName = this.state.updatedParamName;
    } else {
      paramName = this.state.paramName;
    }
    this.props.onChange(
      paramName,
      value,
      this.props.index,
      this.isValidParameterName(paramName),
      this.props.container,
      this.props.schemaName,
      this.props.version
    );
    this.setState({ updatedParamValue: value });
  };

  isValidParameterName = parameterName => {
    return parameterName !== null && parameterName !== "";
  };

  enableEdit = () => {
    this.setState({ editDisabled: false });
  };

  handleDelete = () => {
    this.props.onClick();
  };

  render() {
    return (
      <div className="row">
        <div className="column left">
          <input
            className="config-input-box"
            type="text"
            name={this.state.paramName}
            defaultValue={this.state.paramName}
            onChange={this.onKeyUpdate}
            disabled={this.state.editDisabled}
          />
        </div>
        <div className="column middle">
          {this.state.description && (
            <Tooltip title={this.state.description}>
              <IconButton aria-label="description">
                <LiveHelpSharpIcon
                  style={{ color: orange[300], fontSize: 25 }}
                />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <div className="column middle1">
          <input
            className="config-input-box"
            type="text"
            name={this.state.paramName}
            defaultValue={this.state.paramValue}
            onChange={this.onValueUpdate}
            disabled={this.state.editDisabled}
          />
        </div>
        <div className="column right">
          {this.state.showEditOption && (
            <Tooltip title="Edit">
              <IconButton aria-label="edit" onClick={this.enableEdit}>
                <EditIcon style={{ fontSize: 30 }} color="primary" />
              </IconButton>
            </Tooltip>
          )}

          {this.state.showDeleteOption && (
            <Tooltip title="Delete">
              <IconButton aria-label="delete" onClick={this.handleDelete}>
                <DeleteIcon style={{ fontSize: 30 }} color="primary" />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    );
  }
}

export default ContentEditable;
