import React, { Component } from "react";

import CancelTransferRequestForm from "./CancelTransferRequestForm";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import Link from "@amzn/awsui-components-react/polaris/link";
import Box from "@amzn/awsui-components-react/polaris/box";

import API from "@aws-amplify/api";

import './CancelTransferRequest.css'
import { getAuthorizationHeader } from "@common/Auth";
import UnAuthorizedAccessAlert from "../../../views/layouts/UnAuthorizedAccessAlert";
import {RegionContext} from '@common/RegionContext';
import {constructButaneApiName} from '../../../apiConfig';
import { StatusCodes } from "http-status-codes";
import UnauthorizedUser from "../../../views/layouts/UnauthorizedUser";


class CancelTransferRequest extends Component {
    constructor(props) {
        super(props);

        this.state = {
            warehouseId: '',
            transferRequestIds: [],
            successMessage: undefined,
            errorMessage: undefined,
            cancellationFailedTransferRequestIds: [],
            cancellationSuccessfulTransferRequestIds: [],
            unAuthorizedForBackend: false
        }
    }

    handleSubmit = async (warehouseId, transferRequestIds) => {
        const postData = {
            warehouseId: warehouseId,
            transferRequestIds: transferRequestIds
        }

        API.post(constructButaneApiName(this.context.region), `/transfer/cancel`, { body: postData,
                headers: await getAuthorizationHeader()
            })
            .then(response => {
                let cancellationSuccessfulTransferRequestIds = transferRequestIds
                .filter(transferRequestId => !response.failedTransferRequestIds.includes(transferRequestId))

                this.setState(() => ({
                    warehouseId: warehouseId,
                    transferRequestIds: transferRequestIds,
                    successMessage: `Initiated Transshipment Cancellation successfully for Warehouse : ${warehouseId}
                                     and Transfer Request Ids:`,
                    errorMessage: undefined,
                    cancellationFailedTransferRequestIds: response.failedTransferRequestIds,
                    cancellationSuccessfulTransferRequestIds : cancellationSuccessfulTransferRequestIds
                }));
            }).catch((error) => {
                this.setState(() => ({
                    warehouseId: warehouseId,
                    transferRequestIds: transferRequestIds,
                    errorMessage: `Unable to cancel transshipment due to: ${error}`,
                    successMessage: undefined,
                    cancellationFailedTransferRequestIds: [],
                    cancellationSuccessfulTransferRequestIds: [],
                }));
                if (error?.response?.status === StatusCodes.FORBIDDEN) {
                    this.setState({
                        unAuthorizedForBackend: true
                    })
                }
            });
    }

    renderTransferRequestIdsAsList(transferRequestIds) {
        if (transferRequestIds !== undefined){
            return transferRequestIds.map(requestId => {
                return (
                    <li key={requestId}>
                        <Link href={"https://efpm.amazon.com/outbounds/transfer_out?id=" + requestId}>{requestId}</Link>
                        <br/>
                    </li>
                )
            })
        }
    }

    renderAlerts() {
        if (this.state.errorMessage !== undefined) {
            return (
                <>
                    <Alert
                        statusIconAriaLabel="Error"
                        type="error"
                        header="Error">
                        {this.state.errorMessage}
                    </Alert>
                    <UnauthorizedUser openErrorSnackbar={this.state.unAuthorizedForBackend.toString()}/>
                </>
            );
        } else if (this.state.successMessage !== undefined) {
            const header = this.state.cancellationFailedTransferRequestIds.length === 0 ? "Success" : "Partial Success";
            return (
                <Alert
                    statusIconAriaLabel="Info"
                    type="info"
                    header={header}>
                    {this.state.successMessage}
                    <br/>
                    { this.renderTransferRequestIdsAsList(this.state.cancellationSuccessfulTransferRequestIds) }
                    {this.renderPartialFailureMessage()}
                </Alert>
            );
        }
    }

    renderPartialFailureMessage() {
        if(this.state.cancellationFailedTransferRequestIds.length === 0) {
            return (<></>);
        }
        return (
            <>
                <br/>
                Unable to initiate Transshipment Cancellation for Warehouse: {this.state.warehouseId} and below Transfer Request Ids
                { this.renderTransferRequestIdsAsList(this.state.cancellationFailedTransferRequestIds) }
            </>
        );
    }

    render() {
        if(this.props.isExternal){
            return(
              <div>
                  <UnAuthorizedAccessAlert/>
              </div>
            )
        }
        if (this.props.isAuthenticated) {
            return (
                <div className="select-container">
                    <Box margin={{ vertical: "l", horizontal: "l" }}>
                        <CancelTransferRequestForm
                            warehouseId={this.state.warehouseId}
                            transferRequestId={this.state.transferRequestId}
                            handleSubmit={this.handleSubmit} />
                        { this.renderAlerts() }
                    </Box>
                </div>
            );
        }
        this.props.history.push("/login");
        return null;
    }
}

CancelTransferRequest.contextType = RegionContext;

export default CancelTransferRequest;