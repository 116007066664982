import React from "react";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from "react-select";

const MultiField = ({value, handleChange, onAdd, onDelete, paramTypeList, errorIndex}) => {
    const getFieldItems = value.map((data, index) => {
        let extraComponent = null;

        if(data.type.value === "int") {
            extraComponent = (
                <>
                    <input 
                        className="schema-input-small"
                        placeholder="Min"
                        value={data.min}
                        onChange={(e) => {e.preventDefault(); handleChange(index, "min", e.target.value.trim())}}
                    />
                    <input 
                        className="schema-input-small"
                        placeholder="Max"
                        value={data.max}
                        onChange={(e) => {e.preventDefault(); handleChange(index, "max", e.target.value.trim())}}
                    />
                </>
            )
        }

        if(data.type.value === "list") {
            extraComponent = (
                <input 
                    className="schema-input-medium"
                    placeholder="Valid values"
                    value={data.validValues}
                    onChange={(e) => {e.preventDefault(); handleChange(index, "validValues", e.target.value)}}
                />
            )
        }

        let multiFieldClasses = (errorIndex === index) ? "config-schema-fields-field error-fields" : "config-schema-fields-field";
        return (    
            <div className={multiFieldClasses} key={index}>
                 <input 
                    className="fields-input display-inline"
                    placeholder="Param Name"
                    value={data.param}
                    onChange={(e) => {e.preventDefault() ; handleChange(index, "param", e.target.value.trim())} }
                />

                <Select 
                    className="schema-field-select display-inline"
                    value={data.type}
                    options={paramTypeList}
                    onChange={(selected) => handleChange(index, "type", selected)}
                />

                {extraComponent}

                
                <FormControlLabel
                    className="schema-switch-box"
                    control={
                    <Switch
                        checked={data.required}
                        color="primary"
                        onChange={(e) => {e.preventDefault(); handleChange(index, "required", e.target.checked)}}
                    />
                    }
                    label="Required"
                />

                <IconButton
                    className="config-schema-icon-delete-button display-inline"
                    onClick={(e) => onDelete(index)}
                >
                    <DeleteIcon fontSize="large" />
                </IconButton>
            </div>
        );
    })

    return (
        <div className="config-field display-inline">
            <div className="config-schema-fields">
                {getFieldItems}
            </div>
            <div className="config-field-add">
                <IconButton
                    className="config-schema-icon-add-button"
                    onClick={(e) => onAdd()}
                >
                    <AddCircleIcon fontSize="large" />
                </IconButton>
            </div>
        </div>
    )
}

export default MultiField;  