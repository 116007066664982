import React, {Component} from 'react';
import API from "@aws-amplify/api";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import CancelIcon from '@material-ui/icons/Cancel';
import PageviewIcon from '@material-ui/icons/Pageview'
import Button from "@material-ui/core/Button";
import {fetchRemovalShipmentDetailsColumns} from '../../constants/table/metadata/RemovalShipmentDetailsColumn';
import Tooltip from "@material-ui/core/Tooltip";
import {fetchArsenalLink} from "../../constants/FetchArsenalLink";
import dateFormat from "dateformat";
import {fetchCancelShipmentLink} from "../../constants/FetchCancelShipmentLink";
import {CircularProgress} from "@material-ui/core";
import RemovalShipmentDetailsInfo from "./model/RemovalShipmentDetailsInfo";
import MaterialUITable from "../../components/Table/MaterialTable";
import { getAuthorizationHeader } from '@common/Auth';
import {RegionContext} from '@common/RegionContext';
import {constructButaneApiName} from '../../apiConfig';
import {getEnvironment} from '@modules/signup/Utils';


const formatOfDate = "yyyy-mm-dd";
const MAX_SHIPMENT_IDs = 10; // removals API can fetch details of 10 shipment Ids in one call

class RemovalShipmentDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            removalShipmentDetailsSnapshot: [],
            isShipmentTabSelected: false,
            isUnifiedFRACS: false,
            fetchingRecords: false,
        };
    }

    async fetchRemovalShipmentDetailsData(warehouseId, removalShipmentIds) {

        let removalShipmentDetailsList = [];
        for (let i = 0; i < removalShipmentIds.length; i = i + MAX_SHIPMENT_IDs) {
            let shipmentIds = [];
            for (let j = i; j < i + MAX_SHIPMENT_IDs && j < removalShipmentIds.length; j++) {
                shipmentIds.push(removalShipmentIds[j].shipmentId);
            }
            API.get(constructButaneApiName(this.context.region), `/api/removal/shipment-details/${warehouseId}?shipmentIds=${shipmentIds}`, {
                    headers: await getAuthorizationHeader()
                })
                .then((data) => {
                    let shipmentDetails = data.response;

                    for (let j = 0; j < shipmentIds.length; j++) {

                        let shipmentDetail = shipmentDetails[shipmentIds[j]].shipmentInfo;

                        let lastUpdatedDate = null;
                        if (shipmentDetail.lastUpdatedDate !== null)
                            lastUpdatedDate = dateFormat((new Date(shipmentDetail.lastUpdatedDate * 1000)).toString(), formatOfDate);

                        let removalShipmentDetails = new RemovalShipmentDetailsInfo(
                            shipmentDetail.shipmentId,
                            shipmentDetail.state,
                            shipmentDetail.cancellationReason,
                            lastUpdatedDate,
                            shipmentDetail.stateDescription,
                        )

                        removalShipmentDetailsList.push(removalShipmentDetails);

                        this.setState(() => ({
                            isUnifiedFRACS: shipmentDetails[shipmentIds[j]].isUnifiedFRACS,
                        }));

                    }

                    this.setState(() => ({
                        removalShipmentDetailsSnapshot: removalShipmentDetailsList,
                        fetchingRecords: false
                    }));

                }).catch(error => {
                for (let j = 0; j < shipmentIds.length; j++) {
                    let removalShipmentDetails = new RemovalShipmentDetailsInfo(
                        shipmentIds[j],
                        null,
                        null,
                        null,
                        null
                    )

                    removalShipmentDetailsList.push(removalShipmentDetails);

                }
                this.setState(() => ({
                    removalShipmentDetailsSnapshot: removalShipmentDetailsList,
                    fetchingRecords: false
                }));
            });
        }
    }

    tooltip = (title, placement, content) => {
        return (
            <Tooltip title={title}
                     placement={placement}
            >
                {content}
            </Tooltip>
        )
    }

    viewDetailsButton = (rowData) => {
        return (
            <a href={`/shipment/${rowData['shipmentId']}`}
               className="track-shipment"
               target="_blank"
               rel="noopener noreferrer">
                {this.tooltip("View Shipment Details",
                    "top",
                    <Button variant="contained" size="small" startIcon={<PageviewIcon style={{color: 'blue'}}/>}
                    >
                        View Details

                    </Button>)}
            </a>
        )
    }

    trackButton = (rowData) => {
        return (
            <a href={`${fetchArsenalLink(this.context.region, getEnvironment())}search?id=${rowData['shipmentId']}`}
               className="track-shipment"
               target="_blank"
               rel="noopener noreferrer">
                {this.tooltip("Track Shipment",
                    "top",
                    <Button className="track-button" variant="contained" size="small" startIcon={<LocalShippingIcon/>}
                    >
                        TRACK

                    </Button>)}
            </a>
        )
    }

    cancelButton = () => {
        return (
            <a href={`${fetchCancelShipmentLink(getEnvironment())}`}
               className="cancel-shipment"
               target="_blank"
               rel="noopener noreferrer">
                {this.tooltip("Cancel Shipment",
                    "top",
                    <Button variant="contained" className="cancel-button" size="small"
                            startIcon={<CancelIcon style={{color: 'orangered'}}/>}
                    >
                        CANCEL
                    </Button>)}
            </a>
        )
    }


    getStateDescription = (rowData) => {
        if (rowData !== undefined) {
            let state = (rowData['state'] === 'NOT_FOUND') ? '_' : rowData['state'];
            return (
                this.tooltip(rowData['stateDescription'],
                    "top",
                    <div>{state}</div>)
            )
        }
        return null
    }

    getAction = (rowData) => {
        if (this.state.isUnifiedFRACS === true && rowData !== undefined && rowData['state'] !== null && rowData['state'] !== "NOT_FOUND") {
            if (rowData['state'] !== "CANCELLED" && rowData['state'] !== "COMPLETED") {
                return (
                    <div>
                        {this.viewDetailsButton(rowData)}
                        {this.trackButton(rowData)}
                        {this.cancelButton()}
                    </div>
                )
            } else {
                return (
                    <div>
                        {this.viewDetailsButton(rowData)}
                        {this.trackButton(rowData)}
                    </div>
                )
            }
        }
        return null
    }


    componentDidUpdate(prevProps) {
        if (this.props.selectedTab === "shipmentDetails" && this.state.isShipmentTabSelected === false && this.props.removalShipmentIdsSnapshot.length > 0) {
            this.setState(() => ({
                fetchingRecords: true,
                isShipmentTabSelected: true
            }));
            this.fetchRemovalShipmentDetailsData(this.props.warehouseId, this.props.removalShipmentIdsSnapshot);
        }
        if (prevProps.orderId !== this.props.orderId || prevProps.warehouseId !== this.props.warehouseId) {
            this.setState(() => ({
                isShipmentTabSelected: false
            }));
        }
    }

    renderShipmentDetails() {
        const columnMetadata = fetchRemovalShipmentDetailsColumns(this.getStateDescription, this.getAction);
        return (
            <MaterialUITable
                results={this.state.removalShipmentDetailsSnapshot}
                columnMetadata={columnMetadata}
                title={"Shipment Details"}
            />
        )
    }

    render() {
        if (!!this.state.fetchingRecords) {
            return <CircularProgress className='circular-progress'/>
        }
        return (
            <div>{this.renderShipmentDetails()}</div>
        );
    }
}

RemovalShipmentDetails.contextType = RegionContext;

export default RemovalShipmentDetails;
