import {RouteComponentProps, withRouter} from "react-router";
import React, {useEffect, useState} from "react";
import {useGetInventoryReceipts} from "@modules/inbound/inventoryReceiveNotification/hook";
import {useFlashbar} from "@common/flashbar";
import {DateRangePickerProps} from "@amzn/awsui-components-react";
import {useCollection} from "@amzn/awsui-collection-hooks";
import {getFilterCounterText, TableEmptyState, TableNoMatchState} from "@common/table";
import {
  COLUMN_DEFINITIONS,
  DEFAULT_PREFERENCES,
  FILTERING_PROPERTIES,
  LOADING_TEXT,
  Preferences,
  PROPERTY_FILTERING_LABELS,
  TRACK_BY_COLUMN_NAME
} from "@modules/inbound/inventoryReceiveNotification/table/inventoryReceipt/config";
import Table from "@amzn/awsui-components-react/polaris/table";
import {TransferDepartRequestsDateRangeFilter} from "@modules/transfer";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import {StatusCodes} from "http-status-codes";
import {
  InventoryReceiptsHeader
} from "@modules/inbound/inventoryReceiveNotification/table/inventoryReceipt/InventoryReceiptsHeader";
import PropertyFilter from "@amzn/awsui-components-react/polaris/property-filter";
import {DateRangePickerConverter} from "@modules/transfer/departure-requests/logic";

const SITE_SELECTION_URL = `/receive/inventory_receipts`;

interface InventoryReceiveRequestsTableRouteParams {
  siteName: string;
}

interface InventoryReceiveRequestsTableProps extends RouteComponentProps<InventoryReceiveRequestsTableRouteParams> {
}

export const InventoryReceiptsTable = withRouter(
  ({history, match}: InventoryReceiveRequestsTableProps) => {
    const {
      error,
      isLoading,
      inventoryReceipts,
      getInventoryReceipts
    } = useGetInventoryReceipts();
    const {addMessage, clearAllMessages} = useFlashbar();
    const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
    const [rangeFilter, setRangeFilter] =
      useState<DateRangePickerProps.Value | null>(null);

    async function handleRefreshClick() {
      clearAllMessages();
      const dateRange = DateRangePickerConverter.getDateRange(
        rangeFilter as DateRangePickerProps.Value
      );
      await getInventoryReceipts(match?.params.siteName, dateRange);
    }

    useEffect(() => {
      if (error) {
        switch (error.statusCode) {
          case StatusCodes.NOT_FOUND:
            return addMessage({
              header: error.message,
              type: "warning"
            });
          default:
            return addMessage({
              header: error.message,
              content: error.details ?? null,
              type: "error",
              buttonText: "Retry",
              dismissible: true,
              onButtonClick: async () => {
                await handleRefreshClick();
              }
            });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    function siteNotPresent() {
      return !match?.params?.siteName;
    }

    if (siteNotPresent()) {
      history.push(SITE_SELECTION_URL);
    }

    const {
      items,
      actions,
      filteredItemsCount,
      collectionProps,
      paginationProps,
      propertyFilterProps
    } = useCollection(
      inventoryReceipts, {
        propertyFiltering: {
          filteringProperties: FILTERING_PROPERTIES,
          empty: (
            <TableEmptyState
              showCreateResourceButton={false}
              resourceName="Inventory Receipt"
              resourceNamePlural="Inventory Receipts"
            />
          ),
          noMatch: (
            <TableNoMatchState
              onClearFilterClick={() => {
                actions.setPropertyFiltering({tokens: [], operation: "and"});
              }}
            />
          )
        },
        pagination: {pageSize: preferences.pageSize},
        sorting: {
          defaultState: {
            isDescending: true,
            sortingColumn: {sortingField: "requestDate"}
          }
        },
        selection: {}
      });

    useEffect(() => {
      if (rangeFilter) {
        handleRefreshClick();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match?.params?.siteName, rangeFilter]);

    return (
      <>
        <Table
          {...collectionProps}
          items={items}
          loading={isLoading}
          trackBy={TRACK_BY_COLUMN_NAME}
          selectionType="single"
          columnDefinitions={COLUMN_DEFINITIONS}
          visibleColumns={preferences.visibleContent}
          loadingText={LOADING_TEXT}
          wrapLines={preferences.wraplines}
          pagination={<Pagination {...paginationProps} />}
          preferences={
            <Preferences
              preferences={preferences}
              setPreferences={setPreferences}
            />
          }
          header={
            <InventoryReceiptsHeader
              items={inventoryReceipts}
              isLoading={isLoading}
              siteName={match?.params?.siteName}
              onRefreshClick={async () => await handleRefreshClick}
            />
          }
          filter={
            <div className="filter-container">
              <div className="date-range-filter">
                <TransferDepartRequestsDateRangeFilter
                  rangeFilter={rangeFilter}
                  onRangeFilterChange={(e) => setRangeFilter(e.value)}
                />
              </div>
              <PropertyFilter
                {...propertyFilterProps}
                i18nStrings={PROPERTY_FILTERING_LABELS}
                countText={getFilterCounterText(filteredItemsCount)}
              />
            </div>
          }
        />
      </>);
  }
);
