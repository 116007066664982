import React, {Component} from "react";
import PageTitle from "../../components/PageTitle/PageTitle";

class PageNotFound extends Component {
    render () {
        return (
            <PageTitle>
                Page Not Found !
            </PageTitle>
        )
    }
}

export default PageNotFound;