import {TableEmptyState} from "@common/table";
import Table from "@amzn/awsui-components-react/polaris/table";
import React, {useContext, useEffect, useState} from "react";
import {useCollection} from "@amzn/awsui-collection-hooks";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import API from "@aws-amplify/api";
import {FcSkuInventoryAuditRecord} from "@modules/inventory/audits/fcsku/model/FcSkuInventoryAuditRecord";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import Header from "@amzn/awsui-components-react/polaris/header";
import Link from "@amzn/awsui-components-react/polaris/link";
import {fetchArsenalLink} from "@constants/FetchArsenalLink";
import {RouteComponentProps} from "react-router-dom";
import { getAuthorizationHeader } from "@common/Auth";
import {constructButaneApiName} from "../../../../../apiConfig";
import {RegionContext} from "@common/RegionContext";
import {getEnvironment} from "@modules/signup/Utils";

interface FcSkuInventoryAuditsTableProps extends RouteComponentProps {
    fcSku: string,
    warehouse: string,
    startDate: Date,
    endDate: Date,
}

export const FcSkuInventoryAuditsTable = (props: FcSkuInventoryAuditsTableProps) => {
    const [fetchingAudits, setFetchingAudits] = useState(false);
    const [fcSkuInventoryAuditRecords, setFcSkuInventoryAuditRecords] = useState<FcSkuInventoryAuditRecord[]>([]);
    const [errorMessage, setErrorMessage] = useState("");
    const regionContext = useContext(RegionContext);

    useEffect(() => {
        setFetchingAudits(false);
        setFcSkuInventoryAuditRecords([]);
        setErrorMessage("")
        getFcSkuInventoryAuditRecords(props);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const roundStartDate = (date: Date) => {
        date.setHours(0);
        date.setMinutes(0, 0, 0);
        return date;
    }

    const roundEndDate = (date: Date) => {
        date.setHours(23);
        date.setMinutes(59, 59, 0);
        return date;
    }

    const getFcSkuInventoryAuditRecords = async (props: FcSkuInventoryAuditsTableProps) => {
        setFetchingAudits(true);
        const epochStartDate = roundStartDate(props.startDate).getTime()/1000;
        const epochEndDate = roundEndDate(props.endDate).getTime()/1000;

        API.get(constructButaneApiName(regionContext.region), `/api/inventory/audits/fcsku/${props.fcSku}/?warehouse=${props.warehouse}&startDate=${epochStartDate}&endDate=${epochEndDate}`, {
                headers: await getAuthorizationHeader()
            })
            .then((data) => {
                return data.fcSkuAuditRecords.forEach(audit => {
                    setFcSkuInventoryAuditRecords(prevState => {
                        return [...prevState, {
                            fnSku: audit.fnSku,
                            fcSku: audit.fcSku,
                            inventoryCondition: audit.inventoryCondition,
                            status: audit.status,
                            messageId: audit.messageId,
                            messageType: audit.messageType,
                            entryDate: new Date(audit.entryDate*1000),
                            deltaQuantity: audit.deltaQuantity,
                        }]
                    })
                });
            }, () => {
                setErrorMessage("Error occurred when fetching FCSKU audit records.")
            }).finally(() => {
            setFetchingAudits(false);
        });
    }

    const {
        items,
        filterProps,
        paginationProps,
        collectionProps,
    } = useCollection(fcSkuInventoryAuditRecords, {
        filtering: {},
        pagination: {
            pageSize: 10
        },
        sorting: {
            defaultState: {
                isDescending: true,
                sortingColumn: {sortingField: "entryDate"}
            }
        }
    });

    const redirectToFcSkuTracking = (warehouse: string, fcSku: string) => {
        props.history.push(`/fcsku/details?warehouse=${warehouse}&attributeName=FCSKU&attributeValue=${fcSku}`);
    }

    return <div>
        {
            errorMessage &&
            <Alert
                type={"error"}
                dismissible={true}
                onDismiss={() => setErrorMessage("")}
            >
                {errorMessage}
            </Alert>
        }
        <Table
            {...collectionProps}
            header={
                <Header>
                    FCSKU Inventory Audits
                </Header>
            }
            loading={fetchingAudits}
            loadingText={"Fetching FCSKU Inventory Audit Records"}
            items={items}
            empty={
                <TableEmptyState
                    resourceName={"FCSKU Inventory Audit"}
                    resourceNamePlural={"FCSKU Inventory Audits"}
                    showCreateResourceButton={false}
                />
            }
            columnDefinitions={[
                {
                    id: "entryDate",
                    header: "Entry Date",
                    cell: e => e.entryDate.toLocaleString(),
                    sortingField: "entryDate"
                },
                {
                    id: "fnSku",
                    header: "FNSKU",
                    cell: e => e.fnSku,
                    sortingField: "fnSku"
                },
                {
                    id: "fcSku",
                    header: "FCSKU",
                    cell: e => (<Link onFollow={() => redirectToFcSkuTracking(props.warehouse, props.fcSku)}
                    >{e.fcSku}
                    </Link>),
                    sortingField: "fcSku"
                },
                {
                    id: "inventoryCondition",
                    header: "Inventory Condition",
                    cell: e => e.inventoryCondition,
                    sortingField: "inventoryCondition"
                },
                {
                    id: "status",
                    header: "Status",
                    cell: e => e.status,
                    sortingField: "status"
                },
                {
                    id: "messageType",
                    header: "Message Type",
                    cell: e => e.messageType,
                    sortingField: "messageType"
                },
                {
                    id: "messageId",
                    header: "Message Id",
                    cell: e => (
                        <Link href={fetchArsenalLink(regionContext.region, getEnvironment()) + `search?id=${e.messageId}`}>{e.messageId}</Link>
                    ),
                    sortingField: "messageId"
                },
                {
                    id: "deltaQuantity",
                    header: "Delta Quantity",
                    cell: e => e.deltaQuantity,
                    sortingField: "deltaQuantity"
                }
            ]}
            filter={
                <div>
                    <TextFilter
                        {...filterProps}
                    />
                </div>
            }
            pagination={
                <Pagination
                    {...paginationProps}
                />
            }
        />
    </div>;
}
