import React, { useState } from "react";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";

import { getHeaderCounterText, TableHeader } from "@common/table";
import { FailedDepartItem } from "@modules/transfer/common/model";
import { SelectAmznShipRefIdModal } from "./SelectAmznShipRefIdModal";

const DESCRIPTION = "All displayed times are in UTC.";

interface LegacyFailedDepartItemsTableHeaderProps {
  amznShipRefId: string;
  items: FailedDepartItem[];
}

export function LegacyFailedDepartItemsTableHeader({
  amznShipRefId,
  items
}: LegacyFailedDepartItemsTableHeaderProps) {
  const [displaySelectAmznShipRefIdModal, setDisplaySelectAmznShipRefIdModal] =
    useState(false);

  return (
    <TableHeader
      actions={
        <SpaceBetween size="xs" direction="horizontal">
          <Button
            iconName="settings"
            onClick={() =>
              setDisplaySelectAmznShipRefIdModal((prevState) => !prevState)
            }
          >
            Select Amazon Shipment Reference ID
          </Button>
        </SpaceBetween>
      }
      counterText={getHeaderCounterText(items)}
      children={
        <>
          <SelectAmznShipRefIdModal
            isVisible={displaySelectAmznShipRefIdModal}
            setIsVisible={setDisplaySelectAmznShipRefIdModal}
          />
          Failed Depart Items for {amznShipRefId}
        </>
      }
      description={DESCRIPTION}
    />
  );
}
