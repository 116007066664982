export default class ShipmentSummary {
    constructor(warehouseId, Cond62, Cond3, Cond305, Cond8, Cond5406, Cond5409, Cond36, Cond59, Cond7, Cond13, Cond29, Cond54, Cond4, Cond11, Cond12, Cond32, Cond60, Cond6001, Cond6003, Cond6009, Cond6006, Cond2505, Cond15, Cond1501, MISSEDEXSD, shipmentType) {
        this.warehouseId = warehouseId;
        this.MISSEDEXSD = MISSEDEXSD;
        this.Cond62 = Cond62;
        this.Cond3 = Cond3;
        this.Cond305 = Cond305;
        this.Cond8 = Cond8;
        this.Cond5406 = Cond5406;
        this.Cond5409 = Cond5409;
        this.Cond36 = Cond36;
        this.Cond59 = Cond59;
        this.Cond7 = Cond7;
        this.Cond13 = Cond13;
        this.Cond29 = Cond29;
        this.Cond54 = Cond54;
        this.Cond4 = Cond4;
        this.Cond11 = Cond11;
        this.Cond12 = Cond12;
        this.Cond32 = Cond32;
        this.Cond60 = Cond60;
        this.Cond6001 = Cond6001;
        this.Cond6003 = Cond6003;
        this.Cond6009 = Cond6009;
        this.Cond6006 = Cond6006;
        this.Cond2505 = Cond2505;
        this.Cond15 = Cond15;
        this.Cond1501 = Cond1501;
        this.shipmentType = shipmentType;
    }
}
