import React, { useContext, useMemo } from "react";
import Flashbar from "@amzn/awsui-components-react/polaris/flashbar";

import { FlashbarContext } from "./FlashbarContext";

export function useFlashbar() {
  const context = useContext(FlashbarContext);

  if (context === undefined) {
    throw new Error("useFlashbar must be used within a FlashbarProvider");
  }

  const { addMessage, clearAllMessages, deleteMessage, messages } = context;

  const FlashbarContainer = () =>
    useMemo(() => {
      return <Flashbar items={messages} />;
    }, []);

  return {
    addMessage,
    clearAllMessages,
    deleteMessage,
    FlashbarContainer
  };
}
