import {RouteComponentProps, withRouter} from "react-router-dom";
import {InventoryReceipt} from "@modules/inbound/inventoryReceiveNotification/model";
import {getHeaderCounterText, TableHeader} from "@common/table";
import React, {useState} from "react";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import {Button} from "@amzn/awsui-components-react";
import {SelectSiteModal} from "@modules/inbound/inventoryReceiveNotification/table/inventoryReceipt/SelectSiteModal";

interface InventoryReceiptsHeaderProps extends RouteComponentProps {
  items: InventoryReceipt[];
  isLoading: boolean,
  siteName: string;
  onRefreshClick: () => void;
}

export const InventoryReceiptsHeader = withRouter(
  ({
     items,
     isLoading,
     siteName,
     onRefreshClick
   }: InventoryReceiptsHeaderProps) => {
    const [goToSelectSiteForm, setGoToSelectSiteForm] = useState(false);

    return (
      <TableHeader
        actions={
          <SpaceBetween size="xs" direction="horizontal">
            <Button
              iconName="refresh"
              onClick={onRefreshClick}
              loading={isLoading}
            />
            <Button
              iconName="settings"
              onClick={
                () => setGoToSelectSiteForm((prevState) => !prevState)
              }
            >
              Select Site
            </Button>
          </SpaceBetween>
        }
        counterText={getHeaderCounterText(items)}
        children={
          <>
            <SelectSiteModal
              isVisible={goToSelectSiteForm}
              setIsVisible={setGoToSelectSiteForm}
            />
            Inventory Receipts for {siteName}
          </>
        }
      />
    );
  }
);