import {useCallback, useContext, useState} from "react";
import API from "@aws-amplify/api";
import { StatusCodes } from "http-status-codes";

import { ApiError } from "@common/exception";
import { TransferDepartWorkflow } from "@modules/transfer/common/model";

import { getAuthorizationHeader } from "@common/Auth";
import {RegionContext} from "@common/RegionContext";
import {constructButaneApiName} from "../../../apiConfig";


const TRANSFER_DEPART_WORKFLOW_DETAILS_DATA_KEY = "transferWorkflowDetails";
const MESSAGE_DATA_KEY = "message";

export function useGetTransferDepartWorkflowDetails() {
  const [error, setError] = useState<ApiError | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<TransferDepartWorkflow | null>(null);
  const regionContext = useContext(RegionContext);

  const getTransferDepartWorkflowDetails = useCallback(
    async (workflowId: string) => {
      if (!workflowId) {
        return;
      }

      setIsLoading(true);
      setError(null);

      const apiUrl = `/transfer/workflowDetails/${workflowId}`;
      try {
        const response = await API.get(
          constructButaneApiName(regionContext.region),
          apiUrl, {
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            headers: await getAuthorizationHeader()
          }
        );

        setData(
          response?.data[
            TRANSFER_DEPART_WORKFLOW_DETAILS_DATA_KEY
          ] as unknown as TransferDepartWorkflow
        );
      } catch (exception) {
        console.error(
          `Something went wrong while retrieving Transfer Depart Workflow details for Workflow ID: ${workflowId}`
        );

        const apiError = getApiError(exception);

        setError(apiError);
        setData(null);
      }

      setIsLoading(false);

      function getApiError(error: any) {
        if (error?.response?.status === StatusCodes.BAD_REQUEST) {
          return new ApiError(
            `Invalid request for Workflow ID ${workflowId}.`,
            StatusCodes.BAD_REQUEST,
            error.response.data[MESSAGE_DATA_KEY]
          );
        }
        if (error?.response?.status === StatusCodes.NOT_FOUND) {
          return new ApiError(
            `No Transfer Depart Workflow Details found for Workflow ID ${workflowId}.`,
            StatusCodes.NOT_FOUND
          );
        }

        return new ApiError(
          `Error while retrieving Transfer Depart Workflow Details for Workflow ID ${workflowId}, please try again later.`,
          StatusCodes.INTERNAL_SERVER_ERROR
        );
      }
    },
    [regionContext]
  );

  return {
    error,
    isLoading,
    transferDepartWorkflowDetails: data,
    getTransferDepartWorkflowDetails
  };
}
