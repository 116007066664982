import React from "react";
import { Switch } from "@material-ui/core";
import OpConfigFormTitle from "./OpConfigFormTitle";
import "./opConfigForm.css";

class BoxRecommendation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      boxRecEnabled: this.props.boxRecEnabled,
    };

    this.onToggle = this.onToggle.bind(this);
  }

  onToggle() {
    let config = [];
    config.push({
      paramName: "boxRecEnabled",
      paramValue: (!this.state.boxRecEnabled).toString(),
    });
    this.setState((prevState) => ({
      boxRecEnabled: !prevState.boxRecEnabled,
    }));
    this.props.postVWPConfig(config, this.props.formName);
  }

  render() {
    return (
      <div className="box-container">
        <div className="opconfig-flex-container">
          <OpConfigFormTitle
            title="Box Recommendations"
            description="Allow this node to leverage Amazon service to recommend box for package"
          />

          <div className="responses">
            <Switch
              color="primary"
              checked={this.state.boxRecEnabled}
              onClick={this.onToggle}
              disabled={!this.props.editEnabled}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default BoxRecommendation;
