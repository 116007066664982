import React, { Component } from 'react';
import { Alert, Tabs, Tab } from "react-bootstrap";
import PropTypes from 'prop-types';
import API from "@aws-amplify/api";

/* Importing components*/
import RemovalShipmentDetails from './RemovalShipmentDetails';
import RemovalOrderLevelInfo from './model/RemovalOrderLevelInfo';
import RemovalItemDetails from './model/RemovalItemDetails';
import RemovalOrderLevelDetails from './RemovalOrderLevelDetails'
import { REMOVAL_ITEM_DETAIL_METADATA } from '../../constants/table/metadata/RemovalItemColumn';
import MaterialUITable from "../../components/Table/MaterialTable";
import { getAuthorizationHeader } from '@common/Auth';
import {RegionContext} from '@common/RegionContext';
import {constructButaneApiName} from '../../apiConfig';

class RemovalOrderDetails extends Component {
    static propTypes = {
        orderId: PropTypes.string,
        warehouseId: PropTypes.string,
        errorMessage: PropTypes.string
    };

    constructor(props) {
        super(props);

        this.state = {
            removalOrderLevelDetailsSnapshot: {},
            removalItemDetailsSnapshot: [],
            removalShipmentDetailsSnapshot: [],
            fetchingRecords: false,
            selectedTab: 'itemDetails',
            errorMessage: undefined
        };
    }

    async fetchRemovalOrderDetailsData(orderId, warehouseId) {
        let errorMessage = "";

        API.get(constructButaneApiName(this.context.region), `/api/removal/summary/warehouses/${warehouseId}/id/${orderId}`, {
            headers: await getAuthorizationHeader()
        })
        .then((data) => {

            const removalOrderDetails = data.removalOrderDetails;
            const removalOrderLevelDetails = new RemovalOrderLevelInfo(
                removalOrderDetails.removalOrderId,
                removalOrderDetails.shipmentRequestId,
                removalOrderDetails.fulfillmentChannel==='FRACS' ? 'FRACS' : 'NON-FRACS',
                removalOrderDetails.condition,
                removalOrderDetails.warehouseId,
                removalOrderDetails.totalQuantityRequested,
                removalOrderDetails.totalQuantityCancelled,
                removalOrderDetails.totalQuantityShipped,
                removalOrderDetails.totalQuantityInProcess,
                removalOrderDetails.totalQuantityUnavailable
            );

            let removalItemDetailsList = [];
            const removalOrderItemData = removalOrderDetails.removalOrderItemData;
            for(let i=0; i<removalOrderItemData.length; i++) {
                let itemData = new RemovalItemDetails(
                    removalOrderItemData[i].fnsku,
                    removalOrderItemData[i].quantityRequested,
                    removalOrderItemData[i].quantityInProcess,
                    removalOrderItemData[i].quantityShipped,
                    removalOrderItemData[i].quantityCancelled,
                    removalOrderItemData[i].quantityUnavailable,
                    removalOrderItemData[i].reasonCode
                );
                removalItemDetailsList.push(itemData);
            }

            let removalShipmentDetailsList = [];
            const removalShipmentLevelDetails = data.removalShipmentLevelDetails;
            if (removalShipmentLevelDetails !== null && removalShipmentLevelDetails.length > 0) {
                for(let i=0; i<removalShipmentLevelDetails.length; i++) {
                    let removalShipmentDetails = {
                        shipmentId: removalShipmentLevelDetails[i].shipmentId
                    };
                    removalShipmentDetailsList.push(removalShipmentDetails);
                }
            }

            this.setState(() => ({
                removalOrderLevelDetailsSnapshot: removalOrderLevelDetails,
                removalItemDetailsSnapshot: removalItemDetailsList,
                removalShipmentDetailsSnapshot: removalShipmentDetailsList,
                fetchingRecords: false,
                error: errorMessage
            }));
        })
        .catch(error => {
            this.setState(() => ({
                removalBasicDetailsSnapshot: {},
                removalItemDetailsSnapshot: [],
                removalShipmentDetailsSnapshot: [],
                fetchingRecords: false,
                errorMessage: (error.message === "Request failed with status code 400") ?
                    'Removal order not found' : 'Something went wrong'
            }));
        });
}

    getRemovalOrderDetailsData(orderId, warehouseId) {
        if (orderId === '' || warehouseId === '') {
            return;
        }

        this.setState(() => ({
          noResult: undefined,
          fetchingRecords: true,
          errorMessage: undefined,
          selectedTab: "itemDetails"
        }));

        this.fetchRemovalOrderDetailsData(orderId, warehouseId);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.orderId !== this.props.orderId || prevProps.warehouseId !== this.props.warehouseId) {
            this.getRemovalOrderDetailsData(this.props.orderId, this.props.warehouseId);
        }
    }

    componentDidMount() {
        this.getRemovalOrderDetailsData(this.props.orderId, this.props.warehouseId);
    }

    renderRemovalOrderDetails() {
        const columnMetadata = REMOVAL_ITEM_DETAIL_METADATA;
        // Show warning if orderId is empty.
        if (this.props.orderId === undefined) {
            return(
                <Alert variant="warning">
                    <h5>{"Please provide valid OrderId"}</h5>
                </Alert>
            )
        }
        // else render based on the output of API call.
        return (
            <div>
                {!!this.state.errorMessage ? (
                    <Alert variant="warning">
                        <h5>{this.state.errorMessage}</h5>
                    </Alert>) : (
                    <div  className="removal-details">
                            <RemovalOrderLevelDetails
                                removalOrderLevelDetailsSnapshot={this.state.removalOrderLevelDetailsSnapshot}
                            />
                            <Tabs defaultActiveKey="itemDetails" id="removalDetails"
                                  activeKey={this.state.selectedTab}
                                  onSelect={selectedTab => this.setState({selectedTab})}>
                                <Tab
                                    eventKey="itemDetails"
                                    title="Item Details"
                                    className="removal-item-details"
                                >
                                    <MaterialUITable
                                        results={this.state.removalItemDetailsSnapshot}
                                        columnMetadata={columnMetadata}
                                        title={"Item Details"}
                                    />
                                </Tab>
                                <Tab
                                    eventKey="shipmentDetails"
                                    title="Shipment Details"
                                    className="removal-shipment-details"
                                    disabled={this.state.removalOrderLevelDetailsSnapshot.fulfillmentChannel!=='FRACS'}
                                >
                                    <RemovalShipmentDetails
                                        removalShipmentIdsSnapshot={this.state.removalShipmentDetailsSnapshot}
                                        warehouseId={this.props.warehouseId}
                                        selectedTab={this.state.selectedTab}
                                        orderId={this.props.orderId}
                                    />
                                </Tab>
                            </Tabs>
                        </div>
                    )}
                </div>
            );
    }

    render() {
        let content;
        if(!!this.state.fetchingRecords) {
          return <div className="spinner"></div>
        }
        if (this.props.warehouseId && !this.state.fetchingRecords) {
            content = this.renderRemovalOrderDetails();
        }
        return <div>{content}</div>
    }
}

RemovalOrderDetails.contextType = RegionContext;

export default RemovalOrderDetails;