import React, {Dispatch, SetStateAction, useState} from "react";
import {RouteComponentProps, withRouter} from "react-router";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import SelectWarehouseForm from "@components/SelectWarehouse/SelectWarehouseForm";

interface SelectWarehouseModalProps extends RouteComponentProps {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
}

export const SelectSiteModal = withRouter(
  ({isVisible, setIsVisible, history}: SelectWarehouseModalProps) => {
    const [selectedSite] = useState("");

    function constructRedirectPath(siteName: string) {
      const redirectPath = `/receive/${siteName}/inventory_receipts`;

      setIsVisible(false);
      history.push(redirectPath);
    }

    return (
      <Modal
        header="Select Site"
        visible={isVisible}
        onDismiss={() => setIsVisible(false)}
      >
        <SelectWarehouseForm
          warehouse={selectedSite}
          constructRedirectPath={constructRedirectPath}
          usePolaris={true}
          containerless={true}
        />
      </Modal>
    );
  }
);
