// React
import React from "react";

import { Alert } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";
import {CS_REP_ACCESS_URL, EFPM_ACCESS_URL} from "../../views/layouts/unAuthroizedConstant";

class UnauthorizedUser extends React.Component {
  state = {
    openErrorSnackbar: this.props.openErrorSnackbar
  };

  render() {
    return (
      <Snackbar
        open={this.state.openErrorSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert severity="error" variant="standard">
         User does not have access to this resource.<br/>
         For customer support, please request for access <a href={CS_REP_ACCESS_URL}>here</a> <br/>
         Others can request access using <a href={EFPM_ACCESS_URL}>this</a>
        </Alert>
      </Snackbar>
    );
  }
}

export default UnauthorizedUser;
