import {TableEmptyState} from "@common/table";
import Table from "@amzn/awsui-components-react/polaris/table";
import React, {useContext, useEffect, useState} from "react";
import {useCollection} from "@amzn/awsui-collection-hooks";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import API from "@aws-amplify/api";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import { getAuthorizationHeader } from "@common/Auth";
import {RegionContext} from "@common/RegionContext";
import {constructButaneApiName} from "../../../../apiConfig";

export interface CommittedRemovalItem {
    shipmentRequestId: string,
    inventoryStatus: string,
    quantity: number,
}

interface CommittedRemovalDetailsTableProps {
    fnSku: string,
    warehouse: string,
}

export const CommittedRemovalDetailsTable = (props: CommittedRemovalDetailsTableProps) => {
    const [committedRemovalItems, setCommittedRemovalItems] = useState<CommittedRemovalItem[]>([]);
    const [fetchingCommittedRemovals, setFetchingCommittedRemovals] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const regionContext = useContext(RegionContext);

    useEffect(() => {
        setFetchingCommittedRemovals(false);
        setCommittedRemovalItems([]);
        setErrorMessage("");
        getCommittedRemovalDetails(props, regionContext.region);
    }, [props, regionContext]);

    const getCommittedRemovalDetails = async (props: CommittedRemovalDetailsTableProps, region: string) => {
        setFetchingCommittedRemovals(true);
        let removalItems: CommittedRemovalItem[] = [];
        API.get(constructButaneApiName(region), `/api/removal/fnSku/${props.fnSku}/warehouse/${props.warehouse}`, {
                headers: await getAuthorizationHeader()
            })
            .then((data) => {
                data.removals.forEach(removal => {
                    removalItems.push({
                        shipmentRequestId: removal.shipmentRequestId,
                        inventoryStatus: removal.location,
                        quantity: removal.quantity
                    });
                });
            }).catch(() => {
                setErrorMessage("Error fetching committed grading order details.")
            }).finally(() => {
                setCommittedRemovalItems(removalItems);
                setFetchingCommittedRemovals(false);
            });
    }

    const {
        items,
        filterProps,
        paginationProps
    } = useCollection(committedRemovalItems, {
        filtering: {},
        pagination: {
            pageSize: 10
        }
    });

    return <div>
        {
            errorMessage &&
            <Alert type={"error"}>
                {errorMessage}
            </Alert>
        }
        <Table
            loading={fetchingCommittedRemovals}
            loadingText={`Fetching committed removal orders for FNSKU: ${props.fnSku}`}
            items={items}
            empty={
                <TableEmptyState
                    resourceName={"Commited Removal Order"}
                    resourceNamePlural={"Commited Removal Orders"}
                    showCreateResourceButton={false}/>
            }
            columnDefinitions={[
                {
                    id: "shipmentRequestId",
                    header: "ShipmentRequestId",
                    cell: e => e.shipmentRequestId,
                },
                {
                    id: "inventoryStatus",
                    header: "Inventory Status",
                    cell: e => e.inventoryStatus,
                },
                {
                    id: "quantity",
                    header: "Quantity",
                    cell: e => e.quantity,
                },
            ]}
            filter={
                <div>
                    <TextFilter
                        {...filterProps}
                    />
                </div>
            }
            pagination={
                <Pagination
                    {...paginationProps}
                />
            }
        />
    </div>
}