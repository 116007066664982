import {RouteComponentProps} from "react-router-dom";
import React, {useState} from "react";
import {useFlashbar} from "@common/flashbar";
import SelectWarehouseForm from "@components/SelectWarehouse/SelectWarehouseForm";
import {AppLayout} from "@amzn/awsui-components-react";
import {InventoryReceiptsTable} from "@modules/inbound/inventoryReceiveNotification/table/inventoryReceipt";

interface InventoryReceiptsOverviewParams {
  siteName: string;
}

interface InventoryReceiptsOverviewProps
  extends RouteComponentProps<InventoryReceiptsOverviewParams> {
}

export function InventoryReceiptsOverview(
  {
    history,
    match
  }: InventoryReceiptsOverviewProps) {
  const [selectSite, setSelectSite] = useState("");
  const [redirectPath, setRedirectPath] = useState("");
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const {FlashbarContainer} = useFlashbar();

  function constructRedirectPath(siteName: string) {
    const path = `/receive/${siteName}/inventory_receipts`;

    setSelectSite(siteName);
    setRedirectPath(path);
    setShouldRedirect(true);
  }

  function hasSiteRouteParam() {
    return match?.params?.siteName;
  }

  function shouldSelectSite() {
    return !hasSiteRouteParam() && !shouldRedirect;
  }

  function shouldRedirectWithSiteParam() {
    return !hasSiteRouteParam() && shouldRedirect;
  }

  if (shouldSelectSite()) {
    return (<SelectWarehouseForm
        warehouse={selectSite}
        constructRedirectPath={constructRedirectPath}
        usePolaris={true}
      />
    );
  }

  if (shouldRedirectWithSiteParam()) {
    setShouldRedirect(false);
    history.push(redirectPath);
  }

  return (
    <AppLayout
      notifications={<FlashbarContainer/>}
      navigationHide={true}
      toolsHide={true}
      headerSelector="#top-navigation"
      contentType="table"
      content={
        <>
          <InventoryReceiptsTable/>
        </>
      }
    />
  );
}


