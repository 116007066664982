import {TableEmptyState} from "@common/table";
import Table from "@amzn/awsui-components-react/polaris/table";
import React, {useContext, useEffect, useState} from "react";
import {useCollection} from "@amzn/awsui-collection-hooks";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import API from "@aws-amplify/api";
import './fcSkuTrackingTable.css'
import Alert from "@amzn/awsui-components-react/polaris/alert";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Header from "@amzn/awsui-components-react/polaris/header";
import { getAuthorizationHeader } from "@common/Auth";
import {RegionContext} from "@common/RegionContext";
import {constructButaneApiName} from "../../apiConfig";

export interface FcSkuAttribute {
    attributeName: string,
    attributeValue: string,
}

interface FcSkuTrackingTableProps {
    warehouse: string,
    attributeName: string,
    attributeValue: string,
    asin: string
}

export const FcSkuTrackingTable = (props: FcSkuTrackingTableProps) => {
    const [fcskuAttributes, setFcSkuAttributes] = useState<FcSkuAttribute[]>([]);
    const [fetchingFcSkuAttributes, setFetchingFcSkuAttributes] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const regionContext = useContext(RegionContext);

    useEffect(() => {
        setFcSkuAttributes([]);
        setFetchingFcSkuAttributes(false);
        getFcSkuAttributes(props);
        setErrorMessage("");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const getFcSkuAttributes = async (props: FcSkuTrackingTableProps) => {
        setFetchingFcSkuAttributes(true);

        let path = `/api/fcsku/attributes?warehouse=${props.warehouse}&attributeName=${props.attributeName}&attributeValue=${props.attributeValue}`;
        if (props.asin) {
            path = path.concat(`&asin=${props.asin}`);
        }

        API.get(constructButaneApiName(regionContext.region), path, {
                headers: await getAuthorizationHeader()
            })
            .then((data) => {
                const attributes: FcSkuAttribute[] = [
                    {
                        attributeName: 'FCSKU',
                        attributeValue: data.fcSku
                    }
                ]
                Object.entries(data.attributeNameToValue).forEach(([attributeName, attributeValue]) => {
                    attributes.push({
                        attributeName: attributeName,
                        attributeValue: attributeValue
                    } as FcSkuAttribute)
                })
                return setFcSkuAttributes(attributes);
            }).catch((e) => {
                if(typeof e.response === 'undefined'){
                    setErrorMessage("Network error occurred when fetching FcSku attributes! Please try again.")
                }
                else if (e.response.statusText === "Bad Request") {
                    setErrorMessage("Invalid input parameters ! Cannot find matching FcSku against input");
                } else {
                    setErrorMessage("Error occurred when fetching FcSku attributes! Please try again.")
                }
        })
            .finally(() => {
                setFetchingFcSkuAttributes(false);
            });
    }

    const {
        items,
        filterProps,
        paginationProps,
    } = useCollection(fcskuAttributes, {
        filtering: {},
        pagination: {
            pageSize: 10
        }
    });

    return <div data-fcsku-tracking-table="table">
        <SpaceBetween size={"s"}>
            {
                errorMessage &&
                <Alert type={"error"}>
                    {errorMessage}
                </Alert>
            }
            <Table
                header={
                    <Header>
                        FCSKU Attributes
                    </Header>
                }
                loading={fetchingFcSkuAttributes}
                loadingText={`Fetching FCSKU Attributes`}
                items={items}
                empty={
                    <TableEmptyState
                        resourceName={"Matching FcSku found"}
                        resourceNamePlural={"matching FcSku attributes found"}
                        showCreateResourceButton={false}
                    />
                }
                columnDefinitions={[
                    {
                        id: "attributeName",
                        header: "Attribute Name",
                        cell: e => e.attributeName,
                    },
                    {
                        id: "attributeValue",
                        header: "Attribute Value",
                        cell: e => e.attributeValue,
                    }
                ]}
                filter={
                    <div>
                        <TextFilter
                            {...filterProps}
                        />
                    </div>
                }
                pagination={
                    <Pagination
                        {...paginationProps}
                    />
                }
            />
        </SpaceBetween>
    </div>;
}
