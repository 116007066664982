import {useCallback, useContext, useState} from "react";
import API from "@aws-amplify/api";
import { StatusCodes } from "http-status-codes";

import { ApiError } from "@common/exception";
import ShipmentInfo from "@modules/shipment/model/ShipmentInfo";
import { getAuthorizationHeader } from "@common/Auth";
import {RegionContext} from "@common/RegionContext";
import {constructButaneApiName} from "../../../apiConfig";

const MESSAGE_DATA_KEY = "message";

export function useGetShipmentDetails() {
  const [error, setError] = useState<ApiError | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ShipmentInfo | null>(null);
  const regionContext = useContext(RegionContext);

  const getShipmentDetails = useCallback(
    async (shipmentId: string) => {
      if (!shipmentId) {
        return;
      }

      setIsLoading(true);
      setError(null);

      const apiUrl = `/api/shipment/details?shipmentId=${shipmentId}`;

      try {
        const response = await API.get(
          constructButaneApiName(regionContext.region),
          apiUrl, {
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            headers: await getAuthorizationHeader()  
          }
        );

        setData(
          response?.data as unknown as ShipmentInfo
        );
      } catch (exception) {
        const apiError = getApiError(exception);

        setError(apiError);
        setData(null);
      }

      setIsLoading(false);

      function getApiError(error: any) {
        if (error?.response?.status === StatusCodes.BAD_REQUEST) {
          return new ApiError(
            `Invalid request for shipment ID ${shipmentId}.`,
            StatusCodes.BAD_REQUEST,
            error.response.data[MESSAGE_DATA_KEY]
          );
        }
        if (error?.response?.status === StatusCodes.NOT_FOUND) {
          return new ApiError(
            `No Shipment found for ID ${shipmentId}.`,
            StatusCodes.NOT_FOUND
          );
        }

        return new ApiError(
          `Error while retrieving Shipment Details for ID ${shipmentId}, please try again later.`,
          StatusCodes.INTERNAL_SERVER_ERROR
        );
      }
    },
    [regionContext]
  );

  return {
    error,
    isLoading,
    getShipmentDetails,
    shipmentDetails: data
  };
}
