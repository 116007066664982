import React, { Component } from "react";
import { Button, TextField } from "@material-ui/core";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import PageTitle from "../../../components/PageTitle/PageTitle";
import PolarisButton from "@amzn/awsui-components-react/polaris/button";
import Box from "@amzn/awsui-components-react/polaris/box";
import Header from "@amzn/awsui-components-react/polaris/header";
import Container from "@amzn/awsui-components-react/polaris/container";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input from "@amzn/awsui-components-react/polaris/input";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";

import "../../config/config.css";
import "./selectShipmentIdForm.css";

const shipmentIdRegex = /^[A-Za-z0-9]+$/;
const ENTER_KEY_KEY_CODE = 13;

interface SelectShipmentIdFormProps {
  shipmentId: string;
  usePolaris?: boolean;
  containerless?: boolean;
  constructRedirectPath: (input: string) => void;
}

interface SelectShipmentIdFormState {
  shipmentId: string;
}

class SelectShipmentIdForm extends Component<
  SelectShipmentIdFormProps,
  SelectShipmentIdFormState
> {
  constructor(props) {
    super(props);

    this.state = {
      shipmentId: this.props.shipmentId
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit(e) {
    e.preventDefault();

    const shipmentId = this.props.usePolaris ? e.detail.value : e.target.value;
    this.setState({
      shipmentId: shipmentId
    });

    this.props.constructRedirectPath(this.state.shipmentId);
  }

  handleChange = (e) => {
    e.preventDefault();

    const shipmentId = this.props.usePolaris ? e.detail.value : e.target.value;
    this.setState({
      shipmentId: shipmentId
    });
  };

  handleKeyDown = (e) => {
    if (this.isValidInput() && e?.detail?.keyCode === ENTER_KEY_KEY_CODE) {
      this.props.constructRedirectPath(this.state.shipmentId);
      this.resetShipmentIdState();
      e.preventDefault();
    }
  };

  isValidInput = () => !!this.state.shipmentId && shipmentIdRegex.test(this.state.shipmentId);

  resetShipmentIdState = () => {
    this.setState({
      shipmentId: ""
    });
  };

  render() {
    if (this.props.usePolaris) {
      const BasePolarisContent = () => {
        return (
          <ColumnLayout columns={2}>
            <FormField label="ShipmentId">
              <Input
                value={this.state.shipmentId}
                onChange={this.handleChange}
                onKeyDown={this.handleKeyDown}
                placeholder="e.g. 2816913434222 OR DVv8MC13k"
                type="search"
                autoFocus={true}
              />
            </FormField>
            <FormField label="ㅤ">
              <PolarisButton
                iconName="search"
                variant="primary"
                onClick={this.handleFormSubmit}
                disabled={!this.isValidInput()}
              >
                Search
              </PolarisButton>
            </FormField>
          </ColumnLayout>
        );
      };

      if (this.props.containerless) {
        return <>{BasePolarisContent()} </>;
      }

      return (
        <div className="select-container">
          <Box margin={{ vertical: "l", horizontal: "l" }}>
            <Container header={<Header variant="h2">Select ShipmentId</Header>}>
              {BasePolarisContent()}
            </Container>
          </Box>
        </div>
      );
    }

    return (
      <div>
        <div>
          <PageTitle>Select ShipmentId</PageTitle>
        </div>
        <div className="form-inline">
          <div>
            <div className="filter-form">
              <div className="filters">
                <div>
                  <label className="input-params">ShipmentId</label>
                  <div className="select-shipmentId-form-input-box">
                    <TextField
                      placeholder="e.g. 2816913434222 OR DVv8MC13k"
                      name="shipmentId"
                      className="select-shipmentId-form-input"
                      defaultValue={this.state.shipmentId}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div></div>
              </div>
              <div className="inline align-middle text-center">
                <div className="single-input-upper-margin"></div>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SearchOutlinedIcon />}
                  onClick={this.handleFormSubmit}
                  disabled={!this.isValidInput()}
                >
                  Search
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SelectShipmentIdForm;
