import React from "react";
import BoxRecommendation from "./BoxRecommendation";
import PageTitle from "../../../components/PageTitle/PageTitle";
import OpConfigForm from "./OpConfigForm";
import OperationalConfigurations from "./OperationalConfigurations";
import API from "@aws-amplify/api";
import { Alert } from "react-bootstrap";
import UnauthorizedUser from "../../../views/layouts/UnauthorizedUser";
import "../../../components/Spinner/spinner.css";
import { getAuthorizationHeader } from "@common/Auth";
import BackendError from "../../../views/layouts/BackendError";
import {RegionContext} from '@common/RegionContext';
import {constructButaneApiName} from '../../../apiConfig';

class OpConfigSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      warehouse: this.props.location.state.warehouse,
      configs: new Map(),
      boxRecEnabled: false,
      noResult: false,
      successfulUpdate: 0,
      editEnabled: true,
      openForbiddenSnackbar: false,
      networkError: false
    };
  }

  fetchVWPConfig = async () => {
    return API.get(
      constructButaneApiName(this.context.region),
      `/vwp/config/warehouse/${this.state.warehouse}`,{
        headers: await getAuthorizationHeader()
      }
    )
    .then((response) => {
      if (response.config.length === 0) {
        this.setState({
          loading: false,
          noResult: true,
        });
      } else {
        this.initializeStates(response.config);
      }
    })
    .catch((error) => {
      if (typeof error.response === 'undefined') {
        this.setState(() => ({ networkError: true }));
      }
      else
      error.response.status === 403 ? 
          this.setState(() => ({ openForbiddenSnackbar: true })): this.setState(() => ({ openForbiddenSnackbar: false }));
      this.setState(() => ({
        loading: false,
        noResult: true,
      }));
    });
  };

  initializeStates = (response) => {
    let configs = new Map();

    for (let form of OperationalConfigurations.values()) {
      for (let [paramName, paramDetails] of form) {
        if (paramName !== "FormTitle") {
          let { paramValue } = paramDetails;
          configs.set(paramName, {paramValue});
        }
      }
    }

    for (let i = 0; i < response.length; i++) {
      let { paramName, paramValue } = response[i];
      if (paramName === "boxRecEnabled") {
        this.setState({ boxRecEnabled: paramValue === "true" });
      } else if (configs.has(paramName)) {
        if (OperationalConfigurations.get("processingTime").has(paramName)) {
          paramValue = this.secToHours(paramValue);
        }
        configs.set(paramName, {
          ...response[i],
          paramValue
        });
      }
    }

    this.setState({
      configs: configs,
      loading: false,
    });
  };

  componentDidMount() {
    this.fetchVWPConfig();
  }

  secToHours = (value) => {
    let num = Number(value);
    num /= 3600;
    return num.toString();
  };

  hoursToSec = (value) => {
    let num = Number(value);
    num *= 3600;
    return num.toString();
  };

  postVWPConfig = async (data, formName) => {
    this.setState({ loading: true });
    if (formName === "processingTime") {
      for (let i = 0; i < data.length; i++) {
        data[i].paramValue = this.hoursToSec(data[i].paramValue);
      }
    }
    const postData = {
      warehouse: this.state.warehouse,
      config: data,
    };
    const init = {
      body: postData,
      headers: await getAuthorizationHeader()
    };
    return API.post(constructButaneApiName(this.context.region), `/vwp/setup-node-config`, init)
      .then((response) => {
        this.setState({ successfulUpdate: 1 });
        this.reloadVWPConfig();
      })
      .catch((error) => {
        if (typeof error.response === 'undefined') {
          this.setState(() => ({ networkError: true }));
        }
        else
        error.response.status === 403 ? 
          this.setState(() => ({ openForbiddenSnackbar: true })): this.setState(() => ({ openForbiddenSnackbar: false }));
        this.setState({ successfulUpdate: 2, loading: false });
      });
  };

  reloadVWPConfig = () => {
    this.setState({
      configs: new Map(),
    });
    this.fetchVWPConfig();
  };

  render() {
    if (this.props.isAuthenticated) {
      if (this.state.loading) {
        return <div className="spinner"></div>;
      }
      if(this.state.openForbiddenSnackbar) {
        return <UnauthorizedUser openErrorSnackbar = {true} />;
      }
      if(this.state.networkError) {
        return <BackendError/>;
      }
      if (!!this.state.noResult) {
        return (
          <div>
            <div>
              <PageTitle>
                Operational Configurations: {this.state.warehouse}
              </PageTitle>
            </div>
            <Alert variant="warning">
              No Results found for following warehouse: {this.state.warehouse}
            </Alert>
          </div>
        );
      }

      let opConfigs = [];
      for (let formName of OperationalConfigurations.keys()) {
        opConfigs.push(
          <OpConfigForm
            key={formName}
            data={this.state.configs}
            formName={formName}
            editEnabled={this.state.editEnabled}
            postVWPConfig={this.postVWPConfig}
          />
        );
      }
      return (
        <div>
          <PageTitle>
            Operational Configurations: {this.state.warehouse}
          </PageTitle>
          {this.state.successfulUpdate === 1 && (
            <Alert
              onClose={() => {
                this.setState({ successfulUpdate: 0 });
              }}
              variant="info"
              dismissible
            >
              Successfully Updated Operational Configuration settings for
              warehouse: {this.state.warehouse}
            </Alert>
          )}
          {this.state.successfulUpdate === 2 && (
            <Alert
              onClose={() => {
                this.setState({ successfulUpdate: 0 });
              }}
              variant="danger"
              dismissible
            >
              Update Failure! Please try again later.
            </Alert>
          )}
          <BoxRecommendation
            boxRecEnabled={this.state.boxRecEnabled}
            formName="boxRecommendation"
            editEnabled={this.state.editEnabled}
            postVWPConfig={this.postVWPConfig}
          />

          {opConfigs}
        </div>
      );
    }
    this.props.history.push("/login");
    return null;
  }
}

OpConfigSettings.contextType = RegionContext;

export default OpConfigSettings;
