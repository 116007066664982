import React, { ReactNode } from "react";
import Box from "@amzn/awsui-components-react/polaris/box";

interface ValueWithLabelProps {
  children: ReactNode;
  label: string;
}

export function ValueWithLabel({ children, label }: ValueWithLabelProps) {
  return (
    <div>
      <Box variant="small">{label}</Box>
      <div>{children}</div>
    </div>
  );
}
