import { Box, ColumnLayout, ExpandableSection, Header, Spinner } from "@amzn/awsui-components-react";
import React from "react";
import formatDate from "../../../common/DateFormatter";
import {Operations, Registration} from "../Types";
import ValueWithLabel from "../ValueWithLabel";
import * as _ from "lodash";

interface RegistrationDetailsContainerProps {
  loading: boolean;
  registrationId: string;
  registration?: Registration;
}

const ctiLabels = {
  communication: "Communication CTI",
  inventory: "Inventory CTI",
  receive: "Receive CTI",
  removal: "Removal CTI",
  shipment: "Shipment CTI",
  transfer: "Transfer CTI"
}

const RegistrationDetailsContainer = (props: RegistrationDetailsContainerProps) => {
  const getSectionContents = () => {
    if (props.loading) {
      return (
        <Box textAlign="center">
          <Spinner />
        </Box>
      );
    } else if (_.isNil(props.registration)) {
      return (
        <Box textAlign="center">
          {`Couldn't find a registration with id ${props.registrationId}`}
        </Box>
      );
    } else {
      return (
        <div>
          <ColumnLayout columns={3} borders="vertical">
            <ValueWithLabel label="Registration ID">{props.registration.registrationId}</ValueWithLabel>
            <ValueWithLabel label="Warehouse Management System">{props.registration.warehouseManagementSystem}</ValueWithLabel>
            <ValueWithLabel label="Created">{formatDate(props.registration.created)}</ValueWithLabel>
            <ValueWithLabel label="Marketplace">{props.registration.market}</ValueWithLabel>
            <ValueWithLabel label="Creator">{props.registration.creator}</ValueWithLabel>
            <ValueWithLabel label="Last Updated">{formatDate(props.registration.lastUpdated)}</ValueWithLabel>
            <ValueWithLabel label="Business Type">{props.registration.businessType}</ValueWithLabel>
            <ValueWithLabel label="Contact">{props.registration.email}</ValueWithLabel>
            <ValueWithLabel label="New Inventory Service Registration">{getIsRegistrationForNewInventoryService(props.registration.registrationSiteEquipments)}</ValueWithLabel>
            {getNewInventoryServiceEquipments(props.registration.registrationSiteEquipments)}
          </ColumnLayout>
          <wbr />
          <Header variant="h3">{`Operations`}</Header>
          <ColumnLayout columns={3} borders="vertical">
            {getOperationsContent(props.registration.operations)}
          </ColumnLayout>
        </div>
      );
    }
  }

  const getIsRegistrationForNewInventoryService = (registrationSiteEquipments: string[]) => {
    if (_.isNil(registrationSiteEquipments) || registrationSiteEquipments.length === 0)
      return ("false");

    return "true";
  }

  const getNewInventoryServiceEquipments = (registrationSiteEquipments: string[]) => {

      if (!_.isNil(registrationSiteEquipments) && registrationSiteEquipments.length !== 0) {
        let equipments = registrationSiteEquipments.join(', ');

        return (
          <ValueWithLabel label="New Inventory Service Equipments">{equipments}</ValueWithLabel>
        )
      }

  }

  const getOperationsContent = (operations: Operations) => {
    const content : JSX.Element[] = [];
    if (_.isNil(operations)) {
      content.push(<ValueWithLabel label="Missing Operational Details" key={"missingOperationalDetails"}>{}</ValueWithLabel>);
      return content;
    }

    for (let prop in operations) {
      if (operations.hasOwnProperty(prop) && !_.isNil(operations[prop])) {
        if (typeof operations[prop] != 'boolean') {
          let cti = operations[prop];
          let label = ctiLabels[prop];
          content.push(<ValueWithLabel label={label} key={prop}>{cti.category}/{cti.type}/{cti.item}</ValueWithLabel>);
        }
      }
    }

      let supportsOperationTickets = operations.supportsOperationTickets
      if (!_.isNil(supportsOperationTickets))
          content.push(<ValueWithLabel label="Supports Operation Tickets" key={"supportsOperationTickets"}>{String(supportsOperationTickets)}</ValueWithLabel>);
      else
          content.push(<ValueWithLabel label="Supports Operation Tickets" key={"supportsOperationTickets"}>False</ValueWithLabel>);

    return content;
  }
  return (
    <ExpandableSection
      defaultExpanded={true}
      variant="container"
      header={
        <Header variant="h2">{`Registration Details: ${props.registrationId}`}</Header>
      }
    >
      {getSectionContents()}
    </ExpandableSection>
  );
}

export default RegistrationDetailsContainer;