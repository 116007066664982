const RIVER_DUB='https://river-dub.amazon.com/';
const RIVER_IAD='https://river-iad.amazon.com/';
const RIVER_PDX='https://river-nrt.amazon.com/';
const RIVER_BETA='https://river-website.integ.amazon.com/';
export const fetchRiverLink = (region, environment) => {
    if (environment === 'prod') {
        switch(region.toLowerCase()) {
            case 'iad':
                return RIVER_IAD;
            case 'dub':
                return RIVER_DUB;
            case 'pdx':
                return RIVER_PDX;
            default:
                return RIVER_BETA;
        }
    } else {
        return RIVER_BETA
    }
};
