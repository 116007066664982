import React from "react";
import AuditInputBox from "./AuditInputBox";
import InventoryAuditDetailsTable from "./InventoryAuditDetailsTable";
import {dateRegex} from '../../../constants/Regex';

export default class SearchInventoryAudits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      warehouse: this.setWarehouse(),
      asin: this.setAsin(),
      inventoryLocation: this.setInventoryLocation(),
      startDate: this.setStartDate(),
      endDate: this.setEndDate(),
      spinnerData: [],
      search: false
    };
  }

  componentWillMount() {
    const query = new URLSearchParams(this.props.location.search);
    const isbnQueryString = query.get('fnsku');
    const warehouseQueryString = query.get('warehouse');
    const startDateQueryString = query.get('start_date');
    const endDateQueryString = query.get('end_date');
    const inventoryLocationQueryString = query.get('inventory_location');

    let {asin, warehouse, startDate, endDate, inventoryLocation} = this.state
    
    if(isbnQueryString !== null && isbnQueryString.length >= 0 && asin !== isbnQueryString) {
      asin = isbnQueryString;
    }

    if(warehouseQueryString !== null && warehouseQueryString.length > 0 && warehouseQueryString !== warehouse) {
      warehouse = warehouseQueryString;
    }

    if(startDateQueryString !== null && this.isValidDateString(startDateQueryString)) {
      startDate = new Date(startDateQueryString);
    }

    if(endDateQueryString !== null && this.isValidDateString(endDateQueryString)) {
      endDate = new Date(endDateQueryString);
    }

    if(inventoryLocationQueryString !== null && inventoryLocationQueryString.length > 0 && inventoryLocationQueryString !== undefined && inventoryLocationQueryString !== inventoryLocation) {
      inventoryLocation = inventoryLocationQueryString;
    }
    
    const queryParamsString = this.getURLParamsString(asin, warehouse, inventoryLocation, startDate, endDate);
    const currQueryParamsString = this.props.history.location.search;

    if(currQueryParamsString !== queryParamsString) {
      this.props.history.replace({
        search: queryParamsString
      });
    }

    this.setState(() => ({
      asin,
      warehouse,
      startDate,
      endDate,
      inventoryLocation
    }));
  }

  isValidDateString(dateString) {
    return dateRegex.test(dateString);
  }

  setWarehouse() {
    if(this.props.location.state !== undefined && this.props.location.state.warehouse !== undefined) {
        return this.props.location.state.warehouse
    } else {
        return ''
    }
  }

  setAsin() {
    if(this.props.location.state !== undefined && this.props.location.state.asin !== undefined) {
        return this.props.location.state.asin
    } else {
        return ''
    }
  }

  setInventoryLocation() {
    if(this.props.location.state !== undefined && this.props.location.state.inventoryLocation !== null) {
        return this.props.location.state.inventoryLocation
    } else {
        return ''
    }
  }

  setStartDate() {
    //Default time range will be 3 months
    let startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 3);
    return startDate;
  }

  setEndDate() {
    return new Date();
  }

  getDateISOString(date) {
    const dateString = date.toISOString().slice(0,10);
    return dateString;
  }

  getURLParamsString(asinValue, warehouseValue, inventoryLocationValue, startDateValue, endDateValue) {
    return "?" + new URLSearchParams({fnsku: asinValue}).toString() 
               + '&' + new URLSearchParams({warehouse: warehouseValue}).toString()
               + '&' + new URLSearchParams({start_date: this.getDateISOString(startDateValue)})
               + '&' + new URLSearchParams({end_date: this.getDateISOString(endDateValue)})
               + '&' + new URLSearchParams({inventory_location: inventoryLocationValue}).toString()
  }
    
  renderInventoryAuditDetailsTable = (asinValue, warehouseValue, inventoryLocationValue, startDateValue, endDateValue) => {
    const urlParamsString = this.getURLParamsString(asinValue, warehouseValue, inventoryLocationValue.value, startDateValue, endDateValue);
    const currParamsString = this.props.history.location.search
    if(currParamsString !== urlParamsString) {
      this.props.history.push({
        search: urlParamsString
      })
    }
    
    this.setState(() => ({
      asin: asinValue,
      warehouse: warehouseValue,
      inventoryLocation: inventoryLocationValue.value,
      startDate: startDateValue,
      endDate: endDateValue,
      search:true
    }));
  };

  render() {
    if (this.props.isAuthenticated) {
      return (
        <div>
          <AuditInputBox
            asin={this.state.asin}
            warehouse={this.state.warehouse}
            inventoryLocation={this.state.inventoryLocation}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            renderTables={this.renderInventoryAuditDetailsTable}
          />
          <InventoryAuditDetailsTable
            asin={this.state.asin}
            warehouse={this.state.warehouse}
            inventoryLocation={this.state.inventoryLocation}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            search={this.state.search}
          />
        </div>
      );
    }

    this.props.history.push("/login");
    return null;
  }
}
