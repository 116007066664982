import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import Flashbar from "@amzn/awsui-components-react/polaris/flashbar";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";

import CreateRegistrationModal from "./CreateRegistrationModal";
import { Filter, Registration } from "../Types";
import { FlashbarCustomProps } from "../../../common/Types";
import * as _ from "lodash";
import RegistrationsTable from "./RegistrationsTable";
import queryString from "query-string";
import API from "@aws-amplify/api";
import { getAuthorizationHeader } from "@common/Auth";
import UnAuthorizedAccessAlert from "../../../views/layouts/UnAuthorizedAccessAlert";
import {useContext} from "react";
import {RegionContext} from "@common/RegionContext";
import {constructButaneApiName} from "../../../apiConfig";

interface RegistrationProps extends RouteComponentProps {
  isAuthenticated: boolean;
  userHasAuthenticated: boolean;
  isExternal: boolean;
}

const Registrations = (props: RegistrationProps) => {
  if (!props.isAuthenticated) {
    props.history.push("/login");
  }

  const [newRegistrationModalVisible, setNewRegistrationModalVisible] = React.useState<boolean>(false);
  const [registrationsList, setRegistrationsList] = React.useState<Registration[]>([]);
  const [flashbarProps, setFlashbarProps] = React.useState<FlashbarCustomProps>({});
  const [loading, setLoading] = React.useState<boolean>(true);
  const [marketplaceOptions, setMarketplaceOptions] = React.useState<string[]>([]);
  const regionContext = useContext(RegionContext);

  React.useEffect(() => {
    fetchData({}, regionContext.region);
  }, [regionContext]);

  const fetchData = async (filterObject: Filter, region: string) => {
    setLoading(true);
    API.get(constructButaneApiName(region), `/api/v1/registrations?${queryString.stringify(filterObject)}`, {
      headers: await getAuthorizationHeader()
    }).then((response: { registrations: Registration[] }) => {
      setRegistrationsList(response.registrations);
      setMarketplaceOptions(Array.from(new Set(response.registrations.map(_ => _.market))));
    }).catch((error) => {
      setFlashbarProps({ header: "Error retrieving registrations", msg: error.message, type: "error" });
    }).finally(() => setLoading(false));
  }

  const createNewRegistration = async (businessType: string, marketplace: string, warehouseManagementSystem: string, contact: string, isRegistrationForNewInventoryService: boolean, newInventoryServiceEquipments: any[]) => {
    const requestBody = {
      businessType: businessType,
      market: marketplace,
      warehouseManagementSystem: warehouseManagementSystem,
      email: contact,
      registrationSiteEquipments: newInventoryServiceEquipments.map(e => e.value)
    }

    setLoading(true);
    API.post(constructButaneApiName(regionContext.region), "/api/v1/registrations", { 
      body: requestBody, 
      headers: await getAuthorizationHeader()
    }).then((response) => {
      setFlashbarProps({ header: "Registration created successfully", msg: `New registration was created with registration id: "${response.registrationId}"`, type: "success" });
      fetchData({}, regionContext.region);
    }).catch((error) => {
      if(typeof error.response === 'undefined') {
        setFlashbarProps({ header: "Network error", msg: "Trouble connecting to backend, please try again", type: "error" })
      }
      else setFlashbarProps({ header: error?.response?.data?.code, msg: error?.response?.data?.message, type: "error" })
      setLoading(false);
    })
  }

  return (
    <div>
      {props.isExternal && <UnAuthorizedAccessAlert/>}
      {!props.isExternal && <AppLayout
        navigationHide={true}
        toolsHide={true}
        headerSelector="#top-navigation"
        content={
          <SpaceBetween size="m">
            {
              (!_.isEmpty(flashbarProps.header) || !_.isEmpty(flashbarProps.msg)) &&
              <Flashbar
                items={[{
                  header: flashbarProps.header,
                  content: flashbarProps.msg,
                  type: (flashbarProps.type ? flashbarProps.type : "error"),
                  dismissible: true,
                  onDismiss: () => setFlashbarProps({ header: "", msg: "" })
                }]}
              />
            }
            <CreateRegistrationModal
              visible={newRegistrationModalVisible}
              hideModal={() => setNewRegistrationModalVisible(false)}
              submitForm={createNewRegistration}
            />
            <RegistrationsTable
              registrationsList={registrationsList}
              showNewRegistrationModal={() => setNewRegistrationModalVisible(true)}
              marketplaceOptions={marketplaceOptions}
              fetchData={fetchData}
              loading={loading}
            />
          </SpaceBetween>
        }
      />}
    </div>
  );
}

export default Registrations;
