import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import Table from "@amzn/awsui-components-react/polaris/table";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import Link from "@amzn/awsui-components-react/polaris/link";
import PropertyFilter from "@amzn/awsui-components-react/polaris/property-filter";
import { DateRangePickerProps } from "@amzn/awsui-components-react";
import { useCollection } from "@amzn/awsui-collection-hooks";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import duration from "dayjs/plugin/duration";
import { StatusCodes } from "http-status-codes";

import {
  TableEmptyState,
  getFilterCounterText,
  TableNoMatchState
} from "@common/table";
import { useFlashbar } from "@common/flashbar";
import { useGetTransferDepartRequests } from "@modules/transfer/hooks";
import {
  TransferDepartRequestsHeader,
  TransferDepartRequestsDateRangeFilter
} from "@modules/transfer/departure-requests/table";
import { DateRangePickerConverter } from "@modules/transfer/departure-requests/logic";

import {
  COLUMN_DEFINITIONS,
  DEFAULT_PREFERENCES,
  TRACK_BY_COLUMN_NAME,
  Preferences,
  FILTERING_PROPERTIES,
  LOADING_TEXT,
  PROPERTY_FILTERING_LABELS
} from "./config";

dayjs.extend(isSameOrBefore);
dayjs.extend(duration);

const WAREHOUSE_SELECTION_ROUTE_URL = "/transfers/departure-requests";

interface TransferDepartRequestsTableRouteParams {
  warehouse: string;
}

interface TransferDepartRequestsTableProps
  extends RouteComponentProps<TransferDepartRequestsTableRouteParams> {}

export const TransferDepartRequestsTable = withRouter(
  ({ history, match }: TransferDepartRequestsTableProps) => {
    const {
      isLoading,
      transferDepartRequests,
      error,
      getTransferDepartRequests
    } = useGetTransferDepartRequests();

    const { addMessage, clearAllMessages } = useFlashbar();
    const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);

    const [rangeFilter, setRangeFilter] =
      useState<DateRangePickerProps.Value | null>(null);

    async function handleRefreshClick() {
      clearAllMessages();
      const dateRange = DateRangePickerConverter.getDateRange(
        rangeFilter as DateRangePickerProps.Value
      );

      await getTransferDepartRequests(match?.params.warehouse, dateRange);
    }

    useEffect(() => {
      if (error) {
        switch (error.statusCode) {
          case StatusCodes.NOT_FOUND:
            return addMessage({
              header: error.message,
              type: "warning"
            });
          default:
            return addMessage({
              header: error.message,
              content: error.details ?? null,
              type: "error",
              buttonText: "Retry",
              dismissible: true,
              onButtonClick: async () => {
                await handleRefreshClick();
              }
            });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    const {
      items,
      actions,
      filteredItemsCount,
      collectionProps,
      paginationProps,
      propertyFilterProps
    } = useCollection(transferDepartRequests, {
      propertyFiltering: {
        filteringProperties: FILTERING_PROPERTIES,
        empty: (
          <TableEmptyState
            showCreateResourceButton={false}
            resourceName="Transfer Depart Request"
            resourceNamePlural="Transfer Depart Requests"
          />
        ),
        noMatch: (
          <TableNoMatchState
            onClearFilterClick={() => {
              actions.setPropertyFiltering({ tokens: [], operation: "and" });
            }}
          />
        )
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {
          defaultState: {
              isDescending: true,
              sortingColumn: {sortingField: "creationDate"}
          }
      },
      selection: {}
    });

    useEffect(() => {
      if (rangeFilter) {
        handleRefreshClick();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match?.params?.warehouse, rangeFilter]);

    if (isMissingWarehouseParameter()) {
      history.push(WAREHOUSE_SELECTION_ROUTE_URL);
    }

    function isMissingWarehouseParameter() {
      return !match?.params?.warehouse;
    }

    function EmptyTransferDepartRequestsAlert() {
      if (transferDepartRequests.length === 0 && !isLoading) {
        return (
          <Alert
            type="info"
            header={
              <span>
                No Transfer Depart Requests found for warehouse&nbsp;
                {match?.params.warehouse}
              </span>
            }
          >
            Warehouse is either not Depart2.0-enabled yet or no Transfer Depart
            Requests are available at this time. For legacy (non-Depart2.0)
            transfers, please use&nbsp;
            <Link external={true} href="https://efpm.amazon.com">
              EFPM
            </Link>
            .
          </Alert>
        );
      }
      return null;
    }

    return (
      <>
        <EmptyTransferDepartRequestsAlert />
        <br />
        <Table
          {...collectionProps}
          items={items}
          loading={isLoading}
          trackBy={TRACK_BY_COLUMN_NAME}
          selectionType="single"
          header={
            <TransferDepartRequestsHeader
              items={transferDepartRequests}
              isLoading={isLoading}
              selectedItems={collectionProps?.selectedItems ?? []}
              warehouse={match.params.warehouse}
              onRefreshClick={async () => await handleRefreshClick()}
            />
          }
          filter={
            <div className="filter-container">
              <div className="date-range-filter">
                <TransferDepartRequestsDateRangeFilter
                  rangeFilter={rangeFilter}
                  onRangeFilterChange={(e) => setRangeFilter(e.value)}
                />
              </div>
              <PropertyFilter
                {...propertyFilterProps}
                i18nStrings={PROPERTY_FILTERING_LABELS}
                countText={getFilterCounterText(filteredItemsCount)}
              />
            </div>
          }
          resizableColumns={true}
          columnDefinitions={COLUMN_DEFINITIONS}
          visibleColumns={preferences.visibleContent}
          loadingText={LOADING_TEXT}
          wrapLines={preferences.wraplines}
          pagination={<Pagination {...paginationProps} />}
          preferences={
            <Preferences
              preferences={preferences}
              setPreferences={setPreferences}
            />
          }
        />
      </>
    );
  }
);
