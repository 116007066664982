import React, { Component } from 'react';
import Tooltip from "@material-ui/core/Tooltip";
import { Card, Container, Row, Col } from 'react-bootstrap';

import PageTitle from '../../components/PageTitle/PageTitle';

class RemovalOrderLevelDetails extends Component {

    getRemovalOrderLevelDetails() {
        const removalOrderLevelDetailsSnapshot = this.props.removalOrderLevelDetailsSnapshot;

        const removalStatusMap = new Map([
            ["CREATED", {'alert' : "removal-alert removal-alert-inprocess",
                'status' : "This removal order is dropped to EFP"} ],
            ["PENDING_SPLIT", {'alert' : "removal-alert removal-alert-inprocess",
                'status' : "This removal order is sent to FCRemovals for splitting"} ],
            ["SPLITTED", {'alert' : "removal-alert removal-alert-inprocess",
                'status' : "This removal order is splitted and dropped to EFP"} ],
            ["SUBMITTED", {'alert' : "removal-alert removal-alert-inprocess",
                'status' : "This removal order is sent to Warehouse"} ],
            ["CLOSED", {'alert' : "removal-alert removal-alert-complete",
                'status' : "This removal order is closed"} ],
            ["UNKNOWN", {'alert' : "removal-alert removal-alert-error",
                'status' : "Removal Order Status is Unknown"} ]
        ])

        let removalStatus = removalStatusMap.get(removalOrderLevelDetailsSnapshot.condition)
            || {'alert' : "removal-alert removal-alert-error", 'status' : "Failed to get Removal Order Status"}

        return (
            <div>
                <div className="removal-id"> 
                    <PageTitle>
                        Removal Order: {removalOrderLevelDetailsSnapshot.shipmentRequestId}
                        ({removalOrderLevelDetailsSnapshot.removalOrderId})
                    </PageTitle>
                </div>    
                <strong>Removal Type:</strong> {removalOrderLevelDetailsSnapshot.fulfillmentChannel}
                <p className={removalStatus.alert}>{removalStatus.status}</p>
                <div className="vendor-return-summary">
                    <Card bg="light">
                        <Card.Header bsPrefix="vendor-return-summary-title">Vendor Return Summary</Card.Header>
                        <Card.Body bsPrefix="removal-total-item-details">
                            <Container>
                                <Row>
                                    <Col lg={true}>Units Requested </Col>
                                    <Tooltip
                                        placement="top"
                                        title="Cancelled + Unavailable"
                                    >
                                    <Col lg={true}>Units Cancelled</Col>
                                    </Tooltip>
                                    <Col lg={true}>Units Unavailable</Col>
                                    <Col lg={true}>Units Shipped</Col>
                                    <Col lg={true}>Units In Process</Col>
                                </Row>   
                                <Row>
                                    <Col lg={true}>{removalOrderLevelDetailsSnapshot.totalQuantityRequested}</Col>
                                    <Col lg={true}>{removalOrderLevelDetailsSnapshot.totalQuantityCancelled}</Col>
                                    <Col lg={true}>{removalOrderLevelDetailsSnapshot.totalQuantityUnavailable}</Col>
                                    <Col lg={true}>{removalOrderLevelDetailsSnapshot.totalQuantityShipped}</Col>
                                    <Col lg={true}>{removalOrderLevelDetailsSnapshot.totalQuantityInProcess}</Col>
                                </Row>     
                            </Container>            
                        </Card.Body>
                    </Card> 
                </div>  
            </div>
        )
    }

    render() {
        return (
            this.getRemovalOrderLevelDetails()
        );
    }
}

export default RemovalOrderLevelDetails;