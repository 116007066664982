let processingTime = new Map();

processingTime
.set("FormTitle", {
  title: "Processing Time",
  description: "How long the warehouse takes to process package",
})
.set("PackageProcessingDelay", {
  displayName: "Delay",
  type: "Number",
  paramValue: "0",
  unit: "hours",
})
.set("PackageProcessingDelayFT", {
  displayName: "Fast Track Delay",
  type: "Number",
  paramValue: "0",
  unit: "hours",
})
.set("PackageProcessingDelaySameDayDelivery", {
  displayName: "Same Day Delivery Delay",
  type: "Number",
  paramValue: "0",
  unit: "hours",
})
.set("PackageProcessingDelayRushDelivery", {
  displayName: "rush Delivery Delay",
  type: "Number",
  paramValue: "0",
  unit: "hours",
})
.set("PackageProcessingDelayRushExprDelivery", {
  displayName: "rush-expr Delivery Delay",
  type: "Number",
  paramValue: "0",
  unit: "hours",
})
.set("PackageProcessingMaxSLA", {
  displayName: "Max SLA",
  type: "Number",
  paramValue: "0",
  unit: "hours",
})
.set("PackageProcessingMaxSLAFT", {
  displayName: "Fast Track Max SLA",
  type: "Number",
  paramValue: "0",
  unit: "hours",
})
.set("PackageProcessingMaxSLASameDayDelivery", {
  displayName: "Same Day Delivery Max SLA",
  type: "Number",
  paramValue: "0",
  unit: "hours",
})
.set("PackageProcessingMaxSLARushDelivery", {
  displayName: "rush Delivery Max SLA",
  type: "Number",
  paramValue: "0",
  unit: "hours",
})
.set("PackageProcessingMaxSLARushExprDelivery", {
  displayName: "rush-expr Delivery Max SLA",
  type: "Number",
  paramValue: "0",
  unit: "hours",
});

let OperationalConfigurations = new Map();
OperationalConfigurations.set("processingTime", processingTime);

export default OperationalConfigurations;