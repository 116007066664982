import React from "react";
import { Alert } from "react-bootstrap";
import { AuditDetailResponse } from "../model/AuditDetailResponse";
import { AUDIT_DETAIL_COLUMN_METADATA } from "../../../constants/table/metadata/ConfigAuditDetail";
import PropTypes from "prop-types";
import API from "@aws-amplify/api";
import GriddleTable from "../../../components/Table/GriddleTable";
import UnauthorizedUser from "../../../views/layouts/UnauthorizedUser";
import { getAuthorizationHeader } from "@common/Auth";
import BackendError from "../../../views/layouts/BackendError";
import {RegionContext} from '@common/RegionContext';
import {constructButaneApiName} from '../../../apiConfig';

class AuditDetailTable extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    level: PropTypes.string,
    params: PropTypes.string,
    noResult: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      auditDetailSnapshot: [],
      fetchingRecords: false,
      noResult: undefined,
      openForbiddenSnackbar: false,
      networkError: false
    };
  }

  fetchAuditData = async (id, level, params) => {
    let auditDetailSnapshot = [];
    let noResult = "";
    let queryString = "";
    if (typeof params !== "undefined" && params.length > 0) {
      let parameters = [];
      parameters = params.split(",");
      parameters.forEach(element => {
        queryString = queryString + "params=" + element.trim() + "&";
      });
      queryString = "?" + queryString.slice(0, -1);
    }
    
    return API.get(constructButaneApiName(this.context.region), `/vwp/audit/${level}/${id}${queryString}`, {
      headers: await getAuthorizationHeader()
    })
      .then(data => {
        let auditDetailsList = data.auditDetails;
        for (let i = 0; i < auditDetailsList.length; i++) {
          let obj = new AuditDetailResponse(
            auditDetailsList[i].paramName,
            auditDetailsList[i].paramValue,
            auditDetailsList[i].updatedBy,
            auditDetailsList[i].updatedAt,
            auditDetailsList[i].activityName
          );
          auditDetailSnapshot.push(obj);
        }
        this.setState(() => ({
          auditDetailSnapshot: auditDetailSnapshot,
          fetchingRecords: false,
          noResult: noResult
        }));
      })
      .catch(e => {
        if (typeof e.response === 'undefined') {
          this.setState(() => ({ networkError: true }));
        }
        else
        e.response.status === 403 ? 
          this.setState(() => ({ openForbiddenSnackbar: true })): this.setState(() => ({ openForbiddenSnackbar: false }));
        this.setState(() => ({
          auditDetailSnapshot: [],
          fetchingRecords: false,
          noResult: noResult
        }));
      });
  };

  getAuditDetailsData(id, level, params) {
    if (id === "" || level === "") {
      return;
    }

    this.setState(() => ({
      noResult: undefined,
      fetchingRecords: true
    }));

    this.fetchAuditData(id, level, params);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.id !== this.props.id ||
      prevProps.params !== this.props.params ||
      prevProps.level !== this.props.level
    ) {
      this.getAuditDetailsData(
        this.props.id,
        this.props.level,
        this.props.params
      );
    }
  }

  renderRealTimeAuditDetails() {
    const columnMetadata = AUDIT_DETAIL_COLUMN_METADATA;
    return (
      <div>
        <h5 className="id">
          <span>{this.props.id}</span>
        </h5>
        <div>
          {!!this.state.noResult ? (
            <Alert variant="info">
              No Results found for following id: {this.state.noResult}
            </Alert>
          ) : (
            ""
          )}
        </div>
        <h4>
          <span className="orange-title">Audit Details</span>
        </h4>
        <GriddleTable
          results={this.state.auditDetailSnapshot}
          columnMetadata={columnMetadata}
        />
      </div>
    );
  }

  render() {
    let content;
    if (!!this.state.fetchingRecords) {
      return <div className="spinner"></div>;
    }
    if(this.state.openForbiddenSnackbar) {
      return <UnauthorizedUser openErrorSnackbar = {true} />;
    }
    if(this.state.networkError){
      return <BackendError/>;
    }
    if (!!this.props.id && !!this.props.level && !this.state.fetchingRecords) {
      content = this.renderRealTimeAuditDetails();
    }

    return <div>{content}</div>;
  }
}

AuditDetailTable.contextType = RegionContext;

export default AuditDetailTable;
