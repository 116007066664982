import {RouteComponentProps} from "react-router-dom";
import {InventoryResearchInput} from "@modules/inventory/research/InventoryResearchInput";
import React from "react";
import {FnSkuInventoryTable} from "@modules/inventory/research/tables/FnSkuInventoryTable";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import {FcSkuInventoryTable} from "@modules/inventory/research/tables/FcSkuInventoryTable";

interface InventoryResearchPageProps extends RouteComponentProps<{itemIdentifier: string}> {
    isAuthenticated: boolean,
}
export const InventoryResearchPage = (props: InventoryResearchPageProps) => {
    if (!props.isAuthenticated) {
        props.history.push("/login");
    }

    const urlParams = new URLSearchParams(window.location.search);
    const itemIdentifier = urlParams.get('itemIdentifier') ?? "";
    const warehouseList = urlParams.get('warehouses') ?? "";
    const inventoryType = urlParams.get('inventoryType') ?? "";

    return <div>
        <SpaceBetween size={"s"}>
            <InventoryResearchInput
                {...props}
                itemIdentifier={itemIdentifier}
                warehouseList={warehouseList}
                inventoryType={inventoryType}
            />
            {
                itemIdentifier && warehouseList &&
                (!inventoryType || inventoryType === 'fnSku' || inventoryType === 'fnSkuAndFcSku') &&
                <FnSkuInventoryTable
                    {...props}
                    itemIdentifier={itemIdentifier}
                    warehouseList={warehouseList}
                />
            }
            {
                itemIdentifier && warehouseList &&
                (inventoryType === 'fcSku' || inventoryType === 'fnSkuAndFcSku') &&
                <FcSkuInventoryTable
                    {...props}
                    itemIdentifier={itemIdentifier}
                    warehouseList={warehouseList}
                />
            }
        </SpaceBetween>
    </div>;
}