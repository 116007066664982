import React, { Component } from 'react';
import { Card, Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';

import PageTitle from '../../../components/PageTitle/PageTitle';

class PurchaseOrderLevelDetails extends Component {

    getPurchaseOrderLevelDetails() {
        const purchaseOrderDetails = this.props.purchaseOrderDetails;
        const purchaseOrderStatusMap = new Map([
            [0, { 'alert': "alert alert-inprocess", 'status': "PO Status : Submitted" }],
            [1, { 'alert': "alert alert-inprocess", 'status': "PO Status : Confirmed" }],
            [2, { 'alert': "alert alert-complete", 'status': "PO Status : Completed" }],
            [3, { 'alert': "alert alert-inprocess", 'status': "PO Status : Reserved" }]
        ])

        let orderStatus = purchaseOrderStatusMap.get(purchaseOrderDetails.condition);
        if (typeof orderStatus === 'undefined') {
            orderStatus = { 'alert': "alert alert-error", 'status': "Failed to get Purchase Order Status" }
        }

        return (
            <div>
                <div className="title">
                    <PageTitle>
                        PO Details for OrderId : {purchaseOrderDetails.orderId}
                    </PageTitle>
                </div>
                <p className={orderStatus.alert}>{orderStatus.status}</p>
                <div className="purchase-order-summary">
                    <Card bg="light">
                        <Card.Header bsPrefix="purchase-order-summary-title">Purchase Order Summary</Card.Header>
                        <Card.Body bsPrefix="total-item-details">
                            <Container>
                                <Row>
                                    <Col lg={true}>Warehouse</Col>
                                    <Col lg={true}>Units Expected</Col>
                                    <Col lg={true}>Units Submitted</Col>
                                    <Col lg={true}>Units Ordered</Col>
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip id="popUp">
                                                Received - MisReceived
                                        </Tooltip>
                                        }
                                    >
                                        <Col lg={true}>Units NetReceived</Col>
                                    </OverlayTrigger>
                                </Row>
                                <Row>
                                    <Col lg={true}>{purchaseOrderDetails.warehouseId}</Col>
                                    <Col lg={true}>{purchaseOrderDetails.totalQuantityExpected}</Col>
                                    <Col lg={true}>{purchaseOrderDetails.totalQuantitySubmitted}</Col>
                                    <Col lg={true}>{purchaseOrderDetails.totalQuantityOrdered}</Col>
                                    <Col lg={true}>{purchaseOrderDetails.totalQuantityNetReceived}</Col>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        )
    }

    render() {
        return (
            this.getPurchaseOrderLevelDetails()
        );
    }
}

export default PurchaseOrderLevelDetails;