import {TableEmptyState} from "@common/table";
import Table from "@amzn/awsui-components-react/polaris/table";
import React, {useContext, useEffect, useState} from "react";
import {useCollection} from "@amzn/awsui-collection-hooks";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import API from "@aws-amplify/api";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import { getAuthorizationHeader } from "@common/Auth";
import {RegionContext} from "@common/RegionContext";
import {constructButaneApiName} from "../../../../apiConfig";

export interface CommittedTransferItem {
    transferRequestId: string,
    inventoryStatus: string,
    quantity: number,
}

export interface CommittedTransferDetailsTableProps {
    fnSku: string,
    warehouse: string
}

export const CommittedTransferDetailsTable = (props: CommittedTransferDetailsTableProps) => {
    const [committedTransferItems, setCommittedTransferItems] = useState<CommittedTransferItem[]>([]);
    const [fetchingCommittedTransfers, setFetchingCommittedTransfers] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const regionContext = useContext(RegionContext);

    useEffect(() => {
        setFetchingCommittedTransfers(false);
        setCommittedTransferItems([]);
        setErrorMessage("");
        getCommittedTransferDetails(props, regionContext.region);
    }, [props, regionContext]);

    const getCommittedTransferDetails = async (props: CommittedTransferDetailsTableProps, region: string) => {
        setFetchingCommittedTransfers(true);
        let transferItems: CommittedTransferItem[] = []
        API.get(constructButaneApiName(region), `/api/transshipment/fnSku/${props.fnSku}/warehouse/${props.warehouse}`, {
                headers: await getAuthorizationHeader()
            })
            .then((data) => {
                data.transshipments.forEach(transfer => {
                    transferItems.push({
                        transferRequestId: transfer.transferRequestId,
                        inventoryStatus: transfer.location,
                        quantity: transfer.quantity
                    });
                });
            }).catch(() => {
                setErrorMessage("Error fetching committed grading order details.")
            }).finally(() => {
                setCommittedTransferItems(transferItems);
                setFetchingCommittedTransfers(false)
            });
    }

    const {
        items,
        filterProps,
        paginationProps
    } = useCollection(committedTransferItems, {
        filtering: {},
        pagination: {
            pageSize: 10
        }
    })

    return <div>
        {
            errorMessage &&
            <Alert type={"error"}>
                {errorMessage}
            </Alert>
        }
        <Table
            loading={fetchingCommittedTransfers}
            loadingText={`Fetching committed transfer orders for FNSKU: ${props.fnSku}`}
            items={items}
            empty={
                <TableEmptyState
                    resourceName={"Commited Transfer Order"}
                    resourceNamePlural={"Commited Transfer Orders"}
                    showCreateResourceButton={false}
                />
            }
            columnDefinitions={[
                {
                    id: "transferRequestId",
                    header: "TransferRequestId",
                    cell: e => e.transferRequestId,
                },
                {
                    id: "inventoryStatus",
                    header: "Inventory Status",
                    cell: e => e.inventoryStatus,
                },
                {
                    id: "quantity",
                    header: "Quantity",
                    cell: e => e.quantity,
                },
            ]}
            filter={
                <div>
                    <TextFilter
                        {...filterProps}
                    />
                </div>
            }
            pagination={
                <Pagination
                    {...paginationProps}
                />
            }
        />
    </div>
}