import React, { useEffect } from "react";
import DateRangePicker, {
  DateRangePickerProps
} from "@amzn/awsui-components-react/polaris/date-range-picker";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";

import { DATE_RANGE_FILTER_I18N_LABELS } from "@common/table/labels";
import { DateRangePickerConverter } from "@modules/transfer/departure-requests/logic";

dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(duration);
dayjs.extend(utc);

const TRANSFER_DEPART_REQUESTS_DATE_RANGE_FILTER_LABELS: DateRangePickerProps.I18nStrings =
  {
    ...DATE_RANGE_FILTER_I18N_LABELS,
    customRelativeRangeOptionDescription:
      "Set a custom range in the past (up to three months)",
    dateTimeConstraintText:
      "Range must be between 2 days - 3 months. Use 24 hour format, all times are in UTC."
  };

const LAST_TWO_WEEKS_DATE_RANGE_OPTION: DateRangePickerProps.RelativeOption =
  {
    type: "relative",
    amount: 2,
    unit: "week",
    key: "two-weeks"
  };

const RELATIVE_OPTIONS: DateRangePickerProps.RelativeOption[] = [
  LAST_TWO_WEEKS_DATE_RANGE_OPTION,
  {
    type: "relative",
    amount: 1,
    unit: "month",
    key: "one-month"
  },
  {
    type: "relative",
    amount: 2,
    unit: "month",
    key: "two-months"
  },
  {
    type: "relative",
    amount: 3,
    unit: "month",
    key: "two-months"
  }
];

const DEFAULT_DATE_RANGE_FILTER = LAST_TWO_WEEKS_DATE_RANGE_OPTION;

const UTC_TIME_OFFSET = 0;

const INCLUSIVE_TIME_RANGE_IDENTIFIER = "[]";

const MAX_MONTHS_AHEAD = 3;

const DATE_RANGE_FILTER_PLACEHOLDER = "Filter by a date and time range";

interface TransferDepartRequestsDateRangeFilterProps {
  rangeFilter: DateRangePickerProps.Value | null;
  onRangeFilterChange: (event: DateRangePickerProps.ChangeDetail) => void;
}

export function TransferDepartRequestsDateRangeFilter({
  rangeFilter,
  onRangeFilterChange
}: TransferDepartRequestsDateRangeFilterProps) {
  useEffect(() => {
    onRangeFilterChange({
      value: DEFAULT_DATE_RANGE_FILTER
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function isValidRangeFunction(
    range: DateRangePickerProps.Value | null
  ):
    | DateRangePickerProps.ValidRangeResult
    | DateRangePickerProps.InvalidRangeResult {
    if (!range) {
      return {
        valid: false,
        errorMessage:
          "The selected date range is incomplete. Specify a duration for the date range."
      };
    }

    if (range?.type === "absolute") {
      return validateAbsoluteRangeType(range);
    } else if (range?.type === "relative") {
      return validateRelativeRangeType(range);
    }

    return { valid: true };
  }

  function validateAbsoluteRangeType(
    range: DateRangePickerProps.AbsoluteValue
  ) {
    if (!range.startDate || !range.endDate) {
      return {
        valid: false,
        errorMessage:
          "The selected date range is incomplete. Select a start and end date for the date range."
      };
    }

    const dateRange = DateRangePickerConverter.getDateRange(range);

    const differenceInDays = dateRange?.toDate?.diff(dateRange.fromDate, "day");
    if (differenceInDays! <= 1) {
      return {
        valid: false,
        errorMessage:
          "The selected date range is too small. Select a range larger than one day."
      };
    }

    const startDatePlusThreeMonths = dayjs(dateRange.fromDate)
      .utc()
      .add(MAX_MONTHS_AHEAD, "M")
      .endOf("day");

    const isWithinMaxRangeOfThreeMonths = dayjs(dateRange.toDate)
      .utc()
      .isBetween(
        dateRange.fromDate,
        startDatePlusThreeMonths,
        "millisecond",
        INCLUSIVE_TIME_RANGE_IDENTIFIER
      );

    if (!isWithinMaxRangeOfThreeMonths) {
      return {
        valid: false,
        errorMessage:
          "The selected date range is too large. Select a range up to three months."
      };
    }

    return { valid: true, errorMessage: "" };
  }

  function validateRelativeRangeType(
    range: DateRangePickerProps.RelativeValue
  ) {
    if (isNaN(range.amount)) {
      return {
        valid: false,
        errorMessage:
          "The selected date range is incomplete. Specify a duration for the date range."
      };
    }

    const duration =
      DateRangePickerConverter.getDurationFromRelativeValue(range);

    if (duration.asDays() < 2) {
      return {
        valid: false,
        errorMessage:
          "The selected date range is too small. Select a range larger than one day."
      };
    }
    if (duration.asMonths() > 3) {
      return {
        valid: false,
        errorMessage:
          "The selected date range is too large. Select a range up to three months."
      };
    }

    return { valid: true, errorMessage: "" };
  }

  return (
    <DateRangePicker
      i18nStrings={TRANSFER_DEPART_REQUESTS_DATE_RANGE_FILTER_LABELS}
      value={rangeFilter}
      onChange={(e) => onRangeFilterChange(e.detail)}
      placeholder={DATE_RANGE_FILTER_PLACEHOLDER}
      isValidRange={isValidRangeFunction}
      timeInputFormat="hh:mm"
      isDateEnabled={(date) => {
        return dayjs(date).isSameOrBefore(dayjs());
      }}
      relativeOptions={RELATIVE_OPTIONS}
      timeOffset={UTC_TIME_OFFSET}
    />
  );
}
