import * as React from "react";
import { AppLayout } from "@amzn/awsui-components-react";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from '@amzn/awsui-components-react/polaris/header';
import {RouteComponentProps} from "react-router-dom";


interface UserGuideProps extends RouteComponentProps {
    isAuthenticated: boolean;
}

export const UserGuide: React.FC<UserGuideProps> = (props: UserGuideProps) => {

    //Redirect users to login page if they are not authenticated.
    if (!props.isAuthenticated) {
        props.history.push("/login");
    }

    return (
        <AppLayout
            navigationHide={true}
            toolsHide={true}
            headerSelector="#top-navigation"
            content={
                <Container header={<Header>ButaneWebsite User Guide</Header>}>
                    <div>
                        <div className="cke_widget_wrapper cke_widget_block cke_widget_xwiki-macro cke_widget_wrapper_macro"
                             contentEditable="false" data-cke-widget-wrapper="1" data-cke-filter="off"
                             data-cke-display-name="macro:toc" data-cke-widget-id="0">
                            <div className="macro cke_widget_element" data-macro="startmacro:toc|-|"
                                 data-cke-widget-data="%7B%22name%22%3A%22toc%22%2C%22parameters%22%3A%7B%7D%2C%22inline%22%3Afalse%2C%22classes%22%3A%7B%22macro%22%3A1%7D%7D"
                                 data-cke-widget-upcasted="1" data-cke-widget-keep-attr="0" data-widget="xwiki-macro">
                                <div className="amazon-wiki-toc">
                                    <ul>
                                        <li><span className="wikilink"><a href="#HContext" data-cke-saved-href="#HContext">Context</a></span>
                                        </li>
                                        <li><span className="wikilink"><a href="#HEFPPortalLink" data-cke-saved-href="#HEFPPortalLink">EFP Portal Link</a></span>
                                        </li>
                                        <li><span className="wikilink"><a href="#HHowdoIsignUp3F" data-cke-saved-href="#HHowdoIsignUp3F">How do I sign Up?</a></span>
                                        </li>
                                        <li><span className="wikilink"><a href="#HHowdoiinviteexternal2FinternaluserstosignuponButaneWebsite3F"
                                                                          data-cke-saved-href="#HHowdoiinviteexternal2FinternaluserstosignuponButaneWebsite3F">How do i invite external/internal users to sign up on ButaneWebsite?</a></span>
                                        </li>
                                        <li><span className="wikilink"><a href="#HInventoryVisibilityTool"
                                                                          data-cke-saved-href="#HInventoryVisibilityTool">Inventory Visibility Tool</a></span>
                                        </li>
                                        <li><span className="wikilink"><a href="#HConfigTool" data-cke-saved-href="#HConfigTool">Config Tool</a></span>
                                        </li>
                                        <li><span className="wikilink"><a href="#HRemovalsTool" data-cke-saved-href="#HRemovalsTool">Removals Tool</a></span>
                                        </li>
                                        <li><span className="wikilink"><a href="#HShipmentConditionsTools"
                                                                          data-cke-saved-href="#HShipmentConditionsTools">Shipment Conditions Tools</a></span>
                                        </li>
                                        <li><span className="wikilink"><a href="#HTransferTool" data-cke-saved-href="#HTransferTool">Transfer Tool</a></span>
                                        </li>
                                        <li><span className="wikilink"><a href="#HNodeLaunchTool" data-cke-saved-href="#HNodeLaunchTool">Node Launch Tool</a></span>
                                        </li>
                                        <li><span className="wikilink"><a href="#HInbound">Inbound</a></span></li>
                                        <li><span className="wikilink"><a href="#HAdminTool"
                                                                          data-cke-saved-href="#HAdminTool">Admin Tool</a></span></li>
                                        <li><span className="wikilink"><a href="#HFAQs" data-cke-saved-href="#HFAQs">FAQs</a></span></li>
                                    </ul>
                                </div>
                            </div>
                            <span className="cke_reset cke_widget_drag_handler_container"><img
                                className="cke_reset cke_widget_drag_handler" title="Click and drag to move"
                                src="data:image/gif;base64,R0lGODlhAQABAPABAP///wAAACH5BAEKAAAALAAAAAABAAEAAAICRAEAOw==" alt="" width="15"
                                height="15" data-cke-widget-drag-handler="1"/></span></div>
                        <h3 id="HContext">Context</h3>
                        <p>For operations to manage EFP warehouses, EFP team provides EFPM portal. But due to scalability concerns EFP
                            team is building a new tool set called "Butane Website". This page provides user guide for signing up and
                            using Butane Website. ButaneWebsite is accessible to internal i.e. amazon employees as well as external users
                            i.e. Non-Amazon Employees.</p>
                        <p><strong>Note:</strong> ButaneWebsite is a invite only tool so you need to have temporary login credentials in
                            order to use ButaneWebsite.</p>
                        <p>&nbsp;</p>
                        <h3 id="HEFPPortalLink">EFP Portal Link</h3>
                        <p>We have a globalized website i.e. there are no region specific website.&nbsp;</p>
                        <ul>
                            <li><a title="https://dub.efp.amazonoperations.app/" href="https://dub.efp.amazonoperations.app/"
                                   data-cke-saved-href="https://dub.efp.amazonoperations.app/" data-linktype="external"
                                   data-reference="true|-|url|-|https://dub.efp.amazonoperations.app/">https://butane.efp.amazonoperations.app/</a>&nbsp;
                            </li>
                        </ul>
                        <p><strong>Note :&nbsp;&nbsp;</strong>Once you are successfully logged in, then you have an option to change the
                            region based on the warehouse you are fetching data for.&nbsp;</p>
                        <p>&nbsp;</p>
                        <h3 id="HHowdoIsignUp3F">How do I sign Up?</h3>
                        <ol>
                            <li>ButaneWebsite is a invitation based tool where a user (internal or external) has to be invited by a
                                Website Admin.
                            </li>
                            <li>Once you are invited to use the ButaneWebsite, you will receive an invitation email from <a
                                href="/bin/view/no-reply%40butane/efp/amazonoperations/app/"
                                data-cke-saved-href="/bin/view/no-reply%40butane/efp/amazonoperations/app/" data-linktype=""
                                data-reference="false|-|doc|-|url:no-reply@butane.efp.amazonoperations.app">no-reply@butane.efp.amazonoperations.app</a> with
                                your user name and temporary password.
                            </li>
                            <li>After receiving the temporary credentials, navigate to <a
                                href="https://butane.efp.amazonoperations.app/login"
                                data-cke-saved-href="https://butane.efp.amazonoperations.app/login" data-linktype="external"
                                data-reference="false|-|url|-|https://butane.efp.amazonoperations.app/login">https://butane.efp.amazonoperations.app/login</a> enter
                                your user name and temporary password.
                            </li>
                            <li>Once the username &amp; password is validated, then you will be prompted to create a new password.</li>
                            <li>Enter the new password and hit enter, then you will be able to use ButaneWebsite using your username and
                                newly created password..&nbsp;</li>
                            <li>Once login successfully, you need to make sure you are in the correct region.&nbsp;</li>
                        </ol>
                        <p><strong>Note :&nbsp;</strong> Password should satisfy following conditions :&nbsp;</p>
                        <ul>
                            <li>Contains at least 1 number</li>
                            <li>Contains at least 1 special character</li>
                            <li>Contains at least 1 uppercase letter</li>
                            <li>Contains at least 1 lowercase letter</li>
                        </ul>
                        <p>&nbsp;</p>
                        <h3 id="HHowdoiinviteexternal2FinternaluserstosignuponButaneWebsite3F">How do i invite external/internal users
                            to sign up on ButaneWebsite?</h3>
                        <p>Butane Website is an invitation based tool. ButaneWebsite Admin users can invite internal as well as external
                            users to sign up on the ButaneWebsite using temporary credentials.</p>
                        <p><strong>Who is a ButaneWebsite Admin ?</strong><br/>ButaneWebsite Admin is a privileged user who has the
                            permissions to invite external/internal users to sign up on Butane Website.&nbsp;</p>
                        <p><strong>How can i become a ButaneWebsite Admin ?</strong><br/>You can request access here : <a href="https://permissions.amazon.com/a/team/amzn1.abacus.team.rorwozqq2s24vg3sjbka"> Admin Team</a>.</p>
                        <p><strong>Note :&nbsp;</strong>ButaneWebsite Admin Users is a<strong>&nbsp;</strong>privileged role. Please do
                            not abuse it and your activities are being monitored.&nbsp;&nbsp;</p>
                        <p><strong>I have ButaneWebsite Admin privileges, How can i invite users ?</strong><br/>You can invite Amazon
                            Employees to sign in as well as external 3PL warehouse employees on Butane. Visit link : <a href="https://butane.efp.amazonoperations.app/admin/invite-users"
                                                                                                                        data-cke-saved-href="https://butane.efp.amazonoperations.app/admin/invite-users"
                                                                                                                        data-linktype="external"
                                                                                                                        data-reference="true|-|url|-|https://butane.efp.amazonoperations.app/admin/invite-users">https://butane.efp.amazonoperations.app/admin/invite-users. </a>
                            For external 3PL warehouse associates/managers, You have to enter their email and the warehouse which they
                            belongs to and then hit invite. For Amazon Employees, You have to enter their email.</p>
                        <p><strong>Note :&nbsp;</strong>Warehouse is a mandatory field in case of External 3PL warehouse associates.</p>
                        <p>&nbsp;</p>
                        <h3 id = "HInventoryVisibilityTool">Inventory Tool</h3>

                        <p>Inventory tool itself has many sub-tools which can be used to research the inventory availability and inventory audits.</p>

                        <ul>
                            <li>Inventory Research Page: <a href="https://dub.efp.amazonoperations.app/inventory/research">https://dub.efp.amazonoperations.app/inventory/research</a>

                                <ul>
                                    <li>This page can be used to search the inventory present inside Amazon for a given item identifier and a warehouse. This tool can be used to search both Asin / FnSku and FcSku inventory.</li>
                                </ul>
                            </li>
                            <li>FnSku Inventory Audits Page: <a href="https://dub.efp.amazonoperations.app/inventory/audits">https://dub.efp.amazonoperations.app/inventory/audits</a>
                                <ul>
                                    <li>This page can be used to search audits on FnSku inventory for a given warehouse and selected date range. The audit will show different operations performed on that FnSku like number of units received, units shipped in customer orders, units shipped in transfer order, units lost and found etc.</li>
                                </ul>
                            </li>
                            <li>FcSku Inventory Audits Page: <a href="https://dub.efp.amazonoperations.app/inventory/audits/fcsku">https://dub.efp.amazonoperations.app/inventory/audits/fcsku</a>
                                <ul>
                                    <li>This page is similar to FnSku Inventory Audits Page mentioned above, except that this page will be used to search the audits on FcSku inventory rather than FnSku inventory.</li>
                                </ul>
                            </li>
                            <li>FcSku Inventory Discrepancy Page: <a href="https://dub.efp.amazonoperations.app/inventory/fcsku/discrepancy">https://dub.efp.amazonoperations.app/inventory/fcsku/discrepancy</a>
                                <ul>
                                    <li>This page can be used to search the discrepancy in FcSku inventory comparing the inventory sent by the warehouse in nightly feed vs inventory present inside Amazon for given FcSku. Since the number of different FcSku present inside warehouse is usually large, the records will be shown in pages. In order to download the discrepancy report, please click on Download CSV option given on the top of the table. You will be able to download records in batch of 20,000 records.</li>
                                </ul>
                            </li>
                            <li>FcSku Tracking Page: <a href="https://dub.efp.amazonoperations.app/fcsku/details">https://dub.efp.amazonoperations.app/fcsku/details</a>
                                <ul>
                                    <li>This page can be used to search the different attributes attached to FcSku like LPN, Expiry Date, Provenance attributes like Vendor Code, IOG and PO. You need to enter FcSku and warehouse and the output will show selected set of attributes for that FcSku.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>&nbsp;</p>
                        <h3 id="HConfigTool">Config Tool</h3>
                        <p>This tool is only accessible to Amazon Employees.</p>
                        <p>We have added a <strong>self-service config page</strong>.&nbsp;</p>
                        <ul>
                            <li>Please login with your existing ID.</li>
                            <li>You can see a new Tab called Configuration Management next to the Inventory Tab. Select it.</li>
                            <li>If nothing happens, please logout and clear the browse Cache.</li>
                            <li>Once you select &ldquo;Configuration Management&rdquo;, you will be given 3 options
                                <ul>
                                    <li><strong>Audit Details</strong>: In this page, you can search using Marketplace ID or Warehouse ID. You
                                        will be given the list of VWP (Config) that are available for that node in EFP and the current state
                                        (value) and when it was last updated and by who. If you are looking for a particular Config, you can
                                        filter it by using the search field.
                                    </li>
                                    <li><strong>Bulk Update Feature</strong>: In this page, you can chose one parameter and its value and
                                        multiple Warehouses that needs the update. This was a feature that was asked by most stakeholders.
                                    </li>
                                    <li><strong>Node Settings</strong>: In this page, user can edit any Config in a particular Node/Warehouse.
                                        You search by Warehouse and you will be shown all the Configs for that Warehouse with the current value
                                        and you can edit it.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p>&nbsp;</p>
                        <h3 id="HRemovalsTool">Removals Tool</h3>
                        <p>In order to get removal order summary,</p>
                        <ul>
                            <li>Login with your existing ID.</li>
                            <li>You will see a new Tab call Removals next to Configuration Management. Select it</li>
                            <li>If nothing happens, please close any existing tab for the application and clear the browse Cache</li>
                            <li>Once you select &ldquo;Removals&rdquo;, select "Removal Info"</li>
                            <li>This tool supports search using either removal order ID or VRET/LIQN ID.</li>
                            <li>Enter the either the removal order ID or VRET/LIQN ID. Enter the current warehouse for the removal order
                                and click "Search"
                            </li>
                            <li>If the removal order is present in our system, you will be able to see the response which describes the
                                type of removal order "FRACS/NON-FRACS", removal order status (CREATED, SPLITTING, SPLITTED, CLOSED,
                                UNKOWN), &nbsp;removal summary, item details and shipment details (in case of FRACS shipments)
                            </li>
                        </ul>
                        <p>For any issues, please report here: <a href="https://tiny.amazon.com/92w3auxk"
                                                                  data-cke-saved-href="https://tiny.amazon.com/92w3auxk"
                                                                  data-linktype="internal"
                                                                  data-reference="true|-|url|-|https://tiny.amazon.com/92w3auxk">https://tiny.amazon.com/92w3auxk</a>
                        </p>
                        <p>&nbsp;</p>
                        <h3 id="HShipmentConditionsTools">Shipment Conditions Tools</h3>
                        <ul>
                            <li>Login with your existing ID.</li>
                            <li>Go to Shipment -&gt; Shipment Research</li>
                            <li>Input a comma seperated list of warehouses you want to deep dive into</li>
                            <li>Click on Search Button. This will show shipments pending in various conditions in EFP.</li>
                            <li>For understanding the meaning of each condition - see: <a
                                href="https://w.amazon.com/index.php/FaaST/Teams/External%20Integration/Operations/Documentation/EFP%20Shipment%20Conditions"
                                data-cke-saved-href="https://w.amazon.com/index.php/FaaST/Teams/External%20Integration/Operations/Documentation/EFP%20Shipment%20Conditions"
                                data-linktype="internal"
                                data-reference="true|-|url|-|https://w.amazon.com/index.php/FaaST/Teams/External%20Integration/Operations/Documentation/EFP%20Shipment%20Conditions">https://w.amazon.com/index.php/FaaST/Teams/External%20Integration/Operations/Documentation/EFP%20Shipment%20Conditions</a>
                            </li>
                        </ul>
                        <p>&nbsp;</p>
                        <h3 id="HTransferTool">Transfer Tool</h3>
                        <p>We provide 3 different functionalities under transfers.&nbsp;</p>
                        <ul>
                            <li>Transfer Depart Requests Page : <a
                                href="https://dub.efp.amazonoperations.app/transfers/departure-requests"
                                data-cke-saved-href="https://dub.efp.amazonoperations.app/transfers/departure-requests"
                                data-linktype="external"
                                data-reference="true|-|url|-|https://dub.efp.amazonoperations.app/transfers/departure-requests">https://butane.efp.amazonoperations.app/transfers/departure-requests</a>
                                <ul>
                                    <li>This page provides the information(like AmazonShipmentReferenceId, Status, Creation Date, etc) about
                                        the Outbound Transfer happening from one source warehouse to another warehouse. By default we show
                                        transfer departs from last 2 weeks but you can change the time from the option available on the
                                        page.&nbsp;</li>
                                </ul>
                            </li>
                            <li>Failed Depart Items Page :
                                <ul>
                                    <li>Input : AmazonShipmentReferenceId. You can get this id from the Transfer Depart Requests Page.</li>
                                    <li>This page provides the information about the Outbound Transfer happening from one source warehouse to
                                        another warehouse. By default we show transfer departs from last 2 weeks but you can change the time
                                        from the option available on the page.&nbsp;</li>
                                </ul>
                            </li>
                            <li>&nbsp;Cancel Transfer Request Page :
                                <ul>
                                    <li>This tool is only available to Amazon Employees.</li>
                                    <li>This tool is used to cancel Transfer Requests.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>&nbsp;</p>
                        <h3 id = "HInbound">Inbound</h3>

                        <p>We provide following different functionalities under Inbound Tool :</p>

                        <ul>
                            <li><strong>Purchase Order Page :</strong> This page provides the information about the purchase order and the items. You need to enter purchase order id and warehouse it is associated with as the input.</li>
                            <li><strong>Problem Items Page :&nbsp;</strong>This page provides the information about the problem items for a given warehouse. You need to enter warehouseId as the input.</li>
                            <li><strong>BlowOut RPI :</strong> This tool is only accessible to amazon employees. Using this page you can delete the problem items. You need to enter the problemItemId and the warehouse they belongs to.</li>
                        </ul>
                        <p>&nbsp;</p>
                        <h3 id = "#HNodeLaunchTool">Node Launch Tool</h3>

                        <p>This tool is only accessible to specific Amazon Employees.</p>

                        <p>We provide following different functionalities under Admin Tool :&nbsp;</p>

                        <ul>
                            <li>View Registrations : <a href="https://dub.efp.amazonoperations.app/registrations">https://butane.efp.amazonoperations.app/registrations</a><br />
                                This page provides the details of the all Node Registrations that were created in EFP Systems.</li>
                            <li>View Registration : <a href="https://dub.efp.amazonoperations.app/registrations/72029a69-3ac3-442f-9b56-ab688923ba1c">https://butane.efp.amazonoperations.app/registrations/72029a69-3ac3-442f-9b56-ab688923ba1c</a><br />
                                This page provides the details of a particular Node Registration that was created in EFP Systems and the node launch plans associate with this registration.</li>
                            <li>Create Registrations :<br />
                                This page provides the functionality to create a Node Registration for launching new nodes as part of that registration.</li>
                            <li>Create Node Launch Plan :<br />
                                This page provides the functionality to create a Node Launch Plan so that new nodes can be launched as part of that node launch plan.</li>
                        </ul>
                        <h3>&nbsp;</h3>
                        <h3 id = "#HAdminTool">Admin Tool</h3>
                        <p>This tool is only accessible to specific Amazon Employees.</p>
                        <p>We provide following different functionalities under Admin Tool :</p>
                        <ul>
                            <li><strong>Invite Users Tool :</strong> <a href="https://butane.efp.amazonoperations.app/admin/invite-users">https://butane.efp.amazonoperations.app/admin/invite-users</a><br />
                                This page provides functionality for the ButaneWebsite Admins to invite external 3PL warehouse employees as well as Amazon Employees. Once the Admin enters the user information, then the users will receive a email with the temporary login credentials which they can use to login into Butane Website.</li>
                        </ul>
                        <p>&nbsp;</p>
                        <h3 id="HFAQs">FAQs</h3>
                        <h6 id="H231Iforgotmypassword2Chowcaniresetit3F">#1 I forgot my password, how can i reset it ?</h6>
                        <p>ButaneWebsite provides users an option to reset their password. Visit <a
                            href="https://beta.dub.efp.amazonoperations.app/forgot-password"
                            data-cke-saved-href="https://beta.dub.efp.amazonoperations.app/forgot-password" data-linktype="external"
                            data-reference="true|-|url|-|https://beta.dub.efp.amazonoperations.app/forgot-password">https://butane.efp.amazonoperations.app/forgot-password</a> and
                            enter your email that you use to sign in. A OTP will be then be sent on your email using which you can reset
                            your password.</p>
                        <h6 id="H232Ihavetemporarylogincredentialsbutiamnotabletosing-in3F">#2 I have temporary login credentials but i
                            am not able to sing-in?</h6>
                        <p>Temporary login credentials sent in the invitation mail are only valid for 7 days. Please reach out to your
                            Amazon POC to invite you again.</p>
                        <h6 id="H233Iamtryingtoinviteanexternaluser2Cbutfacingerror3F">#3 I am trying to invite an external user, but
                            facing error ?</h6>
                        <p>There are two possible scenarios here :&nbsp;</p>
                        <ul>
                            <li>&nbsp;User has already been invited and they had also successfully reset their temporary credentials.
                                <ul>
                                    <li>In this case you need to ask user to try resetting their password.&nbsp;</li>
                                </ul>
                            </li>
                            <li>2. User has already been invited but their temporary credentials are not valid anymore.
                                <ul>
                                    <li>You can try re inviting the users and then they will get the fresh credentials which will be valid for
                                        next 7 days.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p>If the issue persists, Please comment on this SIM with details : <a
                            href="https://sim.amazon.com/issues/EFP-VFX-508123"
                            data-cke-saved-href="https://sim.amazon.com/issues/EFP-VFX-508123" data-linktype="internal"
                            data-reference="false|-|url|-|https://sim.amazon.com/issues/EFP-VFX-508123">https://sim.amazon.com/issues/EFP-VFX-508123</a> or
                            reach out to butane-efp@amazon.com.</p>
                        <p>&nbsp;</p>
                    </div>
                </Container>}/>
    );
}