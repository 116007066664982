import Auth from "@aws-amplify/auth";
import React from "react";
import { Redirect } from 'react-router';
import { Form, FormGroup, FormControl, FormLabel, Button, Spinner } from "react-bootstrap";
import { validateEmail, validatePassword } from "./Utils";
import "./forgotPassword.css";

interface ForgotPasswordProps {
  isAuthenticated: boolean;
}

interface ForgotPasswordState {
  loading: boolean;
  email: string;
  newPassword: string;
  verificationCode: string;
  emailVerified: boolean;
  redirect: boolean;
  validated: boolean;
}

export default class ForgotPassword extends React.Component<ForgotPasswordProps, ForgotPasswordState> {
  constructor(props: ForgotPasswordProps) {
    super(props);

    this.state = {
      loading: false,
      email: "",
      newPassword: "",
      verificationCode: "",
      emailVerified: false,
      redirect: false,
      validated: false,
    };
  }

  onChange = (event: any) => {
    const target = event.target as HTMLInputElement;
    this.setState({ ...this.state, [target.name]: target.value });
  }

  onResetPasswordSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (event.currentTarget.checkValidity() === false) {
      event.stopPropagation();
    } else {
      this.setState({ loading: true, validated: true });
      Auth.forgotPasswordSubmit(this.state.email, this.state.verificationCode, this.state.newPassword)
        .then(() => {
          this.setState({ loading: false, redirect: true });
        }).catch((e: any) => {
          alert(e.message);
          this.setState({ loading: false });
        });
    }
    this.setState({ validated: true });
  }

  onForgotPassword = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.setState({ loading: true });
    Auth.forgotPassword(this.state.email)
      .then(() => this.setState({ loading: false, emailVerified: true }))
      .catch((e: any) => {
        alert(e.message);
        this.setState({ loading: false });
      });
  }

  validateResetPasswordForm = () => {
    return validateEmail(this.state.email) && validatePassword(this.state.newPassword);
  }

  validateForgotPasswordForm = () => {
    return validateEmail(this.state.email);
  }

  showForgotPasswordForm = () => {
    if (this.state.redirect) return <Redirect to='/login' />
    const { email } = this.state;

    return (
      <Form noValidate onSubmit={this.onForgotPassword}>
        <FormGroup controlId="confirmationCode">
          <FormLabel>Email</FormLabel>
          <FormControl
            name="email"
            type="email"
            value={email}
            onChange={this.onChange}
            minLength={5}
            isValid={validateEmail(email)}
            required />
          <FormControl.Feedback type="invalid">Must be a valid amazon email address</FormControl.Feedback>
          <Form.Text className="text-muted">
            A confirmation code will be sent to the email address provided
          </Form.Text>
        </FormGroup>
        <Button block type="submit" disabled={!this.validateForgotPasswordForm()}>
          {this.state.loading ?
            <span><Spinner size="sm" animation="border" className="mr-2" />Confirming</span> :
            <span>Submit</span>}
        </Button>
      </Form>
    );
  }

  showResetPasswordForm = () => {
    if (this.state.redirect) return <Redirect to='/login' />
    const { email, newPassword, verificationCode, validated } = this.state;
    return (
      <Form noValidate validated={validated} onSubmit={(e: React.FormEvent<HTMLFormElement>) => this.onResetPasswordSubmit(e)}>
        <FormGroup controlId="email">
          <FormLabel>Email</FormLabel>
          <FormControl
            name="email"
            type="email"
            onChange={this.onChange}
            value={email}
            minLength={5}
            isValid={validateEmail(email)}
            required />
        </FormGroup>
        <FormGroup>
          <FormLabel>Verification Code</FormLabel>
          <FormControl
            name="verificationCode"
            type="tel"
            onChange={this.onChange}
            value={verificationCode}
            minLength={1}
            required />
        </FormGroup>
        <FormGroup controlId="password" >
          <FormLabel>Password</FormLabel>
          <FormControl
            name="newPassword"
            type="password"
            onChange={this.onChange}
            value={newPassword}
            minLength={8}
            isValid={validatePassword(newPassword)}
            required />
          <FormControl.Feedback type="invalid">Use 8 or more characters with a mix of uppercase letters, numbers & symbols</FormControl.Feedback>
          <Form.Text className="text-muted">
            Use 8 or more characters with a mix of uppercase letters, numbers & symbols
        </Form.Text>
        </FormGroup>
        <Button block type="submit" disabled={!this.validateResetPasswordForm()}>
          {this.state.loading ?
            <span><Spinner size="sm" animation="border" className="mr-2" />Resetting Password</span> :
            <span>Reset Password</span>}
        </Button>
      </Form>
    );
  }

  render() {
    if (this.props.isAuthenticated) return <Redirect to='/' />
    return (
      <div className="ForgotPassword">
        {this.state.emailVerified ? this.showResetPasswordForm() : this.showForgotPasswordForm()}
      </div>
    );
  }
}
