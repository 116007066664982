import React from 'react';
import Auth, { CognitoUser } from "@aws-amplify/auth";
import { useState, useEffect } from "react";
import { AuthState } from "@aws-amplify/ui-components";
import { useBetween } from "use-between";
import { OTPPage } from './OTPPage';
import{ Login } from './Login';
import { ResetPassword } from './ResetPassword';
import { useHistory } from "react-router";


export const useSharedAuthState = () => {
    const [authState, setAuthState] = useState<AuthState>(AuthState.SignIn);
    return {
        authState,
        setAuthState
    };
};

export const useSharedUserState = () => {
    const [user, setUser] = useState<CognitoUser | undefined>();
    return {
        user,
        setUser
    };
};

export const useSharedUnAuthEmailState = () => {
    const [unAuthEmail, setUnAuthEmail] = useState<string>("")
    return {
        unAuthEmail,
        setUnAuthEmail
    };
}

export const useSharedUserAttributesState = () => {
    const [email, setEmail] = useState("")
    const [warehouses, setWarehouses] = useState<string>("")

    return {
        email,
        setEmail,
        warehouses,
        setWarehouses
    }
}


export const setUserAttributes = async (setEmail: any,
    setWarehouses: any) => {
    try {
        const currentUser = await Auth.currentUserInfo();
        if (currentUser) {
            const { attributes } = currentUser;
            setEmail(attributes["email"]);
            setWarehouses(attributes["custom:warehouses"]);
        }
    } catch (err) {
        console.error(err);
    }
};

export const LoginWithAuth: React.FC = () => {

    const { authState } = useBetween(useSharedAuthState);
    const history = useHistory();
    useEffect(() => {
        const checkUserAuth = async () => {
            try {
                if(authState === AuthState.SignedIn) {
                    history.push('/');
                    document.getElementById('region-help-popover')?.click();
                }
            } catch (err) {
                console.error(err);
            }
        }
        checkUserAuth();
    }, [ authState, history ]);
    
return (
    <>
        { authState === AuthState.SignIn && <Login/>}
        { authState === AuthState.confirmingSignUpCustomFlow && <ResetPassword />}
        { authState === AuthState.confirmingSignInCustomFlow && <OTPPage />}

    </>);
}
