import React from "react";

import { TableResourceLink } from "@common/table";

interface TransferDepartWorkflowDetailsLinkProps {
  amazonShipmentReferenceId: string;
  warehouse: string;
  workflowId: string;
}

export function TransferDepartWorkflowDetailsLink({
  amazonShipmentReferenceId,
  warehouse,
  workflowId
}: TransferDepartWorkflowDetailsLinkProps) {
  const TRANSFER_DEPART_WORKFLOW_DETAILS_ROUTE = `/transfers/${warehouse}/departure-requests/${workflowId}`;

  return (
    <TableResourceLink
      href={TRANSFER_DEPART_WORKFLOW_DETAILS_ROUTE}
      text={amazonShipmentReferenceId}
    />
  );
}
