import React from "react";

const InputField = ({value, param, handleChange, placeholder, error}) => {
    let inputFieldClasses = (error === true)? "config-field-input error-input": "config-field-input";
    return(
        <div className="config-field display-inline">
            <input 
                className={inputFieldClasses}
                placeholder={placeholder}
                value={value}
                onChange={(e) => handleChange(e, param)}
                />
        </div>
    )
}

export default InputField;