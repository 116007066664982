import React, { Component } from "react";
import { Alert, AlertTitle } from "@material-ui/lab";

import PageTitle from "../../../components/PageTitle/PageTitle";

class BulkDeleteProblemReceiveSummaryDetails extends Component {

    getSuccessfulDeletionDetails() {
        return (
            <div>
                <div className="inbound-page-title">
                    <PageTitle>
                        Problem Item Deletion Details
                    </PageTitle>
                </div>
                {!!this.props.successList &&
                    <div>
                        <Alert className="success-deletion" severity="success">
                            <AlertTitle><strong>Successfully Deleted :  </strong> </AlertTitle>
                            {this.props.successList}
                        </Alert>
                    </div>}
            </div>
        )
    }

    render() {
        return (
            this.getSuccessfulDeletionDetails()
        );
    }
}

export default BulkDeleteProblemReceiveSummaryDetails;