import React, { useState } from "react";
import { RouteComponentProps } from "react-router";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import SelectShipmentIdForm from "@modules/shipment/searchByShipmentId/SelectShipmentIdForm";
import { useFlashbar } from "@common/flashbar";
import { ShipmentDetails } from "@modules/shipment/searchByShipmentId/ShipmentDetails";

interface SearchShipmentByIdParams {
  shipmentId: string;
}

interface SearchShipmentByIdProps
  extends RouteComponentProps<SearchShipmentByIdParams> {
  isAuthenticated: boolean;
}

export function SearchShipmentById({
  isAuthenticated,
  history,
  match
}: SearchShipmentByIdProps) {
  const [shipmentId, setShipmentId] = useState("");
  const [redirectPath, setRedirectPath] = useState("");
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const { FlashbarContainer } = useFlashbar();

  if (!isAuthenticated) {
    history.push("/login");
  }

  function constructRedirectPath(searchShipmentId: string) {
    const redirectPath = `/shipment/${searchShipmentId}`;

    setShipmentId(searchShipmentId);
    setRedirectPath(redirectPath);
    setShouldRedirect(true);
  }

  function hasShipmentIdRouteParam() {
    return match?.params?.shipmentId;
  }

  function shouldSelectShipmentId() {
    return !hasShipmentIdRouteParam() && !shouldRedirect;
  }

  function shouldRedirectWithShipmentIdParam() {
    return !hasShipmentIdRouteParam() && shouldRedirect;
  }

  if (shouldSelectShipmentId()) {
    return (
      <SelectShipmentIdForm
        shipmentId={shipmentId}
        constructRedirectPath={constructRedirectPath}
        usePolaris={true}
      />
    );
  }

  if (shouldRedirectWithShipmentIdParam()) {
    setShouldRedirect(false);
    history.push(redirectPath);
  }

  return (
    <AppLayout
      notifications={<FlashbarContainer />}
      navigationHide={true}
      toolsHide={true}
      headerSelector="#top-navigation"
      contentType="default"
      content={
        <>
          <ShipmentDetails/>
        </>
      }
    />
  );
}
