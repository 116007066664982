// React
import React from "react";
 
// Material UI Components
import {LiveHelp, BugReport, NewReleases, ChromeReaderMode} from '@material-ui/icons';
import { blueGrey } from '@material-ui/core/colors';
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tooltip from "@material-ui/core/Tooltip";
import Menu from "@material-ui/core/Menu";
import { navigateToUrl } from "../../common/Navigate";
 
class Feedback extends React.Component {
    BUG_REPORT_URL = "https://tiny.amazon.com/hp5hj87v/tcorpamazcreaopti";
    FEATURE_REQUEST_URL = "https://tiny.amazon.com/1q22c412";
    USER_GUIDE_URL = "/userguide";

    state = {
        anchorEl: null,
    };
 
    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };
 
    handleClose = () => {
        this.setState({ anchorEl: null });
    };
    render() {
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
 
        return (
            <>
                <Tooltip title="feedback / support">
                    <IconButton
                        aria-haspopup = "true"
                        onClick = {this.handleClick}
                        aria-label = "feedback / support links"
                    >
                        <LiveHelp style={{ color: blueGrey[50] }}/>
                    </IconButton>
                </Tooltip>
 
                <Menu
                    anchorEl = {anchorEl}                    
                    onClose = {this.handleClose}
                    open = {open}
                >
                    <MenuItem 
                        onClick = {event => {this.handleClose(); navigateToUrl(this.BUG_REPORT_URL);}}
                        >
                        <ListItemIcon>
                            <BugReport/>
                        </ListItemIcon>
                        Report a Bug
                    </MenuItem>
                    <MenuItem
                        onClick = {event => {this.handleClose(); navigateToUrl(this.FEATURE_REQUEST_URL);}}
                    >
                        <ListItemIcon>
                            <NewReleases/>
                        </ListItemIcon>
                        Request a Feature
                    </MenuItem>
                    <MenuItem
                        onClick = {event => {this.handleClose(); navigateToUrl(this.USER_GUIDE_URL);}}
                    >
                        <ListItemIcon>
                            <ChromeReaderMode/>
                        </ListItemIcon>
                        User Guide
                    </MenuItem>
                </Menu>
            </>);
    }
}
 
export default (Feedback);