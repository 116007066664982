import React from 'react';
import "./pageTitle.css";
interface PageTypeProps {
    children: React.ReactNode[] | string
}

const PageTitle: React.FC<PageTypeProps> = (props: PageTypeProps) => {
    return (
        <>
            <br />
            <h4 className="orange-title">
                <>
                    {props.children}
                </>
            </h4>
        </>
    );
}

export default PageTitle;
