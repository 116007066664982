import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import Link from "@amzn/awsui-components-react/polaris/link";
import { BaseNavigationDetail } from "@amzn/awsui-components-react/polaris/internal/events";

interface TableResourceLinkProps extends RouteComponentProps {
  href: string;
  text: string;
  onResourceLinkClick?: () => void;
}

export const TableResourceLink = withRouter(
  ({ history, href, text, onResourceLinkClick }: TableResourceLinkProps) => {
    function handleResourceLinkClick(event: CustomEvent<BaseNavigationDetail>) {
      event.preventDefault();

      if (!!onResourceLinkClick) {
        return onResourceLinkClick();
      } else {
        return history.push(href);
      }
    }

    return (
      <Link href={href} onFollow={handleResourceLinkClick}>
        {text}
      </Link>
    );
  }
);
