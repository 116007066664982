import React, { Component } from "react";
import API from "@aws-amplify/api";
import { Snackbar } from '@material-ui/core';
import { Alert } from "@material-ui/lab";
/* Importing components*/
import GriddleTable from "../../../components/Table/GriddleTable";
import PurchaseOrderItemDetails from "./model/PurchaseOrderItemDetails";
import PurchaseOrderLevelInfo from "./model/PurchaseOrderLevelInfo";
import PurchaseOrderLevelDetails from "./PurchaseOrderLevelDetails";
import { PURCHASE_ORDER_ITEM_DETAIL_METADATA } from "../../../constants/table/metadata/PurchaseOrderItemColumn";
import "./PurchaseOrderInfo.css";
import { getAuthorizationHeader } from "@common/Auth";
import {RegionContext} from '@common/RegionContext';
import {constructButaneApiName} from '../../../apiConfig';



class PurchaseOrderItemsDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            purchaseOrderDetails: {},
            purchaseOrderItemsDetail: [],
            fetchingRecords: false,
            errorMessage: undefined,
            openErrorSnackbar: false
        };
    }

    async fetchPurchaseOrderDetailsData(orderId, warehouseId) {
        API.get(constructButaneApiName(this.context.region), `/api/inbounds/po/warehouses/${warehouseId}/orders/${orderId}`, {
            headers: await getAuthorizationHeader()
        })
        .then((data) => {
            const itemDetails = data.itemDetails;
            const purchaseOrderDetails = new PurchaseOrderLevelInfo(
                data.orderId,
                data.condition,
                data.warehouseId,
                data.totalQuantityReceived,
                data.totalQuantitySubmitted,
                data.totalQuantityOrdered,
                data.totalQuantityExpected,
                data.totalQuantityMisReceived,
                data.totalQuantityReceived - data.totalQuantityMisReceived
            );
            let itemDetailsList = [];
            for (let i = 0; i < itemDetails.length; i++) {
                let itemData = new PurchaseOrderItemDetails(
                    itemDetails[i].fnsku,
                    itemDetails[i].quantitySubmitted,
                    itemDetails[i].quantityExpected,
                    itemDetails[i].quantityOrdered,
                    itemDetails[i].quantityReceived,
                    itemDetails[i].quantityMisreceived,
                    (itemDetails[i].quantityReceived - itemDetails[i].quantityMisreceived)
                );
                itemDetailsList.push(itemData);
            }
            this.setState(() => ({
                purchaseOrderDetails: purchaseOrderDetails,
                purchaseOrderItemsDetail: itemDetailsList,
                fetchingRecords: false,
                errorMessage: undefined
            }));
        })
        .catch(error => {
            this.setState(() => ({
                problemItemDeletionDetails: [],
                successList: undefined,
                openErrorSnackbar: true,
                fetchingRecords: false,
            }));
            if(error.response) {
                this.setState(() => ({
                    errorMessage: (error.response.status === 500 && error.response.data.message === "FaaSTPOEventProcessorService or its dependency failed.") ?
                        'Something went wrong' : 'Purchase Order Items not found'
                }));
            } else {
                this.setState(() => ({
                    errorMessage: 'Something went wrong' 
                }));
            }
        });
    }

    getPurchaseOrderDetailsData(orderId, warehouseId) {
        if (orderId === '' || warehouseId === '') {
            return;
        }

        this.setState(() => ({
            fetchingRecords: true
        }));

        this.fetchPurchaseOrderDetailsData(orderId, warehouseId);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.orderId !== this.props.orderId || prevProps.warehouseId !== this.props.warehouseId) {
            this.getPurchaseOrderDetailsData(this.props.orderId, this.props.warehouseId);
        }
    }

    handleClose = () => {
        this.setState(() => ({
            openErrorSnackbar: false
        }));
    }

    renderPurchaseOrderDetails() {
        const columnMetadata = PURCHASE_ORDER_ITEM_DETAIL_METADATA;
        return (
            <div>
                {!!this.state.errorMessage ? (
                    <Snackbar open={this.state.openErrorSnackbar} onClose={this.handleClose}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    >
                        <Alert onClose={this.handleClose} severity="error" variant="filled">
                            {this.state.errorMessage}
                        </Alert>
                    </Snackbar>)
                    : (
                        <div className="po-details">
                            <PurchaseOrderLevelDetails
                                purchaseOrderDetails={this.state.purchaseOrderDetails}
                            />
                            <GriddleTable
                                results={this.state.purchaseOrderItemsDetail}
                                columnMetadata={columnMetadata}
                            />
                        </div>
                    )}
            </div>
        );
    }

    render() {
        let content;
        if (!!this.state.fetchingRecords) {
            return <div className="spinner"></div>
        }
        if (!!this.props.orderId && !!this.props.warehouseId && !this.state.fetchingRecords) {
            content = this.renderPurchaseOrderDetails();
        }
        return <div>{content}</div>
    }
}

PurchaseOrderItemsDetail.contextType = RegionContext;

export default PurchaseOrderItemsDetail;