import React from "react";
import WarehousesShipmentsInConditionsTable from "./WarehousesShipmentsInConditionsTable";
import queryString from "query-string";

export default class ShipmentsDetailsForWarehousesInCondition extends React.Component {

    render() {
        const values = queryString.parse(this.props.location.search);

        if (this.props.isAuthenticated) {
            return (
                <div>
                    <WarehousesShipmentsInConditionsTable
                        condition={values.condition}
                        warehouseList={values.warehouses}
                        shipmentType={values.shipmentType}
                        row={values.rowNumber}
                    />
                </div>
            );
        }
        this.props.history.push("/login");
        return null;
    }
}