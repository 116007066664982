export default class PurchaseOrderLevelInfo {
    constructor(orderId, condition, warehouseId,
        totalQuantityReceived, totalQuantitySubmitted, totalQuantityOrdered, totalQuantityExpected, totalQuantityMisreceived, totalQuantityNetReceived) {
        this.orderId = orderId;
        this.condition = condition;
        this.warehouseId = warehouseId;
        this.totalQuantityReceived = totalQuantityReceived;
        this.totalQuantitySubmitted = totalQuantitySubmitted;
        this.totalQuantityOrdered = totalQuantityOrdered;
        this.totalQuantityExpected = totalQuantityExpected;
        this.totalQuantityMisreceived = totalQuantityMisreceived;
        this.totalQuantityNetReceived = totalQuantityNetReceived;
    }
}