import {RouteComponentProps} from "react-router-dom";
import React, {useState} from "react";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";

import {
    FcSkuInventoryDiscrepancySnapshotInput
} from "@modules/inventory/discrepancy-snapshot/FcSkuInventoryDiscrepancySnapshotInput";
import {
    FcSkuInventoryDiscrepancySnapshotTable
} from "@modules/inventory/discrepancy-snapshot/FcSkuInventoryDiscrepancySnapshotTable";
import DateUtils from "@common/date/DateUtils";

interface FcSkuInventoryDiscrepancySnapshotPageProps extends RouteComponentProps {
    isAuthenticated: boolean,
}
export const FcSkuInventoryDiscrepancySnapshotPage = (props: FcSkuInventoryDiscrepancySnapshotPageProps) => {
    const [renderTable, setRenderTable] = useState(false);

    if (!props.isAuthenticated) {
        props.history.push("/login");
    }

    const urlParams = new URLSearchParams(window.location.search);
    const warehouse = urlParams.get('warehouse') ?? "";
    const startDate = DateUtils.parseDateOrGetDefault(urlParams.get('startDate') ?? undefined);
    const endDate = DateUtils.parseDateOrGetDefault((urlParams.get('endDate') ?? undefined));
    const onlyDiscrepantInventory = urlParams.get('onlyDiscrepantInventory')?.toLowerCase() === 'true';

    return <div>
        <SpaceBetween size={"s"}>
            <FcSkuInventoryDiscrepancySnapshotInput
                {...props}
                warehouse={warehouse}
                startDate={new Date(startDate)}
                endDate={new Date(endDate)}
                renderTable={setRenderTable}
                onlyDiscrepantInventory={onlyDiscrepantInventory}
            />
            {
                renderTable &&
                <FcSkuInventoryDiscrepancySnapshotTable
                    {...props}
                    warehouse={warehouse}
                    startDate={startDate}
                    endDate={endDate}
                    onlyDiscrepantInventory={onlyDiscrepantInventory}
                />
            }
        </SpaceBetween>
    </div>;
}