import {Icon, Popover, TableProps} from "@amzn/awsui-components-react";
import {FnSkuInventoryTuple} from "@modules/inventory/research/model/FnSkuInventoryTuple";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Link from "@amzn/awsui-components-react/polaris/link";
import Button from "@amzn/awsui-components-react/polaris/button";
import React from "react";

export const FnSkuInventoryTableColumnDefinition = (onCommittedQuantityClick: any, redirectToAudits: any)
    : TableProps.ColumnDefinition<FnSkuInventoryTuple>[]  => {
    return [
        {
            id: "warehouse",
            header: "Warehouse",
            cell: e => e.warehouse,
            sortingField: 'warehouse',
        },
        {
            id: "inventoryStatus",
            header: "Inventory Status",
            cell: e => e.inventoryStatus,
            sortingField: 'inventoryStatus'
        },
        {
            id: "availableQuantity",
            header: "Available",
            cell: e => e.availableQuantity,
            sortingField: 'availableQuantity'
        },
        {
            id: "problemSolveQuantity",
            header: <div>
                <SpaceBetween size={"xxs"} direction={"horizontal"}>
                    <text>EFP Excess Qty <br /> (Problem Solve)</text>
                    <Popover
                        triggerType="custom"
                        size={"small"}
                        content={"Quantity available for LOST Operation"}
                    >
                        <Icon name={"status-info"} variant={"subtle"}/>
                    </Popover>
                </SpaceBetween>
            </div>,
            cell: e => e.problemSolveQuantity,
            sortingField: 'problemSolveQuantity',
        },
        {
            id: "pendingResearchQuantity",
            header: <div>
                <SpaceBetween size={"xxs"} direction={"horizontal"}>
                    <text>Sidelined <br />(Pending Research)</text>
                    <Popover
                        triggerType="custom"
                        size={"small"}
                        content={"Quantity available for UNSIDELINE Operation"}
                    >
                        <Icon name={"status-info"} variant={"subtle"}/>
                    </Popover>
                </SpaceBetween>
            </div>,
            cell: e => e.pendingResearchQuantity,
            sortingField: 'pendingResearchQuantity',
        },
        {
            id: "problemReceiveQuantity",
            header: "Problem Receive",
            cell: e => e.problemReceiveQuantity,
            sortingField: 'problemReceiveQuantity'
        },
        {
            id: "committedQuantity",
            header: <div>
                <SpaceBetween size={"xxs"} direction={"horizontal"}>
                    Committed
                    <Popover
                        triggerType={"custom"}
                        size={"small"}
                        content={"Click on quantity below to see committed inventory details"}

                    >
                        <Icon name={"status-info"} variant={"subtle"}/>
                    </Popover>
                </SpaceBetween>
            </div>,
            cell: e => (
                <Link variant={"primary"}
                      onFollow={() => onCommittedQuantityClick(e)}
                >{e.totalCommittedQuantity}
                </Link>),
            sortingField: 'committedQuantity'
        },
        {
            id: "totalQuantity",
            header: "Total",
            cell: e => e.totalQuantity,
            sortingField: 'totalQuantity'
        },
        {
            id: "actions",
            header: "Actions",
            cell: (e) => (
                <div>
                    <Button onClick={() => redirectToAudits(e)}>
                        Audits
                    </Button>
                </div>
            ),
        }
    ]
}