import React, { useState } from "react";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import Button from "@amzn/awsui-components-react/polaris/button";
import BreadcrumbGroup from "@amzn/awsui-components-react/polaris/breadcrumb-group";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import ExpandableSection from "@amzn/awsui-components-react/polaris/expandable-section";
import { RouteComponentProps } from "react-router";

import { useFlashbar } from "@common/flashbar";
import { WorkflowDetails } from "@modules/transfer/departure-requests/components";
import { FailedDepartItemsTable } from "@modules/transfer/common/components";

interface TransferDepartWorkflowDetailsParams {
  sourceWarehouse: string;
  amznShipRefId: string;
}

interface TransferDepartWorkflowDetailsProps
  extends RouteComponentProps<TransferDepartWorkflowDetailsParams> {}

export function TransferDepartWorkflowDetails({
  history,
  match
}: TransferDepartWorkflowDetailsProps) {
  const [isFooterExpanded, setIsFooterExpanded] = useState(true);

  const { FlashbarContainer } = useFlashbar();

  const {
    params: { sourceWarehouse, amznShipRefId }
  } = match;

  function handleRefresh() {
    history.push("/");
    history.goBack();
  }

  return (
    <AppLayout
      navigationHide={true}
      breadcrumbs={
        <BreadcrumbGroup
          onFollow={(e) => {
            e.preventDefault();
            history.push(e.detail.href);
          }}
          items={[
            {
              href: "/transfers/departure-requests",
              text: "Transfer Depart Requests"
            },
            {
              href: `/transfers/${sourceWarehouse}/departure-requests`,
              text: sourceWarehouse
            },
            {
              href: "/",
              text: amznShipRefId
            }
          ]}
        />
      }
      notifications={<FlashbarContainer />}
      toolsHide={true}
      headerSelector="#top-navigation"
      contentType="default"
      content={
        <>
          <Container
            header={
              <Header
                variant="h2"
                actions={
                  <>
                    <Button
                      iconName="refresh"
                      onClick={() => handleRefresh()}
                    />
                  </>
                }
              >
                Transfer {amznShipRefId} Workflow Details
              </Header>
            }
            footer={
              <ExpandableSection
                header="Pending Items"
                variant="footer"
                expanded={isFooterExpanded}
                onChange={({ detail: { expanded } }) =>
                  setIsFooterExpanded(expanded)
                }
              >
                <FailedDepartItemsTable
                  amznShipRefId={amznShipRefId}
                  isLegacyTransfer={false}
                  suppressErrors={true}
                />
              </ExpandableSection>
            }
          >
            <WorkflowDetails />
          </Container>
        </>
      }
    />
  );
}
