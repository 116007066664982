import React, { Component } from 'react';
import FormField from "./FormField";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import { getErrorMessage } from "../../../constants/ErrorMessageMapper";
import { numericRegex} from "../../../constants/Regex";
import {INPUT_ERROR_TIMEOUT} from "../../../constants/Values";

const heirarchyListValues = [
    {label: "Warehouse", value: "warehouse"},
    {label: "Vendor", value: "vendor"},
    {label: "MarketPlace", value: "marketplace"},
    {label: "Global", value: "global"}
];

const paramTypeList = [
    {label: "String", value: "string"},
    {label: "Integer", value: "int"},
    {label: "List", value: "list"},
    {label: "Boolean", value: "bool"}
];

class SchemaForm extends Component {
    constructor(props) {
        super(props);
        this.state= {  
            schemaName: "",
            schemaDescription:  "",
            hierarchyList: [],
            fields: [{
                param: "",
                type: {label: "String", value: "string"},
                min: "",
                max: "",
                validValues: "",
                required: false
            }],
            schemaNameError: false,
            schemaDescriptionError: false,
            hierarchyListError: false,
            fieldsErrorIndex: -1
        };
    }

    handleChange = (e, fieldName) => {
        e.preventDefault();
        let val = e.target.value;
        if(fieldName === "schemaName") {
            val = val.trim();
        }

        const currState = this.state;
        currState[fieldName] = val;
        this.setState(() => ({
            ...currState
        }));
    }

    hierarchyListChange = (values) =>  {
        this.setState({
            hierarchyList: values
        });
    }

    componentDidUpdate = () => {
        if(this.props.clearData) {
            this.setState({
                schemaName: "",
                schemaDescription:  "",
                hierarchyList: [],
                fields: [{
                    param: "",
                    type: {label: "String", value: "string"},
                    min: "",
                    max: "",
                    validValues: "",
                    required: false
                }]
            })
        }
    }

    validate = () => {
        const {schemaName, schemaDescription, hierarchyList} = this.state;
        const fields = this.state.fields;
        if(schemaName.length === 0) {
            this.setState({schemaNameError: true},()=>{
                window.setTimeout(()=>{
                  this.setState({schemaNameError: false})
                },INPUT_ERROR_TIMEOUT)
            });
            return getErrorMessage("invalidSchemaName");
        }

        if(schemaDescription.trim().length === 0) {
            this.setState({schemaDescriptionError: true},()=>{
                window.setTimeout(()=>{
                  this.setState({schemaDescriptionError: false})
                },INPUT_ERROR_TIMEOUT)
            });
            return getErrorMessage("noDescription");
        }

        if(hierarchyList.length === 0) {
            this.setState({hierarchyListError: true},()=>{
                window.setTimeout(()=>{
                  this.setState({hierarchyListError: false})
                },INPUT_ERROR_TIMEOUT)
            });
            return getErrorMessage("invalidHierarchyList");
        }

        if(fields.length === 0) {
            return getErrorMessage("emptyFields");
        }

        for(let i = 0; i < fields.length; i++) {
            const field = fields[i];
            if(field.param.length === 0) {
                this.setState({fieldsErrorIndex: i},()=>{
                    window.setTimeout(()=>{
                      this.setState({fieldsErrorIndex: -1})
                    },INPUT_ERROR_TIMEOUT)
                });
                return getErrorMessage("invalidParamName");
            }
            
            if(field.type.value === "int") {
                if(field.min !== null && 
                    field.min.length !== 0 &&
                    !numericRegex.test(field.min)) {
                        this.setState({fieldsErrorIndex: i},()=>{
                            window.setTimeout(()=>{
                              this.setState({fieldsErrorIndex: -1})
                            },INPUT_ERROR_TIMEOUT)
                        });
                    return getErrorMessage("notNumeric") + field.param;
                }
                if(field.max !== null &&
                    field.max.length !== 0 && 
                    !numericRegex.test(field.max)) {      
                        this.setState({fieldsErrorIndex: i},()=>{
                            window.setTimeout(()=>{
                                this.setState({fieldsErrorIndex: -1})
                            },INPUT_ERROR_TIMEOUT)
                        });              
                    return getErrorMessage("notNumeric") + field.param;
                }        
            }

            if(field.type.value === "list") {
                if(field.validValues !== null &&
                    field.validValues.trim().length === 0) {
                        this.setState({fieldsErrorIndex: i},()=>{
                            window.setTimeout(()=>{
                              this.setState({fieldsErrorIndex: -1})
                            },INPUT_ERROR_TIMEOUT)
                        });
                    return getErrorMessage("invalidlistValidValues") + field.param;
                }
            }            
        }

        return null;
    }

    onSubmit = () => {
        const error = this.validate();
        if(error) {
            return this.props.onSubmit(null, {message: error});
        }
        
        const {schemaName, schemaDescription} = this.state;
        const fields = this.state.fields;
        const hierarchyList = this.state.hierarchyList;

        let data = {
            schemaName,
            schemaDescription: schemaDescription.trim()
        };

        data.hierarchyList = hierarchyList.map(e => e.value);
        data.fields = fields.map(field => {
            if(field.type.value === "string" || field.type.value === "bool") {
                return {
                    param: field.param,
                    type: field.type.value,
                    required: field.required,
                    min: null,
                    max: null,
                    validValues: null
                }
            }


            if(field.type.value === "int") {
                return {
                    param: field.param,
                    type: field.type.value,
                    required: field.required,
                    min: (field.min.trim().length !== 0) ? field.min.trim() : null,
                    max: (field.max.trim().length !== 0) ? field.max.trim() : null,
                    validValues: null
                }
            }

            if(field.type.value === "list") {
                let validValues = [];
                if(field.validValues !== null) {
                    validValues = field.validValues.split(',').filter(val => (val.trim().length > 0));
                    validValues = validValues.map(e => e.trim());
                }

                return {
                    param: field.param,
                    type: field.type.value,
                    required: field.required,
                    min: null,
                    max: null,
                    validValues: validValues
                }
            }

            return null;
        });


        return this.props.onSubmit(data, null);
    }

    fieldsOnChange = (index, param, value) => {
        let fields = this.state.fields;
        fields[index][param] = value;
        this.setState({
            fields
        });
    }

    fieldsOnAdd = () => {
        let fields = this.state.fields;
        
        fields.push({
            param: "",
            type: {label: "String", value: "string"},
            min: "",
            max: "",
            validValues: "",
            required: false
        });

        this.setState({
            fields
        });
    }

    fieldsOnDelete = (index) => {
        let fields = this.state.fields;
        fields.splice(index, 1);
        this.setState({
            fields
        });
    }

    getSchemaFormData() {
        const {schemaName, schemaDescription, hierarchyList, fields} = this.state;
        const {schemaNameError, schemaDescriptionError, hierarchyListError, fieldsErrorIndex} = this.state;

        const formData = [
           {
                label: "Schema Name",
                param: "schemaName",
                value: schemaName,
                type: "input",
                handleChange: this.handleChange,
                placeholder: "",
                error: schemaNameError
           },
           {
                label: "Schema Description",
                param: "schemaDescription",
                value: schemaDescription,
                type: "input",
                handleChange: this.handleChange,
                placeholder: "Why this Schema is for ?",
                error: schemaDescriptionError
           },
           {
                label: "Hierarchy List",
                param: "hierarchyList",
                value: hierarchyList,
                type: "multi-dropdown",
                list: heirarchyListValues,
                handleChange: this.hierarchyListChange,
                error: hierarchyListError
           },
           {
                label: "Fields",
                param: "fields",
                value: fields,
                type: "multifield",
                paramTypeList: paramTypeList,
                handleChange: this.fieldsOnChange,
                onAdd: this.fieldsOnAdd,
                onDelete: this.fieldsOnDelete,
                errorIndex: fieldsErrorIndex
           }
        ]
        return formData;
    }

    render() {
        const formData = this.getSchemaFormData();
        return(
            <div className="box-container">
                <div className="box-schema-form">
                    <FormField
                        formData={formData}
                        onSubmit={this.onSubmit}
                    />
                </div>  
                <div className="box-schema-submit">
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        startIcon={<SaveIcon />}
                        onClick={this.onSubmit}
                        >
                        Save
                    </Button>
                </div>
            </div>
        )
    }
}

export default SchemaForm;