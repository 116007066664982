export const fetchShipmentSummaryColumns = function (getConditionValue, conditionDescription) {

    return [

        {
            field: 'warehouseId',
            title: 'WarehouseId',
            sortable: false,
            width: '10rem',
            render: rowData => getConditionValue(rowData, 'warehouseId', 'WarehouseId')
        },
        {
            field: 'MISSEDEXSD',
            title: 'LATE',
            sortable: false,
            width: '10rem',
            tooltip: conditionDescription['MISSEDEXSD'],
            render: rowData => getConditionValue(rowData, 'MISSEDEXSD', 'LATE')
        },
        {
            field: 'Cond62',
            title: '62',
            sortable: false,
            width: '10rem',
            tooltip: conditionDescription['62'],
            render: rowData => getConditionValue(rowData, 'Cond62', '62')
        },
        {
            field: 'Cond3',
            title: '3',
            sortable: false,
            width: '10rem',
            tooltip: conditionDescription['3'],
            render: rowData => getConditionValue(rowData, 'Cond3', '3')
        },
        {
            field: 'Cond305',
            title: '305',
            sortable: false,
            width: '10rem',
            tooltip: conditionDescription['305'],
            render: rowData => getConditionValue(rowData, 'Cond305', '305')
        },
        {
            field: 'Cond8',
            title: '8',
            sortable: false,
            width: '10rem',
            tooltip: conditionDescription['8'],
            render: rowData => getConditionValue(rowData, 'Cond8', '8')
        },
        {
            field: 'Cond5406',
            title: '5406',
            sortable: false,
            width: '10rem',
            tooltip: conditionDescription['5406'],
            render: rowData => getConditionValue(rowData, 'Cond5406', '5406')
        },
        {
            field: 'Cond5409',
            title: '5409',
            sortable: false,
            width: '10rem',
            tooltip: conditionDescription['5409'],
            render: rowData => getConditionValue(rowData, 'Cond5409', '5409')
        },
        {
            field: 'Cond36',
            title: '36',
            sortable: false,
            width: '10rem',
            tooltip: conditionDescription['36'],
            render: rowData => getConditionValue(rowData, 'Cond36', '36')
        },
        {
            field: 'Cond59',
            title: '59',
            sortable: false,
            width: '10rem',
            tooltip: conditionDescription['59'],
            render: rowData => getConditionValue(rowData, 'Cond59', '59')
        },
        {
            field: 'Cond7',
            title: '7',
            sortable: false,
            width: '10rem',
            tooltip: conditionDescription['7'],
            render: rowData => getConditionValue(rowData, 'Cond7', '7')
        },
        {
            field: 'Cond13',
            title: '13',
            sortable: false,
            width: '10rem',
            tooltip: conditionDescription['13'],
            render: rowData => getConditionValue(rowData, 'Cond13', '13')
        },
        {
            field: 'Cond29',
            title: '29',
            sortable: false,
            width: '10rem',
            tooltip: conditionDescription['29'],
            render: rowData => getConditionValue(rowData, 'Cond29', '29')
        },
        {
            field: 'Cond54',
            title: '54',
            sortable: false,
            width: '10rem',
            tooltip: conditionDescription['54'],
            render: rowData => getConditionValue(rowData, 'Cond54', '54')
        },
        {
            field: 'Cond4',
            title: '4',
            sortable: false,
            width: '10rem',
            tooltip: conditionDescription['4'],
            render: rowData => getConditionValue(rowData, 'Cond4', '4')
        },
        {
            field: 'Cond11',
            title: '11',
            sortable: false,
            width: '10rem',
            tooltip: conditionDescription['11'],
            render: rowData => getConditionValue(rowData, 'Cond11', '11')
        },
        {
            field: 'Cond12',
            title: '12',
            sortable: false,
            width: '10rem',
            tooltip: conditionDescription['12'],
            render: rowData => getConditionValue(rowData, 'Cond12', '12')
        },
        {
            field: 'Cond32',
            title: '32',
            sortable: false,
            width: '10rem',
            tooltip: conditionDescription['32'],
            render: rowData => getConditionValue(rowData, 'Cond32', '32')
        },
        {
            field: 'Cond60',
            title: '60',
            sortable: false,
            width: '10rem',
            tooltip: conditionDescription['60'],
            render: rowData => getConditionValue(rowData, 'Cond60', '60')
        },
        {
            field: 'Cond6001',
            title: '6001',
            sortable: false,
            width: '10rem',
            tooltip: conditionDescription['6001'],
            render: rowData => getConditionValue(rowData, 'Cond6001', '6001')
        },
        {
            field: 'Cond6003',
            title: '6003',
            sortable: false,
            width: '10rem',
            tooltip: conditionDescription['6003'],
            render: rowData => getConditionValue(rowData, 'Cond6003', '6003')
        },
        {
            field: 'Cond6009',
            title: '6009',
            sortable: false,
            width: '10rem',
            tooltip: conditionDescription['6009'],
            render: rowData => getConditionValue(rowData, 'Cond6009', '6009')

        },
        {
            field: 'Cond6006',
            title: '6006',
            sortable: false,
            width: '10rem',
            tooltip: conditionDescription['6006'],
            render: rowData => getConditionValue(rowData, 'Cond6006', '6006')
        },
        {
            field: 'Cond2505',
            title: '2505',
            sortable: false,
            width: '10rem',
            tooltip: conditionDescription['2505'],
            render: rowData => getConditionValue(rowData, 'Cond2505', '2505')
        },
        {
            field: 'Cond15',
            title: '15',
            sortable: false,
            width: '10rem',
            tooltip: conditionDescription['15'],
            render: rowData => getConditionValue(rowData, 'Cond15', '15')
        },
        {
            field: 'Cond1501',
            title: '1501',
            sortable: false,
            width: '10rem',
            tooltip: conditionDescription['1501'],
            render: rowData => getConditionValue(rowData, 'Cond1501', '1501')
        }

    ];

}