import React, { Dispatch, SetStateAction, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import Modal from "@amzn/awsui-components-react/polaris/modal";

import SelectWarehouseForm from "@components/SelectWarehouse/SelectWarehouseForm";

interface SelectWarehouseModalProps extends RouteComponentProps {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
}

export const SelectWarehouseModal = withRouter(
  ({ isVisible, setIsVisible, history }: SelectWarehouseModalProps) => {
    const [selectedWarehouse] = useState("");

    function constructRedirectPath(warehouse: string) {
      const redirectPath = `/transfers/${warehouse}/departure-requests`;

      setIsVisible(false);
      history.push(redirectPath);
    }

    return (
      <Modal
        header="Select Warehouse"
        visible={isVisible}
        onDismiss={() => setIsVisible(false)}
      >
        <SelectWarehouseForm
          warehouse={selectedWarehouse}
          constructRedirectPath={constructRedirectPath}
          usePolaris={true}
          containerless={true}
        />
      </Modal>
    );
  }
);
