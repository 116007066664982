import { Popover, StatusIndicator } from "@amzn/awsui-components-react";
import React from "react";

const Popovers = ({content}) => (
  <Popover
    position="top"
    size="large"
    triggerType="custom"
    content={content}
  >
    <StatusIndicator type="info">Info</StatusIndicator>
  </Popover>
);

export default Popovers;