import {useCallback, useContext, useState} from "react";
import API from "@aws-amplify/api";
import { StatusCodes } from "http-status-codes";

import { ApiError } from "@common/exception";
import { FailedDepartItem } from "@modules/transfer/common/model";

import { getAuthorizationHeader } from "@common/Auth";
import {RegionContext} from "@common/RegionContext";
import {constructButaneApiName} from "../../../apiConfig";

const FAILED_DEPART_ITEMS_DATA_KEY = "failedDepartItems";
const MESSAGE_DATA_KEY = "message";

export function useGetFailedDepartItems() {
  const [error, setError] = useState<ApiError | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<FailedDepartItem[]>([]);
  const regionContext = useContext(RegionContext);

  const getFailedDepartItems = useCallback(async (amznShipRefId: string) => {
    if (!amznShipRefId) {
      return;
    }

    setIsLoading(true);
    setError(null);

    const apiUrl = `/transfer/failedDepartItems/${amznShipRefId}`;

    try {
      const response = await API.get(
        constructButaneApiName(regionContext.region),
        apiUrl, {
          response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
          headers: await getAuthorizationHeader()  
        }
      );

      setData(
        (response?.data[FAILED_DEPART_ITEMS_DATA_KEY] ??
          []) as unknown as FailedDepartItem[]
      );
    } catch (exception) {
      console.error(
        `Something went wrong while retrieving failed depart items for Amazon Shipment Reference ID: ${amznShipRefId}`
      );

      const apiError = getApiError(exception);

      setError(apiError);
      setData([]);
    }

    setIsLoading(false);

    function getApiError(error: any) {
      if (error?.response?.status === StatusCodes.BAD_REQUEST) {
        return new ApiError(
          `Invalid request for Amazon Shipment Reference ID ${amznShipRefId}.`,
          StatusCodes.BAD_REQUEST,
          error.response.data[MESSAGE_DATA_KEY]
        );
      }
      if (error?.response?.status === StatusCodes.NOT_FOUND) {
        return new ApiError(
          `No failed Depart Items found for Amazon Shipment Reference ID ${amznShipRefId}.`,
          StatusCodes.NOT_FOUND
        );
      }

      return new ApiError(
        `Error while retrieving failed Depart Items for Amazon Shipment Reference ID ${amznShipRefId}, please try again later.`,
        StatusCodes.INTERNAL_SERVER_ERROR
      );
    }
  }, [regionContext]);

  return {
    error,
    isLoading,
    failedDepartItems: data,
    getFailedDepartItems
  };
}
