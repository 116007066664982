const BUTANE_DELETE_RPI_EU='https://dub.efp.amazonoperations.app/inbounds/delete-rpi';
const BUTANE_DELETE_RPI_NA='https://iad.efp.amazonoperations.app/inbounds/delete-rpi';
const BUTANE_DELETE_RPI_JP='https://pdx.efp.amazonoperations.app/inbounds/delete-rpi';
const BUTANE_DELETE_RPI_BETA='https://beta.dub.efp.amazonoperations.app/inbounds/delete-rpi';

const fetchDeleteRPILink = function(region, environment) {
    if (environment === 'prod') {
        switch(region.toLowerCase()) {
            case 'iad':
                return BUTANE_DELETE_RPI_NA;
            case 'dub':
                return BUTANE_DELETE_RPI_EU;
            case 'pdx':
                return BUTANE_DELETE_RPI_JP;
            default:
                return BUTANE_DELETE_RPI_BETA;
        }
    } else {
        return BUTANE_DELETE_RPI_BETA
    }
};
export { fetchDeleteRPILink };
