export const PURCHASE_ORDER_ITEM_DETAIL_METADATA = [
  {
    columnName: 'fnsku',
    width: '30rem',
    displayName: 'FNSKU',
  }, 
  {
    columnName: 'quantityExpected',
    width: '30rem',
    displayName: 'Quantity Expected'
  },
  {
    columnName: 'quantitySubmitted',
    width: '30rem',
    displayName: 'Quantity Submitted'
  },
  {
    columnName: 'quantityOrdered',
    width: '30rem',
    displayName: 'Quantity Ordered'
  },
  {
    columnName: 'quantityNetReceived',
    width: '30rem',
    displayName: 'Quantity Net Received'
  }
];