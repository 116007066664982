import React from "react";
import { Button, TextField } from "@material-ui/core";
import { MuiPickersUtilsProvider,KeyboardDatePicker } from "@material-ui/pickers";
import { asinRegex, warehouseIdRegex } from '../../../constants/Regex';
import DateFnsUtils from "@date-io/date-fns";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import PageTitle from "../../../components/PageTitle/PageTitle";
import API from "@aws-amplify/api";
import PropTypes from "prop-types";
import Select from 'react-select';
import "./auditInputBox.css";
import { getAuthorizationHeader } from "@common/Auth";
import BackendError from "../../../views/layouts/BackendError";
import {RegionContext} from '@common/RegionContext';
import {constructButaneApiName} from '../../../apiConfig';

const DATE_FORMAT = "dd/MM/yyyy";

const customDropDownMenuStyles = {
    menu: (provided, state) => ({
        ...provided,
        zIndex: 15
    })
}

export default class AuditInputBox extends React.Component {
    static propTypes = {
        asin: PropTypes.string,
        warehouse: PropTypes.string,
        renderTables: PropTypes.func.isRequired,
        startDate: PropTypes.instanceOf(Date),
        endDate: PropTypes.instanceOf(Date)
    };

    constructor(props) {
        super(props);
        this.state = {
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            asin: this.props.asin,
            warehouse: this.props.warehouse,
            inventoryLocation: {},
            maxDate: this.props.endDate,
            minDate: this.props.startDate,
            inventoryLocationList: [],
            selectDisabled: true,
            searchDisabled: true,
            networkError: false
        }
    }

    handleChange = event => {
        const value = event.target.value;
        this.setState({
          [event.target.name]: value.toUpperCase()
        });
    };

    validInput = () => {
        return asinRegex.test(this.state.asin) && warehouseIdRegex.test(this.state.warehouse);
    };

    searchForAsin = () => {
        this.props.renderTables(this.state.asin, this.state.warehouse, this.state.inventoryLocation,
                                                    this.state.startDate, this.state.endDate);
    };

    handleStartDateChange = (date) => {
        this.setState({startDate: date, minDate: date}, () => {
            this.setMaxDate();
        });
    };

    handleInventoryLocationChange (e){
        if(e!==undefined ){
            this.setState({ inventoryLocation: e }, () => {
                if( this.state.inventoryLocation.value!=='NA'){
                    this.setState({
                        searchDisabled:false
                    });
                }
            });
        }
    };

    setMaxDate() {
        const moment = new Date();
        const tempStartDate = new Date(this.state.startDate)
        const yearAfterStartDate = new Date(tempStartDate.getFullYear() + 1, tempStartDate.getMonth(),
                                            tempStartDate.getDate())
        this.setState({
            maxDate: (moment <= yearAfterStartDate)? moment : yearAfterStartDate
        });
    }

    handleEndDateChange = (date) => {
        this.setState({
            endDate: date
        });
    };

    componentDidMount() {
        this.fetchInventoryLocationList();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.warehouse!==this.state.warehouse ||
            prevState.asin !== this.state.asin) {
            this.setState({ selectDisabled: true}, () => {
                this.fetchInventoryLocationList();
            });
        }
    }

    getInventoryLocation(inventoryLocationList) {
        const {inventoryLocation} = this.props;
        if(inventoryLocation === null || inventoryLocation === undefined || inventoryLocationList.length === 0) {
            return null;
        }
        const data = {value: inventoryLocation, label: inventoryLocation}
        for(let i = 0; i < inventoryLocationList.length; i++) {
            if(inventoryLocationList[i].value === data.value) {
                return inventoryLocationList[i];
            }
        }
        return null;
    }

    async fetchInventoryLocationList() {
        if(asinRegex.test(this.state.asin) && warehouseIdRegex.test(this.state.warehouse)){
            let noLocationAuditRecords = [{value: "NA", label: "No location found which contains audit records for this asin and warehouse"}];
            return API.get(constructButaneApiName(this.context.region), `/api/inventory/location/fnSku/${this.state.asin}/warehouse/${this.state.warehouse}`, {
                    headers: await getAuthorizationHeader()
                })
                .then((data) => {
                    let inventoryLocationList = [];
                    for(let i=0 ; i<data.inventoryLocationList.length ; i++) {
                        let temp = { value: data.inventoryLocationList[i] , label: data.inventoryLocationList[i] };
                        inventoryLocationList.push(temp);
                    }
                    if(inventoryLocationList.length === 0){
                        inventoryLocationList = noLocationAuditRecords
                        this.setState({
                            searchDisabled: true
                        });
                    }
                    const inventoryLocation = this.getInventoryLocation(inventoryLocationList);
                    this.setState(() => ({
                        inventoryLocationList: inventoryLocationList,
                        selectDisabled: false,
                        searchDisabled: (inventoryLocation === null) ? true : false,
                        inventoryLocation
                    }));
                }).catch((e) => {
                  this.setState(() => ({
                      inventoryLocationList: noLocationAuditRecords,
                      selectDisabled: false,
                      inventoryLocation: null,
                      networkError: true
                  }));
            });
        }
        this.setState({
            searchDisabled: true
        });
    }

    render() {
        return (
          <div>
            {this.state.networkError && <BackendError/>}
            <div>
              <PageTitle>Search Inventory Audits</PageTitle>
            </div>
            <div className="form-inline">
              <div>
                <div className="audit-filter-form">
                  <div className="filters">
                    <div className="inventory-audit-asin-warehouse-box">
                      <label className="inventory-audit-asin-label">FNSKU</label>
                      <div className="inventory-audit-asin-input-box">
                        <TextField
                            placeholder="FNSKU, eg.BT00CTP9EC"
                            name="asin"
                            className="inventory-audit-asin-input"
                            defaultValue={this.state.asin}
                            onChange={this.handleChange}
                        />
                      </div>
                      <label className="inventory-audit-warehouse-label">WAREHOUSE</label>
                      <div className="inventory-audit-warehouse-input-box">
                      <TextField
                          placeholder="Warehouse Id, eg. VUSB"
                          name="warehouse"
                          defaultValue={this.state.warehouse}
                          onChange={this.handleChange}
                          className="inventory-audit-warehouse-input"
                      />
                      </div>
                    </div>
                    <div className="audit-date-box">
                      <label className="audit-start-date-label">START DATE</label>
                          <div className='audit-start-date-selector-box'>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                      autoOk
                                      disableFuture
                                      variant="inline"
                                      inputVariant="standard"
                                      format={ DATE_FORMAT }
                                      value={this.state.startDate}
                                      InputAdornmentProps={{ position: "start" }}
                                      onChange={date => this.handleStartDateChange(date)}
                                      className='audit-start-date-selector'
                                    />
                              </MuiPickersUtilsProvider>
                          </div>
                          <label className="audit-end-date-label">END DATE</label>
                          <div className='audit-end-date-selector-box'>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                   <KeyboardDatePicker
                                       autoOk
                                       disableFuture
                                       variant="inline"
                                       inputVariant="standard"
                                       format={ DATE_FORMAT }
                                       value={this.state.endDate}
                                       InputAdornmentProps={{ position: "start" }}
                                       onChange={date => this.handleEndDateChange(date)}
                                       minDate={this.state.minDate}
                                       maxDate={this.state.maxDate}
                                       className='audit-end-date-selector'
                                       maxDateMessage="Max date range is 1 year"
                                    />
                              </MuiPickersUtilsProvider>
                           </div>
                    </div>
                    <div>
                      <label className="inventory-audit-input-params">INVENTORY STATUS</label>
                      <div className="audit-select-box">
                          <Select
                              name="inventoryLocation"
                              styles={customDropDownMenuStyles}
                              value={this.state.inventoryLocation}
                              onChange={this.handleInventoryLocationChange.bind(this)}
                              isDisabled={this.state.selectDisabled}
                              options={this.state.inventoryLocationList}
                          />
                      </div>
                    </div>
                  </div>
                  <div className="inline align-middle text-center">
                    <div className="audit-button-padding"></div>
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<SearchOutlinedIcon />}
                          disabled={this.state.searchDisabled}
                          onClick={this.searchForAsin}
                        >
                            Search
                        </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}

AuditInputBox.contextType = RegionContext;
