import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from "react-router-dom";
import './index.css';
import {App} from './App';
import Amplify from "@aws-amplify/core";
import apiConfig from "./apiConfig";
import cognitoConfig from "./cognitoConfig";
import registerServiceWorker from './registerServiceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import {getEnvironment} from "@modules/signup/Utils";

const environment = getEnvironment();

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: cognitoConfig[environment].REGION,
    userPoolId: cognitoConfig[environment].USER_POOL_ID,
    identityPoolId: cognitoConfig[environment].IDENTITY_POOL_ID,
    userPoolWebClientId: cognitoConfig[environment].APP_CLIENT_ID,
    authenticationFlowType: 'CUSTOM_AUTH',
  },
  API: {
    endpoints: apiConfig.endpoints[environment]
  }
});

ReactDOM.render(
    <Router>
        <App environment={environment}/>
    </Router>,
    document.getElementById('root')
);
registerServiceWorker();