
export const fetchInventoryAuditTableColumns = function(getActionStyle, getMessageIdStyle, getDestinationStyle, getQuantityStyle,
                                                        getFailedStyle, getAmzStyle, getWarehouseStyle, getDiffStyle) {

    return [

        { title: 'Entry Date' , field: 'entryDate' },

        { title: 'Action' , field: 'action', sorting:false, render: rowData => getActionStyle(rowData)},

        { title: 'Message Id', field: 'messageId', sorting:false, render: rowData => getMessageIdStyle(rowData)},

        { title: 'Business Id', field: 'businessId', sorting:false },

        { title: 'Source', field: 'source', sorting:false },

        { title: 'Destination', field: 'destination', sorting:false, render: rowData => getDestinationStyle(rowData)},

        { title: 'Quantity', field: 'quantity', sorting:false, render: rowData => getQuantityStyle(rowData)},

        { title: 'Failed Quantity', field: 'failedQuantity', sorting:false, render: rowData => getFailedStyle(rowData)},

        { title: 'Amazon*', field: 'amazonAvailableAndCommitted', sorting:false, render: rowData => getAmzStyle(rowData)},

        { title: 'Warehouse*', field: 'warehouseAvailableAndCommitted', sorting:false, render: rowData => getWarehouseStyle(rowData) },

        { title: 'Diff*', field: 'difference', sorting:false,  render: rowData => getDiffStyle(rowData)}

    ];

}