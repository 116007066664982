import Table from "@amzn/awsui-components-react/polaris/table";
import React, {useContext, useEffect, useState} from "react";
import API from "@aws-amplify/api";
import {FcSkuInventoryTuple} from "@modules/inventory/research/model/FcSkuInventoryTuple";
import {useCollection} from "@amzn/awsui-collection-hooks";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import {TableEmptyState} from "@common/table";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import Header from "@amzn/awsui-components-react/polaris/header";
import {RouteComponentProps} from "react-router-dom";
import {
    FcSkuInventoryTableColumnDefinition
} from "@modules/inventory/research/tables/FcSkuInventoryTableColumnDefinition";
import { getAuthorizationHeader } from "@common/Auth";
import {RegionContext} from "@common/RegionContext";
import {constructButaneApiName} from "../../../../apiConfig";

interface FcSkuInventoryTableProps extends RouteComponentProps {
    itemIdentifier: string,
    warehouseList: string,
}

export const FcSkuInventoryTable = (props: FcSkuInventoryTableProps) => {

    const [fcSkuInventoryTuples, setFcSkuInventoryTuples] = useState<FcSkuInventoryTuple[]>([]);
    const [noInventoryWarehouses, setNoInventoryWarehouses] = useState<string[]>([]);
    const [fetchingRecords, setFetchingRecords] = useState(false);
    const [backendError, setBackendError] = useState(false);
    const regionContext = useContext(RegionContext);

    useEffect(() => {
        setNoInventoryWarehouses([])
        setFcSkuInventoryTuples([])
        searchInventoryForAllWarehouses(props)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const searchInventoryForAllWarehouses = (props) => {
        setFetchingRecords(true);
        const warehouses = props.warehouseList.split(',');
        Promise.allSettled(warehouses.map(warehouse => {
            return callEndpointForSingleWarehouse(props.itemIdentifier, warehouse)
                .then(
                    (data) => handleSuccessfulResponse(data),
                    () => handleErrorResponse(warehouse));
        })).finally(() => {
            setFetchingRecords(false)
        });
    }

    const callEndpointForSingleWarehouse = async (itemIdentifier: string, warehouse: string) => {
        return API.get(constructButaneApiName(regionContext.region),
            `/api/inventory/fcsku/${itemIdentifier}?warehouse=${warehouse}`, {
                headers: await getAuthorizationHeader()
            }
        );
    }

    const handleSuccessfulResponse = (data: any) => {
        const fcSkuInventoryList = data.fcSkuInventoryList;
        const warehouse = data.warehouse;

        if (fcSkuInventoryList.length === 0) {
            setNoInventoryWarehouses((prevState) => {
                return [...prevState, warehouse]
            })
            return;
        }

        fcSkuInventoryList.forEach(fcSkuInventory => {
            const availableQuantity = fcSkuInventory.fcSkuInventoryStatusToQuantity.AVAILABLE ?? 0;
            const unavailableQuantity = fcSkuInventory.fcSkuInventoryStatusToQuantity.UNAVAILABLE ?? 0;
            const problemReceiveQuantity = fcSkuInventory.fcSkuInventoryStatusToQuantity.PROBLEM_RECEIVE ?? 0;
            const totalQuantity = availableQuantity + unavailableQuantity + problemReceiveQuantity;
            const fcSkuInventoryTuple: FcSkuInventoryTuple = {
                warehouse: warehouse,
                fnSku: fcSkuInventory.fnSku,
                fcSku: fcSkuInventory.fcSku,
                inventoryCondition: fcSkuInventory.inventoryCondition,
                availableQuantity: availableQuantity,
                unavailableQuantity: unavailableQuantity,
                problemReceiveQuantity: problemReceiveQuantity,
                totalQuantity: totalQuantity
            };
            setFcSkuInventoryTuples((prevState) => {
                return [...prevState, fcSkuInventoryTuple];
            })
        });

        setFetchingRecords(false);
    }

    const handleErrorResponse = (warehouse: string) => {
        setBackendError(true)
        setNoInventoryWarehouses((prevState) => {
            return [...prevState, warehouse];
        });
    }

    const redirectToAudits = (fcSku: string, warehouse: string, startDate: Date, endDate: Date) => {
        props.history.push(`/inventory/audits/fcsku/${fcSku}/?warehouse=${warehouse}&startDate=${endDate.toISOString().slice(0,10)}&endDate=${endDate.toISOString().slice(0,10)}`);
    }

    const redirectToFcSkuTracking = (warehouse: string, fcSku: string) => {
        props.history.push(`/fcsku/details?warehouse=${warehouse}&attributeName=FCSKU&attributeValue=${fcSku}`);
    }

    const {
        items,
        filterProps,
        paginationProps
    } = useCollection(fcSkuInventoryTuples, {
        filtering: {},
        pagination: {
            pageSize: 10
        },
        sorting: {
            defaultState: {
                sortingColumn: {sortingField: "warehouse"}
            }
        }
    });

    return <div>
        <SpaceBetween size={"xxs"}>
            {
                noInventoryWarehouses.length > 0 &&
                <Alert type={"info"}>
                  No FCSKU inventory found for warehouse: {noInventoryWarehouses.toString()}
                </Alert>
            }
            {
                backendError && <Alert type={"error"} header={"Error"}>
                  Error occurred when fetching data, please try again.
              </Alert>
            }
            <Table
                header={
                    <Header >
                        Real Time FCSKU Inventory
                    </Header>
                }
                items={items}
                loading={fetchingRecords}
                loadingText={"Loading FCSKU inventory"}
                pagination={
                    <Pagination
                        {...paginationProps}
                    />
                }
                empty={
                    <TableEmptyState
                        resourceName={"FCSKU Inventory"}
                        resourceNamePlural={"FCSKU Inventory"}
                        showCreateResourceButton={false}
                    />
                }
                filter={
                    <div>
                        <TextFilter
                            {...filterProps}
                        />
                    </div>
                }
                columnDefinitions={FcSkuInventoryTableColumnDefinition(redirectToAudits, redirectToFcSkuTracking)}
            />
        </SpaceBetween>
    </div>;
}