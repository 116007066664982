import React from "react";
import { Switch } from "react-router-dom";
import PropsRoute from "./common/PropsRoute";
import { Home } from "./modules/signup/Home";
import ForgotPassword from "./modules/signup/ForgotPassword";
import SearchInventoryAudits from "./modules/inventory/audits/SearchInventoryAudits.jsx"
import AuditDetail from "./modules/config/audits/AuditDetail";
import NodeSettings from "./modules/config/nodeSettings/NodeSettings";
import ShipmentsDetailsForWarehousesInCondition from "./modules/shipment/shipmentsDetailsForWarehousesInCondition/ShipmentsDetailsForWarehousesInCondition";
import SearchByWarehouses from "./modules/shipment/searchByWarehousesPage/SearchByWarehouses.jsx";
import SelectWarehouse from "./modules/config/nodeSettings/SelectWarehouse";
import MultiNodeConfigUpdate from "./modules/config/batchUpdate/MultiNodeConfigUpdate";
import RemovalOrderInfo from "./modules/removal/RemovalOrderInfo";
import PurchaseOrderInfo from "./modules/inbound/po/PurchaseOrderInfo";
import SelectWarehouseOpConfig from "./modules/config/operationalConfig/SelectWarehouseOpConfig";
import OpConfigSettings from "./modules/config/operationalConfig/Settings";
import { InviteUsersForm } from "./modules/admin/InviteUsersForm";
import { DeleteUsersForm } from "./modules/admin/DeleteUsersForm";
import ProblemItemInfo from "./modules/inbound/problemreceive/ProblemItemInfo";
import BulkDeleteProblemReceiveItems from "./modules/inbound/deleteProblemItems/BulkDeleteProblemReceiveItems";
import Registrations from "./modules/node-management-service/registrations-page/Registrations";
import RegistrationDetails from "./modules/node-management-service/registration-details-page/RegistrationDetails";
import CreateContainer from "./modules/config/createConfig/CreateContainer";
import CreateSchema from "./modules/config/createConfig/CreateSchema";
import CancelTransferRequest from "@modules/transfer/cancel-transfers/CancelTransferRequest";
import PageNotFound from "./modules/404/PageNotFound";
import LaunchPlanDetails from "./modules/node-management-service/launch-plan-details-page/LaunchPlanDetails";
import {
  LegacyFailedDepartItemsOverview,
  TransferDepartRequestsOverview,
  TransferDepartWorkflowDetails
} from "@modules/transfer";
import { SearchShipmentById } from "@modules/shipment/searchByShipmentId/SearchShipmentById";
import { LoginWithAuth } from "@modules/signup/LoginWithAuth";
import {InventoryResearchPage} from "@modules/inventory/research/InventoryResearchPage";
import {FcSkuInventoryAuditsPage} from "@modules/inventory/audits/fcsku/FcSkuInventoryAuditsPage";
import {FcSkuTrackingPage} from "@modules/fcsku/FcSkuTrackingPage";
import {
    FcSkuInventoryDiscrepancySnapshotPage
} from "@modules/inventory/discrepancy-snapshot/FcSkuInventoryDiscrepancySnapshotPage";
import {UserGuide} from "@modules/userguide/UserGuide";
import {InventoryReceiptsOverview} from "@modules/inbound/inventoryReceiveNotification";
import {InventoryReceiptDetailsTable} from "@modules/inbound/inventoryReceiveNotification/table/inventoryReceiptDetail";

interface RouteProps {
  isAuthenticated: boolean;
  isExternal: boolean;
}

export const Routes: React.FC<RouteProps> = (childProps: RouteProps) => {
  return (
    <Switch>
      <PropsRoute path="/" exact component={Home} props={childProps} />
      <PropsRoute path="/metrics/aging-report" exact component={SearchByWarehouses} props={childProps} />
      <PropsRoute path="/metrics/aging-report-detail" exact component={ShipmentsDetailsForWarehousesInCondition} props={childProps} />
      <PropsRoute path="/shipment/search" exact component={SearchShipmentById} props={childProps} />
      <PropsRoute path="/shipment/:shipmentId" exact component={SearchShipmentById} props={childProps} />
      <PropsRoute path="/login" exact component={LoginWithAuth} props={childProps} />
      <PropsRoute path="/inventory/research" exact component={InventoryResearchPage} props={childProps} />
      <PropsRoute path="/forgot-password" exact component={ForgotPassword} props={childProps} />
      <PropsRoute path="/node/select" exact component={SelectWarehouse} props={childProps} />
      <PropsRoute path="/node/settings/audit" exact component={AuditDetail} props={childProps} />
      <PropsRoute path="/node/:warehouse/settings" exact component={NodeSettings} props={childProps} />
      <PropsRoute path="/node/settings/bulk-update" exact component={MultiNodeConfigUpdate} props={childProps} />
      <PropsRoute path="/op-config/select" exact component={SelectWarehouseOpConfig} props={childProps} />
      <PropsRoute path="/op-config/:warehouse/settings" exact component={OpConfigSettings} props={childProps} />
      <PropsRoute path="/removal/info" exact component={RemovalOrderInfo} props={childProps} />
      <PropsRoute path="/removal/info/:warehouseId" exact component={RemovalOrderInfo} props={childProps} />
      <PropsRoute path="/removal/info/:warehouseId/:orderId" exact component={RemovalOrderInfo} props={childProps} />
      <PropsRoute path="/inbounds/po" exact component={PurchaseOrderInfo} props={childProps} />
      <PropsRoute path="/inbounds/summary" exact component={ProblemItemInfo} props={childProps} />
      <PropsRoute path="/inbounds/delete-rpi" exact component={BulkDeleteProblemReceiveItems} props={childProps} />
      <PropsRoute path="/inventory/audits" exact component={SearchInventoryAudits} props={childProps} />
      <PropsRoute path="/inventory/audits/fcsku/" exact component={FcSkuInventoryAuditsPage} props={childProps} />
      <PropsRoute path="/inventory/audits/fcsku/:fcsku" exact component={FcSkuInventoryAuditsPage} props={childProps} />
      <PropsRoute path="/inventory/fcsku/discrepancy" exact component={FcSkuInventoryDiscrepancySnapshotPage} props={childProps} />
      <PropsRoute path="/registrations" exact component={Registrations} props={childProps} />
      <PropsRoute path="/registrations/:registrationId" exact component={RegistrationDetails} props={childProps} />
      <PropsRoute path="/registrations/:registrationId/launch-plans/:nodeLaunchPlanId" exact component={LaunchPlanDetails} props={childProps} />
      <PropsRoute path="/transfers/departure-requests" exact component={TransferDepartRequestsOverview} props={childProps} />
      <PropsRoute path="/transfers/cancel" exact component={CancelTransferRequest} props={childProps} />
      <PropsRoute path="/transfers/:warehouse/departure-requests" exact component={TransferDepartRequestsOverview} props={childProps} />
      <PropsRoute path="/transfers/failed-depart-items" exact component={LegacyFailedDepartItemsOverview} props={childProps} />
      <PropsRoute path="/transfers/failed-depart-items/:amznShipRefId" exact component={LegacyFailedDepartItemsOverview} props={childProps} />
      <PropsRoute path="/transfers/:sourceWarehouse/departure-requests/:amznShipRefId" exact component={TransferDepartWorkflowDetails} props={childProps} />
      <PropsRoute path="/config/create-container" exact component={CreateContainer} props={childProps} />
      <PropsRoute path="/fcsku/details" exact component={FcSkuTrackingPage} props={childProps} />
      <PropsRoute path="/config/create-schema" exact component={CreateSchema} props={childProps} />
      <PropsRoute path="/admin/invite-users" exact component={InviteUsersForm} props={childProps} />
      <PropsRoute path="/admin/delete-users" exact component={DeleteUsersForm} props={childProps} />
      <PropsRoute path= "/userguide" exact component={UserGuide} props={childProps}/>
      <PropsRoute path="/receive/inventory_receipts" exact component={InventoryReceiptsOverview} props={childProps} />
      <PropsRoute path="/receive/:siteName/inventory_receipts" exact component={InventoryReceiptsOverview} props={childProps} />
      <PropsRoute path="/receive/:siteName/inventory_receipts/:mcnBatchId" exact component={InventoryReceiptDetailsTable} props={childProps} />
      <PropsRoute path="/*" component={PageNotFound} props={childProps} />
    </Switch>
  );
}
