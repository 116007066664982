import * as React from "react";
import {useState} from "react";
import {
    setUserAttributes,
    useSharedAuthState,
    useSharedUserAttributesState,
    useSharedUserState
} from '@modules/signup/LoginWithAuth';
import {useBetween} from "use-between";
import {useEffect} from "react";
import {AuthState} from "@aws-amplify/ui-components";
import {Routes} from './Routes';
import {FlashbarProvider} from "@common/flashbar";
import Header from './views/layouts/Header';
import {Form, Button} from "react-bootstrap";
import Auth from "@aws-amplify/auth";
import { amazonEmailRegex } from "@constants/Regex";
import {useHistory} from "react-router";
import {RegionContext} from "@common/RegionContext";
import {ENV_TO_DEFAULT_REGIONS, ENV_TO_REGIONS, Region} from "@constants/Region";

interface AppProps {
    environment: string
}

export const App = (props: AppProps) => {
    const history = useHistory();
    const {authState, setAuthState} = useBetween(useSharedAuthState);
    const {setUser} = useBetween(useSharedUserState);
    const {email, setEmail, setWarehouses} = useBetween(useSharedUserAttributesState);
    const [loading, setLoading] = useState<Boolean>(true);
    const [region, setRegion] = useState<string>(ENV_TO_DEFAULT_REGIONS[props.environment].name);

    const showLoggedInBar = () => (
        <Form inline>
            <Button variant="outline-light" onClick={handleLogout}>Log out</Button>
        </Form>
    );

    const handleLogout = async () => {
        try {
            await Auth.signOut();
            setAuthState(AuthState.SignIn);
            setUser(undefined);
        } catch (err) {
        }
    }

    useEffect(() => {
        const checkUserAuth = async () => {
            Auth.currentAuthenticatedUser({bypassCache: true})
                .then((cognitoUser) => {
                    setAuthState(AuthState.SignedIn);
                    setUserAttributes(setEmail, setWarehouses);
                    const zoneinfo = cognitoUser.attributes['zoneinfo'];
                    const supportedRegionsInEnvironment =
                        ENV_TO_REGIONS[props.environment].map((region: Region) => {return region.name});
                    if (zoneinfo && supportedRegionsInEnvironment.includes(zoneinfo)) {
                        setRegion(zoneinfo);
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
        checkUserAuth();
    }, [authState, setAuthState, setEmail, setWarehouses, setRegion, history, props]);

    const onContextSetRegion = async (region: string) => {
        setRegion(region);
        try {
            const user = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(user, {
                'zoneinfo': region
            });
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <>
            {!loading &&
                <RegionContext.Provider value={{region: region, setRegion: onContextSetRegion}}>
                    <FlashbarProvider>
                        <Header
                            isAuthenticated={authState === AuthState.SignedIn}
                            showLoggedInBar={showLoggedInBar}
                            supportedRegions={ENV_TO_REGIONS[props.environment]}
                        />
                        <Routes
                            isAuthenticated={authState === AuthState.SignedIn}
                            isExternal={!amazonEmailRegex.test(email)}
                        />
                    </FlashbarProvider>
                </RegionContext.Provider>
            }
        </>
    );
}
