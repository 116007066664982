import React from "react";
import { TableProps } from "@amzn/awsui-components-react";

import { FormattedISOTimestamp } from "@common/date";
import { StandaloneValue } from "@common/presentation";
import { FailedDepartItem } from "@modules/transfer/common/model";

export const TRACK_BY_COLUMN_NAME = "transferManifestItemIdentifier";

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<FailedDepartItem>[] =
  [
    {
      id: "transferManifestId",
      header: "Transfer Manifest ID",
      cell: (item) => <StandaloneValue value={item.transferManifestId} />,
      minWidth: "90px"
    },
    {
      id: "transferManifestItemIdentifier.asin",
      header: "ASIN",
      cell: (item) => (
        <StandaloneValue value={item?.transferManifestItemIdentifier?.asin} />
      ),
      minWidth: "60px"
    },
    {
      id: "transferManifestItemIdentifier.fcsku",
      header: "FCSku",
      cell: (item) => (
        <StandaloneValue value={item?.transferManifestItemIdentifier?.fcsku} />
      ),
      minWidth: "60px"
    },
    {
      id: "transferManifestItemIdentifier.transferRequestId",
      header: "Transfer Request ID",
      cell: (item) => (
        <StandaloneValue
          value={item?.transferManifestItemIdentifier?.transferRequestId}
        />
      ),
      minWidth: "60px"
    },
    {
      id: "transferManifestItemIdentifier.inventoryConditionCode",
      header: "Inventory Condition",
      cell: (item) => (
        <StandaloneValue
          value={item?.transferManifestItemIdentifier?.inventoryConditionCode}
        />
      ),
      minWidth: "60px"
    },
    {
      id: "transferManifestItemIdentifier.itemLocation",
      header: "Item Location",
      cell: (item) => (
        <StandaloneValue
          value={item?.transferManifestItemIdentifier?.itemLocation}
        />
      ),
      minWidth: "60px"
    },
    {
      id: "failureReason",
      header: "Failure Reason",
      cell: (item) => <StandaloneValue value={item.failureReason} />,
      minWidth: "60px"
    },
    {
      id: "quantity",
      header: "Quantity",
      cell: (item) => <StandaloneValue value={item.quantity} />,
      minWidth: "60px"
    },
    {
      id: "failureDate",
      header: "Failure Date",
      cell: (item) => (
        <FormattedISOTimestamp
          date={new Date(item.failureDate)}
          format="dateTime"
        />
      ),
      minWidth: "60px"
    }
  ];

export const LOADING_TEXT = "Loading Failed Depart Items...";
