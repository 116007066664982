import React, { useCallback, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { FlashbarProps } from "@amzn/awsui-components-react/polaris/flashbar";

import { FlashbarContext } from "./FlashbarContext";

interface FlashbarMessageInput extends FlashbarProps.MessageDefinition {
  id: string;
  onDismissCallback?: () => void;
}

export const FlashbarProvider = ({ children }) => {
  const [flashbarMessages, setFlashbarMessages] = useState<
    FlashbarMessageInput[]
  >([]);

  const deleteMessage = useCallback(
    (id: string) => {
      setFlashbarMessages((messages) =>
        messages.filter((message) => message.id !== id)
      );
    },
    [setFlashbarMessages]
  );

  const addMessage = useCallback(
    (message: FlashbarProps.MessageDefinition) => {
      const flashbarMessage: FlashbarMessageInput = {
        ...message,
        id: getId()
      };

      if (requiresDismissCallback()) {
        Object.assign(flashbarMessage, {
          onDismissCallback: message.onDismiss
        });
      }

      if (message.dismissible) {
        const onDismissHandler = () => {
          if (hasCustomOnDismiss()) {
            flashbarMessage.onDismissCallback!();
          }
          deleteMessage(flashbarMessage.id);
        };

        Object.assign(flashbarMessage, {
          onDismiss: onDismissHandler
        });
      }

      setFlashbarMessages((prevMessages) => {
        return [...prevMessages, flashbarMessage];
      });

      function getId() {
        return uuidv4();
      }

      function requiresDismissCallback() {
        return message.dismissible && !!message.onDismiss;
      }

      function hasCustomOnDismiss() {
        return (
          message.dismissible &&
          !!message?.onDismiss &&
          flashbarMessage?.onDismissCallback
        );
      }
    },
    [setFlashbarMessages, deleteMessage]
  );

  const clearAllMessages = useCallback(
    () => setFlashbarMessages([]),
    [setFlashbarMessages]
  );

  return (
    <FlashbarContext.Provider
      value={{
        addMessage: addMessage,
        clearAllMessages: clearAllMessages,
        deleteMessage: deleteMessage,
        messages: flashbarMessages
      }}
    >
      {children}
    </FlashbarContext.Provider>
  );
};
