import Table from "@amzn/awsui-components-react/polaris/table";
import React, {useContext, useEffect, useState} from "react";
import API from "@aws-amplify/api";
import {useCollection} from "@amzn/awsui-collection-hooks";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import {TableEmptyState} from "@common/table";
import {FnSkuInventoryTuple} from "@modules/inventory/research/model/FnSkuInventoryTuple";
import {CommittedFnSkuModal} from "@modules/inventory/research/CommittedFnSkuModal";
import {RouteComponentProps} from "react-router-dom";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import Header from "@amzn/awsui-components-react/polaris/header";
import {
    FnSkuInventoryTableColumnDefinition
} from "@modules/inventory/research/tables/FnSkuInventoryTableColumnDefinition";
import { getAuthorizationHeader } from "@common/Auth";
import {RegionContext} from "@common/RegionContext";
import {constructButaneApiName} from "../../../../apiConfig";

interface FnSkuInventoryTableProps extends RouteComponentProps {
    itemIdentifier: string,
    warehouseList: string,
}

interface CommittedOrderModalDetails {
    warehouse: string,
    fnSku: string,
    committedOrderQuantity: number,
    committedTransferQuantity: number,
    committedRemovalQuantity: number,
    committedGradingQuantity: number,
    backendError: boolean,
}

export const FnSkuInventoryTable = (props: FnSkuInventoryTableProps) => {

    const [fnSkuInventoryTuples, setFnSkuInventoryTuples] = useState<FnSkuInventoryTuple[]>([]);
    const [noInventoryWarehouses, setNoInventoryWarehouses] = useState<string[]>([]);
    const [fetchingRecords, setFetchingRecords] = useState(false);
    const [displayCommittedOrderModal, setDisplayCommittedOrderModal] = useState(false);
    const [committedOrderModalDetails, setCommittedOrderModalDetails] = useState<CommittedOrderModalDetails>();
    const [showAlert, setShowAlert] = useState(true);
    const [backendError, setBackendError] = useState(false);
    const regionContext = useContext(RegionContext);

    useEffect(() => {
        setNoInventoryWarehouses([]);
        setFnSkuInventoryTuples([]);
        setShowAlert(true);
        searchInventory(props);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const searchInventory = (props) => {
        setFetchingRecords(true);

        callEndpoint(props.itemIdentifier, props.warehouseList).then(
            (data) => handleSuccessfulResponse(data),
            () => handleErrorResponse(props.warehouseList)
        ).finally(() => {
            setFetchingRecords(false)
        })
    };

    const toggleCommittedFnSkuModal = (value: boolean) => {
        setDisplayCommittedOrderModal(value);
    }

    const callEndpoint = async (itemIdentifier: string, warehouseList: string) => {
        return API.get(constructButaneApiName(regionContext.region),
            `/api/network/inventory/${itemIdentifier}?warehouses=${warehouseList}`, {
                headers: await getAuthorizationHeader()
            });
    }

    const handleSuccessfulResponse = (data: any) => {
        let inventoryStatusList = data.inventoryStatusList;


        for (let i=0; i<inventoryStatusList.length; i++) {
            let inventoryStatus: any = inventoryStatusList[i];
            if (!inventoryStatus.inventoryInformation || inventoryStatus.inventoryInformation.length === 0) {
                setNoInventoryWarehouses(prevState => {
                    return [...prevState, inventoryStatus.warehouseId];
                })
                continue;
            }

            inventoryStatus.inventoryInformation.forEach(inventoryInformation => {
                const committedOrderQuantity = inventoryInformation.inventoryOwnerDetails.COMMITTED_ORDER ?? 0;
                const committedRemovalQuantity = inventoryInformation.inventoryOwnerDetails.COMMITTED_REMOVAL ?? 0;
                const committedTransferQuantity = inventoryInformation.inventoryOwnerDetails.COMMITTED_TRANSSHIP ?? 0;
                const committedGradingQuantity = inventoryInformation.inventoryOwnerDetails.COMMITTED_GRADING ?? 0;
                const totalCommittedQuantity = committedOrderQuantity + committedRemovalQuantity +
                    committedTransferQuantity + committedGradingQuantity;

                const availableQuantity = inventoryInformation.inventoryOwnerDetails.AVAILABLE ?? 0;
                const problemSolveQuantity = inventoryInformation.inventoryOwnerDetails.PROBLEM_SOLVE ?? 0;
                const pendingResearchQuantity = inventoryInformation.inventoryOwnerDetails.PENDING_RESEARCH ?? 0;
                const problemReceiveQuantity = inventoryInformation.inventoryOwnerDetails.PROBLEM_RECEIVE ?? 0;
                const totalQuantity = totalCommittedQuantity + availableQuantity + pendingResearchQuantity +
                    + problemReceiveQuantity;

                const fnSkuInventoryTuple: FnSkuInventoryTuple = {
                    warehouse: inventoryStatus.warehouseId,
                    inventoryStatus: inventoryInformation.externalInventoryStatus,
                    committedOrderQuantity: committedOrderQuantity,
                    committedGradingQuantity: committedGradingQuantity,
                    committedTransferQuantity: committedTransferQuantity,
                    committedRemovalQuantity: committedRemovalQuantity,
                    totalCommittedQuantity: totalCommittedQuantity,
                    availableQuantity: availableQuantity,
                    problemSolveQuantity: problemSolveQuantity,
                    pendingResearchQuantity: pendingResearchQuantity,
                    problemReceiveQuantity: problemReceiveQuantity,
                    totalQuantity: totalQuantity,
                };

                setFnSkuInventoryTuples(prevState => {
                    return [...prevState, fnSkuInventoryTuple]
                });
            });
        }
    }

    const handleErrorResponse = (warehouseList: string) => {
        setBackendError(true)
        setNoInventoryWarehouses((prevState) => {
            return [...prevState, ...warehouseList.split(',')];
        });
    }

    const onCommittedQuantityClick = (e) => {
        setDisplayCommittedOrderModal(true);
        setCommittedOrderModalDetails({
            warehouse: e.warehouse,
            fnSku: props.itemIdentifier,
            committedOrderQuantity: e.committedOrderQuantity,
            committedGradingQuantity: e.committedGradingQuantity,
            committedTransferQuantity: e.committedTransferQuantity,
            committedRemovalQuantity: e.committedRemovalQuantity,
            backendError: e.backendError
        })
    }

    const redirectToAudits = (e) => {
        props.history.push(`/inventory/audits/?fnsku=${props.itemIdentifier}&warehouse=${e.warehouse}&inventoryLocation=${e.inventoryStatus}`);
    }

    const {
        items,
        filterProps,
        paginationProps
    } = useCollection(fnSkuInventoryTuples, {
        filtering: {},
        pagination: {
            pageSize: 10
        },
        sorting: {
            defaultState: {
                sortingColumn: {sortingField: "warehouse"}
            }
        }
    });

    return <div>
        {
            displayCommittedOrderModal && committedOrderModalDetails &&
            <CommittedFnSkuModal
                visible={displayCommittedOrderModal}
                {...committedOrderModalDetails}
                onDismiss={toggleCommittedFnSkuModal}
            />
        }
        <SpaceBetween size={"xxs"}>
            {
                showAlert &&
                <Alert type={"info"}
                       header={"Known Issue"}
                       dismissible
                       onDismiss={() => setShowAlert(false)}>
                    1. UNSIDELINE operation can be done on Pending Research inventory.
                    LOST can only be done after UNSIDELINE for Pending Research inventory.
                    <br/>
                    2. LOST operation can be done on Problem Solve inventory. <br/>
                    3. Flex Portal will show the Problem Solve quantity added inside Pending Research only.
                    Only consider mismatch discrepancy if Pending Research of Flex does not
                    match sum of Problem Solve and Pending Research here.
                </Alert>
            }
            {
                noInventoryWarehouses.length > 0 &&
                <Alert type={"info"}>
                  No FNSKU inventory found for warehouse: {noInventoryWarehouses.toString()}
                </Alert>
            }
            {
                backendError &&
                <Alert type={"error"} header={"Error"}>
                    Error occurred when fetching data, please try again.
                </Alert>
            }
            <Table
                header={
                    <Header >
                        Real Time FNSKU Inventory
                    </Header>
                }
                items={items}
                loading={fetchingRecords}
                loadingText={"Loading FNSKU inventory"}
                pagination={
                    <Pagination
                        {...paginationProps}
                    />
                }
                empty={
                    <TableEmptyState
                        resourceName={"FNSKU Inventory"}
                        resourceNamePlural={"FNSKU Inventory"}
                        showCreateResourceButton={false}
                    />
                }
                filter={
                    <div>
                        <TextFilter
                            {...filterProps}
                        />
                    </div>
                }
                columnDefinitions={FnSkuInventoryTableColumnDefinition(onCommittedQuantityClick, redirectToAudits)}
            />
        </SpaceBetween>
    </div>;
}