export const MULTI_NODE_CONFIG_UPDATE_COLUMN_METADATA = [
  {
    columnName: "warehouse",
    width: "30rem",
    displayName: "Warehouse"
  },
  {
    columnName: "paramName",
    width: "30rem",
    displayName: "Attribute Name"
  },
  {
    columnName: "paramValue",
    width: "30rem",
    displayName: "Attribute Value"
  }
];
