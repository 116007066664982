export const DELETE_RECEIVE_PROBLEM_ITEM_DETAIL_METADATA = [
  {
    columnName: 'problemItemId',
    width: '22rem',
    displayName: 'RPI Id'
  },
  {
    columnName: 'deletionStatus',
    width: '22rem',
    displayName: 'Deletion Status',
  },
  {
    columnName: 'errorMessage',
    width: '30rem',
    displayName: 'Error Message',
  }
];