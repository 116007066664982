import * as React from "react";
import Box from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import Header from "@amzn/awsui-components-react/polaris/header";
import Link from "@amzn/awsui-components-react/polaris/link";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import Select from "@amzn/awsui-components-react/polaris/select";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Table from "@amzn/awsui-components-react/polaris/table";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import { TableProps } from "@amzn/awsui-components-react";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";
import * as _ from "lodash";

import { Filter, Registration } from "../Types";
import formatDate from "../../../common/DateFormatter";
import {useContext} from "react";
import {RegionContext} from "@common/RegionContext";


interface RegistrationsTableProps {
  registrationsList: Registration[];
  loading: boolean;
  showNewRegistrationModal: () => void;
  marketplaceOptions: string[];
  fetchData: (filterObject: Filter, region: string) => void;
}

const RegistrationsTable = (props: RegistrationsTableProps) => {
  const ALL_OPTION: OptionDefinition = { label: "Any Marketplace", value: undefined }
  const [selectedOption, setSelectedOption] = React.useState<OptionDefinition>(ALL_OPTION);
  const regionContext = useContext(RegionContext);
  const PAGE_SIZE = 20;

  const collectionHook = useCollection<Registration>(
    props.registrationsList,
    {
      filtering: {
        fields: ["registrationId", "businessType", "marketplace", "warehouseManagementSystem", "creator", "lastUpdated"],
        noMatch: (
          <Box textAlign="center">
            <b>No matches</b>
            <p>We can’t find a match.</p>
            <Button onClick={() => {
              collectionHook.actions.setFiltering('')
              setSelectedOption(ALL_OPTION);
              props.fetchData({}, regionContext.region);
            }}
            >Clear filter</Button>
          </Box>
        ),
        empty: (
          <Box textAlign="center">
            <SpaceBetween direction="vertical" size="s">
              <b>No Registrations available</b>
              <Button onClick={props.showNewRegistrationModal}>Create registration</Button>
            </SpaceBetween>
          </Box>
        )
      },
      sorting: {
        defaultState: {
          sortingColumn: {
            sortingField: "lastUpdated",
          },
          isDescending: true
        }
      },
      pagination: {
        defaultPage: 1,
        pageSize: PAGE_SIZE
      }
    },
  );

  const columnDefinitions: ReadonlyArray<TableProps.ColumnDefinition<Registration>> = [
    { id: "registrationId", header: "Registration ID", cell: item => <Link href={`/registrations/${item.registrationId}`}>{item.registrationId}</Link> },
    { id: "businessType", header: "Business type", cell: item => item.businessType, sortingField: "businessType" },
    { id: "marketplace", header: "Marketplace", cell: item => item.market, sortingField: "marketplace" },
    { id: "warehouseManagementSystem", header: "Warehouse management system", cell: item => item.warehouseManagementSystem, sortingField: "warehouseManagementSystem" },
    { id: "creator", header: "Creator", cell: item => item.creator, sortingField: "creator" },
    { id: "lastUpdated", header: "Last updated", cell: item => formatDate(item.lastUpdated), sortingField: "lastUpdated" }
  ];

  return (
    <Table
      {...collectionHook.collectionProps}
      loading={props.loading}
      columnDefinitions={columnDefinitions}
      header={
        <Header
          actions={<Button variant="primary" onClick={props.showNewRegistrationModal}>Create registration</Button>}
        >
          Registrations
        </Header>
      }
      items={collectionHook.items}
      filter={
        <SpaceBetween direction="horizontal" size="s">
          <Grid gridDefinition={[{ colspan: 7 }, { colspan: 5 }]}>
            <TextFilter {...collectionHook.filterProps} filteringPlaceholder="Find registration" />
            <Select
              selectedOption={selectedOption}
              options={_.concat([ALL_OPTION], props.marketplaceOptions.map(option => ({ value: option, label: option })))}
              onChange={event => {
                setSelectedOption(event.detail.selectedOption);
                props.fetchData({ "market": event.detail.selectedOption.value }, regionContext.region)
              }}
              placeholder="Marketplace"
              empty="No Registrations"
            />
          </Grid>
        </SpaceBetween>
      }
      pagination={
        <Pagination {...collectionHook.paginationProps} />
      }
    />
  );
}

export default RegistrationsTable;