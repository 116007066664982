import React from "react";
import {fetchRiverLink} from '../../../constants/FetchRiverLink';
import {fetchFosPortalLink} from '../../../constants/FetchFosportalLink';
import '../../../components/Table/./griddleTable.css';
import {RegionContext} from '@common/RegionContext';
import {getEnvironment} from '@modules/signup/Utils';

export default class MessageProvider extends React.Component {

    render() {
        let message,content;
        switch(this.props.row){
            case('0'):
                switch (this.props.condition) {
                    case('MISSEDEXSD'):
                        message = <div className = "message"><strong> Late Shipments  </strong> <small className = {"warehouseName"}> generated at: {new Date().toLocaleString()} </small></div>
                        break;
                    default:
                        message = <div className = "message"> <strong> Aging Order Details In Condition {this.props.condition} </strong> <small className = {"warehouseName"}> generated at: {new Date().toLocaleString()} </small> </div>
                }
                break;
            default:
                switch (this.props.condition) {
                    case('MISSEDEXSD'):
                        message = <div className = "message"> <strong> Late Shipments <small className = {"warehouseName"}> For warehouse {this.props.warehouseList} </small> </strong> <small className = {"warehouseName"}> generated at: {new Date().toLocaleString()} </small> </div>
                        break;
                    case('ALL'):
                        message = <div className = "message"> <strong> Aging Order Details <small className = {"warehouseName"}> For warehouse {this.props.warehouseList} </small> </strong> <small className = {"warehouseName"}> generated at: {new Date().toLocaleString()} </small> </div>
                        break;
                    default:
                        message = <div className = "message"> <strong> Aging Order Details In Condition {this.props.condition} <small className = {"warehouseName"}> For warehouse {this.props.warehouseList} ; </small> </strong> <small className = {"warehouseName"}> generated at: {new Date().toLocaleString()} </small> </div>
                }
        }
        content=<div className = "info">
                        <p id = "first"> For troubleshooting stuck shipments, please raise tickets via River with the help of following steps : <br/>
                        1) Visit : <a href={`${fetchRiverLink(this.context.region, getEnvironment())}`} className = "LinkStyle" target = "_blank" rel="noopener noreferrer"> {fetchRiverLink(this.context.region, getEnvironment())}</a> <br/>
                        2) Choose Business Type from River Menu, as per the type of FC like Third Party Logistics (3PL) or Vendor FLex (VF). To check that :<br/>
                           <p id = "second">
                            a) Go to  FOS portal : <a href = {`${fetchFosPortalLink(this.context.region, getEnvironment())}`} className = "LinkStyle" target = "_blank" rel="noopener noreferrer"> {fetchFosPortalLink(this.context.region, getEnvironment())}</a> <br/>
                            b) Enter the Node in Node Search <br/>
                            c) Once the Node Search Results are populated, under section "Other Attributes" look for "Fulfillment Node Type"
                            </p>
                            3) From the list of provided issues, select Stuck Shipment.
                        </p>
                </div>
        return (
            <div>
                {message}
                {content}
            </div>
        );
    }
}

MessageProvider.contextType = RegionContext;