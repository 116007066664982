import React from "react";

import { getHeaderCounterText, TableHeader } from "@common/table";
import { FailedDepartItem } from "@modules/transfer/common/model";

const DESCRIPTION = "All displayed times are in UTC.";

interface FailedDepartItemsTableHeaderProps {
  items: FailedDepartItem[];
}

export function FailedDepartItemsTableHeader({
  items
}: FailedDepartItemsTableHeaderProps) {
  return (
    <TableHeader
      actions={<></>}
      counterText={getHeaderCounterText(items)}
      children={<>Failed Depart Items</>}
      description={DESCRIPTION}
    />
  );
}
