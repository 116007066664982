import React, { Component } from "react";

/* Import Components */
import MultiNodeConfigUpdateForm from "./MultiNodeConfigUpdateForm";
import MultiNodeConfigUpdateTable from "./MultiNodeConfigUpdateTable";
import UnAuthorizedAccessAlert from "../../../views/layouts/UnAuthorizedAccessAlert";

class MultiNodeConfigUpdate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isButtonDisabled: false,
      nodesUpdateRequest: {
        warehouses: "",
        paramName: "",
        paramValue: ""
      }
    };
  }

  renderTables = (warehouses, paramName, paramValue) => {
    this.setState(() => ({
      warehouses: warehouses,
      paramName: paramName,
      paramValue: paramValue
    }));
  };

  render() {
    if (this.props.isAuthenticated) {
      return (
        <div>
          {this.props.isExternal && <UnAuthorizedAccessAlert/>}
          {!this.props.isExternal && <MultiNodeConfigUpdateForm
            warehouses={this.state.warehouses}
            paramName={this.state.paramName}
            paramValue={this.state.paramValue}
            renderTables={this.renderTables}
          />}
          {!this.props.isExternal && <MultiNodeConfigUpdateTable
            warehouses={this.state.warehouses}
            paramName={this.state.paramName}
            paramValue={this.state.paramValue}
          />}
        </div>
      );
    }
    this.props.history.push("/login");
    return null;
  }
}

export default MultiNodeConfigUpdate;
