export const REMOVAL_ITEM_DETAIL_METADATA = [
  {
    field: 'fnsku',
    width: '20rem',
    title: 'FNSKU'
  }, 
  {
    field: 'quantitySubmitted',
    width: '20rem',
    title: 'Quantity Submitted'
  },
  {
    field: 'quantityInProcess',
    width: '20rem',
    title: 'Quantity in Process'
  },
  {
    field: 'quantityShipped',
    width: '20rem',
    title: 'Quantity Shipped'
  },
  {
    field: 'quantityCancelled',
    width: '20rem',
    title: 'Quantity Cancelled'
  },
  {
    field: 'quantityUnavailable',
    width: '20rem',
    title: 'Quantity Unavailable'
  },
  {
    field: 'reasonCode',
    width: '20rem',
    title: 'Reason'
  }
];