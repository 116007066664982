import React from 'react';
import { useState } from "react";
import {
    Button,
    Form,
    Header,
    FormField,
    Input,
    Container,
    Box,
    Grid
} from "@amzn/awsui-components-react";
import  Auth from '@aws-amplify/auth';
import { AuthState, ChallengeName } from '@aws-amplify/ui-components';
import { useBetween } from "use-between";
import {
    useSharedUserState,
    setUserAttributes,
    useSharedUserAttributesState,
    useSharedAuthState,
} from "./LoginWithAuth";
import { AlertMessage } from "../../components/AlertMessage"
import { useHistory } from "react-router";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between"


export const OTPPage: React.FC = () => {

    const [ secretCode, setSecretCode ] = useState<string>("");
    const { setAuthState } = useBetween(useSharedAuthState)
    const [ alertMessage, setAlertMessage ] =  useState<Object>();;
    const [ loading, setLoading ] = useState<boolean>(false);
    const { user, setUser } = useBetween(useSharedUserState);
    const [ attemptsLeft, setAttemptsLeft ] = useState<number>(3);
    const { setEmail, setWarehouses } = useBetween(useSharedUserAttributesState);
    const [ userNotAuthenticated, setUserNotAuthenticated ] = useState<boolean>(false)
    const history = useHistory();

    const handleOTPBtnClick = async () => {
        setLoading(true);
        try {
            const cognitoUser = await Auth.sendCustomChallengeAnswer(user, secretCode);
            setAttemptsLeft((attemptsLeft) => attemptsLeft - 1);
            if(cognitoUser.challengeName === ChallengeName.CustomChallenge) {
                setAlertMessage(`Invalid OTP: ${secretCode} entered. ${ attemptsLeft - 1 } more attempts remaining`);
            } else {
                setAuthState(AuthState.SignedIn);
                setUserAttributes(setEmail, setWarehouses);
                setUser(cognitoUser);
                history.push("/");
            }
        } catch (err) {
            if (err.name === "NotAuthorizedException") {
                setUserNotAuthenticated(true);
                setAlertMessage(["Max OTP attempts reached. Please click ", <a href="/login">here</a>, " to retry login"]);
            }
        } finally {
            setSecretCode("");
            setLoading(false);
        }
    }

    return (
        <>
            <div>
                {/* colspan places the login container centre of the screen */}
                <Grid
                    gridDefinition={[{ colspan: 3 }, { colspan: 6 }, { colspan: 3 }]}
                >
                    <div>
                    </div>
                    
                    <div>
                    
                        <Box margin="xxl" padding="xxl" textAlign="center">
                        <SpaceBetween size="m">
                            <Container>
                                <Form
                                    header={<Header variant="h2">Enter your OTP</Header>}
                                    actions={
                                        <Button
                                            variant="primary"
                                            onClick={handleOTPBtnClick}
                                            disabled={userNotAuthenticated}
                                            loading={loading}
                                        >
                                            Enter
                                        </Button>
                                    }
                                >
                                    <FormField
                                        description={"Please enter the OTP received in the email"}
                                    >
                                        <Input
                                            value={secretCode}
                                            autoComplete={true}
                                            disabled={userNotAuthenticated}
                                            autoFocus
                                            placeholder="Enter your OTP here"
                                            onChange={(e: any) => setSecretCode(e.detail.value)}
                                            onKeyDown={(e) => {
                                                if (e.detail.key === "Enter" && !userNotAuthenticated) handleOTPBtnClick();
                                            }}
                                        />
                                    </FormField>
                                </Form>
                            </Container>

                            {attemptsLeft !== 3 && !loading && ( 
                                <AlertMessage
                                    alertMessage={alertMessage}
                                    visible={true}
                                    dismissible={false}
                                    alertType={"error"}
                                /> 
                                )}
                                </SpaceBetween>
                        </Box>
                    </div>
                </Grid >
            </div>
        </>
    )
}