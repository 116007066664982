import Modal from "@amzn/awsui-components-react/polaris/modal";
import React, {useState} from "react";
import Select from "@amzn/awsui-components-react/polaris/select";
import {OptionDefinition} from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import {CommittedOrderDetailsTable} from "@modules/inventory/research/tables/CommittedOrderDetailsTable";
import {CommittedTransferDetailsTable} from "@modules/inventory/research/tables/CommittedTransferDetailsTable";
import {CommittedRemovalDetailsTable} from "@modules/inventory/research/tables/CommittedRemovalDetailsTable";
import {CommittedGradingDetailsTable} from "@modules/inventory/research/tables/CommittedGradingDetailsTable";
import {CommittedSummaryDetailsTable} from "@modules/inventory/research/tables/CommittedSummaryDetailsTable";

interface CommittedFnSkuModalProps {
    visible: boolean,
    warehouse: string,
    fnSku: string,
    committedOrderQuantity?: number,
    committedTransferQuantity?: number,
    committedRemovalQuantity?: number,
    committedGradingQuantity?: number,
    onDismiss(value: boolean): any,
}

export const CommittedFnSkuModal = (props: CommittedFnSkuModalProps) => {
    const [selectedDropdownOption, setSelectedDropdownOption] = useState("Summary");

    const getDisplayOptions = () => {
        let displayOptions: OptionDefinition[] = [];
        displayOptions.push({label: "Summary", value: "Summary"});
        props.committedOrderQuantity !== 0 && displayOptions.push({label: "Order", value: "Order"});
        props.committedTransferQuantity !== 0 && displayOptions.push({label: "Transfer", value: "Transfer"});
        props.committedRemovalQuantity !== 0 && displayOptions.push({label: "Removal", value: "Removal"});
        props.committedGradingQuantity !== 0 && displayOptions.push({label: "Grading", value: "Grading"});
        return displayOptions;
    }

    const getCommittedSummaryTable = () => {
        return selectedDropdownOption === "Summary" &&
            <CommittedSummaryDetailsTable
                committedOrderQuantity={props.committedOrderQuantity ?? 0}
                committedTransferQuantity={props.committedTransferQuantity ?? 0}
                committedGradingQuantity={props.committedGradingQuantity ?? 0}
                committedRemovalQuantity={props.committedRemovalQuantity ?? 0}
                onSelectDropDown={(value) => setSelectedDropdownOption(value)}
            />;
    }

    const getCommittedOrderTable = () => {
        return selectedDropdownOption === "Order" &&
            <CommittedOrderDetailsTable
                fnSku={props.fnSku}
                warehouse={props.warehouse}
            />;
    }

    const getCommittedTransferTable = () => {
        return selectedDropdownOption === "Transfer" &&
            <CommittedTransferDetailsTable
                fnSku={props.fnSku}
                warehouse={props.warehouse}
            />;
    }

    const getCommittedRemovalTable = () => {
        return selectedDropdownOption === "Removal" &&
            <CommittedRemovalDetailsTable
                fnSku={props.fnSku}
                warehouse={props.warehouse}
            />;
    }

    const getCommittedGradingTable = () => {
        return selectedDropdownOption === "Grading" &&
            <CommittedGradingDetailsTable
                fnSku={props.fnSku}
                warehouse={props.warehouse}
            />;
    }

    return <div>
        <Modal
            visible={props.visible}
            onDismiss={() => props.onDismiss(false)}
        >
            <SpaceBetween size={"s"}>
                <Select
                    selectedOption={{
                        label: selectedDropdownOption,
                        value: selectedDropdownOption
                    }}
                    options={getDisplayOptions()}
                    onChange={(e) => {setSelectedDropdownOption(e.detail.selectedOption.value ?? "Summary")}}
                />
                {
                    getCommittedSummaryTable()
                }
                {
                    getCommittedOrderTable()
                }
                {
                    getCommittedTransferTable()
                }
                {
                    getCommittedRemovalTable()
                }
                {
                    getCommittedGradingTable()
                }
            </SpaceBetween>
        </Modal>
    </div>;
}