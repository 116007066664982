import {RouteComponentProps} from "react-router-dom";
import React, {useState} from "react";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import {FcSkuTrackingInput} from "@modules/fcsku/FcSkuTrackingInput";
import {FcSkuTrackingTable} from "@modules/fcsku/FcSkuTrackingTable";

interface FcSkuTrackingPageProps extends RouteComponentProps {
    isAuthenticated: boolean,
}
export const FcSkuTrackingPage = (props: FcSkuTrackingPageProps) => {
    const [renderTable, setRenderTable] = useState(false);

    if (!props.isAuthenticated) {
        props.history.push("/login");
    }

    const urlParams = new URLSearchParams(window.location.search);
    const attributeName = urlParams.get('attributeName') ?? "";
    const attributeValue = urlParams.get('attributeValue') ?? "";
    const warehouse = urlParams.get('warehouse') ?? "";
    const asin = urlParams.get('asin') ?? "";

    return <div>
        <SpaceBetween size={"xl"}>
            <FcSkuTrackingInput
                {...props}
                warehouse={warehouse}
                attributeName={attributeName}
                attributeValue={attributeValue}
                asin={asin}
                renderTable={(value) => setRenderTable(value)}
            />
            {
                renderTable &&
                <FcSkuTrackingTable
                    asin={asin}
                    warehouse={warehouse}
                    attributeValue={attributeValue}
                    attributeName={attributeName}
                />
            }
        </SpaceBetween>
    </div>;
}
