import React, { Component } from "react";
import API from "@aws-amplify/api";
import { Alert } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";

/* Importing components*/
import GriddleTable from "../../../components/Table/GriddleTable";
import { DELETE_RECEIVE_PROBLEM_ITEM_DETAIL_METADATA } from '../../../constants/table/metadata/DeleteProblemReceiveItemsColumn';
import BulkDeleteProblemReceiveSummaryDetails from './BulkDeleteProblemReceiveSummaryDetails';
import ProblemReceiveItemDeletionDetails from './model/ProblemReceiveItemDeletionDetails';
import "./BulkDeleteProblemReceiveItems.css";
import { getAuthorizationHeader } from "@common/Auth";
import {RegionContext} from '@common/RegionContext';
import {constructButaneApiName} from '../../../apiConfig';

class BulkDeleteProblemReceiveItemDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            problemItemDeletionDetails: [],
            successListState: undefined,
            fetchingRecords: false,
            errorMessage: undefined,
            openErrorSnackbar: false
        };
    }

    async deleteProblemItemList(receiveProblemItemIds, warehouseId) {
        API.del(constructButaneApiName(this.context.region), `/api/inbounds/po/warehouses/${warehouseId}/problem-items?receiveProblemItemIds=${receiveProblemItemIds}`, {
            headers: await getAuthorizationHeader()
        })
        .then((data) => {
            let itemDetails = data.receiveProblemItemStatusList;
            let successList = [];
            let problemItemDeletionDetailsList = [];
            for (let i = 0; i < itemDetails.length; i++) {
                if (itemDetails[i].receiveProblemItemDeleted === "FAILURE") {
                    let itemData = new ProblemReceiveItemDeletionDetails(
                        itemDetails[i].rpiId,
                        itemDetails[i].receiveProblemItemDeleted,
                        itemDetails[i].errorMessage
                    );
                    problemItemDeletionDetailsList.push(itemData);
                } else {
                    successList.push(itemDetails[i].rpiId);
                }
            }
            this.setState(() => ({
                problemItemDeletionDetails: problemItemDeletionDetailsList,
                fetchingRecords: false,
                errorMessage: undefined,
                successListState: successList.toString(),
            }));
        })
        .catch(error => {
            this.setState(() => ({
                problemItemDeletionDetails: [],
                successList: undefined,
                openErrorSnackbar: true,
                fetchingRecords: false,
            }));
            if(error.response) {
                this.setState(() => ({
                    errorMessage: (error.response.status === 500 && 
                        error.response.data.message === "EFProblemReceiveService or its dependency failed.") ?
                        'Something went wrong' : 'Deletion failed for given RPI Ids'
                }));
            } else {
                this.setState(() => ({
                    errorMessage: 'Something went wrong' 
                }));
            }
        });
    }

    deleteProblemItems(problemItemList, warehouseId) {
        this.setState(() => ({
            noResult: undefined,
            fetchingRecords: true
        }));
        this.deleteProblemItemList(problemItemList, warehouseId);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.problemItemList !== this.props.problemItemList || 
                prevProps.warehouseId !== this.props.warehouseId) {
            this.deleteProblemItems(this.props.problemItemList, this.props.warehouseId);
        }
    }

    handleClose = () => {
        this.setState(() => ({
            openSnackbar: false
        }));
    }

    renderDeletionSummary() {
        const columnMetadata = DELETE_RECEIVE_PROBLEM_ITEM_DETAIL_METADATA;
        return (
            <div>
                {!!this.state.errorMessage ? (
                    <Snackbar open={this.state.openErrorSnackbar} onClose={this.handleClose}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    >
                        <Alert onClose={this.handleClose} severity="error" variant="filled">
                            {this.state.errorMessage}
                        </Alert>
                    </Snackbar>)
                    : (
                        <div className="problem-item-deletion-details">
                            <BulkDeleteProblemReceiveSummaryDetails
                                successList={this.state.successListState}
                            />
                            {this.state.problemItemDeletionDetails.length > 0 &&
                                <GriddleTable
                                    results={this.state.problemItemDeletionDetails}
                                    columnMetadata={columnMetadata}
                                />}
                        </div>
                    )}
            </div>
        );
    }

    render() {
        let content;
        if (!!this.state.fetchingRecords) {
            return <div className="spinner"></div>
        }
        if (!!this.props.problemItemList && !!this.props.warehouseId && !this.state.fetchingRecords) {
            content = this.renderDeletionSummary();
        }
        return <div>{content}</div>
    }
}

BulkDeleteProblemReceiveItemDetails.contextType = RegionContext;

export default BulkDeleteProblemReceiveItemDetails;

