import { TransferManifestItemIdentifier } from "@modules/transfer/common/model";

/**
 * Model which holds the definition of a `FailedDepartItem`
 * (as defined in `TransferManifestServicePersistence`: https://tiny.amazon.com/s2pu1b3d/codeamazpackTranblobheadmain).
 */
export class FailedDepartItem {
  public readonly amazonShipmentReferenceId!: string;

  public readonly transferManifestItemIdentifier!: TransferManifestItemIdentifier;

  public readonly quantity!: number;

  public readonly failureDate!: string;

  public readonly transferManifestId!: number;

  public readonly failureReason!: string;
}
