export default {
  localhost: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_ykhMRRx3O",
    APP_CLIENT_ID: "1pn7vfg7mhbhn370354rj1vg6f",
    IDENTITY_POOL_ID: "eu-west-1:4e567ee7-fdaf-461b-bcb2-3a8aeb215e2f"
  },
  beta: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_ykhMRRx3O",
    APP_CLIENT_ID: "1pn7vfg7mhbhn370354rj1vg6f",
    IDENTITY_POOL_ID: "eu-west-1:4e567ee7-fdaf-461b-bcb2-3a8aeb215e2f"
  },
  gamma: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_icqBQG9OT",
    APP_CLIENT_ID: "74po3rlqmlvgmjikevgfjkgpn1",
    IDENTITY_POOL_ID: "us-east-1:2ab017e4-ffbf-400e-a1c4-83cc64f8ac31"
  },
  prod: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_sHCzgRtID",
    APP_CLIENT_ID: "5ct56b0c2dof85f12poo8j5m5d",
    IDENTITY_POOL_ID: "us-east-1:4602125a-f452-4c6e-9d29-3b76ea5343f4"
  },
};