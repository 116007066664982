import {TableProps} from "@amzn/awsui-components-react";
import {FcSkuInventoryTuple} from "@modules/inventory/research/model/FcSkuInventoryTuple";
import Button from "@amzn/awsui-components-react/polaris/button";
import React from "react";
import Link from "@amzn/awsui-components-react/polaris/link";

export const FcSkuInventoryTableColumnDefinition = (redirectToAudits: any, redirectToFcSkuTracking: any)
        : TableProps.ColumnDefinition<FcSkuInventoryTuple>[]  => {
    return [
        {
            id: "warehouse",
            header: "Warehouse",
            cell: e => e.warehouse,
        },
        {
            id: "fnSku",
            header: "FNSKU",
            cell: e => e.fnSku,
        },
        {
            id: "fcSku",
            header: "FCSKU",
            cell: e => (<Link onFollow={() => redirectToFcSkuTracking(e.warehouse, e.fcSku)}
            >{e.fcSku}
            </Link>),
        },
        {
            id: "inventoryCondition",
            header: "Inventory Condition",
            cell: e => e.inventoryCondition,
        },
        {
            id: "availableQuantity",
            header: "Available",
            cell: e => e.availableQuantity,
        },
        {
            id: "unavailableQuantity",
            header: "Unavailable",
            cell: e => e.unavailableQuantity,
        },
        {
            id: "problemReceiveQuantity",
            header: "Problem Receive",
            cell: e => e.problemReceiveQuantity,
        },
        {
            id: "totalQuantity",
            header: "Total",
            cell: e => e.totalQuantity,
        },
        {
            id: "actions",
            header: "Actions",
            cell: (e) => {
                let startDate: Date = new Date();
                startDate.setMonth(startDate.getMonth() - 1);
                let endDate: Date = new Date();
                return <div>
                    <Button onClick={() => redirectToAudits(e.fcSku, e.warehouse, startDate, endDate)}>
                        Audits
                    </Button>
                </div>
            }
        }
    ]
}